import api from '../api';

class Service extends api {
    save = (data) => {
        return this.api.post('/centro_custos', JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post('/centro_custos/'+id+'/update', JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.post('/centro_custos/'+id+'/delete');
    }

    getById = (id) => {
        return this.api.get('/centro_custos/busca/'+id)
    }

    getAll = (params) => {
        let req_url = '/centro_custos';
        if(params)
            req_url += this.generateQueryString(params);
        return this.api.get(req_url);

    }
}

export default Service;

