import api from '../api';

class CampaignAPIService extends api {

  getCampaigns = (params) => {
    let queryString = this.generateQueryString(params);
    let req_url = '/campanhasV2' + queryString;

    return this.api.get(req_url);
  }

  getInfoCampaign = (campaign_id) => {
    let req_url = '/campanha/' + campaign_id;

    return this.api.get(req_url);
  }

  getProductsCampaign = (campaign_id) => {
    let req_url = '/campanha/' + campaign_id + '/itens/produtos';

    return this.api.get(req_url);
  }

  getCategoriesCampaign = (campaign_id) => {
    let req_url = '/campanha/' + campaign_id + '/itens/categorias';

    return this.api.get(req_url);
  }

  getEnterpisesCampaign = (campaign_id) => {
    let req_url = '/campanha/' + campaign_id + '/empreendimentos';

    return this.api.get(req_url);
  }

  getSchedulesCampaign = (campaign_id) => {
    let req_url = '/campanha/' + campaign_id + '/agendamentos';

    return this.api.get(req_url);
  }
}

export default CampaignAPIService;
