import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	CardHeader,
	ListGroup,
	ListGroupItem
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input, InputGroup, InputMask } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import CustomModal from '../components/modal';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
	ProductsService, 
	ConfigurationsService, 
	WarehousesService,
	OPService
} from '~/services/WebliniaERPAPI';
import { numberFormat } from '~/utils/formatValue';
import { generateUUID } from '~/utils/functions';
import { useAuth } from '~/hooks/Auth';

function PageView(props) {
	const formRef = useRef(null);
	const userLoggedData = useAuth();
	const { addToast } = useToasts();

	const [isLoadingOPData, setIsLoadingOPData] = useState(false);
	const [orderData, setOrderData] = React.useState(null);
	const [modalsOpen, setModalsOpen] = React.useState({});
	const [modalFilterParams, setModalFilterParams] = React.useState([]);
	const [advancedFiltersView, setAdvancedFiltersView] = useState([{type: 'ListProduct'},{type: 'ListWarehouse'}]);
	const [advancedFilters, setAdvancedFilters] = useState({});
	const [quantity, setQuantity] = useState(0);
	const [finalQuantity, setFinalQuantity] = useState(0);
	const [productInputs, setProductInputs] = useState(null);
	const [draftMode, setDraftMode] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [hasStockErrors, setHasStockErrors] = useState(false);
	const [activeProductInput, setActiveProductInput] = useState(null);
	
	const [uuid, setUUID] = useState(generateUUID());

	useEffect(()=>{
		let urlParams = queryString.parse(props.location.search);
		if (urlParams && urlParams.id)
			loadOPData(urlParams.id);
		else
			loadDefaultWarehouse();
	},[]);

	const modalToggle = useCallback((name) => {
		switch(name) {
			case 'ListProduct':
				setModalFilterParams([{
					key: 'pro->flg_produto_composto',
					value: 1
				},{
					key: 'pro->flg_utiliza_balanca',
					value: 1
				},{
					key: 'withCombinacoes',
					value: 'true'
				}]);
				break;
		}

    	setModalsOpen({
      		...modalsOpen,
      		[name]: !modalsOpen[name]
    	});

  	}, [modalsOpen]);

	const handleSelect = useCallback((name, value, externalLoad=true) => {
		setAdvancedFilters({
			...advancedFilters,
			[name]: value
		});

		if(value && externalLoad) {
			switch(name) {
				case 'ListProduct':
					loadProductInputs(value);
					break;
			}
		}
  	}, [advancedFilters]);

	const getValueAdvancedFilter = useCallback((property, name) => {
		if(advancedFilters){
			if(advancedFilters[property])
				return advancedFilters[property][name];
			else
				return '';
		}
		return '';
  	}, [advancedFilters]);

	const handleSubmitForm = useCallback(async formData => {
		formRef.current.setErrors({});
		const schema = Yup.object().shape({
			productName: Yup.string().required('Selecione um produto'),
			quantity: Yup.string().required('Informe uma quantidade'),
			startDate: Yup.string().required('Selecione uma Data Inicial'),
			endDate: Yup.string().test("is-greater", "Deve ser maior que a inicial", function(value) {
				const { startDate } = this.parent;
				return moment(value, "yyyy-mm-dd").isSameOrAfter(moment(startDate, "yyyy-mm-dd"));
			}).required('Selecione uma Data Final')
		});
		try {
			await schema.validate(formData, { abortEarly: false });
			
			const { quantity, startDate, endDate } = formData;
			const { 
				ListProduct: { id: id_produto }, 
				ListWarehouse: { id: id_deposito } 
			} = advancedFilters;

			let _productInputs = [];
			productInputs.map(productInput => {
				let qtd_needle = (productInput.qtd * quantity);
				if (productInput.prc_perda_producao) {
					let qtd_adicional = (qtd_needle * productInput.prc_perda_producao);
					// Fração
					if (productInput.flg_unidade_fracao && parseInt(productInput.flg_unidade_fracao, 10) === 1) {
						qtd_needle += qtd_adicional;
					}
					// Unidade
					else {
						qtd_needle += Math.ceil(qtd_adicional);
					}
				}
				_productInputs.push({
					id: productInput.id,
					qtd_necessidade: qtd_needle
				});
			});

			// se for Publicar a OP e tem insumo sem estoque...
			if(!draftMode && hasItemOutOfStock()) {
				return false;
			}

			let payload = {
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				id_responsavel: userLoggedData.user.id,
				id_status: 4, // Rascunho
				id_deposito: Number(id_deposito), 
				flg_delivery: 0,
				itens: [{
					id: Number(id_produto),
					qtd: Number(quantity),
					insumos: _productInputs
				}],
				dta_inicio_previsto: startDate, 
				dta_conclusao_previsto: endDate,
				uuid
			};
			
			try {
				const opService = new OPService();
				const { data: saved_op_data } = await opService.save(payload);
				if (!draftMode) {
					scheduleOP(saved_op_data);
				}
				else {
					clearFormData(`Ordem de Produção #${saved_op_data.id} gerada com sucesso em modo rascunho!`, 'success');
				}
			}
			catch({ response }) {
				console.log('caiu aqui 2', response);
				console.log(response);
			}
		}
		catch(error) {
			console.log('caiu aqui 1', error);
			if (error instanceof Yup.ValidationError) {
				formRef.current.setErrors(getValidationErrors(error));
				return;
			}
			setIsLoading(false);
		}
	}, [advancedFilters, productInputs, draftMode, quantity]);

	const clearFormData = (message, appearance) => {
		setOrderData(null);
		setQuantity(null);
		handleSelect('ListProduct', null);
		setProductInputs(null);
		setIsLoading(false);
		setDraftMode(false);
		formRef.current.reset();
		addToast(message, {
			placement: 'bottom-right',
			appearance: appearance,
			autoDismiss: true
		});
		setUUID(generateUUID());
	}

	const hasItemOutOfStock = () => {
		let has_out_of_stock = false;
		
		let _pi = productInputs.map(pi => {
			// se for uma nova OP...
			if (!orderData) {
				pi.qtd_necessidade = (pi.qtd * quantity);

				// caso o insumo tenha margem de perda p/ produção...
				if (pi.prc_perda_producao) {
					let qtd_adicional = (pi.qtd_necessidade * pi.prc_perda_producao);
					// se a unidade de medida for fracionada, considera a perda...
					if (pi.flg_unidade_fracao && pi.flg_unidade_fracao === 1) { // Fração
						pi.qtd_necessidade += qtd_adicional;
					}
					else { // Unidade
						pi.qtd_necessidade += Math.ceil(qtd_adicional);
					}
				}
			}

			// verifica se o estoque disponível é menor que a necessidade p/ produção...
			if (pi.qtd_estoque_disponivel < pi.qtd_necessidade) {
				pi.is_out_of_stock = true;
				has_out_of_stock = true;
			}

			return pi;
		});

		if(has_out_of_stock) {
			setProductInputs(_pi);
			addToast('Não é possível publicar a O.P. pois existem insumos com estoque abaixo do necessário p/ produção!', {
				placement: 'bottom-right',
				appearance: 'error',
				autoDismiss: true
			});
		}

		return has_out_of_stock;
	}

	const loadOPData = async (id) => {
		setIsLoadingOPData(true);
		const opService = new OPService();
		try {
			const { data: order_data } = await opService.getById(id);

			switch(parseInt(order_data.id_status, 10)) {
				case 4:	// Rascunho
					order_data.cls_status_color = 'secondary';
					break;
				case 1:	// Pendente / Aguardando Produção
					order_data.cls_status_color = 'warning';
					break;
				case 5:	// Iniciada / Pendente Separação de Insumos
					order_data.cls_status_color = 'info';
					break;
				case 6:	// Iniciada / Em Separação de Insumos
					order_data.cls_status_color = 'java';
					break;
				case 2:	// Iniciada / Em Produção
					order_data.cls_status_color = 'royal-blue';
					break;
				case 3:	// Produção Finalizada
					order_data.cls_status_color = 'success';
					break;
				case 7:	// Produção Cancelada
					order_data.cls_status_color = 'danger';
					break;
			}
			
			setOrderData(order_data);

			let product = _.clone(order_data.itens[0]);
				product.nome = product.nme_produto;
				product.img = product.img_produto;
			
			console.log(product);

			handleSelect('ListProduct', product, false);

			setQuantity(parseFloat(product.qtd_item));
			setFinalQuantity(parseFloat(product.qtd_final));

			// se o status for "Iniciada / Em Produção", auto definir a qtd final
			if(parseInt(order_data.id_status, 10) === 2) {
				setFinalQuantity(product.qtd_item)
			}

			let _productInputs = product.insumos.map(productInput => {
				productInput.id = productInput.id_insumo;
				return productInput;
			});
			
			setProductInputs(_productInputs);

			let warehouse = {
				id: order_data.id_deposito,
				nme_deposito: order_data.nme_deposito
			};

			handleSelect('ListWarehouse', warehouse, false);
			setIsLoadingOPData(false);
		}
		catch (error) {
			console.log(error);
			setIsLoadingOPData(false);
		}
	}
	
	const loadDefaultWarehouse = async () => {
		const configsService = new ConfigurationsService();
		try {
			let { data: configs } = await configsService.getConfigurationByKey(userLoggedData.enterprise.id_empreendimento,  'id_deposito_padrao');
			const warehousesService = new WarehousesService();
			let { data: warehouseData } = await warehousesService.getWarehouseById(configs.id_deposito_padrao);
			setAdvancedFilters({
				...advancedFilters,
				ListWarehouse: warehouseData
			});
		}
		catch(error) {
			console.log(error);
		}
	}

	const loadProductInputs = async (product) => {
		setProductInputs([]);
		let params = [{
			key: 'tpe->id_empreendimento',
			value: userLoggedData.enterprise.id_empreendimento
		}];
		
		const productsService = new ProductsService();
		
		try {
			const { data: inputs } = await productsService.getProductInputs(product.id, params);
			setProductInputs(inputs);
		}
		catch(error) {
			setAdvancedFilters({});
			setProductInputs(null);
			addToast('O produto selecionado não possúi nenhum insumo cadastrado!', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
		}
	}

	const setProductInputStockError = () => {
		let _productInputs = productInputs.map(productInput => {
			if (productInput.id === activeProductInput.id) {
				let qtd_necessidade = parseFloat(productInput.qtd_necessidade).toFixed(4);
				if (parseFloat(activeProductInput.qtd_disponivel) >= parseFloat(qtd_necessidade)) {
					addToast('A quantidade informada é maior ou igual a necessidade.\nFavor informar que este item não tem problema de estoque!', {
						placement: 'bottom-right',
						appearance: 'warning',
						autoDismiss: true
					});
				}
				else {
					productInput = _.clone(activeProductInput);
					productInput.is_stock_ok = false;
					productInput.is_validated = true;
					setHasStockErrors(true);
				}
			}
			return productInput;
		});
		setProductInputs(_productInputs);
		setActiveProductInput(null);
	}

	const setProductInputStockOk = (_productInput) => {
		let _productInputs = productInputs.map(productInput => {
			if (productInput.id === _productInput.id) {
				productInput.is_validated = true;
				productInput.is_stock_ok = true;
			}
			return productInput;
		});
		setProductInputs(_productInputs);
	}

	// OP Functions

	const saveOPInDraftMode = () => {
		setDraftMode(true);
		setIsLoading(true);
		setTimeout(() => {
			formRef.current.submitForm();
		}, 1500);
	}

	const scheduleOP = async (op_data) => {
		setIsLoading(true);
		
		if (hasItemOutOfStock()) {
			setIsLoading(false);
			return false; // Do nothing...
		}
		else {
			const opService = new OPService();
			
			try {
				const { data } = await opService.schedule(op_data.id, {
					id_ordem_producao: op_data.id,
					id_empreendimento: userLoggedData.enterprise.id_empreendimento,
					id_usuario: userLoggedData.user.id,
					uuid: uuid
				});
				
				clearFormData(`Ordem de Produção #${op_data.id} agendada com sucesso!`, 'success');
			}
			catch({ response: { data, status } }) {
				setIsLoading(false);
				switch(status) {
					case 406: // Not Acceptable
						let _productInputs = productInputs.map(productInput => {
							data.out_estoque.forEach(out_item => {
								if (productInput.id === out_item.id_insumo) {
									productInput.is_out_of_stock = true;
									productInput.qtd_estoque_disponivel = out_item.qtd_disponivel;
								}
							});
							return productInput;
						});
						setProductInputs(_productInputs);
						addToast('Não é possível publicar a O.P. pois existem insumos com estoque abaixo do necessário p/ produção!', {
							placement: 'bottom-right',
							appearance: 'error',
							autoDismiss: true
						});
						break;
					default: // Others
						addToast(data, {
							placement: 'bottom-right',
							appearance: 'error',
							autoDismiss: true
						});
						break;
				}
				return false;
			}
		}
	}

	const publishOP = async (op_data) => {
		setIsLoading(true);
		try {
			const opService = new OPService();

			const { data } = await opService.publish(op_data.id, {
				id_ordem_producao: op_data.id,
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				id_usuario: userLoggedData.user.id,
				uuid: uuid
			});
			
			clearFormData(`Ordem de Produção #${op_data.id} publicada com sucesso!`, 'success');
		}
		catch(error) {
			setIsLoading(false);
			console.log(error);
		}
	}

	const startPickingOP = async (op_data) => {
		setIsLoading(true);

		try {
			const opService = new OPService();

			const { data } = await opService.startPicking(op_data.id, {
				id_ordem_producao: op_data.id,
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				id_usuario: userLoggedData.user.id,
				uuid: uuid
			});
			
			clearFormData(`Separação da Ordem de Produção #${op_data.id} iniciada com sucesso!`, 'success');
			
			loadOPData(op_data.id);
		}
		catch(error) {
			setIsLoading(false);
			console.log(error);
		}
	}

	const startProductionOP = async (op_data) => {
		setIsLoading(true);
		let is_all_ok = true;

		productInputs.forEach(productInput => {
			if(!productInput.is_validated || !productInput.is_stock_ok || productInput.is_out_of_stock) {
				is_all_ok = false;
			}
		});
		if (!is_all_ok) {
			addToast('Favor validar todos os itens', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
			return false;
		}

		try {
			const opService = new OPService();

			const { data } = await opService.startProduction(op_data.id, {
				id_ordem_producao: op_data.id,
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				id_usuario: userLoggedData.user.id,
				uuid: uuid
			});
			
			clearFormData(`Produção da Ordem de Produção #${op_data.id} iniciada com sucesso!`, 'success');
		}
		catch(error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	const finishProductionOP = async (op_data) => {
		setIsLoading(true);
		try {
			const opService = new OPService();

			const { data } = await opService.finishProduction(op_data.id, {
				id_ordem_producao: op_data.id,
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				id_usuario: userLoggedData.user.id,
				qtd_final: finalQuantity,
				uuid: uuid
			});
			
			clearFormData(`Produção da Ordem de Produção #${op_data.id} concluída com sucesso!`, 'success');
		}
		catch(error) {
			console.log(error);
			setIsLoading(false);
		}
	}

	const rollbackOP = async (op_data) => {
		let is_all_ok = true;

		productInputs.forEach(productInput => {
			if(!productInput.is_validated) {
				is_all_ok = false;
			}
		});

		if (!is_all_ok) {
			addToast('Favor validar todos os itens', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
			return false;
		}

		confirmAlert({
			title: 'Tem certeza?',
			message: 'Você confirma as informações estabelecidas aqui?',
			buttons: [{
				label: 'Sim',
				onClick: async () => {
					setIsLoading(true);
					try {
						const opService = new OPService();
						let payload = {
							id_ordem_producao: op_data.id,
							id_empreendimento: userLoggedData.enterprise.id_empreendimento,
							id_usuario: userLoggedData.user.id,
							itens: [{
								id: op_data.itens[0].cod_produto,
								qtd: quantity,
								insumos: productInputs
							}]
						};

						console.log(JSON.stringify(payload));

						const { data, status } = await opService.rollback(op_data.id, payload);
						
						console.log(data, status);
						
						clearFormData(`Produção da Ordem de Produção #${op_data.id} estornada com sucesso!`, 'success');
					}
					catch(error) {
						console.log(error);
						setIsLoading(false);
					}
				}
			},{
				label: 'Cancelar',
				onClick: () => alert('Click No')
			}]
		});
	}

	const openOPSheet = (op_data) => {
		window.open(`https://crn.ngweb.net.br/reports?template=production-order-sheet&id=${op_data.id}`, '_blank');
	}

	const renderDatesElems = () => {
		return (
			<>
				<span className="d-flex mb-2">
					<i className="material-icons mr-1">save</i>
					<strong className="mr-1">Dta. Criação:</strong>
					<span>{moment(orderData.dta_create).format('DD/MM/YYYY HH:mm')}</span>
				</span>

				{(!orderData.dta_inicio_realizado) ? (
					<span className="d-flex mb-2">
						<i className="material-icons mr-1">calendar_today</i>
						<strong className="mr-1">Prev. Início:</strong>
						<span>{moment(orderData.dta_inicio_previsto).format('DD/MM/YYYY')}</span>
					</span>
				) : (
					<span className="d-flex mb-2">
						<i className="material-icons mr-1">calendar_today</i>
						<strong className="mr-1">Dta. Início:</strong>
						<span>{moment(orderData.dta_inicio_realizado).format('DD/MM/YYYY HH:mm')}</span>
					</span>
				)}

				{(!orderData.dta_conclusao_realizado) ? (
					<span className="d-flex mb-2">
						<i className="material-icons mr-1">schedule</i>
						<strong className="mr-1">Prev. Conclusão:</strong>
						<span>{orderData.dta_conclusao_previsto ? moment(orderData.dta_conclusao_previsto).format('DD/MM/YYYY') : 'N/D'}</span>
					</span>
				) : (
					<span className="d-flex mb-2">
						<i className="material-icons mr-1">check_circle</i>
						<strong className="mr-1">Dta. Conclusão:</strong>
						<span>{orderData.dta_conclusao_realizado ? moment(orderData.dta_conclusao_realizado).format('DD/MM/YYYY HH:mm') : 'N/D'}</span>
					</span>
				)}

				<span></span>
			</>
		)
	}

	let img_base_url = `https://crn.ngweb.net.br`;

	return (
		<>
			<Container fluid className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle 
						title='Abertura de Ordem de Produção' 
						subtitle='Controle de Produção' 
						className='text-sm-left'
					/>
				</Row>
		
				<Form ref={formRef} onSubmit={handleSubmitForm}>
					<Row>
						<Col lg="9" md="12">
							<Row>
								<Col lg="12">
									<Card small className='mb-3'>
										<CardHeader className="border-bottom">
											<h6 className="m-0">Produto Final</h6>
										</CardHeader>

										<CardBody>
											<Row>
												{isLoadingOPData ? (
													<Col lg="12">
														<i className="fa fa-spinner fa-spin"></i>
														&nbsp;
														Aguarde, carregando informações da Ordem de Produção...
													</Col>
												) : (
													<>
														<Col lg="1">
															{(orderData && orderData.itens[0].img_produto) ? (
																<img
																	className="border rounded"
																	src={`${img_base_url}/${orderData.itens[0].img_produto.replace('/home/webliniaerp/www/', '')}`}
																	style={{ height: 70 }}
																/>
															) : (advancedFilters && advancedFilters.ListProduct && advancedFilters.ListProduct.img) ? (
																<img
																	className="border rounded"
																	src={`${img_base_url}/${advancedFilters.ListProduct.img}`}
																	style={{ height: 70 }}
																/>
															) : (
																<img
																	className="border rounded"
																	src={'https://via.placeholder.com/100x100.png?text=PRODUCT'}
																	style={{ height: 70 }}
																/>
															)}
														</Col>

														<Col lg="7">
															<InputGroup
																name="productName" 
																label="Produto a ser fabricado" 
																onClick={(orderData) ? undefined : () => modalToggle('ListProduct')}
																value={(orderData) ? orderData.itens[0].nme_produto : getValueAdvancedFilter('ListProduct', 'nome')}
																disabled
															/>
														</Col>

														<Col lg="2">
															<Input 
																name="quantity" 
																label={`${(orderData && parseInt(orderData.id_status, 10) === 4) ? 'Qtde.' : 'Qtde. Prevista'}`}
																className="text-right"
																onChange={event => setQuantity(Number(event.target.value))}
																value={quantity}
																disabled={(orderData)}
															/>
														</Col>


														{((orderData) && (parseInt(orderData.id_status, 10) === 2 || parseInt(orderData.id_status, 10) === 3)) && (
															<Col lg="2">
																<Input 
																	name="finalQuantity" 
																	label="Qtde. Final" 
																	className="text-right"
																	onChange={event => setFinalQuantity(Number(event.target.value))}
																	value={finalQuantity}
																	disabled={(orderData && parseInt(orderData.id_status, 10) === 3)}
																/>
															</Col>
														)}
													</>
												)}
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>

							{(productInputs) && (
								<Row>
									<Col lg="12">
										<Card small className='mb-3'>
											<CardHeader>
												<h6 className="m-0">Insumos p/ Produção</h6>
											</CardHeader>

											<CardBody className="p-0">
												{(!orderData) ? (
													<table className="table table-condensed table-hover mb-0">
														<thead className="bg-light">
															<th scope="col" className="text-center" width="100">Código</th>
															<th scope="col">Nome do Insumo</th>
															<th scope="col" className="text-right" width="120">Qtd</th>
															<th scope="col" className="text-right" width="100">% Perda</th>
															<th scope="col" className="text-right" width="140">Necessidade</th>
															<th scope="col" className="text-right" width="140">Disponível</th>
														</thead>
														<tbody>
															{(productInputs && productInputs.length === 0) && (
																<tr>
																	<td colSpan={6} className="text-center">
																		<i className="fa fa-spinner fa-spin"></i>
																		&nbsp;
																		Aguarde, carregando insumos...
																	</td>
																</tr>
															)}
															{productInputs && productInputs.map(productInput => {
																let color_text = 'default';
																let qtd_needle = productInput.qtd;
																let line_color = undefined;
																
																if (productInput.prc_perda_producao) {
																	let qtd_adicional = (qtd_needle * productInput.prc_perda_producao);
																	// Fração
																	if (productInput.flg_unidade_fracao && parseInt(productInput.flg_unidade_fracao, 10) === 1) {
																		qtd_needle += qtd_adicional;
																	}
																	// Unidade
																	else {
																		// Do nothing here...
																	}
																}

																if (quantity > 0) {
																	qtd_needle = (qtd_needle * quantity);
																	
																	if (productInput.prc_perda_producao) {
																		if (!productInput.flg_unidade_fracao || parseInt(productInput.flg_unidade_fracao, 10) === 0) {
																			qtd_needle += Math.ceil(qtd_needle * productInput.prc_perda_producao);
																		}
																	}

																	if (productInput.qtd_estoque_disponivel > qtd_needle) {
																		color_text = 'success';
																	}
																	else if (productInput.qtd_estoque_disponivel < qtd_needle) {
																		color_text = 'danger';
																	}
																	else {
																		color_text = 'warning';
																	}
																}

																if(productInput.is_out_of_stock)
																	line_color = 'bg-danger';

																return (
																	<tr className={line_color} key={productInput.id.toString()}>
																		<td className="text-center">{productInput.id}</td>
																		<td>{productInput.nome}</td>
																		<td className="text-right">
																			{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																				<span>{numberFormat(productInput.qtd, 10, ',', '.')}</span>
																			) : (
																				<span>{productInput.qtd}</span>
																			)}
																		</td>
																		<td className="text-right">
																			{productInput.prc_perda_producao && (
																				`${numberFormat(productInput.prc_perda_producao * 100, 2, ',', '.')}%`
																			)}
																		</td>
																		<td className={`text-right text-${color_text}`}>
																			{(quantity > 0) && (parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																				<span>{numberFormat(qtd_needle, 10, ',', '.')}</span>
																			) : (quantity > 0) ? (
																				<span>{qtd_needle}</span>
																			) : (
																				<span>N/D</span>
																			)}
																			{(quantity > 0) && (productInput.qtd_estoque_disponivel > qtd_needle) ? (
																				<i className="material-icons mr-1">check_circle</i>
																			) : (quantity > 0) && ((productInput.qtd_estoque_disponivel < qtd_needle) ? (
																				<i className="material-icons mr-1">local_fire_department</i>
																			) : (quantity > 0) && (
																				<i className="material-icons mr-1">warning</i>
																			))}
																		</td>
																		<td className="text-right">
																			{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																				<span>{numberFormat(productInput.qtd_estoque_disponivel, 10, ',', '.')}</span>
																			) : (
																				<span>{productInput.qtd_estoque_disponivel}</span>
																			)}
																		</td>
																	</tr>
																)
															})}
														</tbody>
													</table>
												) : (
													<table className="table table-condensed table-hover mb-0">
														<thead className="bg-light">
															<th scope="col" className="text-center" width="100">Código</th>
															<th scope="col">Nome do Insumo</th>
															<th scope="col" className="text-right" width="120">Qtd</th>
															<th scope="col" className="text-right" width="100">% Perda</th>
															<th scope="col" className="text-right" width="140">Necessidade</th>
															{(parseInt(orderData.id_status,10) === 4) && ( // Rascunho
																<th scope="col" className="text-right" width="140">Disponível</th>
															)}
															
															{(parseInt(orderData.id_status,10) === 6) && ( // Iniciada / Em Separação de Insumos
																<th scope="col" className="text-center" width="150">Separação</th>
															)}
														</thead>
														<tbody>
															{(productInputs && productInputs.length === 0) && (
																<tr>
																	<td colSpan={6} className="text-center">
																		<i className="fa fa-spinner fa-spin"></i>
																		&nbsp;
																		Aguarde, carregando insumos...
																	</td>
																</tr>
															)}

															{
																productInputs && productInputs.map(productInput => {
																	let color_text = '';
																	let line_color = undefined;

																	if (productInput.qtd_estoque_disponivel > (productInput.qtd_necessidade))
																		color_text = 'success';
																	else if (productInput.qtd_estoque_disponivel < (productInput.qtd_necessidade))
																		color_text = 'danger';
																	else
																		color_text = 'warning';
																	

																	if (productInput.is_validated) {
																		if (productInput.is_stock_ok)
																			line_color = 'bg-success';
																		else
																			line_color = 'bg-warning';
																	}

																	return (
																		<tr className={line_color} key={productInput.id.toString()}>
																			<td className="text-center">{productInput.id}</td>
																			<td>{productInput.nome}</td>
																			<td className="text-right">
																				{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																					<span>{numberFormat(productInput.qtd_prevista, 10, ',', '.')}</span>
																				) : (
																					<span>{productInput.qtd_prevista}</span>
																				)}
																			</td>
																			<td className="text-right">{numberFormat((productInput.prc_perda_producao * 100), 2, ',', '.')}%</td>
																			<td className="text-right">
																				{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																					<span>{numberFormat(productInput.qtd_necessidade, 10, ',', '.')}</span>
																				) : (
																					<span>{productInput.qtd_necessidade}</span>
																				)}
																			</td>
																			
																			{(parseInt(orderData.id_status,10) === 4) && ( // Rascunho
																				<td className={`text-right text-${color_text}`}>
																					{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																						<span>{numberFormat(productInput.qtd_estoque_disponivel, 10, ',', '.')}</span>
																					) : (
																						<span>{productInput.qtd_estoque_disponivel}</span>
																					)}
																				</td>
																			)}

																			{(parseInt(orderData.id_status,10) === 6) && ( // Iniciada / Em Separação de Insumos
																				<td className="text-center">
																					{
																						(!productInput.is_validated) ? (
																							<>
																								<Button 
																									theme="danger" size="sm" className="ml-auto"
																									onClick={() => setActiveProductInput(productInput)}
																								>
																									<i className="material-icons">warning</i>
																								</Button>
																								&nbsp;
																								<Button 
																									theme="success" size="sm" className="ml-auto"
																									onClick={() => setProductInputStockOk(productInput)}
																								>
																									<i className="material-icons">check_circle</i>
																								</Button>
																							</>
																						) : (!productInput.is_stock_ok) ? (
																							<span>
																								{
																									(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? 
																									numberFormat(productInput.qtd_disponivel, 10, ',', '.') : 
																									productInput.qtd_disponivel
																								}
																							</span>
																						) : undefined
																					}
																				</td>
																			)}
																		</tr>
																	)
																})
															}
														</tbody>
													</table>
												)}
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
						</Col>

						{!isLoadingOPData && (
							<Col lg="3" md="12">
								<Card small className='mb-3'>
									<CardHeader className="border-bottom">
										<h6 className="m-0">Ações</h6>
									</CardHeader>

									<CardBody className="p-0">
										<ListGroup flush>
											<ListGroupItem className="p-3">
												{(orderData && orderData.id) && (
													<span className="d-flex mb-2">
														<i className="material-icons mr-1">copyright</i>
														<strong className="mr-1">Nº O.P.:</strong>
														<strong>{orderData.id}</strong>
													</span>
												)}
												<span className="d-flex mb-2">
													<i className="material-icons mr-1">flag</i>
													<strong className="mr-1">Status:</strong>
													<strong className={(orderData) && `text-${orderData.cls_status_color}`}>{(orderData) ? orderData.dsc_status : 'Rascunho'}</strong>
												</span>
												<span className="d-flex mb-2">
													<i className="material-icons mr-1">apartment</i>
													<strong className="mr-1">Estoque:</strong>
													{(orderData) ? (
														<span>{orderData.nme_deposito}</span>
													) : (
														<strong className="text-success">{getValueAdvancedFilter('ListWarehouse', 'nme_deposito')}</strong>
													)}
													{(!orderData) && (
														<a className="ml-auto" href="#" onClick={() => modalToggle('ListWarehouse')}>
															Editar
														</a>
													)}
												</span>
												{(orderData) ? (
													<>
														{renderDatesElems()}
													</>
												) : (
													<>
														<span className="d-flex mb-2">
															<Input
																type='date'
																name='startDate'
																label="Previsão de Início"
															/>
														</span>
														<span className="d-flex">
															<Input
																type='date'
																name='endDate'
																label="Previsão de Conclusão"
															/>
														</span>
													</>
												)}
											</ListGroupItem>
											
											<ListGroupItem className="d-flex px-3">
												{isLoading ? (
													<span>
														<i className="fa fa-spinner fa-spin"></i>
														&nbsp;
														Aguarde, gravando informações...
													</span>
												) : (
													<>
														{(!orderData) ? (
															<>
																<Button outline theme="secondary" size="sm" onClick={saveOPInDraftMode}>
																	<i className="material-icons">save</i> Salvar rascunho
																</Button>
																<Button type={'submit'} theme="accent" size="sm" className="ml-auto">
																	<i className="material-icons">event_available</i> Agendar OP
																</Button>
															</>
														) : (!hasStockErrors) && (parseInt(orderData.id_status, 10) !== 3 && parseInt(orderData.id_status, 10) !== 7) && (
															<Button outline theme="danger" size="sm">
																<i className="material-icons">delete</i>
															</Button>
														)}

														{orderData && ( // Iniciada / Em Produção
															<Button 
																theme="primary" size="sm" outline className="ml-auto"
																onClick={() => openOPSheet(orderData)}
															>
																<i className="material-icons">description</i> Imprimir
															</Button>
														)}
														
														{(hasStockErrors) && (
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => rollbackOP(orderData)}
															>
																<i className="material-icons">save</i> Salvar Informações
															</Button>
														)}
														
														{(orderData && parseInt(orderData.id_status, 10) === 4) && ( // Rascunho
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => scheduleOP(orderData)}
															>
																<i className="material-icons">event_available</i> Agendar OP
															</Button>
														)}
														
														{(orderData && parseInt(orderData.id_status, 10) === 1) && ( // Pendente / Aguardando Produção
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => publishOP(orderData)}
															>
																<i className="material-icons">receipt_long</i> Publicar OP
															</Button>
														)}
														
														{(orderData && parseInt(orderData.id_status, 10) === 5) && ( // Iniciada / Pendente Separação de Insumos
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => startPickingOP(orderData)}
															>
																<i className="material-icons">inventory</i> Iniciar Separação
															</Button>
														)}
														
														{(orderData && parseInt(orderData.id_status, 10) === 6) && !hasStockErrors && ( // Iniciada / Em Separação de Insumos
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => startProductionOP(orderData)}
															>
																<i className="material-icons">play_circle_filled</i> Iniciar Produção
															</Button>
														)}
														
														{(orderData && parseInt(orderData.id_status, 10) === 2) && ( // Iniciada / Em Produção
															<Button 
																theme="accent" size="sm" className="ml-auto"
																onClick={() => finishProductionOP(orderData)}
															>
																<i className="material-icons">check_circle</i> Finalizar Produção
															</Button>
														)}
													</>
												)}
											</ListGroupItem>
										</ListGroup>
									</CardBody>
								</Card>
							</Col>
						)}
					</Row>
				</Form>
			</Container>

			<Modal open={(activeProductInput)}>
				<ModalHeader className="p-3">
					Apontar divergência no estoque
				</ModalHeader>
				<ModalBody className="p-3">
					<Form>
						<Row>
							<Col lg="3">
								<Input 
									name="codigo" label="Código" disabled
									value={(activeProductInput) ? activeProductInput.id : undefined}
								/>
							</Col>
							
							<Col lg="9">
								<Input 
									name="nome" label="Insumo" disabled
									value={(activeProductInput) ? activeProductInput.nome : undefined}
								/>
							</Col>
						</Row>

						<Row>
							<Col lg="4">
								<Input 
									name="qtd_necessidade" label="Necessidade" className="text-right" disabled
									value={
										(activeProductInput && (parseInt(activeProductInput.flg_unidade_fracao, 10) === 1)) ? 
										numberFormat(activeProductInput.qtd_necessidade, 10, ',', '.') : 
										((activeProductInput) ? activeProductInput.qtd_necessidade : undefined)
									}
								/>
							</Col>

							<Col lg="4">
								<InputMask 
									name="qtd_disponivel" label="Estoque Físico" className="text-right"
									decimalScale={4} thousandSeparator="." decimalSeparator=","
									isNumericString onValueChange={value => {
										setActiveProductInput({
											...activeProductInput, 
											['qtd_disponivel']: value.floatValue
										});
									}}
								/>
							</Col>
						</Row>
					</Form>
				</ModalBody>
				<ModalFooter className="p-3">
					<Button 
						theme="secondary" size="sm" outline
						onClick={() => setActiveProductInput(null)}
					>
						Cancelar
					</Button>
					<Button 
						theme="accent" size="sm"
						onClick={() => setProductInputStockError()}
					>
						<i className="material-icons">save</i> Salvar
					</Button>
				</ModalFooter>
			</Modal>

			{advancedFiltersView.map((item, index) => {
				if(modalsOpen[item.type]) {
					return (
						<CustomModal
							key={index.toString()}
							type={item.type}
							size="lg"
							open={modalsOpen[item.type]}
							toggle={() => {modalToggle(item.type)}}
							selectCallback={handleSelect}
							filterParams={modalFilterParams}
						/>
					)
				}
			})}
		</>
	)
}

export default PageView;