import api from '../api';

class SalesTargetService extends api {

    save = (data) => {
        return this.api.post(`/meta-vendas`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/meta-vendas/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.post(`/meta-vendas/${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/meta-vendas/${id}`);
    }

    getAll = (params) => {
        let req_url = '/metas-vendas';
        if(params)
            req_url += this.generateQueryString(params);
        return this.api.get(req_url);
    }

    getRepresentativesById = (id) => {
        return this.api.get(`/meta-vendas/${id}/representantes`);
    }
}

export default SalesTargetService;