import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  ButtonGroup
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import { CampaignAPIService } from './../services/WebliniaERPAPI/index';
import { Local, KEYS } from './../services/Storage/storage.service';
// import { CampaignModal } from '../components/modal/index';

function Campaigns({ history }){
  const [ tableDataProps, setTableDataProps ] = useState({
    tableColumns: [
      {
        dataBaseColumn: 'cam.id',
        Header: "#",
        accessor: "campaign_id",
        maxWidth: 60,
        className: "text-center",
      },
      {
        dataBaseColumn: 'cam.nme_campanha',
        Header: "Nome da Campanha",
        accessor: "campaign_name",
      },
      {
        dataBaseColumn: 'cam.dta_inicial_campanha',
        Header: "Data de Início",
        accessor: "campaign_start_date",
        className: "text-center",
        Cell: row =>
          dateFormat(new Date(row.original.campaign_start_date), "dd/mm/yyyy")
      },
      {
        dataBaseColumn: 'cam.dta_final_campanha',
        Header: "Data de Fim",
        accessor: "campaign_end_date",
        className: "text-center",
        Cell: row =>
          dateFormat(new Date(row.original.campaign_end_date), "dd/mm/yyyy")
      }
    ],
    tableData: [],
    pageSize: 10
  });
  const [ campaign, setCampaign ] = useState({
    campaign_id: null,
    campaign_action: 'new'
  });
  const [ openModal, setOpenModal ] = useState(false);

  async function getCampaigns(){
    let params = [
      { key: "tce->id_empreendimento", value: Local.getData(KEYS.USER_LOGGED_ENTERPRISE).id_empreendimento },
      { key: "columns", value: tableDataProps.tableColumns }
    ]

    const campaignService = new CampaignAPIService();
    const { data: resultData } = await campaignService.getCampaigns(params);
    let { campanhas: tableData, paginacao } = resultData;

    tableData.forEach(campaign => {
      campaign.campaign_id = campaign.campaign_id.toString();
    });

    const tableColumns = tableDataProps.tableColumns;
    tableColumns.push({
      Header: "Ações",
      accessor: "actions",
      maxWidth: 300,
      minWidth: 180,
      sortable: false,
      Cell: row => (
        <ButtonGroup size="sm" className="d-table mx-auto">
          <Button theme="white" onClick={() => handleItemConfirm(row)}>
            <i className="material-icons">&#xE5CA;</i>
          </Button>
          <Button theme="white" onClick={() => handleItemEdit(row)}>
            <i className="material-icons">&#xE254;</i>
          </Button>
          <Button theme="white" onClick={() => handleItemDelete(row)}>
            <i className="material-icons">&#xE872;</i>
          </Button>
        </ButtonGroup>
      )
    });

    setTableDataProps({
      ...tableDataProps,
      tableColumns,
      tableData
    });
  }

  function newCampaign(){
    setCampaign({
      campaign_id: null,
      campaign_action: 'new'
    });
    setOpenModal(true);
  }

  function handleItemConfirm(row) {
    alert(`Confirming transaction "${row.original.campaign_id}"!`);
  }

  function handleItemEdit(row) {
    setCampaign({
      campaign_id: row.original.campaign_id,
      campaign_action: 'edit'
    });
    setOpenModal(true);
  }

  function handleItemDelete(row) {
    alert(`Deleting transaction "${row.original.campaign_id}"!`);
  }

  function callbackModal(closeModal=false, dataObject={}){
    console.log(dataObject);
    if (closeModal)
      setOpenModal(false);
  }

  useEffect(() => {
    getCampaigns();
  }, []);

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      <Row noGutters className="page-header py-4">
        <PageTitle title="Campanhas" subtitle="Vendas" className="text-sm-left mb-3" />
        <Col className="d-flex ml-auto my-auto justify-content-end">
          <Button theme="primary" onClick={() => newCampaign()}>
            <i className="fa fa-plus"></i> Nova Campanha
          </Button>
        </Col>
      </Row>

      {/* { openModal && (
        <CampaignModal
          size="lg"
          open={openModal}
          campaign={campaign.campaign_id}
          action={campaign.campaign_action}
          toggle={callbackModal}
          backdropClassName="static" />
      )} */}

      <Card className="p-0">
        <CardHeader className="p-0">
          <Container fluid className="file-manager__filters border-bottom">
            {/* <Row>
              <Col className="file-manager__filters__rows d-flex" md="6">

              </Col>
            </Row> */}
          </Container>
        </CardHeader>
        <CardBody className="p-0">
          <div>
            <ReactTable
              columns={tableDataProps.tableColumns}
              data={tableDataProps.tableData}
              pageSize={tableDataProps.pageSize}
              showPageSizeOptions={false}
              resizable={true}
              noDataText={"Nenhum registro encontrado..."}
            />
          </div>
        </CardBody>
      </Card>
    </Container>
  )
}

export default Campaigns;
