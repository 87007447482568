import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { 
  DefaultLayout, 
  HeaderNavigation, 
  IconSidebar 
} from "./layouts";

// Route Views
import Analytics from "./views/Analytics";
import OnlineStore from "./views/OnlineStore";
import BlogOverview from "./views/BlogOverview";
import UserProfile from "./views/UserProfile";
import UserProfileLite from "./views/UserProfileLite";
import EditUserProfile from "./views/EditUserProfile";
import Login from "./views/Login";
import Register from "./views/Register";
import ForgotPassword from "./views/ForgotPassword";
import ChangePassword from "./views/ChangePassword";
import FileManagerList from "./views/FileManagerList";
import FileManagerCards from "./views/FileManagerCards";
import TransactionHistory from "./views/TransactionHistory";
import Calendar from "./views/Calendar";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import HeaderNav from "./views/HeaderNavigation";
import IconSidebarView from "./views/IconSidebar";
import Campaigns from "./views/Campaigns";
import BIView from "./views/BIView";
import ProductPriceImportView from './views/ProductPriceImportView';
import ProductSKUNetshoesImportView from './views/ProductSKUNetshoesImportView';
import SubEnterpriseView from './views/SubEnterpriseView';
import ExpensesCenterView from './views/ExpensesCenterView';
import PurchaseRequestProviderFormView from './views/PurchaseRequestProviderFormView';
import SalesTargetNewView from './views/SalesTargetNewView';
import SalesTargetListView from './views/SalesTargetListView';
import SalesComissionConsolidationView from './views/SalesComissionConsolidationView';
import SalesCommissionReportView from './views/SalesCommissionReportView';
import GoalTrackingReportView from './views/GoalTrackingReport';

import EntryTaxesReportView from './views/EntryTaxesReportView';

import OPGridView from './views/OPGridView';
import OPNewView from './views/OPNewView';
import OPPlaningView from './views/OPPlaningView';
import { GoalTrackingReportService } from "./services/WebliniaERPAPI";
import InvoiceReceivedView from './views/InvoiceReceivedView';
import CommissionCheckView from './views/CommissionCheckView';
import StoreReplenishment from './views/StoreReplenishmentView';
import DashboardIntegratedView from './views/DashboardIntegratedView';
import Providers from './views/ProvidersView';
import Manufacturers from './views/ManufacturersView';
import Accountbanking from './views/AccountBankingView';
import CreditCardMachine from './views/CreditCardMachineView';
import Importers from './views/ImportersView';

import CashFlowView from './views/CashFlowView';





const BlankIconSidebarLayout = ({ children }) => (
  <IconSidebar noNavbar noFooter noSidebar>
    {children}
  </IconSidebar>
);

export default [
  {
    path: "/",
    exact: true,
    layout: BlankIconSidebarLayout,
    component: () => <Redirect to="/login" />
  },
  {
    path: "/analytics",
    layout: DefaultLayout,
    component: Analytics
  },
  {
    path: "/ecommerce",
    layout: DefaultLayout,
    component: OnlineStore
  },
  {
    path: "/dashboard/model",
    layout: HeaderNavigation,
    component: BlogOverview
  },
  {
    path: "/user-profile",
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/edit-user-profile",
    layout: DefaultLayout,
    component: EditUserProfile
  },
  {
    path: "/login",
    layout: BlankIconSidebarLayout,
    component: Login
  },
  {
    path: "/register",
    layout: BlankIconSidebarLayout,
    component: Register
  },
  {
    path: "/forgot-password",
    layout: BlankIconSidebarLayout,
    component: ForgotPassword
  },
  {
    path: "/change-password",
    layout: BlankIconSidebarLayout,
    component: ChangePassword
  },
  {
    path: "/file-manager-list",
    layout: DefaultLayout,
    component: FileManagerList
  },
  {
    path: "/file-manager-cards",
    layout: DefaultLayout,
    component: FileManagerCards
  },
  {
    path: "/transaction-history",
    layout: DefaultLayout,
    component: TransactionHistory
  },
  {
    path: "/calendar",
    layout: DefaultLayout,
    component: Calendar
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: BlankIconSidebarLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/header-navigation",
    layout: HeaderNavigation,
    component: HeaderNav
  },
  {
    path: "/icon-sidebar-nav",
    layout: IconSidebar,
    component: IconSidebarView
  },
  {
    path: "/campaigns",
    layout: HeaderNavigation,
    component: Campaigns
  },
  {
    path: "/produtos/importacao/precos",
    layout: HeaderNavigation,
    component: ProductPriceImportView
  },
  {
    path: "/produtos/importacao/sku-netshoes",
    layout: HeaderNavigation,
    component: ProductSKUNetshoesImportView
  },
  {
    path: "/relatorios/business-intelligence",
    layout: HeaderNavigation,
    component: BIView
  },
  {
    path: "/pcp/op/grid",
    layout: HeaderNavigation,
    component: OPGridView
  },
  {
    path: "/pcp/op/new",
    layout: HeaderNavigation,
    component: OPNewView
  },
  {
    path: "/pcp/op/planing",
    layout: HeaderNavigation,
    component: OPPlaningView
  },
  {
    path: "/cadastros/sub-empreendimento",
    layout: HeaderNavigation,
    component: SubEnterpriseView
  },
  {
    path: "/cadastros/centro-custos",
    layout: HeaderNavigation,
    component: ExpensesCenterView
  },
  {
    path: "/supply-chain/request/form/provider",
    layout: HeaderNavigation,
    component: PurchaseRequestProviderFormView
  },
  {
    path: "/cadastros/metas-venda/new",
    layout: HeaderNavigation,
    component: SalesTargetNewView
  },
  {
    path: "/cadastros/metas-venda/list",
    layout: HeaderNavigation,
    component: SalesTargetListView
  },
  {
    path: "/sales/comission/consolidation",
    layout: HeaderNavigation,
    component: SalesComissionConsolidationView
  },
  {
    path: "/sales/comission/report",
    layout: HeaderNavigation,
    component: SalesCommissionReportView
  },
  {
    path: "/goal/tracking/report",
    layout: HeaderNavigation,
    component: GoalTrackingReportView
  },
  {
    path: "/report/entry/taxes",
    layout: HeaderNavigation,
    component: EntryTaxesReportView
  },
  {
    path: "/received/invoice",
    layout: HeaderNavigation,
    component: InvoiceReceivedView
  },
  {
    path: "/check/commission",
    layout: HeaderNavigation,
    component: CommissionCheckView
  },
  {
    path: "/check/store-replenishment",
    layout: HeaderNavigation,
    component: StoreReplenishment
  },
  {
    path: "/dashboard",
    layout: HeaderNavigation,
    component: DashboardIntegratedView
  },
  {
    path: "/records/providers",
    layout: HeaderNavigation,
    component: Providers
  },
  {
    path: "/records/manufacturers",
    layout: HeaderNavigation,
    component: Manufacturers
  },
  {
    path: "/records/account/banking",
    layout: HeaderNavigation,
    component: Accountbanking
  },
  {
    path: "/records/credit-card/machine",
    layout: HeaderNavigation,
    component: CreditCardMachine
  },
  {
    path: "/records/importers",
    layout: HeaderNavigation,
    component: Importers
  },
  {
    path: "/check/cash-flow",
    layout: HeaderNavigation,
    component: CashFlowView
  }

];
