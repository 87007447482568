import api from '../api';

class Service extends api {
    save = (data) => {
        return this.api.post(`/fornecedor`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/fornecedor/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.get(`/fornecedor/${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/fornecedor/${id}`)
    }

    getAll = (offset, limit, params) => {
        let req_url = `/fornecedores`;

        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;
        
        if(params)
          req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

    getEnterprisesById = (id) => {
        return this.api.get(`/fornecedor/${id}/empreendimentos`);
    }
}

export default Service;

