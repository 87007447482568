import api from '../api';

class PurchaseRequestProviderService extends api {

    save = (data) => {
        return this.api.post(`/pedido/compra/cotacao`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/pedido/compra/cotacao/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.post(`/pedido/compra/cotacao/${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/pedido/compra/cotacao/${id}`);
    }

    getItensById = (id) => {
        return this.api.get(`/pedido/compra/cotacao/${id}/itens`);
    }

    getAll = (params) => {
        let req_url = '/pedido/compra/cotacoes';
        if(params)
            req_url += this.generateQueryString(params);
        return this.api.get(req_url);
    }

}

export default PurchaseRequestProviderService;