import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react";

import moment from 'moment';
import { useAuth } from '~/hooks/Auth';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form } from '@unform/web';
import { Select } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import CustomModal from '../components/modal';

import { 
    getBaseUrlReportsOld, 
    generateQueryString 
} from '~/utils/functions';

import _ from 'lodash';
import { numberFormat } from '~/utils/formatValue';

import { 
    SalesComimissionReportService
  } from '~/services/WebliniaERPAPI';
import { isEmpty } from 'underscore';

function PageView(){
    const {user ,enterprise } = useAuth();
    
    const formRef = useRef(null);

    const [years, setYears] = useState([
        { id: 2021, nome: 2021},
        { id: 2022, nome: 2022},
        { id: 2023, nome: 2023},
        { id: 2024, nome: 2024},
        { id: 2025, nome: 2025},
        { id: 2026, nome: 2026},
        { id: 2027, nome: 2027},
        { id: 2028, nome: 2028},
        { id: 2029, nome: 2029},
        { id: 2030, nome: 2030},
        { id: 2031, nome: 2031}
    ]);
    const [months, setMonths] = useState([
        { id: '01', nome: 'Janeiro'},
        { id: '02', nome: 'Fevereiro'},
        { id: '03', nome: 'Março'},
        { id: '04', nome: 'Abril'},
        { id: '05', nome: 'Maio'},
        { id: '06', nome: 'Junho'},
        { id: '07', nome: 'Julho'},
        { id: '08', nome: 'Agosto'},
        { id: '09', nome: 'Setembro'},
        { id: '10', nome: 'Outubro'},
        { id: '11', nome: 'Novembro'},
        { id: '12', nome: 'Dezembro'}
    ]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);

    const [modalsOpen, setModalsOpen] = React.useState({});
    const [TotalVenda, setTotalVenda] = useState(false);
    const [TotalVendaMp, setTotalVendaMp] = useState(false);
    const [TotalVendaMt, setTotalVendaMt] = useState(false);
    const [TotalVendaAta, setTotalVendaAta] = useState(false);
    const [TotalCom, setTotalCom] = useState(false);
    const [TotalComMp, setTotalComMp] = useState(false);
    const [TotalComMt, setTotalComMt] = useState(false);
    const [TotalComAta, setTotalComAta] = useState(false);
    const [installments, setInstallments] = useState(null);
    const [totalInstallments, setTotalInstallments] = useState(0);
    const [isOpenModalInstallments, setIsOpenModalInstallments] = useState(false);
    const [advancedFiltersView, setAdvancedFiltersView] = useState([]);
    const [isOpenModalSales, setIsOpenModalSales] = useState(false);
    const [isOpenModalVendors, setOpenModalVendors] = useState(false);
    const [salesmanSelected, setSalesmanSelected]= useState(false);
    const [items, setItems] = useState(null);
    const [vendas, setVendas] = useState(null);
    const [parcelas, setParcelas] = useState(null);
    const [isRepresentante, setIsRepresentante] = useState(null);

    useEffect(() => {
        let isFlgRepresentante = (user.flg_representante == 1 ) ? true : false;

        if(isFlgRepresentante){
            setSalesmanSelected(user);
            setIsRepresentante(isFlgRepresentante);
        }
    }, []);
    

    const modalTogglesVend = useCallback (() => {
        setOpenModalVendors(!isOpenModalVendors);
    },[isOpenModalVendors]);
    

    const getComissoesRepresentantes = async () => {
        try {

            let params = [{
                key: 'cod_empreendimento',
                value: enterprise.id_empreendimento
            }, {
                key: 'ano_referencia',
                value: selectedYear
            }, {
                key: 'mes_referencia',
                value: selectedMonth
            }];
    
            if(salesmanSelected && !(isEmpty(salesmanSelected))) {
                params.push({
                    key: 'cod_vendedor',
                    value: salesmanSelected.id
                });
            }

            let total_venda = 0;
            let total_mp = 0;
            let total_mt = 0;
            let total_ata = 0;
            let total_com = 0;
            let total_com_mp = 0;
            let total_com_mt = 0;
            let total_com_ata = 0;

            setIsLoadingSearch(true);
            const service = new SalesComimissionReportService();
            let { data: _items } = await service.getComissoesRepresentantes(params);
            _items = _items.map(_item => {

                total_mp += _item.vlr_total_vendido_marcas_proprias;
                total_venda += _item.vlr_total_vendido;
                total_mt += _item.vlr_total_vendido_marcas_terceiros;
                total_ata += _item.vlr_total_vendido_atacado_distribuicao;
                total_com += _item.vlr_total_comissao;
                total_com_mp += _item.vlr_total_comissao_marcas_proprias;
                total_com_mt += _item.vlr_total_comissao_marcas_terceiros;
                total_com_ata += _item.vlr_total_comissao_atacado_distribuicao;

                return _item;
            });         
            setItems(_items);
            setIsLoadingSearch(false);
            
            setTotalVenda(total_venda);
            setTotalVendaMp(total_mp);
            setTotalVendaMt(total_mt);
            setTotalVendaAta(total_ata);
            setTotalCom(total_com);
            setTotalComMp(total_com_mp);
            setTotalComMt(total_com_mt);
            setTotalComAta(total_com_ata);
        }
        catch(err) {
            console.log(err);
            setIsLoadingSearch(false);
        }
    }
    
    const getVendasComissoesRepresentantes = async (id) =>{
        try{
            const service = new SalesComimissionReportService();
            let {data: _vendas} = await service.getVendasComissoesRepresentantes(id);
            _vendas = _vendas.map(_venda => {
                return _venda;
            });
            setVendas(_vendas);
        }catch(err){
            console.log(err);
        }
    }
    
    const getParcelasComissoesRepresentantes = async (id) =>{
        try{
            let meses = {};
            const service = new SalesComimissionReportService();
            let _totalInstallments = 0;
            let {data: _parcelas} = await service.getParcelasComissoesRepresentantes(id);
            _parcelas = _parcelas.map(_parcela => {
                
                let mes_referencia = moment(_parcela.dta_vencimento, 'YYYY-MM-DD').format('YYYY/MM');
                if(!meses[mes_referencia])
                    meses[mes_referencia] = _parcela.vlr_parcela;
                else
                    meses[mes_referencia] += _parcela.vlr_parcela;
                
                _totalInstallments += _parcela.vlr_parcela;

                return _parcela;
            });
            setParcelas(_parcelas);
            setInstallments(meses);
            setTotalInstallments(_totalInstallments);
        }catch(err){
            console.log(err);
        }
    }
    
    const openComissionReport = (cod_vendedor) => {
        let params = [{
            key: 'template',
            value: 'relatorio-apuracao-comissao-vendedor'
        },{
            key: 'cod_empreendimento',
            value: enterprise.id_empreendimento
        },{
            key: 'ano_referencia',
            value: selectedYear
        },{
            key: 'mes_referencia',
            value: selectedMonth
        },{
            key: 'cod_vendedor',
            value: cod_vendedor
        },];

        let qs = generateQueryString(params);

		window.open(`${getBaseUrlReportsOld()}${qs}`, '_blank');
	}

    return (
        <>
            <Container fluid className='main-content-container px-6 pb-6'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Apuração Comissão Vendedores' 
                        subtitle='Relatórios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                            <Form ref={formRef} >
                                <CardBody>
                                    <Row>
                                        <Col lg="2">
                                            <Select
                                                name='year'
                                                label='Selecione o Ano'
                                                data={years}
                                                noMargin
                                                onChange={(e) => setSelectedYear(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <Select
                                                name='month'
                                                label='Selecione o Mês'
                                                data={months}
                                                noMargin
                                                onChange={(e) => setSelectedMonth(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg="4">
                                            <label>Selecione o Representante</label>
                                            {(isRepresentante) ? (
                                                <InputGroup 
                                                    className="mb-3"
                                                >
                                                    <InputGroupAddon
                                                        style={{cursor: 'pointer'}}
                                                        name='vendedor' type='prepend'    
                                                    >
                                                        <InputGroupText>
                                                            <i className="fa fa-bars"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <FormInput placeholder="Representante" readOnly
                                                        style={{cursor: 'pointer' }}
                                                        value={salesmanSelected ? salesmanSelected.nome : ''}
                                                        defaultValue={salesmanSelected ? salesmanSelected.nome : ''}
                                                    />
                                                </InputGroup>
                                            ) : (
                                                <InputGroup 
                                                    className="mb-3"
                                                >
                                                    <InputGroupAddon
                                                        style={{cursor: 'pointer'}}
                                                        name='vendedor' type='prepend'
                                                        onClick={() =>setOpenModalVendors(true)}
                                                    >
                                                        <InputGroupText>
                                                            <i className="fa fa-bars"></i>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <FormInput placeholder="Representante" readOnly
                                                        style={{cursor: 'pointer' }}
                                                        value={salesmanSelected ? salesmanSelected.nome : ''}
                                                        defaultValue={salesmanSelected ? salesmanSelected.nome : ''}
                                                        onClick={() => setOpenModalVendors(true)}
                                                    />
                                                </InputGroup>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='d-flex ml-auto my-auto justify-content-end'>
                                            {isLoadingSearch && (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;
                                                    Aguarde, buscando informações...
                                                </>
                                            )}

                                            {!isLoadingSearch && (
                                                <>
                                                    <Button theme="primary" size="md" onClick={getComissoesRepresentantes}>
                                                        <i className="material-icons">search</i> Filtrar
                                                    </Button>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>  
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                            <CardBody>
                                <table className='table table-bordered table-condensed table-hover mb-0'>
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{ verticalAlign: 'middle' }}>Nome</th>
                                            <th rowSpan={2} className='text-center' width="120" style={{ verticalAlign: 'middle' }}>Meta</th>
                                            <th rowSpan={2} className='text-center' width="100" style={{ verticalAlign: 'middle' }}>% Meta Atingido</th>
                                            
                                            <th colSpan={4} className='text-center'>Vendas/Faturamento</th>
                                            <th colSpan={4} className='text-center'>Comissão</th>

                                            <th rowSpan={2} className='text-center' width="120"  style={{ verticalAlign: 'middle' }}>Opções</th>
                                        </tr>
                                        <tr>
                                            <th width="110" className='text-center' style={{ verticalAlign: 'middle' }}>Total</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Marcas Próprias</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Marcas Terceiros</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Atacado e Distribuição</th>

                                            <th width="110" className='text-center' style={{ verticalAlign: 'middle' }}>Total</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Marcas Próprias</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Marcas Terceiros</th>
                                            <th width="150" className='text-center' style={{ verticalAlign: 'middle' }}>Atacado e Distribuição</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items && items.map(item => (
                                            <tr>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <strong>{item.nme_vendedor}</strong>
                                                    <br/>
                                                    <small className='text-muted'>#{item.cod_vendedor}</small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat(item.vlr_meta, 2, ',', '.')}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-center'>{numberFormat(item.prc_atingido_meta, 2, ',', '.')}%</td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_vendido), 2, ',', '.')}
                                                    <br/>

                                                    {(item.vlr_total_vendido_representantes && item.vlr_total_vendido_representantes > 0) && (
                                                        <small className="text-muted">
                                                        ({numberFormat((item.vlr_total_vendido + item.vlr_total_vendido_representantes), 2, ',', '.')})
                                                        </small>
                                                    )}

                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_vendido_marcas_proprias + item.vlr_total_vendido_marcas_proprias_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat((((item.vlr_total_vendido_marcas_proprias + item.vlr_total_vendido_marcas_proprias_representantes) / ((item.vlr_total_vendido + item.vlr_total_vendido_representantes))) * 100),2,',','.')}%)
                                                    </small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_vendido_marcas_terceiros + item.vlr_total_vendido_marcas_terceiros_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat((((item.vlr_total_vendido_marcas_terceiros + item.vlr_total_vendido_marcas_terceiros_representantes) / ((item.vlr_total_vendido + item.vlr_total_vendido_representantes))) * 100),2,',','.')}%)
                                                    </small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_vendido_atacado_distribuicao + item.vlr_total_vendido_atacado_distribuicao_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat((((item.vlr_total_vendido_atacado_distribuicao + item.vlr_total_vendido_atacado_distribuicao_representantes) / ((item.vlr_total_vendido + item.vlr_total_vendido_representantes))) * 100),2,',','.')}%)
                                                    </small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_comissao + item.vlr_total_comissao_representantes), 2, ',', '.')}
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_comissao_marcas_proprias + item.vlr_total_comissao_marcas_proprias_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat(item.prc_comissao_marcas_proprias, 2, ',', '.')}%)
                                                    </small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_comissao_marcas_terceiros + item.vlr_total_comissao_marcas_terceiros_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat(item.prc_comissao_marcas_terceiros, 2, ',', '.')}%)
                                                    </small>
                                                </td>
                                                <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                                    {numberFormat((item.vlr_total_comissao_atacado_distribuicao + item.vlr_total_comissao_atacado_distribuicao_representantes), 2, ',', '.')}
                                                    <br/>
                                                    <small className="text-muted">
                                                        ({numberFormat(item.prc_comissao_atacado_distribuicao, 2, ',', '.')}%)
                                                    </small>
                                                </td> 
                                                <td>
                                                    <Button theme="secondary" size="sm" onClick={() => {
                                                        // getInstallments(item.id_vendedor);
                                                        setIsOpenModalInstallments(true);
                                                        getParcelasComissoesRepresentantes(item.id);
                                                    }}>
                                                        <i className="material-icons">money</i> Parcelas
                                                    </Button>
                                                    <br/>
                                                    <Button theme="secondary" size="sm" className="mt-1"
                                                        onClick={() => {
                                                            // getSales(item.id_vendedor);
                                                            setIsOpenModalSales(true);
                                                            getVendasComissoesRepresentantes(item.id);
                                                        }}
                                                    >
                                                        <i className="material-icons">list</i> Vendas
                                                    </Button>
                                                    <br/>
                                                    <Button theme="secondary" outline size="sm" className="mt-1"
                                                        onClick={() => openComissionReport(item.cod_vendedor)}>
                                                        <i className="fa fa-file-pdf"></i> PDF
                                                    </Button>
                                                </td> 
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th colSpan={3} class="text-right">TOTAIS:</th>
                                            <th class="text-right">{numberFormat((TotalVenda), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalVendaMp), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalVendaMt), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalVendaAta), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalCom), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalComMp), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalComMt), 2, ',', '.')}</th>
                                            <th class="text-right">{numberFormat((TotalComAta), 2, ',', '.')}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {isOpenModalVendors && 
                    <CustomModal
                        type='ListVendors'
                        size="lg"
                        open={isOpenModalVendors}
                        toggle={modalTogglesVend}
                        selectCallback={(listType, itemSelected) =>{
                            setSalesmanSelected(itemSelected);
                        }}
                        autoClose={false}
                    />
                }
            </Container>
            

            <Modal open={isOpenModalInstallments} size="sm">
                <ModalHeader className="p-3">
                    Desdobramento de Parcelas
                </ModalHeader>
                <table className='table table-bordered table-condensed table-hover mb-0'>
                    <thead>
                        <tr>
                            <th>Mês</th>
                            <th className='text-right' width="150">Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {installments && _.sortBy(Object.keys(installments)).map(month => (
                            <tr>
                                <td>{moment(month, 'YYYY-MM').format('MMMM/YYYY')}</td>
                                <td className='text-right'>R$ {numberFormat(installments[month], 2, ',', '.')}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <th className='text-center'>
                            Total
                        </th>
                        <th className='text-right'>
                            R$ {numberFormat(totalInstallments, 2, ',', '.')}
                        </th>
                    </tfoot>
                </table>
                <ModalFooter className="p-3">
                    <Button theme="accent" size="md" onClick={() => setIsOpenModalInstallments(false)}>
                    <i className="material-icons">close</i> Fechar
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal open={isOpenModalSales} size="xl">
                <ModalHeader className="p-3">
                    Desdobramento de Vendas
                </ModalHeader>
                <ModalBody className="p-0" style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
                    <table className='table table-bordered table-condensed table-hover mb-0'>
                        <thead>
                            <tr>
                                <th style={{ verticalAlign: 'middle' }} className='text-center' rowSpan={2} width="100">#</th>
                                <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Cliente</th>
                                <th style={{ verticalAlign: 'middle' }} className='text-center' rowSpan={2}  width="130">Valor</th>
                                <th className='text-center' colSpan={4}>Comissão</th>
                            </tr>
                            <tr>
                                <th className='text-center' width="130">Total</th>
                                <th className='text-center' width="130">Marcas Próprias</th>
                                <th className='text-center' width="130">Marcas Terceiros</th>
                                <th className='text-center' width="130">Atacado e Distribuição</th>
                            </tr>
                        </thead>
                        <tbody>
                            {vendas && vendas.map(venda => (
                                <tr>
                                    <td className='text-center' style={{ verticalAlign: 'middle' }}>{venda.cod_venda}</td>
                                    <td style={{ verticalAlign: 'middle' }}>
                                        <strong>{venda.cnpj ? venda.razao_social : venda.nme_cliente}</strong>
                                        <br/>
                                        <small>{venda.cnpj ? `CNPJ: ${venda.cnpj}` : `CPF: ${venda.cpf}`}</small>
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                        R$ {numberFormat(venda.vlr_total_venda, 2, ',', '.')}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                        R$ {numberFormat(venda.vlr_total_comissao, 2, ',', '.')}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                        R$ {numberFormat(venda.vlr_total_comissao_marcas_proprias, 2, ',', '.')}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                        R$ {numberFormat(venda.vlr_total_comissao_marcas_terceiros, 2, ',', '.')}
                                    </td>
                                    <td style={{ verticalAlign: 'middle' }} className='text-right'>
                                        R$ {numberFormat(venda.vlr_total_comissao_atacado_distribuicao, 2, ',', '.')}
                                    </td> 
                                </tr>
                            ))} 
                        </tbody>
                    </table>
                </ModalBody>
                <ModalFooter className="p-3">
                    <Button theme="accent" size="md" onClick={() => setIsOpenModalSales(false)}>
                        <i className="material-icons">close</i> Fechar
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default PageView