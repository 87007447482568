export default function () {
  return [{
    title: 'Dashboards',
    items: [{
      title: 'Dashboard',
      to: '/dashboard',
      htmlBefore: '<i class="material-icons">&#xE917;</i>',
      htmlAfter: '',
    }],
  },
  {
    title: 'Gerenciamento',
    items: [
      {
        title: 'Vendas',
        htmlBefore: '<i class="material-icons">&#xE889;</i>',
        open: false,
        items: [
          {
            title: 'Campanhas',
            to: '/campaigns',
          },
        ],
      },// {
      //   title: 'File Managers',
      //   htmlBefore: '<i class="material-icons">&#xE2C7;</i>',
      //   open: false,
      //   items: [{
      //     title: 'Files - List View',
      //     to: '/file-manager-list',
      //   }, {
      //     title: 'Files - Cards View',
      //     to: '/file-manager-cards',
      //   }],
      // }, {
      //   title: 'Transaction History',
      //   htmlBefore: '<i class="material-icons">&#xE889;</i>',
      //   to: '/transaction-history',
      // }, {
      //   title: 'Calendar',
      //   htmlBefore: '<i class="material-icons">calendar_today</i>',
      //   to: '/calendar',
      // }, {
      //   title: 'Add New Post',
      //   htmlBefore: '<i class="material-icons">note_add</i>',
      //   to: '/add-new-post',
      // }, {
      //   title: 'Errors',
      //   htmlBefore: '<i class="material-icons">error</i>',
      //   to: '/errors',
      // }
    ],
  }];
}
