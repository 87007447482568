import React from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import './styles.css';
import aggregators from './aggregators';
import renders from './renders';
// import {  } from 'react-pivottable/Utilities';

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);
const test = Object.assign({}, TableRenderers, PlotlyRenderers);


class ProductPivotTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = props;
    }

    change(s) {
      this.setState(s);
      this.state.onChangePivot(s);
    }

    render() {
        return (
            <PivotTableUI
              data={this.state.data}
              onChange={s => this.change(s)}
              renderers={renders}
              aggregators={aggregators}
              {...this.state}
            />
        );
    }
}

export default ProductPivotTable;
