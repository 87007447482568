import React,{useEffect, useRef} from 'react';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Button,
    Col,
    Row
} from "shards-react";
import { Form } from '@unform/web';
import { Select } from '../../unform';
import Chart from "~/utils/chart";
import { numberFormat } from '~/utils/formatValue';

function Component({title, chart_config, itens ,options, onOptionSelected, onButtonClick, buttonText, buttonIcon, isLoading}) {

    const canvasRef = useRef();

    useEffect(() => {
        if(chart_config && chart_config.datasets && chart_config.datasets.length > 0)
            buildGraph();
    }, [chart_config, canvasRef])

    const buildGraph = () => {
      const chartOptions = {
        ...{
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false
          }
        },
        ...chartOptions
      };
  
      const chartConfig = {
        ...{
          type: "doughnut",
          data: {
            ...{ labels: chart_config.labels },
            ...{ datasets: chart_config.datasets }
          },
          options: chartOptions
        },
        ...chartConfig
      };
  
      if (canvasRef && canvasRef.current) {
          let chart = new Chart(canvasRef.current, chartConfig);
      }
    }

    return (
        <Card className='mb-3'>
            <Form>
                <CardHeader className='border-bottom pl-3 '>
                    <h6>{title}</h6>
                </CardHeader>
                <CardBody>

                  {(isLoading == true) && (
                    <Row>
                        <Col sm="12" className='text-center'>
                            <h4 className="font-weight-bold" style={{fontSize : '20px', position:'relative',zIndex:'10'}}>
                                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                            </h4>
                        </Col>
                    </Row>
                  )}
                  {(isLoading == false) && (
                      <canvas
                          height={220}
                          ref={canvasRef}
                          className="blog-users-by-device m-auto"
                      />
                  )}
                </CardBody >
                <CardFooter>
                  <Row className={'pt-2'}>
                      <Col>
                          <Select
                              name='status'
                              data={options}
                              onChange={onOptionSelected}
                          />
                      </Col>
                      <Col>
                        <Button 
                            type='button'
                            theme="outline-secondary"  
                            className="pvtDropdownIcon"
                            onClick={onButtonClick}
                        >
                            {buttonText}
                            <i class={`fa ${buttonIcon}`} aria-hidden="true"></i>
                        </Button>      
                      </Col>
                  </Row>
                </CardFooter>
            </Form>
        </Card>
    );
}

export default Component;