import api, { base_url } from '../api';

class Service extends api {
    
    doExportExcel = (payload) => {

        console.log(payload);

        return this.api.post(`/export-data-excel`, JSON.stringify(payload));

    }

    downloadGeneratedFile = (file) => {
        window.open(`${base_url}/${file.filename}`,"_blank");
    }

}

export default Service;