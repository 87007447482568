import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory , NavLink} from 'react-router-dom';
import {
	Container,
	Row,
	Col,
	Card,
  CardHeader,
	CardBody,
  CardFooter,
	Button,
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input, Select } from '../components/unform';
import PageTitle from "../components/common/PageTitle";

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
  SubEnterpriseService,
  CitiesService,
  FederativesUnitsService
} from '~/services/WebliniaERPAPI';
import DefaultForm from '~/components/default-form';
import { isEmpty } from 'lodash';

function PageView(props) {

  // API Services
  const service = new SubEnterpriseService();

  // Global Vars
  const { enterprise } = useAuth();
  const { addToast } = useToasts();

  // View Controls
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState(null);
  
  // Form Fields States
  const [isAportAccount, setIsAportAccount] = useState(false);
  const [ID,  setID] = useState(null);
  const [numCnpj, setNumCnpj] = useState('');
  const [numInscricaoEstadual, setNumInscricaoEstadual] = useState('');
  const [nmeRazaoSocial, setNmeRazaoSocial] = useState('');
  const [nmeFantasia, setNmeFantasia] = useState('');
  const [numCep, setNumCep] = useState('');
  const [nmeEndereco, setNmeEndereco] = useState('');
  const [numLogradouro, setNumLogradouro] = useState('');
  const [endComplemento, setEndComplemento] = useState('');
  const [nmeBairro, setNmeBairro] = useState('');
  const [codEstado, setCodEstado] = useState('');
  const [codCidade, setCodCidade] = useState('');
  const [numTelefone, setNumTelefone] = useState('');
  const [endEmail, setEndEmail] = useState('');
  const [flgRecebeDepositoDireto, setFlgRecebeDepositoDireto] = useState(null);

  // Aux State Lists
  const [federativesUnits, setFederativesUnits] = useState(null);
  const [cities, setCities] = useState(null);

  // Forms Fields
  const formFields = [
    {
      columns: [
        {
          label: 'ID',
          cols: 1, 
          name:'ID',
          value: ID, isReadOnly: true, isVisible: (ID !== null),
        }
        
      ]
    },
    {
      columns: [
        {
          label: 'CNPJ',              
          cols: 2, 
          name:'numCnpj',
          value: numCnpj,                 
          onChangeCallback: e => setNumCnpj(e.target.value),
          isInput: true
        },
        {
          label: 'Inscrição Estadual',
          cols: 2, 
          name:'numInscricaoEstadual',
          value: numInscricaoEstadual,    
          onChangeCallback: e => setNumInscricaoEstadual(e.target.value),
          isInput: true

        },
      ]
    },  
    {
      columns: [
        {
          label: 'Razão Social',      
          cols: 6, 
          name:'nmeRazaoSocial',
          value: nmeRazaoSocial,          
          onChangeCallback: e => setNmeRazaoSocial(e.target.value),
          isInput: true

        },
        {
          label: 'Nome Fantasia',     
          cols: 6, 
          name:'n meFantasia',
          value: nmeFantasia,             
          onChangeCallback: e => setNmeFantasia(e.target.value),
          isInput: true
        },
      ]
    }, 
    {
      columns: [
        {
          label: 'CEP',               
          cols: 2, 
          name:'numCep',
          value: numCep,                  
          onChangeCallback: e => setNumCep(e.target.value),
          isInput: true
        },
        {
          label: 'Logradouro',        
          cols: 5, 
          name:'nomeEndereco',
          value: nmeEndereco,             
          onChangeCallback: e => setNmeEndereco(e.target.value),
          isInput: true
        },
        {
          label: 'Número',            
          cols: 2, 
          name:'numLogradouro',
          value: numLogradouro,           
          onChangeCallback: e => setNumLogradouro(e.target.value),
          isInput: true
        },
        {
          label: 'Bairro',            
          cols: 3, 
          name: 'nmeBairro',
          value: nmeBairro,               
          onChangeCallback: e => setNmeBairro(e.target.value),
          isInput: true
        },
      ]
    },
    {
      columns: [
        {
          label: 'Estado',            
          cols: 2, 
          name: 'codEstado',               
          onChangeCallback: e => {
            setCodEstado(e.target.value);
            loadCitiesByUF(e.target.value);
          },
          isSelect: true,
          options: federativesUnits
        },
        {
          label: 'Cidade/Município',  
          cols: 4, 
          name: 'codCidade',
          value: codCidade,               
          onChangeCallback: e => setCodCidade(e.target.value),
          isSelect: true,
          options: cities
        },
        {
          label: 'Complemento',       
          cols: 6, 
          name:'endComplemento',
          value: endComplemento,          
          onChangeCallback: e => setEndComplemento(e.target.value),
          isInput: true
        },
      ]
    },
    {
      columns: [
        {
          label: 'Nº Telefone',       
          cols: 3, 
          name:'numTelefone',
          value: numTelefone,             
          onChangeCallback: e => setNumTelefone(e.target.value),
          isInput: true
        },
        {
          label: 'E-mail',            
          cols: 6,
          name :'endEmail',
          value: endEmail,
          onChangeCallback: e => setEndEmail(e.target.value),
          isInput: true
        },
      ]
    },
    {
      columns: [
        {
          label: 'Recebe Depósitos?',
          cols: 2,
          name:'flgRecebeDepositoDireto',
          value: flgRecebeDepositoDireto,
          onChangeCallback: e => setFlgRecebeDepositoDireto(e.target.value),
          onClickCallback: () => setIsAportAccount(!isAportAccount),
          isChecked:isAportAccount,
          isCheckBox: true
        },
      ]
    }
  ];

   // Payload Structure
  const payload = [{
    num_cnpj: numCnpj,
    num_inscricao_estadual: numInscricaoEstadual,
    nme_razao_social: nmeRazaoSocial,
    nme_fantasia: nmeFantasia,
    num_cep: numCep,
    nme_endereco: nmeEndereco,
    num_logradouro: numLogradouro,
    end_complemento: endComplemento,
    nme_bairro: nmeBairro,
    cod_estado: codEstado,
    cod_cidade: codCidade,
    num_telefone: numTelefone,
    end_email: endEmail,
    flg_recebe_depositos_diretos: isAportAccount ? 1 : 0,
    empreendimentos:[
        { 
            id: enterprise.id_empreendimento 
        }
    ]                
  }];

  // On Load View 
  useEffect(() => {
      getItems();
      loadFederativesUnits();
  }, []);

  // Remote Services Actions
  const saveOrUpdate = useCallback(async (payload) => {
    try {

        setIsLoading(true);

        if (ID || !isEmpty(ID))
            var response = await service.update(ID, payload);           
        else
            var response = await service.save(payload);

        if(response){
            setIsLoading(false);

            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });

            resetFormFields();

            getItems();
        }
    }
    catch(error) {
        setIsLoading(false);

        const { response: { status, data } } = error;

        switch (status) {
        case 406:
            addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
            placement: 'bottom-right',
            appearance: 'warning',
            autoDismiss: true
            });
            break;
        case 500:
            addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
            placement: 'bottom-right',
            appearance: 'error',
            autoDismiss: true
            });
            break;
        default:
            break;
        }
    }
  }, [ isAportAccount]);

  const deleteItem = async (id) => {
    const service = new SubEnterpriseService();
    try {
        await service.delete(id);
        getItems();
        addToast(`Ação realizada com sucesso!`, {
            placement: 'bottom-right',
            appearance: 'success',
            autoDismiss: true
        });
        resetFormFields();
    }
    catch(error) {
        console.log(error);
    }
  }

  const getItems = async () => {
    setItems([]);
    try {
        let params = [
            {
            key: 'see->cod_empreendimento',
            value: enterprise.id_empreendimento
        }, {
            key: 'tse->flg_excluido',
            value: 0
        }]
        const { data: items } = await service.getAll(params);
        setItems(items);
    }
    catch(error) {
        setItems(null);
        console.log(error);
    }
  }

  // View Actions
  const updateForm = async (item) => {
    setID(item.id);
    setNumCnpj(item.num_cnpj);
    setNumInscricaoEstadual(item.num_inscricao_estadual);
    setNmeRazaoSocial(item.nme_razao_social);
    setNmeFantasia(item.nme_fantasia);
    setNumCep(item.num_cep);
    setNmeEndereco(item.nme_endereco);
    setNumLogradouro(item.num_logradouro);
    setEndComplemento(item.end_complemento);
    setNmeBairro(item.nme_bairro);
    setCodEstado(item.cod_estado);
    setCodCidade(item.cod_cidade);
    setNumTelefone(item.num_telefone);
    setEndEmail(item.end_email);
    setIsAportAccount(item.flg_recebe_depositos_diretos);
  }

  const resetFormFields = async() => {
    setID(null);
    setNumCnpj('');
    setNumInscricaoEstadual('');
    setNmeRazaoSocial('');
    setNmeFantasia('');
    setNumCep('');
    setNmeEndereco('');
    setNumLogradouro('');
    setEndComplemento('');
    setNmeBairro('');
    setCodEstado('');
    setCodCidade('');
    setNumTelefone('');
    setEndEmail('');
    setIsAportAccount(null);
  }

  // Aux Remote Services
  const loadFederativesUnits = async () => {
    setFederativesUnits(null);
    const srv = new FederativesUnitsService();
    try {
        const { data } = await srv.getAll();
        setFederativesUnits(data);
    }
    catch(error) {
        console.log(error);
    }
  }

  const loadCitiesByUF = async (idUF) => {
      setCities(null);
      const srv = new CitiesService();
      try {
          const { data } = await srv.getAll(idUF);
          setCities(data);
      }
      catch(error) {
          console.log(error);
      }
  }

  return (
    <>
      <Container fluid className='main-content-container px-4 pb-4'>
        <Row noGutters className='page-header py-4'>
          <PageTitle 
            title='Novo Sub-Empreendimento' 
            subtitle='Cadastros' 
            className='text-sm-left'
          />
        </Row>

        <Row>
          <Col lg="9">
            <Card small className='mb-3'>
              <Form>
                <CardBody>
                    <DefaultForm rows={formFields}/>
                </CardBody>

                <CardFooter className="border-top">
                  <Row>
                    {isLoading ? (
                      <Col lg="12" className="text-right">
                        <i className="fa fa-spinner fa-spin"></i>
                        &nbsp;
                        Aguarde, salvando informações...
                      </Col>
                    ) : (
                      <Col lg="12" className="text-right">
                        <Button
                          outline
                          theme="secondary"
                          size="sm"
                          onClick={() => resetFormFields()}
                        >
                            Cancelar
                        </Button>
                        &nbsp;
                        <Button 
                          onClick={() => saveOrUpdate(payload)}
                          theme="accent" 
                          size="sm" 
                          className="ml-auto"
                        >
                          <i className="material-icons">save</i> Salvar
                        </Button>
                      </Col>
                    )}
                  </Row>
                </CardFooter>
              </Form>
            </Card>
          </Col>
          
          <Col lg="3">
            <Card small className='mb-3'>
              <CardHeader className="border-bottom">
                  <h6 className="m-0">Listagem de Dados</h6>
              </CardHeader>
              <CardBody className='pt-0'>
                  <Row>
                      <Col lg="12">
                          <table className="table table-condensed table-hover mb-0">
                              <tbody>
                                  {items && items.length === 0 && (
                                      <tr>
                                          <td className='text-center' colSpan={5}>
                                              <i className="fa fa-spinner fa-spin"></i>
                                              &nbsp;
                                              Aguarde, carregando informações...
                                          </td>
                                      </tr>
                                  )}

                                  {items && items.map(item => (
                                      <tr>
                                        <td>
                                          <span onClick={() => updateForm(item)}>
                                            <small className="text-fiord-dark">
                                              {item.nme_fantasia}
                                            </small>
                                            <br/>
                                            <small className="text-secondary">
                                              {item.nme_razao_social}
                                            </small>
                                            <br/>
                                            <small className="text-secondary">
                                              {item.num_cnpj}
                                            </small>
                                            <br/>
                                            <small>
                                            # {item.id}&nbsp;•&nbsp;
                                            </small>
                                          </span>
                                          <small>
                                            <a href="#" className="text-danger" onClick={() => deleteItem(item.id)}>
                                                <i className="material-icons">delete</i> Remover
                                            </a>
                                          </small>
                                        </td>
                                      </tr>
                                  ))}
                              </tbody>
                          </table>
                      </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PageView;