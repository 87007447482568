import React from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row
} from "shards-react";
import { numberFormat } from '~/utils/formatValue';

function Component({itemTitle, itemSubtitle, imagePath, totalAmount, unitSold, markup, averagePrice}) {

    return (
        <Card className='mb-3' style={{minHeight:'100%',minWidth:'100%'}}>
            <CardBody className='pl-3 pr-3'>
                <div className='text-right'>
                    <Button 
                        disabled 
                        style={{backgroundColor:`${markup > 0 ? '#17c671' : '#c4183c'}`, borderColor: '#ffff', borderRadius: '31px / 29px'}}
                    >                        
                        {markup > 0 ? 'Positivo' : 'Negativo'}
                    </Button>
                </div>
                <div className='text-center pb-3 pt-3'>
                    {imagePath && <img src={imagePath} style={{ maxWidth: '100%', maxHeight:'200px' }}/>}
                </div>

                <Row className={'pt-2'}>
                    <Col>
                        <Row>
                            <Col>
                                <h6 className="font-weight-bold">
                                    {itemTitle}
                                    <br/>
                                    {itemSubtitle && <small className='text-muted'>{itemSubtitle}</small>}
                                </h6>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <p className='mb-0'>
                                    Faturamento Total: 
                                    <span style={{float: 'right'}}>
                                        {numberFormat(totalAmount, 2, ',', '.')}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <p className='mb-0'>
                                    Unidades Vendidas: 
                                    <span style={{float: 'right'}}>
                                        {unitSold}
                                    </span>
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p className='mb-0'>
                                    Preço Médio: 
                                    <span style={{float: 'right'}}>
                                        {numberFormat(averagePrice, 2, ',', '.')}
                                    </span>
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <p className='mb-0 font-weight-bold' style={{color:`${markup > 0 ? '#17c671' : '#c4183c'}`}}>
                                    Markup: 
                                    <span style={{float: 'right'}}>
                                        {markup}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default Component;