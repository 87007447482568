import React, { createContext, useCallback, useState, useContext } from 'react';
import { KEYS } from '../services/Storage/storage.service';
import { UsersService } from '../services/WebliniaERPAPI';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const cod_enterprise = localStorage.getItem(KEYS.USER_LOGGED_COD_ENTERPRISE);
    const user = localStorage.getItem(KEYS.USER_LOGGED);
    const enterprise = localStorage.getItem(KEYS.USER_LOGGED_ENTERPRISE);

    if (cod_enterprise && user && enterprise) {
      return { cod_enterprise, user: JSON.parse(user), enterprise: JSON.parse(enterprise) };
    }

    return {};
  });

  const signIn = useCallback(async ({ cod_enterprise ,login, password }) => {
    try {
      localStorage.setItem(KEYS.USER_LOGGED_COD_ENTERPRISE, cod_enterprise);

      const usersService = new UsersService();
      const response = await usersService.Login({
        login,
        password,
      });

      const user = response.data;

      localStorage.setItem(KEYS.USER_LOGGED, JSON.stringify(user));

      setData({ user, cod_enterprise });
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem(KEYS.USER_LOGGED_COD_ENTERPRISE);
    localStorage.removeItem(KEYS.USER_LOGGED);
    localStorage.removeItem(KEYS.USER_LOGGED_ENTERPRISE);

    setData({});
  }, []);

  const selectEnterprise = useCallback(async (id_enterprise) => {
    if(data){
      const user_id = data.user.id;

      const usersService = new UsersService();
      const response = await usersService.getEnterpriseUser(
        id_enterprise,
        user_id,
      );
      const enterprise = response.data;
      localStorage.setItem(KEYS.USER_LOGGED_ENTERPRISE, JSON.stringify(enterprise));

      await setData({ user: data.user, enterprise });
    }
  }, [data]);

  return (
    <AuthContext.Provider value={{ cod_enterprise: data.cod_enterprise, user: data.user, enterprise: data.enterprise, signIn, signOut, selectEnterprise }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
