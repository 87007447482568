import api from '../api';

class Service extends api {

    getVendedoresVendasFlgRepresentanteGrupoComissao = (id_empreendimento, date, offset, limit) => {
        let params = [{
            key: 'id_empreendimento',
            value: id_empreendimento
        }, {
            key: 'date',
            value: date
        }];

        let url_params = this.generateQueryString(params);
        
        return this.api.get(`/analise/apuracao/venda${url_params}`);
    }

    getVendedoresSemMeta = (date, offset, limit) => {
        let params = [{
            key: 'date',
            value: date
        }];

        let url_params = this.generateQueryString(params);
        
        return this.api.get(`/analise/apuracao/meta${url_params}`);
    }

    getVendedoresSemMetaComVendasNoMes = (id_empreendimento, date, offset, limit) => {
        let params = [{
            key: 'id_empreendimento',
            value: id_empreendimento
        }, {
            key: 'date',
            value: date
        }];

        let url_params = this.generateQueryString(params);
        
        return this.api.get(`/analise/apuracao/meta/mensal/${offset}/${limit}${url_params}`);
    }

    getVendasVendedoresSemMeta = (id_empreendimento, date, offset, limit) => {
        let params = [{
            key: 'id_empreendimento',
            value: id_empreendimento
        }, {
            key: 'date',
            value: date
        }];

        let url_params = this.generateQueryString(params);
        
        return this.api.get(`/analise/apuracao/meta/venda/${offset}/${limit}${url_params}`);
    }

    getVendedoresSemGerente = (offset, limit) => {
        
        return this.api.get(`/analise/apuracao/representantes/gerente`);
    }

    getVendedoresFlgRepresentante = (offset, limit) => {
        
        return this.api.get(`/analise/apuracao/representantes/flg`);
    }
    
    getRepresentantesComDoisGerentes = (offset, limit) => {
        
        return this.api.get(`/analise/apuracao/representantes/gerentes`);
    }

}

export default Service;