import api from '../api';

class ModuleAPIService extends api {
  getModules(enterprise_id, profile_id, user_id, params) {
    let req_url = '/modulos/empreendimento/' + enterprise_id + '/usuario/' + user_id;

    if (params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }

  getModulesByUserId(enterprise_id, profile_id, user_id, params) {
    let req_url = `/modulos/${enterprise_id}/null/${user_id}`;

    if (params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }

}

export default ModuleAPIService;
