import api from '../api';

class Service extends api {

    save = (data) => {
        return this.api.post(`/conta_bancaria`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/conta_bancaria/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.get(`/conta_bancaria/${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/conta_bancaria/${id}`)
    }
    
    getAll = (params, offset, limit) => {
        let req_url = '/contas_bancarias';
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;
        
        if(params)
          req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

    getAccounTypes = (params, offset, limit) => {
        let req_url = '/contas_bancarias/tipos';
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;
        
        if(params)
          req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

    

}

export default Service;