import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core';
import NumberFormat from 'react-number-format';
import PropTypes from "prop-types";

import { FormGroup, FormFeedback, FormInput } from "shards-react";

function InputMask({ name, ...rest }) {
    const inputRef = useRef(null);

    const {
        fieldName,
        registerField,
        defaultValue,
        error
    } = useField(name);

    const {
        invalid,
        label
      } = {...rest};

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value',
            setValue(ref, value){
                ref.setInputValue(value);
            },
            clearValue(ref){
                ref.setInputValue('');
            }
        })
    }, [fieldName, registerField]);

    return (
        <>
          <FormGroup>
            {label && (
              <label htmlFor={fieldName}>{label}</label>
            )}
            <NumberFormat 
                ref={inputRef}
                defaultValue={defaultValue}
                customInput={FormInput}
                invalid={invalid || !!error}
                {...rest}
            />
            {error && (
              <FormFeedback>{error}</FormFeedback>
            )}
          </FormGroup>
        </>
      );
}

InputMask.propTypes = {
    name: PropTypes.string
};

export default InputMask;