import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams} from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
    CardFooter,
    CardHeader,
	Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; //* Import css
import queryString from 'query-string';

import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import DefaultForm from '~/components/default-form';

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import {
    ProvidersService,
    FederativesUnitsService,
    CitiesService,
    BanksService,
    AccountPlansService,
    ExpensesCentersService
} from '~/services/WebliniaERPAPI';
import { isEmpty } from 'lodash';
import { isNumber } from 'underscore';
import _ from 'lodash';
import CustomModal from '../components/modal';

function PageView() {

    // API Services
    const service = new ProvidersService();

    // Global Vars
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    
    // View Controls
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisplayed, setIsDisplay] = useState(false);
    const [pagination, setPagination] = useState(null);

    // Form Fields States
    const [ID, setID] = useState(null);
    const [nmeFornecedor, setNmeFornecedor] =useState('');
    const [flgTipoPessoa, setFlgTipoPessoa] = useState('pj');
    const [nmeFantasia, setNmeFantasia] = useState('');
    const [numCNPJ, setNumCNPJ] = useState('');
    const [numCPF, setNumCPF] = useState('');
    const [numInscricaoEstadual, setNumInscricaoEstadual] = useState('');
    const [numCEP, setNumCEP] = useState('');
    const [nmeEndereco, setNmeEndereco] = useState('');
    const [numLogradouro, setNumLogradouro] = useState('');
    const [nmeBairro, setNmeBairro]  =useState('');
    const [codEstado, setCodEstado] = useState('');
    const [codCidade, setCodCidade] = useState('');
    const [endComplemento, setEndComplemento] = useState('');
    const [numTelefone1, setNumTelefone1] = useState('');
    const [numTelefone2, setNumTelefone2] = useState('');
    const [naturezaPadrao, setNaturezaPadrao] = useState('');
    const [idCentroCustos, setIdCentroCustos] = useState('');
    const [endEmail, setEndEmail] = useState('');
    const [nmeBanco, setNmeBanco] = useState('');
    const [numAgencia, setNumAgencia] = useState('');
    const [numConta, setNumConta] = useState('');
    const [idEnterpriseRef, setIdEnterpriseRef] = useState('');
    const [listModal, setListModal] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(null);
    const [activeTab, setActiveTab] = useState("form-info");
    

    //* Aux State Lists
    const [federativesUnits, setFederativesUnits] = useState(null);
    const [cities, setCities] = useState(null);
    const [banks, setBanks] = useState(null);
    const [accountPlans, setAccountPlans] = useState(null);
    const [expensesCenters, setExpensesCenters] = useState(null);

    //* Forms Fields
    const formFields = [
        {
            columns: [
                {
                    isCheckBox: true,
                    cols: 4,
                    label: 'Tipo de Cadastro',
                    value: flgTipoPessoa,
                    onChangeCallback: value => setFlgTipoPessoa(value),
                    options: [ 
                        {
                            label: 'Pessoa Fisica',
                            value: 'pf'
                        },
                        {
                            label: 'Pessoa Jurídica',
                            value: 'pj'
                        }
                    ]
                }
            ]
        },
        {
            columns: [
                {
                    isInput: true,
                    label: 'CPF',
                    cols: 2,
                    name: 'numCPF',
                    value: numCPF,
                    onChangeCallback: e => setNumCPF(e.target.value),
                    isVisible: (flgTipoPessoa === 'pf')
                },
                {
                    isInput: true,
                    label: 'Nome Fornecedor',
                    cols: 6,
                    name: 'nmeFornecedor',
                    value: nmeFornecedor,
                    onChangeCallback: e => setNmeFornecedor(e.target.value),
                    isVisible: (flgTipoPessoa === 'pf')
                }
            ]
        },
        {
            columns: [
                {
                    isInput: true,
                    label: 'CNPJ',
                    cols: 2,
                    name: 'numCPNJ',
                    value: numCNPJ,
                    onChangeCallback: e => setNumCNPJ(e.target.value),
                    isVisible: (flgTipoPessoa === 'pj')
                },
                {
                    isInput: true,
                    label: 'I.E.',
                    cols: 2,
                    name: 'numIncricaoEstadual',
                    value: numInscricaoEstadual,
                    onChangeCallback: e => setNumInscricaoEstadual(e.target.value),
                    isVisible: (flgTipoPessoa === 'pj')
                }
            ]
        },
        {
            columns: [
                {
                    isInput: true,
                    label: 'Razão Social', 
                    cols: 6, 
                    name: 'nmeRazaoSocial', 
                    value: nmeFornecedor,
                    onChangeCallback: e => setNmeFornecedor(e.target.value),
                    isVisible: (flgTipoPessoa === 'pj')
                }, 
                {
                    isInput: true,
                    label: 'Nome Fantasia',
                    cols: 6,
                    name: 'nmeFantasia',
                    value: nmeFantasia,
                    onChangeCallback: e => setNmeFantasia(e.target.value),
                    isVisible: (flgTipoPessoa === 'pj')
                }
            ]
        },
        {
            columns: [
                {
                    isInput: true, 
                    label: 'CEP',
                    cols: 2,
                    name: 'numCEP',
                    value: numCEP,
                    onChangeCallback: e => setNumCEP(e.target.value),
                },
                {
                    isInput: true,
                    label: 'Logradouro',
                    cols: 5,
                    name: 'nmeEndereco',
                    value: nmeEndereco,
                    onChangeCallback: e => setNmeEndereco(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'Número',
                    cols: 2,
                    name: 'numLagradouro',
                    value: numLogradouro,
                    onChangeCallback: e => setNumLogradouro(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'Bairro',
                    cols: 3,
                    name: 'nmeBairro',
                    value: nmeBairro,
                    onChangeCallback: e => setNmeBairro(e.target.value),
                },
            ]
        },
        {
            columns: [
                {
                    isSelect: true,
                    options: federativesUnits,
                    label: 'Estado',
                    cols: 3,
                    name: 'codEstado',
                    value: codEstado,
                    onChangeCallback: e => {
                        setCodEstado(e.target.value);
                        loadCitiesByUF(e.target.value);
                    },
                },
                {
                    isSelect: true,
                    label: 'Cidade',
                    cols: 4,
                    name: 'codCidade',
                    value: codCidade,
                    onChangeCallback: e => setCodCidade(e.target.value),
                    options: cities
                },
                {
                    isInput: true, 
                    label: 'Complemento: ',
                    cols: 5,
                    name: 'endComplemento',
                    value: endComplemento,
                    onChangeCallback: e => setEndComplemento(e.target.value),
                },
            ]
        },
        {
            columns: [
                {
                    isInput: true,
                    label: 'Telefone 1',
                    cols: 2,
                    name: 'numTelefone1',
                    value: numTelefone1,
                    onChangeCallback: e => setNumTelefone1(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'Telefone 2',
                    cols: 2,
                    name: 'numTelefone2',
                    value: numTelefone2,
                    onChangeCallback: e => setNumTelefone2(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'Email',
                    cols: 6,
                    name: 'endEmail',
                    value: endEmail,
                    onChangeCallback: e => setEndEmail(e.target.value),
                },
            ]
        },
        {
            columns: [
                {
                    isFieldset: true,
                    label: 'Contas a Pagar/Receber',
                    cols: 12,
                    
                }
            ]
        },
        {
            columns: [
                {
                    isSelect: true,
                    label: 'Natureza Padrão',
                    cols: 6,
                    name: 'naturezaPadrao',
                    value: naturezaPadrao,
                    onChangeCallback: e => setNaturezaPadrao(e.target.value),
                    options: accountPlans
                },
                {
                    isSelect: true,
                    label: 'Centro de Custos',
                    cols: 3,
                    name: 'idCentroCustos',
                    value: idCentroCustos,
                    onChangeCallback: e => setIdCentroCustos(e.target.value),
                    options: expensesCenters
                },
                {
                    isInput: true,
                    label: 'ID Empreendimento Ref.',
                    cols: 3,
                    name: 'idEnterpriseRef',
                    value: idEnterpriseRef,
                    onChangeCallback: e => setIdEnterpriseRef(e.target.value),
                },
            ]
        },
        {
            columns: [
                {
                    isSelect: true,
                    label: 'Banco',
                    cols: 5,
                    name: 'nmeBanco',
                    value: nmeBanco,
                    onChangeCallback: e => setNmeBanco(e.target.value),
                    options: banks
                },
                {
                    isInput: true,
                    label: 'Agência',
                    cols: 2,
                    name: 'numAgencia',
                    value: numAgencia,
                    onChangeCallback: e => setNumAgencia(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'C/C',
                    cols: 2,
                    name: 'numConta',
                    value: numConta,
                    onChangeCallback: e => setNumConta(e.target.value),
                }
            ]
        },
    ];

    //* Payload Structure
    const payload = {
        id: ID,
        id_empreendimento: enterprise.id_empreendimento,
        nme_fantasia: nmeFantasia,
        nome_fornecedor: nmeFornecedor,
        num_cnpj: numCNPJ,
        num_cpf: numCPF,
        num_inscricao_estadual: numInscricaoEstadual,
        num_cep: numCEP,
        nme_endereco: nmeEndereco,
        num_logradouro: numLogradouro,
        nme_bairro: nmeBairro,
        cod_estado: codEstado,
        cod_cidade: codCidade,
        end_complemento: endComplemento,
        id_plano_contas_padrao: naturezaPadrao,
        id_centro_custos: idCentroCustos,
        email: endEmail,
        id_banco: nmeBanco,
        num_agencia: numAgencia,
        num_conta: numConta,
        id_empreendimento_referencia: idEnterpriseRef,
        empreendimentos: listModal,
        tipo_cadastro: flgTipoPessoa,
        flg_excluido: 0,
        telefones: [
            {
                id: null,
                id_referencia: ID,
                num_telefone: numTelefone1,
                tbl_referencia:'tbl_fornecedores'
            },
            {
                id: null,
                id_referencia: ID,
                num_telefone: numTelefone2,
                tbl_referencia:'optionstbl_fornecedores'
            }
        ]
    };

    //* On Load View 
    useEffect(() => {
        getItems(0);
        loadFederativesUnits();
        loadBanks();
        loadAccountPlans();
        loadExpensesCenters();
    }, []);

    //* Modal View
    const toggleModal = (modal) => {
        switch(modal) {
            case 'enterprises':
                setIsOpenModal(!isOpenModal);
                break;
        }
    }

    //* Remote Services Actions
    const saveOrUpdate = useCallback(async (payload) => {
        try {

            let canSave = true;

            if(!(listModal) || listModal.length <= 0){
                canSave = false;
                addToast('Por favor adicionar um empreendimento', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
            }

            if(canSave){
                setIsLoading(true);
    
                if (ID || !isEmpty(ID))
                    var response = await service.update(ID, payload);          
                else
                    var response = await service.save(payload);
    
                if(response){
                    setIsLoading(false);
        
                    addToast(`Ação realizada com sucesso!`, {
                        placement: 'bottom-right',
                        appearance: 'success',
                        autoDismiss: true
                    });
        
                    resetFormFields();
    
                    getItems(0);
                }
            }

        }
        catch(error) {
            setIsLoading(false);

            const { response: { status, data } } = error;

            switch (status) {
            case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
                break;
            case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
                });
                break;
            default:
                break;
            }
        }
    },[payload]);

    const deleteItem = async (id) => {
        try {
            await service.delete(id);
            getItems(0);
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            resetFormFields();
        }
        catch(error) {
            console.log(error);
        }
    }

    const getItems = async (offset) => {
        setItems([]);
        try {
            let params = [{
                key: 'frn->id_empreendimento',
                value: enterprise.id_empreendimento
            }];
            const { data: { fornecedores, paginacao } } = await service.getAll(offset, 5, params);
            setItems(fornecedores);
            setPagination(paginacao);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }

    const getItemsEnterprises =  async (id) => {
        try {
            let {data : _enterprise} = await service.getEnterprisesById(id);

            if(_enterprise && _enterprise.length > 0){
                setListModal(_enterprise);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //* View Actions
    const updateForm = async (item) => {
        setID(item.id);
        setNmeFornecedor(item.nome_fornecedor);
        setNmeFantasia(item.nme_fantasia);
        setNumCNPJ(item.num_cnpj);
        setNumCPF(item.num_cpf);
        setNumInscricaoEstadual(item.num_inscricao_estadual);
        setNumCEP(item.num_cep);
        setNmeEndereco(item.nme_endereco);
        setNumLogradouro(item.num_logradouro);
        setNmeBairro(item.nme_bairro);
        setCodEstado(item.cod_estado);
        setCodCidade(item.cod_cidade);
        setEndComplemento(item.end_complemento);
        setNumTelefone1(item.num_telefone1);
        setNumTelefone2(item.num_telefone2);
        setNaturezaPadrao(item.id_plano_contas_padrao);
        setIdCentroCustos(item.id_centro_custos);
        setEndEmail(item.email);
        setNmeBanco(item.id_banco);
        setNumAgencia(item.num_agencia);
        setNumConta(item.num_conta);
        setIdEnterpriseRef(item.id_empreendimento_referencia);
        loadCitiesByUF(item.cod_estado);
        getItemsEnterprises(item.id);
    }

    const resetFormFields = async () => {
        setID(null);
        setNmeFantasia('');
        setNmeFornecedor('');
        setNumCNPJ('');
        setNumCPF('');
        setNumInscricaoEstadual('');
        setNumCEP('');
        setNmeEndereco('');
        setNumLogradouro('');
        setNmeBairro('');
        setCodEstado('');
        setCodCidade('');
        setEndComplemento('');
        setNumTelefone1('');
        setNumTelefone2('');
        setNaturezaPadrao('');
        setIdCentroCustos('');
        setEndEmail('');
        setNmeBanco('');
        setNumAgencia('');
        setNumConta('');
        setIdEnterpriseRef('');
        setListModal([]);
        
    }

    const addItemModal = useCallback(async (name, item) => {
        if(item) {
          try{
            // clonando p/ poder manipular
            let _listModal = _.clone(listModal);
            
            // inicializando o array, caso esteja null ou undefined
            if(!_listModal)
              _listModal = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if(_listModal.length > 0) {
              _listModal.forEach(emp => {
                if(emp.id === item.id) {
                    can_add = false;
                    addToast(`Esse item já contem na listagem!`, {
                        placement: 'bottom-right',
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
              })
            }
    
            // add apenas se não estiver no array
            if(can_add)
              _listModal.push(item);

            _listModal = _listModal
            _listModal = _.sortBy(_listModal, (_emp) => { return _emp.id });
    
            // atualiza o state
            setListModal(_listModal);

          }catch(err){
              console.log(err);
          }
        }else{
          setIsOpenModal(false);
        }
    },[listModal]);
    
    const delItemModal = async (index) =>{
        let _listModal = _.clone(listModal);
        if(_listModal)
            _listModal.splice(index, 1);
        setListModal(_listModal);
    }

    //* Aux Remote Services
    const loadFederativesUnits = async () => {
        setFederativesUnits(null);
        const srv = new FederativesUnitsService();
        try {
            const { data } = await srv.getAll();
            setFederativesUnits(data);
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadCitiesByUF = async (idUF) => {
        setCities(null);
        const srv = new CitiesService();
        try {
            const { data } = await srv.getAll(idUF);
            setCities(data);
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadBanks = async () => {
        setBanks(null);
        const srv = new BanksService();
        try {
            const { data } = await srv.getAll();
            setBanks(data.bancos);
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadAccountPlans = async () => {
        setAccountPlans(null);
        const srv = new AccountPlansService();
        try {
            let params = [{
                key: 'tpce->id_empreendimento',
                value: enterprise.id_empreendimento
            }];
            const { data } = await srv.getAll(params);
            setAccountPlans(data.map(item => {
                item.nome = item.dsc_completa;
                return item;
            }));
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadExpensesCenters = async () => {
        setExpensesCenters(null);
        const srv = new ExpensesCentersService();
        try {
            let params = [{
                key: 'tcc->flg_excluido',
                value: 0
            }];
            const { data } = await srv.getAll(params);
            setExpensesCenters(data.map(item => {
                item.nome = item.nme_centro_custos;
                return item;
            }));
        }
        catch(error) {
            console.log(error);
        }
    }


    return (
        <>
            <Container fluid className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                       title='Fornecedores' 
                       subtitle='Cadastros' 
                       className='text-sm-left' 
                    />
                    <Col xs="12" sm="" className="col d-flex align-items-center">
						&nbsp;
					</Col>
                </Row>

                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="12">
                                <Card small className='mb-3'>
                                    <Form>
                                        <CardBody>
                                            <Row>
                                                <Col lg='12' className=' pb-1'>
                                                    <div className="border-bottom clearfix d-flex ">
                                                            <Nav tabs className="border-0 mt-auto ml-0 pt-2">
                                                                <NavItem>
                                                                    <NavLink 
                                                                        active={(activeTab && activeTab === 'form-info')}
                                                                        onClick={() => setActiveTab('form-info')}
                                                                    >
                                                                        Produtos
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink 
                                                                        active={(activeTab && activeTab === 'enterprise-info')}
                                                                        onClick={() => setActiveTab('enterprise-info')}
                                                                    >
                                                                        Empreendimentos
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                    </div>
                                                </Col>
                                            
                                                {activeTab && activeTab === 'form-info' && (
                                                    
                                                    <Col sm='12'>
                                                        <DefaultForm rows={formFields} />    
                                                    </Col>
                                                                                
                                                )}
                                                {activeTab && activeTab === 'enterprise-info' && (
                                                    <>
                                                        <Col lg='4'>
                                                            <div style={{ maxHeight: '100%', overflowY: (listModal.length > 5) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                <table className='table table-bordered table-hover'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ verticalAlign: 'middle' }}>
                                                                                Empreendimentos
                                                                            </th>
                                                                            <th width='100' className='text-center'>
                                                                                <Button 
                                                                                    size='xs'
                                                                                    theme='success'
                                                                                    onClick={() => toggleModal('enterprises')}
                                                                                >
                                                                                    <i class="fa fa-plus-circle"></i> Incluir
                                                                                </Button>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listModal && listModal.map((item, index) =>(
                                                                        <tr>
                                                                            <td>
                                                                                <small>{item.nome_empreendimento}</small>
                                                                            </td>
                                                                            <td className='text-right'>
                                                                                <Button 
                                                                                    size='xs'
                                                                                    theme='danger'
                                                                                    onClick={() => delItemModal(index)}
                                                                                >
                                                                                    <i className="material-icons">delete</i> Remover
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>  
                                                        </Col>  
                                                    </>                            
                                                )}
                                            </Row>
                                        </CardBody>
                                            
                                        <CardFooter className="border-top">
                                            <Row>
                                                {isLoading ? (
                                                    <Col lg="12" className="text-right">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, salvando informações...
                                                    </Col>
                                                ) : (

                                                    <Col lg="12" className="text-right">
                                                        <Button outline theme="secondary" size="sm" onClick={() => resetFormFields()}
                                                        >
                                                            <i class="fa-solid fa-circle-x"></i>Cancelar
                                                        </Button>
                                                        &nbsp;
                                                        <Button 
                                                            theme="accent" 
                                                            size="sm" 
                                                            className="ml-auto" 
                                                            onClick={() => saveOrUpdate(payload)}
                                                        >
                                                            <i className="material-icons">save</i>Salvar
                                                        </Button>
                                                    </Col>
                                                )}
                                            
                                            </Row>
                                        </CardFooter>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3">
                        <Row>
                           <Col sm='12'>
                                <Card small className='mb-3'>
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">
                                            <i className="fas fa-list"></i> Itens Cadastrados
                                        </h6>
                                    </CardHeader>

                                    <CardBody className='pt-0'>
                                        <Row>
                                            <Col lg="12">
                                                <table className="table table-condensed table-hover mb-0">
                                                    <tbody>
                                                        {items && items.length === 0 && (
                                                            <tr>
                                                                <td className='text-center' colSpan={5}>
                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                    &nbsp;
                                                                    Aguarde, carregando informações...
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {items && items.map(item => ( 
                                                            <tr>
                                                                <td> 
                                                                    <span onClick={() => updateForm(item)}>
                                                                        <small className="text-fiord-dark">
                                                                            {(item.nome_fornecedor) ? item.nome_fornecedor : item.nme_fantasia}
                                                                        </small>
                                                                        <br/>
                                                                        {item.num_cpf && <small className="text-secondary">{item.num_cpf}</small>}
                                                                        {item.num_cnpj && <small className="text-secondary">{item.num_cnpj}</small>}
                                                                        {item.num_cpf || item.num_cnpj && <br/>}
                                                                        <small className='text-muted'>
                                                                            #{item.id}
                                                                        </small>
                                                                        &nbsp;•&nbsp;
                                                                    </span>
                                                                    <small>
                                                                        <a href="#" className='text-danger' onClick={() => deleteItem(item.id)}>
                                                                            <i className="material-icons">delete</i> Remover
                                                                        </a>
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>

                                        {pagination && (
                                            <Row>
                                                <Col sm="12" className="text-center">
                                                    <ButtonGroup>
                                                        {pagination.map((value, index) => (
                                                            <Button
                                                                key={index} 
                                                                size='xs'
                                                                theme={value.current ? 'primary' : 'white'}
                                                                onClick={() => getItems(value.offset)}
                                                            >
                                                                {value.index}
                                                            </Button>
                                                        ))}
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                           </Col>
                        </Row>
                    </Col>
                </Row>


                {isOpenModal && 
                    <CustomModal
                        type='ListEnterprises'
                        size="lg"
                        open={isOpenModal}
                        toggle={() => toggleModal('enterprises')}
                        selectCallback={(type, item) => addItemModal(type, item)} 
                        autoClose={false}
                    />
                }
                
            </Container>
        </>
    );
}

export default PageView
