import React, { useCallback, useRef, useEffect, useState } from 'react'
import { NavLink, Link } from "react-router-dom";
import { Form } from '@unform/web';
import {
	Container,
	Row,
	Col,
	Card,
	Button,
    FormInput,
	CardHeader,
	CardBody,
    Badge
} from 'shards-react';
import { Input, InputGroup, InputMask } from '../components/unform';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash';
import { useToasts } from 'react-toast-notifications';
import PageTitle from '../components/common/PageTitle';
import FileUploadInput from '../components/file-upload-input';
import CustomModal from '../components/modal';
import { numberFormat } from '~/utils/formatValue';

import { useAuth } from '~/hooks/Auth';
import { 
    ProductsService, 
    ConfigurationsService 
} from '../services/WebliniaERPAPI';

export default function ProductPriceImportView() {
    const { addToast } = useToasts();
    const userLoggedData = useAuth();
    
    const [isReadingFile, setIsReadingFile] = useState(false);
    const [isSavingImport, setIsSavingImport] = useState(false);
    const [isShowProductsModal, setIsShowProductsModal] = useState(false);
    const [modalFilterParams, setModalFilterParams] = React.useState([]);
    const [configs, setConfigs] = useState(null);
    const [filedSelected, setFileSelected] = useState(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        loadEmpConfigs();
    }, []);

    const loadEmpConfigs = async () => {
        try {
            const service = new ConfigurationsService();
            const { data: _configs } = await service.getConfigurations(userLoggedData.enterprise.id_empreendimento);
            setConfigs(_configs);
        }
        catch(error) {
            console.log(error);
        }
    }

    const modalToggle = useCallback(() => {
		setModalFilterParams([{
			key: 'withCombinacoes',
			value: 'true'
		}]);
    	setIsShowProductsModal(!isShowProductsModal);
  	}, [isShowProductsModal]);
    
    const handleSelectProduct = useCallback((list, product, _products) => {
		_products = (_products) ? _products : _.clone(products);
		
		if(!_products) {
			_products = [];
		}

		let can_add = true;

		_products.forEach(_product => {
			if (parseInt(_product.id_produto, 10) === parseInt(product.id_produto, 10)) {
				can_add = false;
			}
		});

		if (can_add) {
			if(!product.img || product.img.length === 0)
				product.img = 'https://via.placeholder.com/100x100.png?text=PRODUCT';
			else
				product.img = `https://crn.ngweb.net.br/${product.img.replace('/home/webliniaerp/www/', '')}`;

			_products.push({
                id_produto: product.id_produto,
                nome_produto: product.nome,
                vlr_venda_atacado: product.vlr_venda_atacado,
                vlr_venda_intermediario: product.vlr_venda_intermediario,
                vlr_venda_intermediario_ii: product.vlr_venda_intermediario_ii,
                vlr_venda_varejo: product.vlr_venda_varejo,
                vlr_venda_original: product.vlr_venda_original,
                vlr_venda_promocional: product.vlr_venda_varejo,
                db_info: product
            });
			
            setProducts(_products);
		}
		else {
			addToast('O produto selecionado já foi incluído na lista de importação!', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
		}
  	}, [products]);

    const downloadModelFile = () => {
        window.location.href = 'https://api.crn.ngweb.net.br/files/modelo-importacao-sku-netshoes.csv';
    }

    const onFileSelected = (file) => {
        console.log(file);
        setFileSelected(file);
        setIsReadingFile(true);
        uploadFile(file);
    }

    const uploadFile = async (file) => {
        try {
            let formData = new FormData();
                formData.append('id_empreendimento', userLoggedData.enterprise.id_empreendimento);
                formData.append('file', file);
            
            const service = new ProductsService();
            const { data } = await service.readFileProductSKUNetshoes(formData);
            setIsReadingFile(false);
            console.log(data);
            setProducts(data);
        }
        catch(error) {
            if (error.response) {
                const { response: { data: error_message } } = error;
                addToast(error_message, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
            }
            cancelImport();
        }
    }

    const updateFieldValue = useCallback((data, field, value) => {
        const _products = products.map((product) => {
            if(product.id_produto === data.id_produto) {
                product[field] = value;
            }
            return product;
        });
        setProducts(_products);
    },[products]);

    const cancelImport = () => {
        setFileSelected(null);
        setIsReadingFile(false);
        setProducts([]);
    }

    const removeProduct = useCallback((product, index) => {
		let _products = _.clone(products);
		
		if (_products)
			_products.splice(index, 1);

		if (_products && _products.length > 0)
			setProducts(_products);
	}, [products]);

    const saveImport = async () => {
        setIsSavingImport(true);
        let payload = {
            id_empreendimento: userLoggedData.enterprise.id_empreendimento, 
            produtos: products
        };
        try {
            const service = new ProductsService();
            const { data } = await service.saveProductSKUNetshoes(payload);
            console.log(data);
            setIsSavingImport(false);
            addToast('Importação realizada e SKUs da Netshoes atualizados com sucesso nos produtos!', {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            cancelImport();
        }
        catch(error) {
            console.log(error);
            addToast(JSON.stringify(error), {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
        }
    }

    return (
        <>
			<Container className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle 
						title='Importação de SKU Netshoes' 
						subtitle='Cadastro de Produtos' 
						className='text-sm-left'
					/>
					<Col sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>

					<Col sm="4" className="d-flex justify-content-end">
                        {(!filedSelected) ? (
                            <>
                                <Button outline theme="secondary" style={{ lineHeight: '2.4em' }}
                                    onClick={downloadModelFile}>
                                    <i className="material-icons">download</i> Baixar arquivo modelo
                                </Button>
                                &nbsp;
                                <FileUploadInput handleFileUpload={onFileSelected} />
                            </>
                        ) : (isReadingFile) && (
                            <span style={{ lineHeight: 3.4, verticalAlign: 'middle' }}>
                                <i className="fa fa-spin fa-spinner"/> Aguarde, lendo arquivo...
                            </span>
                        )}
					</Col>
				</Row>

                <Card small className='mb-3'>
					<CardHeader className="border-bottom">
						<Row>
                            <Col sm="6" className="col d-flex align-items-center">
                                <h6 className="m-0">
                                    Produtos p/ Atualização
                                    {(products && products.length > 0) && (
                                        <>
                                            <br/>
                                            <small><strong>{products.length} produtos</strong> encontrados no arquivo <strong>{filedSelected && filedSelected.name}</strong></small>
                                        </>
                                    )}
                                </h6>
                            </Col>
                            <Col sm="6" className="d-flex justify-content-end">
                                {(products && products.length > 0) && (
                                    <>
                                        {(isSavingImport) ? (
                                            <span style={{ lineHeight: 3.4, verticalAlign: 'middle' }}>
                                                <i className="fa fa-spin fa-spinner"/> Aguarde, salvando importação...
                                            </span>
                                        ) : (
                                            <>
                                                &nbsp;
                                                <Button outline theme="secondary" style={{ lineHeight: '2.4em' }}
                                                    onClick={cancelImport}>
                                                    <i className="material-icons">cancel</i> Cancelar
                                                </Button>
                                                &nbsp;
                                                <Button outline theme="primary" 
                                                    style={{ lineHeight: '2.4em' }}
                                                    onClick={() => modalToggle()}
                                                >
                                                    <i className="material-icons">add_circle</i> Adicionar Produto
                                                </Button>
                                                &nbsp;
                                                <Button 
                                                    theme="success" style={{ lineHeight: '2.4em' }}
                                                    onClick={() => saveImport()}
                                                >
                                                    <i className="material-icons">save</i> Salvar Importação
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
					</CardHeader>
					
					<CardBody>
						<Container fluid>
                            <table className="table table-bordered table-condensed table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th rowspan={2} className="text-center" width="50">#</th>
                                        <th rowspan={2} className="text-center" width="90">Código</th>
                                        <th rowspan={2}>Produto</th>
                                        <th colSpan={2} className="text-center" width="220">SKU Netshoes</th>
                                    </tr>
                                    <tr>
                                        <th className="text-center" width="220">Atual</th>
                                        <th className="text-center" width="220">Novo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {products && products.map((product, index) => {
                                        return (
                                            <tr key={product.id_produto.toString()} className={(!product.db_info) ? 'bg-danger' : ''}>
                                                <td className={`text-center ${(!product.db_info) ? 'text-white' : ''}`}>
                                                    {(index+1)}
                                                </td>

                                                <td className={`text-center ${(!product.db_info) ? 'text-white' : ''}`}>
                                                    #{product.id_produto}
                                                    <br/>
                                                    <small>
                                                        <a href="#" 
                                                            className={(!product.db_info) ? 'text-white' : 'text-danger'}
                                                            onClick={() => removeProduct(product, index)}
                                                        >
                                                            <i className="material-icons">delete</i> Remover
                                                        </a>
                                                    </small>
                                                </td>

                                                <td className={`${(!product.db_info) ? 'text-white' : ''}`}>
                                                    <strong>{product.nome_produto}</strong>
                                                    <br/>
                                                    <small>
                                                        {product.db_info && product.db_info.nome_fabricante && (
                                                            <Badge theme="secondary" style={{ padding: '3px', borderRadius: '3px', fontSize: '100%' }}>
                                                                {product.db_info.nome_fabricante}
                                                            </Badge>
                                                        )}
                                                        {product.db_info && product.db_info.nome_tamanho && (
                                                            <>&nbsp;•&nbsp; {product.db_info.nome_tamanho}</>
                                                        )}
                                                        {product.db_info && product.db_info.nome_cor_sabor && (
                                                            <>&nbsp;•&nbsp; {product.db_info.nome_cor_sabor}</>
                                                        )}
                                                    </small>
                                                </td>
                                                
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <FormInput 
                                                        className="text-center" 
                                                        disabled={true}
                                                        style={{ backgroundColor: '#dcdcdc' }}
                                                        value={product.db_info && product.db_info.sku_netshoes} 
                                                    />
                                                </td>
                                                <td className={`${(!product.db_info) ? 'text-white' : ''}`}>
                                                    <FormInput 
                                                        name="teste"
                                                        className="text-center" 
                                                        value={product && product.sku_netshoes}
                                                        onChange={(event) => updateFieldValue(product, 'sku_netshoes', event.target.value)}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
            
            {(isShowProductsModal) && (
				<CustomModal
					type={'ListProduct'}
					size="lg"
					open={true}
					toggle={() => {modalToggle()}}
					selectCallback={handleSelectProduct}
					filterParams={modalFilterParams}
				/>
			)}
        </>
    )
}
