import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    ButtonGroup,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    CardHeader,
    ListGroupItem
} from "shards-react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form } from '@unform/web';
import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import { Select } from '../components/unform';
import { numberFormat } from '~/utils/formatValue';
import { TableExport } from 'tableexport';
import _ from 'lodash';

import { 
    getBaseUrlWebOld, 
    generateQueryString 
} from '~/utils/functions';

import * as Yup from 'yup';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import getValidationErrors from '../utils/getValidationErrors';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { useAuth } from '~/hooks/Auth';

import { 
    InvoiceReceivedService
  } from '~/services/WebliniaERPAPI';

import { set } from 'react-ga';

function PageView(props) {
    const formRef = useRef(null);
    
    const { addToast } = useToasts();
    const { user, enterprise } = useAuth();

    const [notasFiscaisRecebidas, setNotasFiscaisRecebidas] = useState(null) ;
    const [pagination, setPagination] = useState(null);

    const [isOpenModalSales, setIsOpenModalSales] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [noteDetails, setNoteDetails] = useState(false);
    const [isImported, setIsImported] = useState(false);
    const [activeTab, setActiveTab] = useState("general-info");

    const [numCNPJ, setNumCNPJ] = useState();
    const [nomeRazaoSocial, setNomeRazaoSocial] = useState();
    const [numNFe, setNumNFe] = useState();
    const [dtaInicial, setDtaInicial] = useState();
    const [dtaFinal, setDtaFinal] = useState();
    const [hideFilters, setHideFilters ] = useState(false);

    const handleResetFilter = useCallback(() => {
        formRef.current.reset();
        setNumCNPJ(null);
        setNomeRazaoSocial(null);
        setNumNFe(null);
        setDtaInicial(null);
        setDtaFinal(null);
    }, [numCNPJ, nomeRazaoSocial, numNFe, dtaInicial, dtaFinal]);

    const handleApplyFilters = useCallback(() => {
        getNotaFiscalRecebida(0,10);
    }, [numCNPJ, nomeRazaoSocial, numNFe, dtaInicial, dtaFinal]);

    useEffect(() => {
        getNotaFiscalRecebida(0,10);
    }, []);

    const getNotaFiscalRecebida = async (offset, limit) => {
        try {

            let params = [{
                key: 'nfre->cod_empreendimento',
                value: enterprise.id_empreendimento
            }];

            if (numCNPJ && numCNPJ.length > 0) {
                params.push({
                    key: 'nfr->doc_emitente',
                    value: numCNPJ
                });
            }
    
            if (nomeRazaoSocial && nomeRazaoSocial.length > 0) {
                params.push({
                    key: 'nfr->nme_emitente',
                    value: nomeRazaoSocial
                });
            }

            if (numNFe && numNFe.length > 0) {
                let _numNFe = numNFe.padStart(9, "0")
                params.push({
                    key: 'nfr->chave_nfe[exp]',
                    value: `substring(nfre.chave_nfe, 26, 9) = '${_numNFe}'`
                });
            }
            
            if ((dtaInicial && dtaInicial.length > 0) && (dtaFinal && dtaFinal.length > 0)) {
                params.push({
                    key: "date_format(nfr->dta_emissao,'%Y-%m-%d')[exp]",
                    value: `BETWEEN '${dtaInicial}' AND '${dtaFinal}'`
                });
            }
    
            const service = new InvoiceReceivedService();
            let { data: { paginacao: _pagination, registros: _registros}} = await service.getAllNotasRecebidas(offset,limit,params);
    
            setPagination(_pagination);
            setNotasFiscaisRecebidas(_registros);
            setHideFilters(true);
        }   catch (err) {
            console.log(err)
            setNotasFiscaisRecebidas(null);
            setHideFilters(false);
            
        }
    }

    const importarNotaFiscal = async (key_note,id_nfe_recebida) => {
        try{
            let tipo_arquivo = 'xml';

            if(await isImportNotaFiscalRecebida(id_nfe_recebida)) {
                // exibir alerta de que nao ta disponivel ainda...
                addToast('Nota Fiscal Recebida e já importada', {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                  });
                setIsLoadingSearch(false);
                getNotaFiscalRecebida(0,10);
                
            }else {
                if (await isAvaliableNotaFiscalRecebida(key_note,tipo_arquivo)) {
   
                    let params = [{
                        key: 'chave_nfe',
                        value: key_note
                    },{
                        key: 'id_nfe_recebida',
                        value: id_nfe_recebida
                    },{
                        key: 'id_empreendimento',
                        value: enterprise.id_empreendimento
                    },{
                        key: 'nome_empreendimento',
                        value: enterprise.nome_empreendimento
                    }]
            
                    let qs = generateQueryString(params);
            
                    window.open(`${getBaseUrlWebOld()}/estoque.php${qs}`,"_blank");
                }
                else {

                    switch (tipo_arquivo) {
                        case 'pdf':
                            // exibir alerta de que nao ta disponivel ainda...
                            addToast('Nota Fiscal Recebida, por favor, aguarde a liberação do PDF!', {
                                placement: 'bottom-right',
                                appearance: 'error',
                                autoDismiss: true
                            });
                            setIsLoadingSearch(false);
                            break;
                    
                        case 'xml':
                            // exibir alerta de que nao ta disponivel ainda...
                            addToast('Nota Fiscal Recebida, por favor, aguarde a liberação do XML!', {
                                placement: 'bottom-right',
                                appearance: 'error',
                                autoDismiss: true
                            });
                            setIsLoadingSearch(false);
                            break;
                    }    
                }
            }
        }
        catch(err){
            console.log(err);
            setIsLoadingSearch(false);
        }
    }

    const isImportNotaFiscalRecebida =  async (id_nfe_recebida) => {
        try{
            const service = new InvoiceReceivedService();
            await service.getByFlgImportada(id_nfe_recebida);
        }
        catch({ response: { status } }){
            switch (status) {
                case 409:
                    return true;
                case 404:
                    return false;
            }
        }
    }

    const isAvaliableNotaFiscalRecebida = async (key_note,tipo_arquivo) => {
        try{
            let params = [{
                key: 'id_empreendimento',
                value: enterprise.id_empreendimento
            },{
                key: 'formato_saida',
                value: tipo_arquivo
            }];

            const service = new InvoiceReceivedService();
            let { status } = await service.validateNotaFiscal(key_note,params);
            return true;
        }
        catch(err){
            return false;
        }
    }

    const openArquivoNotaRecebida = async (id,key_note,tipo_arquivo) => {
        try {
            setIsLoadingSearch(true);
            if (await isAvaliableNotaFiscalRecebida(key_note,tipo_arquivo)) {
                let params = [{
                    key: 'id_nfe_recebida',
                    value: id
                },{
                    key: 'id_empreendimento',
                    value: enterprise.id_empreendimento
                }]

                const service = new InvoiceReceivedService();
                let { data: url } = await service.getUrlArquivoNotaRecebida(key_note,tipo_arquivo,params);
                window.open(url, '_blank');
                setIsLoadingSearch(false);
            }
            else {
                // exibir alerta de que nao ta disponivel ainda...
                addToast('Nota Fiscal Recebida ainda não contem os dados para PDF/XML!', {
                            placement: 'bottom-right',
                            appearance: 'error',
                            autoDismiss: true
                          });
                setIsLoadingSearch(false);
            }
        }
        catch(err){
            console.log(err);
            setIsLoadingSearch(false);
        }
    }

    const openDetailsNotaRecebida = async (id,key_note) => {
        try {
            setIsLoadingSearch(true);
            if (await isAvaliableNotaFiscalRecebida(key_note,'xml')) {

                let params = [{
                    key: 'id_nfe_recebida',
                    value: id
                },{
                    key: 'id_empreendimento',
                    value: enterprise.id_empreendimento
                }]

                const service = new InvoiceReceivedService();
                let {data: data} = await service.getDetailsArquivoNotaRecebida(key_note,params);
                setIsOpenModalSales(true);
                setNoteDetails(data);
                setIsLoadingSearch(false);
            }
            else {
                setIsOpenModalSales(false);
                // exibir alerta de que nao ta disponivel ainda...
                addToast('Os detalhes da nota ainda estão indisponiveis', {
                            placement: 'bottom-right',
                            appearance: 'error',
                            autoDismiss: true
                        });
                setIsLoadingSearch(false);
            }
        }
        catch(err){
            console.log(err);
            setIsLoadingSearch(false);
        }
    }


    return (
        <>
            <Container fluid>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Notas Fiscais Recebidas' 
                        subtitle='Relátorios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="9">
                        <Card small className='mb-3'>
                            <Form ref={formRef}>
                                <CardBody>
                                    <table id="table-taxes" className='table table-bordered table-condensed table-hover mb-0'>
                                        <thead>
                                            <tr>
                                                <th style={{ verticalAlign: 'middle' }} width="110" >Nota Fiscal</th>
                                                <th style={{ verticalAlign: 'middle' }} width="50" className='text-center'>Total</th>
                                                <th style={{ verticalAlign: 'middle' }} width="50" className='text-center'>Emissão</th>
                                                <th style={{ verticalAlign: 'middle' }} width="300" className='text-center'>Opções</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {notasFiscaisRecebidas && notasFiscaisRecebidas.map(nota => (
                                                <tr>
                                                    <td>
                                                        <strong>{nota.nme_emitente}</strong>
                                                        <br/>
                                                        <small className='text-muted'><strong>CNPJ: {nota.doc_emitente}</strong></small>
                                                        <br/>
                                                        <small className='text-muted'>Chave NF-e: {nota.chave_nfe}</small>
                                                    </td>
                                                    <td className='text-right' style={{ verticalAlign: 'middle' }} >
                                                        R$ {numberFormat((nota.vlr_total), 2, ',', '.')}
                                                    </td>
                                                    <td className='text-center' style={{ verticalAlign: 'middle' }} >{moment(nota.dta_emissao).format('DD/MM/YYYY')}</td>
                                                    <td className='text-center' style={{ verticalAlign: 'middle' }}>
                                                        <Button
                                                            size = "sm"
                                                            theme={`${(nota.flg_importada === '1') ? 'success' : 'primary'}`}
                                                            onClick={() => importarNotaFiscal(nota.chave_nfe,nota.id)}
                                                            disabled={(nota.flg_importada === '1')}
                                                        >
                                                            <i class={`fa ${(nota.flg_importada === '1') ? 'fa-check-circle' : 'fa-download'}`}></i> 
                                                            &nbsp;
                                                            {`${(nota.flg_importada === '1') ? 'Importada' : 'Importar'}`}
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            size = "sm"
                                                            theme="info"
                                                            outline
                                                            disabled={(isLoadingSearch)}
                                                            onClick={() => {
                                                                openDetailsNotaRecebida(nota.id,nota.chave_nfe);
                                                            }}
                                                        >
                                                          <i class={`fa ${(isLoadingSearch) ? 'fa-spinner fa-spin' : 'fa-info-circle'}`}></i> 
                                                          &nbsp;
                                                          {`${(isLoadingSearch) ? 'Aguarde' : 'Detalhes'}`}
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            size = "sm"
                                                            theme="secondary"
                                                            outline
                                                            disabled={(isLoadingSearch)}
                                                            onClick={() => openArquivoNotaRecebida(nota.id,nota.chave_nfe,'xml')}
                                                        >
                                                            <i class={`fa ${(isLoadingSearch) ? 'fa-spinner fa-spin' : 'fa-file-code'}`}></i> 
                                                            &nbsp;
                                                            {`${(isLoadingSearch) ? 'Aguarde' : 'XML'}`}
                                                        </Button>
                                                        &nbsp;
                                                        <Button
                                                            size = "sm"
                                                            theme="secondary"
                                                            outline
                                                            disabled={(isLoadingSearch)}
                                                            onClick={() => openArquivoNotaRecebida(nota.id,nota.chave_nfe,'pdf')}
                                                        >
                                                           <i class={`fa ${(isLoadingSearch) ? 'fa-spinner fa-spin' : 'fa-file-pdf'}`}></i> 
                                                           &nbsp;
                                                           {`${(isLoadingSearch) ? 'Aguarde' : 'PDF'}`}
                                                        </Button>
                                                    </td>
                                                </tr>   
                                            ))}
                                        </tbody>
                                    </table>
                                </CardBody>
                                <CardFooter>
                                    {(pagination && pagination.length > 10) && (
                                        <Row>
                                            <Col sm="12" className="text-right">
                                                <ButtonGroup>
                                                    {pagination.map((value, index) => (
                                                        <Button
                                                            key={index} theme={value.current ? 'primary' : 'white'}
                                                            onClick={() => { getNotaFiscalRecebida(value.offset, value.limit) }}
                                                        >
                                                            {value.index}
                                                        </Button>
                                                    ))}
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                    )}
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>

                    <Col md="3">
                        <Card className='p-0'>
                            <CardHeader className="border-bottom p-3">
                                <Row>
                                    <Col md='6'>
                                        <h6 className="M-0">
                                            <i className='fa fa-filter'></i> Opções de Filtro
                                        </h6>
                                    </Col>
                                    <Col sm='6' className={'text-right'}>
                                        <small>
                                            <a href="#" className="text-primary"  onClick={() => setHideFilters(!hideFilters)}>
                                                {(hideFilters) && 
                                                    <span>
                                                        Exibir&nbsp;
                                                        <i class="fa fa-chevron-down"></i>
                                                    </span>
                                                }
                                                {(!hideFilters) && 
                                                    <span>
                                                        Ocultar&nbsp;
                                                        <i class="fa fa-chevron-up"></i>
                                                    </span>
                                                }
                                            </a>
                                        </small>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className='p-0' style={{ display: (hideFilters) ? 'none' : ''}}>
                                <Container fluid className='file-manager__filters border-bottom'>
                                    <Form ref={formRef}>
                                        <Row>
                                            <Col md='12'>
                                                <Input
                                                    type='text'
                                                    label='CNPJ'
                                                    name='numCNPJ'
                                                    defaultValue={numCNPJ}
                                                    value={numCNPJ}
                                                    onChange={e => setNumCNPJ(e.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md='12'>
                                                <Input
                                                    type='text'
                                                    name='nomeRazaoSocial'
                                                    label='Nome /Razão Social/ Fantasia'
                                                    defaultValue={nomeRazaoSocial}
                                                    value={nomeRazaoSocial}
                                                    onChange={e => setNomeRazaoSocial(e.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md='12'>
                                                <Input
                                                    type='text'
                                                    name='numNFe'
                                                    label='N° NF-e'
                                                    defaultValue={numNFe}
                                                    value={numNFe}
                                                    onChange={e => setNumNFe(e.target.value)}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md='6'>
                                                <Input
                                                    type='date'
                                                    name='dtaInicial'
                                                    label='Data Inicial'
                                                    defaultValue={dtaInicial}
                                                    value={dtaInicial}
                                                    onChange={e => setDtaInicial(e.target.value)}
                                                />
                                            </Col>
                                            <Col md='6'>
                                                <Input
                                                    type='date'
                                                    name='dtaFinal'
                                                    label='Data Final'
                                                    defaultValue={dtaFinal}
                                                    value={dtaFinal}
                                                    onChange={e => setDtaFinal(e.target.value)}
                                                />
                                            </Col>
                                        </Row> 

                                        <Row>
                                            <Col sm='6' className={'text-center'}>
                                                <Button 
                                                    outline theme='secondary' 
                                                    className="ml-2"
                                                    onClick={handleResetFilter}
                                                >
                                                    <i className="fa fa-times"></i> Limpar Filtro
                                                </Button>
                                            </Col>
                                            <Col sm='6' className={'text-center'}>
                                                <Button 
                                                    theme="accent" 
                                                    className="ml-2"
                                                    onClick={handleApplyFilters}
                                                    
                                                >
                                                    <i className="fa fa-filter"></i> Aplicar Filtro
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            <Modal open={isOpenModalSales} size="xl">
                <ModalHeader className="p-3">
                    Detalhamento da Nota Fiscal
                </ModalHeader>
                <ModalBody className="p-0" style={{ maxHeight: '65vh' }}>
                    <Row>
                       <Col lg="12">
                            <div className="border-bottom clearfix d-flex">
                                <Nav tabs className="border-0 mt-auto mx-4 pt-2">
                                    <NavItem>
                                        <NavLink 
                                            active={(activeTab && activeTab === 'general-info')}
                                            onClick={() => setActiveTab('general-info')}
                                        >
                                            Dados Gerais
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            active={(activeTab && activeTab === 'sender-info')}
                                            onClick={() => setActiveTab('sender-info')}
                                        >
                                            Emitente
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            active={(activeTab && activeTab === 'receiver-info')}
                                            onClick={() => setActiveTab('receiver-info')}
                                        >
                                            Destinatario
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            active={(activeTab && activeTab === 'itens-info')}
                                            onClick={() => setActiveTab('itens-info')}
                                        >
                                            Itens
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink 
                                            active={(activeTab && activeTab === 'tax-info')}
                                            onClick={() => setActiveTab('tax-info')}
                                        >
                                            Impostos
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <Form ref={formRef}>
                                <Col sm="12">
                                    {activeTab && activeTab === 'general-info' && (
                                        <div className='pt-3 pb-2'>
                                            <Row>
                                                <Col sm="4">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.chave_nfe}
                                                    name="chave_nfe" 
                                                    label="Chave de Acesso"
                                                    />
                                                </Col>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={'R$ '+ noteDetails.valor_total}
                                                    name="valor_total" 
                                                    label="Valor Total"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.situacao}
                                                    name="situacao" 
                                                    label="Situacao"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    {activeTab && activeTab === 'sender-info' && (
                                        <div className='pt-3 pb-2'>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.documento_emitente}
                                                    name="documento_emitente" 
                                                    label="CNPJ"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.inscricao_estadual_emitente}
                                                    name="inscricao_estadual_emitente" 
                                                    label="Inscrição Estadual"
                                                    />
                                                </Col>

                                                <Col sm="4">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.nome_emitente}
                                                    name="nome_emitente" 
                                                    label="Razão Social"
                                                    />
                                                </Col>

                                                <Col sm="4">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.nome_fantasia_emitente}
                                                    name="nome_fantasia_emitente" 
                                                    label="Nome Fantasia"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.cep_emitente}
                                                    name="cep_emitente" 
                                                    label="Cep"
                                                    />
                                                </Col>

                                                <Col sm="6">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.logradouro_emitente}
                                                    name="logradouro_emitente" 
                                                    label="Endereço"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.numero_emitente}
                                                    name="numero_emitente" 
                                                    label="Número"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="3">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.bairro_emitente}
                                                    name="bairro_emitente" 
                                                    label="Bairro"
                                                    />
                                                </Col>

                                                <Col sm="3">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.municipio_emitente}
                                                    name="municipio_emitente" 
                                                    label="Cidade"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.uf_emitente}
                                                    name="uf_emitente" 
                                                    label="Estado"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.telefone_emitente}
                                                    name="telefone_emitente" 
                                                    label="Telefone"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    {activeTab && activeTab === 'receiver-info' && (
                                        <div className='pt-3 pb-2'>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.cnpj_destinatario}
                                                    name="documento_destinatario" 
                                                    label="CNPJ"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.inscricao_estadual_destinatario}
                                                    name="inscricao_estadual_destinatario" 
                                                    label="Inscrição Estadual"
                                                    />
                                                </Col>

                                                <Col sm="4">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.nome_destinatario}
                                                    name="nome_destinatario" 
                                                    label="Razão Social"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.cep_destinatario}
                                                    name="cep_destinatario" 
                                                    label="Cep"
                                                    />
                                                </Col>

                                                <Col sm="6">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.logradouro_destinatario}
                                                    name="logradouro_destinatario" 
                                                    label="Endereço"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.numero_destinatario}
                                                    name="numero_destinatario" 
                                                    label="Número"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="3">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.bairro_destinatario}
                                                    name="bairro_destinatario" 
                                                    label="Bairro"
                                                    />
                                                </Col>

                                                <Col sm="3">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.municipio_destinatario}
                                                    name="municipio_destinatario" 
                                                    label="Cidade"
                                                    />
                                                </Col>

                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.uf_destinatario}
                                                    name="uf_destinatario" 
                                                    label="Estado"
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="2">
                                                    <Input
                                                    readOnly 
                                                    value={noteDetails.requisicao_nota_fiscal.telefone_destinatario}
                                                    name="telefone_destinatario" 
                                                    label="Telefone"
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}

                                    
                                    {activeTab && activeTab === 'itens-info' && (
                                            <div 
                                                className='pt-3 pb-2' 
                                                // style="max-height: 500px,overflow-y: scroll"
                                                style={{ maxHeight: '400px', overflowY: 'scroll' }}
                                            >
                                                <table 
                                                    className='table table-bordered table-condensed table-hover mb-0'
                                                    style={{ fontSize: '80%' }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                rowSpan={2} 
                                                                style={{ verticalAlign: 'middle' }}
                                                                width="200"
                                                            >
                                                                Nome
                                                            </th>

                                                            <th
                                                                rowSpan={2} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="40"
                                                            >
                                                                NCM
                                                            </th>
                                                            
                                                            <th
                                                                rowSpan={2} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="40"
                                                            >
                                                                CFOP
                                                            </th>

                                                            <th
                                                                rowSpan={2} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="10"
                                                            >
                                                                Unid.
                                                            </th>

                                                            <th
                                                                rowSpan={2} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="50"
                                                            >
                                                                Subtotal
                                                            </th>
                                                            
                                                            <th
                                                                colSpan={4} 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="60"
                                                            >
                                                                Impostos
                                                            </th>

                                                        </tr>
                                                        <tr>
                                                            <th 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="70"
                                                            >
                                                                ICMS
                                                            </th>

                                                            <th 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="40"
                                                            >
                                                                IPI
                                                            </th>

                                                            <th 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="70"
                                                            >
                                                                PIS
                                                            </th>

                                                            <th 
                                                                style={{ verticalAlign: 'middle' }} 
                                                                className='text-center'
                                                                width="70"
                                                            >
                                                                COFINS
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {noteDetails.requisicao_nota_fiscal.itens && noteDetails.requisicao_nota_fiscal.itens.map(item => (
                                                            <tr>
                                                                <td
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >      
                                                                    <strong>{item.descricao}</strong>
                                                                    <br/>
                                                                    <span className='text-muted'>#{item.codigo_produto} • EAN {item.codigo_barras_comercial}</span>
                                                                </td>
                                                                <td
                                                                    className='text-center'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    {item.codigo_ncm}
                                                                </td>
                                                                <td
                                                                    className='text-center'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    {item.cfop}
                                                                </td>
                                                                <td
                                                                    className='text-center'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    {item.unidade_comercial.toUpperCase()}
                                                                </td>
                                                                <td
                                                                    className='text-right'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    R$ {numberFormat((item.valor_bruto), 2, ',', '.')}
                                                                    <br/>
                                                                    <span className='text-muted'>{item.quantidade_comercial} x R$ {numberFormat((item.valor_unitario_comercial), 2, ',', '.')} </span>
                                                                </td>
                                                                <td
                                                                    className='text-right'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    R$ {numberFormat((item.icms_valor), 2, ',', '.')}
                                                                </td>
                                                                <td
                                                                    className='text-right'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    R$ {numberFormat(((item.ipi_valor) ? item.ipi_valor : 0), 2, ',', '.')}
                                                                </td>
                                                                <td
                                                                    className='text-right'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    R$ {numberFormat((item.pis_valor), 2, ',', '.')}
                                                                </td>
                                                                <td
                                                                    className='text-right'
                                                                    style={{ verticalAlign: 'middle' }}
                                                                >
                                                                    R$ {numberFormat((item.cofins_valor), 2, ',', '.')}
                                                                </td>
                                                            </tr>   
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                    )}
                                    

                                    {activeTab && activeTab === 'tax-info' && (
                                        <div className='pt-3 pb-2'>
                                            <fieldset>
                                                <h5><strong>ICMS</strong></h5>
                                                <Row>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.icms_base_calculo}
                                                        name="icms_base_calculo" 
                                                        label="Base Calculo"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.icms_valor_total}
                                                        name="icms_valor_total" 
                                                        label="Valor Total"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.icms_valor_total_desonerado}
                                                        name="icms_valor_total_desonerado" 
                                                        label="Valor Total Desonerado"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.icms_base_calculo_st}
                                                        name="icms_base_calculo_st" 
                                                        label="Base Calculo ST"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.icms_valor_total_st}
                                                        name="icms_valor_total_st" 
                                                        label="Valor Total ST"
                                                        />
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset>
                                                <h5><strong>FCP</strong></h5>
                                                <Row>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.fcp_valor_total}
                                                        name="fcp_valor_total" 
                                                        label="Valor Total"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.fcp_valor_total_st}
                                                        name="fcp_valor_total_st" 
                                                        label="Valor Total ST"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.fcp_valor_total_retido_st}
                                                        name="fcp_valor_total_retido_st" 
                                                        label="Valor Total Retido ST"
                                                        />
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset>
                                                <h5><strong>PIS / COFINS / IPI</strong></h5>
                                                <Row>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.valor_pis}
                                                        name="valor_pis" 
                                                        label="Valor PIS"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.valor_cofins}
                                                        name="valor_cofins" 
                                                        label="Valor COFINS"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.valor_ipi}
                                                        name="valor_ipi" 
                                                        label="Valor IPI"
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Input
                                                        readOnly 
                                                        value={'R$ '+ noteDetails.requisicao_nota_fiscal.valor_ipi_devolvido}
                                                        name="valor_ipi_devolvido" 
                                                        label="Valor IPI devolvido"
                                                        />
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                        </div>
                                    )}

                                </Col>
                            </Form>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="p-3">
                    <Button theme="accent" size="md" onClick={() => setIsOpenModalSales(false)}>
                        <i className="material-icons">close</i> Fechar
                    </Button>
                </ModalFooter>
            </Modal>

        </>
    )

}

export default PageView
