import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody
} from "shards-react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form } from '@unform/web';
import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import { Select } from '../components/unform';
import { numberFormat } from '~/utils/formatValue';
import { TableExport } from 'tableexport';
import _ from 'lodash';
import * as Yup from 'yup';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import getValidationErrors from '../utils/getValidationErrors';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { useAuth } from '~/hooks/Auth';

import { 
    ReportsService
  } from '~/services/WebliniaERPAPI';

import { set } from 'react-ga';

function PageView(props) {
    const formRef = useRef(null);
    
    const { addToast } = useToasts();
    const { user, enterprise } = useAuth();

    const [isLoadingSearch, setIsLoadingSearch] = useState(false);

    const [vlrTotalNotasFiscais, setVlrTotalNotasFiscais] = useState(0);
    const [vlr_vTotTrib, setVlr_vTotTrib] = useState(0);
    const [vlr_ICMS_vICMS, setVlr_ICMS_vICMS] = useState(0);
    const [vlr_ICMS_vICMSST, setVlr_ICMS_vICMSST] = useState(0);
    const [vlr_ICMS_vCredICMSSN, setVlr_ICMS_vCredICMSSN] = useState(0);
    const [vlr_PIS_vPIS, setVlr_PIS_vPIS] = useState(0);
    const [vlr_COFINS_vCOFINS, setVlr_COFINS_vCOFINS] = useState(0);
    const [vlr_IPI_vIPI, setVlr_IPI_vIPI] = useState(0);

    const [entries, setEntries] = useState(null);

    const handleSubmitFilter = async(data) => {
        try {
          formRef.current.setErrors({});
          const schema = Yup.object().shape({
            startDate: Yup.string().required('Selecione uma Data Inicial'),
            endDate: Yup.string().test("is-greater", "Deve ser maior que a inicial", function(value) {
              const { startDate } = this.parent;
              return moment(value, "yyyy-mm-dd").isSameOrAfter(moment(startDate, "yyyy-mm-dd"));
            }).required('Selecione uma Data Final')
          });
    
          await schema.validate(data, {
            abortEarly: false,
          });
    
          await getAllEntryTaxes(data);
        } catch (error) {
          if (error instanceof Yup.ValidationError) {
            formRef.current.setErrors(getValidationErrors(error));
            return;
          }
        }
    }

    const getAllEntryTaxes = async (data) =>{
        try {

            let vlrTotalNotasFiscais = 0;
            let vlr_vTotTrib = 0;
            let vlr_ICMS_vICMS = 0;
            let vlr_ICMS_vICMSST = 0;
            let vlr_ICMS_vCredICMSSN = 0;
            let vlr_PIS_vPIS = 0;
            let vlr_COFINS_vCOFINS = 0;
            let vlr_IPI_vIPI = 0;

            const urlParams = [
                {
                    key: "est_entrada->dta_entrada[exp]", 
                    value: decodeURI(`BETWEEN '${data.startDate} 00:00:00' AND '${data.endDate} 23:59:59'`)
                }, {
                    key: "(est_entrada->id_transferencia_estoque[exp]", 
                    value: 'is null or est_entrada.id_transferencia_estoque = 0)'
                }, {
                    key: "(est_entrada->id_devolucao[exp]", 
                    value: 'is null or est_entrada.id_devolucao = 0)'
                }, {
                    key: '(est_entrada->id_fornecedor[exp]',
                    value: 'is not null)'
                }
            ];

            const service = new ReportsService();
            
            let {data: {entradas: _items}} = await service.getEntryTaxes(urlParams, enterprise.id_empreendimento, null, null);

            _items = _items.map(_item => {
                vlrTotalNotasFiscais += parseFloat(_item.vlr_total_nota_fiscal);
                vlr_vTotTrib += parseFloat(_item.vlr_vTotTrib);
                vlr_ICMS_vICMS += parseFloat(_item.vlr_ICMS_vICMS);
                vlr_ICMS_vICMSST += parseFloat(_item.vlr_ICMS_vICMSST);
                vlr_ICMS_vCredICMSSN += parseFloat(_item.vlr_ICMS_vCredICMSSN);
                vlr_PIS_vPIS += parseFloat(_item.vlr_PIS_vPIS);
                vlr_COFINS_vCOFINS += parseFloat(_item.vlr_COFINS_vCOFINS);
                vlr_IPI_vIPI += parseFloat(_item.vlr_IPI_vIPI);

                return _item;
            });
            
            setEntries(_items);
            setVlrTotalNotasFiscais(vlrTotalNotasFiscais);
            setVlr_vTotTrib(vlr_vTotTrib);
            setVlr_ICMS_vICMS(vlr_ICMS_vICMS);
            setVlr_ICMS_vICMSST(vlr_ICMS_vICMSST);
            setVlr_ICMS_vCredICMSSN(vlr_ICMS_vCredICMSSN);
            setVlr_PIS_vPIS(vlr_PIS_vPIS);
            setVlr_COFINS_vCOFINS(vlr_COFINS_vCOFINS);
            setVlr_IPI_vIPI(vlr_IPI_vIPI);
        }catch(err) {
            console.log(err);
        }
    }

    return (
        <>
            <Container fluid className='main-content-container px-6 pb-6'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Impostos de Entrada' 
                        subtitle='Relátorios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                           <Form ref={formRef} onSubmit={handleSubmitFilter} >
                               <CardBody>
                                   <Row>
                                        <Col lg="2">
                                            <Input
                                                type='date'
                                                name='startDate'
                                                label='Data Inicial'
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <Input
                                                type='date'
                                                name='endDate'
                                                label='Data Final'
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='d-flex ml-auto my-auto justify-content-end'>
                                            {(entries && entries.length > 0) && (
                                                <ReactHTMLTableToExcel
                                                    id="test-table-xls-button"
                                                    className="btn btn-white mr-2"
                                                    table="table-taxes"
                                                    filename="Relatório-Impostos-Entrada"
                                                    sheet="Relatório-Impostos-Entrada"
                                                    buttonText="Exportar para Excel"
                                                />
                                            )}
                                            {isLoadingSearch && (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;
                                                    Aguarde, buscando informações...
                                                </>
                                            )}

                                            {!isLoadingSearch && (
                                                <Button theme="primary" 
                                                    type='submit'
                                                    size="md" >
                                                    <i className="material-icons">search</i> Aplicar Filtros
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                               </CardBody>
                           </Form>
                        </Card>
                    </Col>  
                </Row>    
                
                <Row>
                     <Col lg="12">
                        <Card small className='mb-3'>
                            <CardBody>
                                <table id="table-taxes" className='table table-bordered table-condensed table-hover mb-0'>
                                    <thead>
                                        <tr>
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="100">Data Recebimento</th>
                                            <th className='text-center' style={{ verticalAlign: 'middle' }} rowSpan={2} width="100">Nº NF-e</th>
                                            
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2}>Fornecedor</th>

                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="150" className='text-center'>Total NF-e</th>
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="150" className='text-center'>Total Tributos</th>
                                            
                                            <th style={{ verticalAlign: 'middle' }} colSpan={3} className='text-center'>ICMS</th>
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="150" className='text-center'>PIS</th>
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="150" className='text-center'>COFINS</th>
                                            <th style={{ verticalAlign: 'middle' }} rowSpan={2} width="150" className='text-center'>IPI</th>
                                        </tr>
                                        <tr>
                                            <th className='text-center' style={{ verticalAlign: 'middle' }} width={130}>Total</th>
                                            <th className='text-center' style={{ verticalAlign: 'middle' }} width={130}>Substituição Tributária</th>
                                            <th className='text-center' style={{ verticalAlign: 'middle' }} width={130}>Créditos Simples Nacional</th>
                                        </tr>
                                    </thead>
                                    
                                    <tbody>
                                        {entries && entries.map(tax => (
                                            <tr>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    {moment(tax.dta_entrada).format('DD/MM/YYYY')}
                                                    <br/>
                                                    <small className='text-muted'>#{tax.id}</small>
                                                </td>
                                                
                                                <td className='text-center' style={{ verticalAlign: 'middle' }}>
                                                    {tax.num_nota_fiscal}
                                                </td>

                                                <td style={{ verticalAlign: 'middle' }}>{tax.nome_fornecedor ? tax.nome_fornecedor : 'SEM INDENTIFICAÇÃO' }</td>
                                                
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_total_nota_fiscal, 2, ',', '.')}</td>
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_vTotTrib, 2, ',', '.')}</td>
                                                
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_ICMS_vICMS, 2, ',', '.')}</td>
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_ICMS_vICMSST, 2, ',', '.')}</td>
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_ICMS_vCredICMSSN, 2, ',', '.')}</td>
                                                
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_PIS_vPIS, 2, ',', '.')}</td>

                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_COFINS_vCOFINS, 2, ',', '.')}</td>
                                                
                                                <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(tax.vlr_IPI_vIPI, 2, ',', '.')}</td>
                                            </tr>   
                                        ))}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <th className="text-right" colSpan={3}>Totais</th>
                                            
                                            <th className="text-right">R$ {numberFormat(vlrTotalNotasFiscais, 2, ',', '.')}</th>
                                            <th className="text-right">R$ {numberFormat(vlr_vTotTrib, 2, ',', '.')}</th>
                                            
                                            <th className="text-right">R$ {numberFormat(vlr_ICMS_vICMS, 2, ',', '.')}</th>
                                            <th className="text-right">R$ {numberFormat(vlr_ICMS_vICMSST, 2, ',', '.')}</th>
                                            <th className="text-right">R$ {numberFormat(vlr_ICMS_vCredICMSSN, 2, ',', '.')}</th>
                                            
                                            <th className="text-right">R$ {numberFormat(vlr_PIS_vPIS, 2, ',', '.')}</th>
                                            
                                            <th className="text-right">R$ {numberFormat(vlr_COFINS_vCOFINS, 2, ',', '.')}</th>

                                            <th className="text-right">R$ {numberFormat(vlr_IPI_vIPI, 2, ',', '.')}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </CardBody>
                        </Card>
                     </Col>
                </Row>
            </Container>
        </>
    )

}

export default PageView
