import api from '../api';

class Service extends api {

    getComissoesRepresentantes = (params) => {
        
        let url_params = this.generateQueryString(params);
        
        return this.api.get(`/relatorio/apuracao/comissao/vendedores${url_params}`);
    }

    getVendasComissoesRepresentantes = (id_apuracao) => {
        
        return this.api.get(`/relatorio/apuracao/comissao/vendedor/${id_apuracao}/vendas`);
    }

    getParcelasComissoesRepresentantes = (id_apuracao) => {

        return this.api.get(`/relatorio/apuracao/comissao/vendedor/${id_apuracao}/parcelas`);
    }
}

export default Service;