import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    FormInput
} from "shards-react";

import { Form } from '@unform/web';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PageTitle from "../components/common/PageTitle";
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import { useAuth } from '~/hooks/Auth';
import _ from 'lodash';
import { numberFormat } from '~/utils/formatValue';
import { 
    PurchaseRequestProviderService
  } from '~/services/WebliniaERPAPI';


function PageView(props) {

    const [totalOrder, setTotalOrder] = useState(0);
    const [freightValue, setFreightValue] = useState(0);
    const { addToast } = useToasts();
    const [formData, setFormData] = useState(null);
    const [products, setProducts] = useState(null);

    useEffect(() => {
        let urlParams = queryString.parse(props.location.search);
        if (urlParams && urlParams.id)
            getDataById(urlParams.id);
    }, []);

    const getDataById = async (id) => {
        const service = new PurchaseRequestProviderService();
        try {
            const { data: _formData } = await service.getById(id);
            setFormData(_formData);

            const { data: _itens } = await service.getItensById(id);
            setProducts(_itens);
        }
        catch(error) {
          console.log(error);
        }
    }

    const updateUnitProductValue = (id, value) =>{
        let _products = _.cloneDeep(products);
        _products = _products.map(_product => {
            if (_product.id === id) {
                _product.vlr_unitario = value;
                _product.vlr_subtotal = (_product.vlr_unitario * _product.qtd_solicitada);
            }
            return _product;
        });
        setProducts(_products);
        calcTotalOrder(_products, freightValue);
    };

    const calcTotalOrder = (_products, _freightValue) => {
        let vlr_total = 0;

        _products.forEach((product, index) => {
            vlr_total += product.vlr_subtotal ;
        });

        setTotalOrder(vlr_total + _freightValue);
    };

    const validateForm = () => {
        let isValid = true;
        products.forEach(product => {
            if (!(product.vlr_unitario && !isNaN(product.vlr_unitario) && parseFloat(product.vlr_unitario) > 0)) {
                isValid = false;
            }
        });
        return isValid;
    }

    const save = async () => {
        if ( validateForm() ) {
            let payload = _.cloneDeep(formData);
                payload.vlr_frete = freightValue;
                payload.itens = _.cloneDeep(products);
            
            try {
                    const service = new PurchaseRequestProviderService();
                    const { data } = await service.update(formData.id, payload);
                    console.log(data);
                    addToast(`Ação realizada com sucesso!`, {
                        placement: 'bottom-right',
                        appearance: 'success',
                        autoDismiss: true
                    });
            }
            catch(error) {
                const { response: { status, data } } = error;
                switch (status) {
                  case 406:
                    addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                      placement: 'bottom-right',
                      appearance: 'warning',
                      autoDismiss: true
                    });
                    break;
                  case 500:
                    addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                      placement: 'bottom-right',
                      appearance: 'error',
                      autoDismiss: true
                    });
                    break;
                  default:
                    break;
                }
            }
        }
        else {
            addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
              });
        }
    } 

    return (
        <>
            <Container fluid className='main-content-container px-4 pb-4'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Solicitação de Cotação' 
                        subtitle='Pedido de Compra' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                            <table className='table table-bordered table-condensed table-hover mb-0'>
                                <thead>
                                    <th width={50} className='text-middle text-center' >Item</th>
                                    <th width={100} className='text-middle text-center' >Código</th>
                                    <th className='text-middle'>Produto/Insumo</th>
                                    <th width={150} className='text-middle text-center'>Un. Medida</th>
                                    <th width={120} className='text-middle text-center'>Qtd.</th>
                                    <th width={150} className='text-middle text-center'>R$ Unit.</th>
                                    <th width={130} className='text-middle text-right'>Subtotal</th>
                                </thead>
                                <tbody>
                                    {products && products.map((product, index) =>(
                                        <tr>
                                            <td className='text-center' style={{ verticalAlign: 'middle' }}>{index+1}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle' }}>{product.id}</td>
                                            <td style={{ verticalAlign: 'middle' }}>{product.nome}</td>
                                            <td className='text-center' style={{ verticalAlign: 'middle' }}>{product.dsc_unidade_medida}</td>
                                            <td className='text-right' style={{ verticalAlign: 'middle' }}>
                                                {numberFormat(product.qtd_solicitada, 10, ',', '.')}
                                            </td>
                                            <td className='text-center' style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat 
                                                    className="text-right"
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={product.vlr_unitario}
                                                    defaultValue= {product.vlr_unitario}
                                                    onValueChange={({floatValue}) => updateUnitProductValue(product.id, floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td className='text-right' style={{ verticalAlign: 'middle' }}>
                                                R$ {numberFormat(product.vlr_subtotal, 2, ',', '.')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th className='text-right' colSpan={6} style={{ verticalAlign: 'middle' }}> Valor do Frete</th>
                                        <th className='text-right' >
                                            <NumberFormat 
                                                className="text-right"
                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                value={freightValue}
                                                defaultValue= {freightValue}
                                                onValueChange={({floatValue}) => {
                                                    setFreightValue(floatValue);
                                                    calcTotalOrder(products, floatValue);
                                                }}
                                                customInput={FormInput}
                                            />
                                        </th>
                                    </tr>
                                    <tr>
                                    <th className='text-right' colSpan={6}> Valor Total</th>
                                    <th className='text-right'>R$ {numberFormat(totalOrder, 2, ',', '.')}</th>
                                    </tr>
                                </tfoot>
                            </table>
                            <CardFooter 
                                className="text-right">
                                <Button 
                                    theme="accent" 
                                    size="md"
                                    onClick={save}
                                >
                                    <i className="material-icons">save</i> Salvar
                                </Button>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PageView
