import React, { useCallback, useRef, useEffect, useState } from 'react'
import { NavLink, Link } from "react-router-dom";
import { Form } from '@unform/web';
import {
	Container,
	Row,
	Col,
	Card,
	Button,
    FormInput,
	CardHeader,
	CardBody,
    Badge
} from 'shards-react';
import { Select, InputMask } from '../components/unform';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import _ from 'lodash';
import { useToasts } from 'react-toast-notifications';
import PageTitle from '../components/common/PageTitle';
import FileUploadInput from '../components/file-upload-input';
import CustomModal from '../components/modal';
import { numberFormat } from '~/utils/formatValue';

import { useAuth } from '~/hooks/Auth';
import { 
    ProductsPriceService, 
    ConfigurationsService 
} from '../services/WebliniaERPAPI';

export default function ProductPriceImportView() {
    const { addToast } = useToasts();
    const userLoggedData = useAuth();
    const formRef = useRef(null);
    
    const [isReadingFile, setIsReadingFile] = useState(false);
    const [isSavingImport, setIsSavingImport] = useState(false);
    const [isShowProductsModal, setIsShowProductsModal] = useState(false);
    const [modalFilterParams, setModalFilterParams] = React.useState([]);
    const [configs, setConfigs] = useState(null);
    const [integratedPlatforms, setIntegratedPlatforms] = useState([
        {id: 'netshoes', nome: 'Netshoes'},
        {id: 'mercadolivre', nome: 'Mercado Livre'},
        {id: 'skyhub', nome: 'B2W'},
        {id: 'integracommerce', nome: 'Magazine Luiza'},
        {id: 'centauro', nome: 'Centauro'}
    ]);
    const [activeColumns, setActiveColumns] = useState({
        vlr_custo: true, 
        vlr_venda_atacado: true, 
        vlr_venda_intermediario: true, 
        vlr_venda_intermediario_ii: true, 
        vlr_venda_varejo: true,
        vlr_venda_original: true,
        vlr_venda_promocional: true
    });
    const [platformSelected, setPlatformSelected] = useState(null);
    const [filedSelected, setFileSelected] = useState(null);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        loadEmpConfigs();
    }, []);

    const loadEmpConfigs = async () => {
        try {
            const service = new ConfigurationsService();
            const { data: _configs } = await service.getConfigurations(userLoggedData.enterprise.id_empreendimento);
            setConfigs(_configs);
        }
        catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        defineActiveColumns();
    }, [configs, products]);

    const defineActiveColumns = useCallback(() => {
        if (configs) {
            let _activeColumns = _.clone(activeColumns);
            switch (configs.flg_modo_preco) {
                case 'tabela':
                    _activeColumns.vlr_custo                  = hasColumnConfig(null, 'vlr_custo');
                    _activeColumns.vlr_venda_atacado          = hasColumnConfig('atacado', 'vlr_venda_atacado');
                    _activeColumns.vlr_venda_intermediario    = hasColumnConfig('intermediario', 'vlr_venda_intermediario');
                    _activeColumns.vlr_venda_intermediario_ii = hasColumnConfig('intermediario_ii', 'vlr_venda_intermediario_ii');
                    _activeColumns.vlr_venda_varejo           = hasColumnConfig('varejo', 'vlr_venda_varejo');
                    _activeColumns.vlr_venda_original         = false;
                    _activeColumns.vlr_venda_promocional      = false;
                    break;
                case 'plataforma':
                    _activeColumns.vlr_custo                  = false;
                    _activeColumns.vlr_venda_atacado          = false;
                    _activeColumns.vlr_venda_intermediario    = false;
                    _activeColumns.vlr_venda_intermediario_ii = false;
                    _activeColumns.vlr_venda_varejo           = false;
                    _activeColumns.vlr_venda_original         = true;
                    _activeColumns.vlr_venda_promocional      = true;
                    break;
            }
            setActiveColumns(_activeColumns);
        }
    }, [configs, products]);

    const hasColumnConfig = useCallback((column, field) => {
        let can_show = false;
        
        if (column) {
            // valida se esta configurado p/ utilizar a tabela de preço
            if (configs && configs.tabela_de_vendas) {
                JSON.parse(configs.tabela_de_vendas).forEach(tabela => {
                    if(tabela.name === column && parseInt(tabela.value, 10) === 1) {
                        can_show = true;
                    }
                });
            }
        }
        else {
            can_show = true;
        }
        
        if ( products && products.length > 0 ) {
            // valida se a coluna veio na estrutura de dados da planilha
            if ( typeof(products[0][field]) === 'undefined' ) {
                can_show = false;
            }
        }

        return can_show;
    }, [configs, products]);

    const modalToggle = useCallback(() => {
		setModalFilterParams([{
			key: 'withCombinacoes',
			value: 'true'
		}]);
    	setIsShowProductsModal(!isShowProductsModal);
  	}, [isShowProductsModal]);
    
    const handleSelectProduct = useCallback((list, product, _products) => {
		_products = (_products) ? _products : _.clone(products);
		
		if(!_products) {
			_products = [];
		}

		let can_add = true;

		_products.forEach(_product => {
			if (parseInt(_product.id_produto, 10) === parseInt(product.id_produto, 10)) {
				can_add = false;
			}
		});

		if (can_add) {
			if(!product.img || product.img.length === 0)
				product.img = 'https://via.placeholder.com/100x100.png?text=PRODUCT';
			else
				product.img = `https://crn.ngweb.net.br/${product.img.replace('/home/webliniaerp/www/', '')}`;

			_products.push({
                id_produto: product.id_produto,
                nome_produto: product.nome,
                vlr_venda_atacado: product.vlr_venda_atacado,
                vlr_venda_intermediario: product.vlr_venda_intermediario,
                vlr_venda_intermediario_ii: product.vlr_venda_intermediario_ii,
                vlr_venda_varejo: product.vlr_venda_varejo,
                vlr_venda_original: product.vlr_venda_original,
                vlr_venda_promocional: product.vlr_venda_varejo,
                db_info: product
            });
			
            setProducts(_products);
		}
		else {
			addToast('O produto selecionado já foi incluído na lista de importação!', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
		}
  	}, [products]);

    const downloadModelFile = () => {
        window.location.href = 'https://api.crn.ngweb.net.br/files/modelo-importacao-precos.csv';
    }

    const onFileSelected = (file) => {
        if (configs && configs.flg_modo_preco === 'plataforma' && !platformSelected) {
            cancelImport();
            addToast('Você deve selecionar a plataforma que deseja atualizar!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
            });
            return false;
        }

        console.log(file);
        setFileSelected(file);
        setIsReadingFile(true);
        uploadFile(file);
    }

    const uploadFile = async (file) => {
        try {
            let formData = new FormData();
                formData.append('id_empreendimento', userLoggedData.enterprise.id_empreendimento);
                formData.append('file', file);
            
            if (configs && configs.flg_modo_preco === 'plataforma' && platformSelected) {
                formData.append('nome_plataforma_ecommerce', platformSelected);
            }
            
            const service = new ProductsPriceService();
            const { data } = await service.readFileProductPrices(formData);
            setIsReadingFile(false);
            console.log(data);
            setProducts(data);
        }
        catch(error) {
            if (error.response) {
                const { response: { data: error_message } } = error;
                addToast(error_message, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
            }
            cancelImport();
        }
    }

    const updateFieldValue = useCallback((data, field, value) => {
        const _products = products.map((product) => {
            if(product.id_produto === data.id_produto) {
                product[field] = parseFloat(value.replace('.','').replace(',','.'));
            }
            return product;
        });
        setProducts(_products);
    },[products])

    const cancelImport = () => {
        setFileSelected(null);
        setIsReadingFile(false);
        setProducts([]);
    }

    const removeProduct = useCallback((product, index) => {
		let _products = _.clone(products);
		
		if (_products)
			_products.splice(index, 1);

		if (_products && _products.length > 0)
			setProducts(_products);
	}, [products]);

    const saveImport = async () => {
        setIsSavingImport(true);
        const _products = products.map(product => {
            if (product && product.db_info) {
                if (typeof(product.vlr_custo) === 'undefined') {
                    product.vlr_custo = product.db_info.vlr_custo;
                }
    
                Object.keys(activeColumns).map(column => {
                    if (column === 'vlr_custo') {
                        if (typeof(product.vlr_custo) !== 'undefined') {
                            if (typeof(product.vlr_custo) === "string")
                                product.vlr_custo = product.vlr_custo.replace('.','').replace(',', '.');
                        }
                    }
                    else {
                        if (product.db_info) {
                            let percent_column = column.replace('vlr', 'perc');
                            if (typeof(product[column]) !== 'undefined') {
                                if (typeof(product[column]) === "string")
                                    product[column] = product[column].replace('.','').replace(',', '.');
                                product[percent_column] = ((parseFloat(product[column]) / parseFloat(product.vlr_custo)) - 1);
                            }
                            else
                                product[percent_column] = product.db_info[percent_column]
                        }
                    }
                });
            }

            return product;
        });
        let payload = {
            id_empreendimento: userLoggedData.enterprise.id_empreendimento, 
            produtos: _products
        };
        if (configs && configs.flg_modo_preco === 'plataforma' && platformSelected) {
            payload.nome_plataforma_ecommerce = platformSelected;
        }
        try {
            const service = new ProductsPriceService();
            const { data } = await service.saveProductPriceImport(payload);
            console.log(data);
            setIsSavingImport(false);
            addToast('Importação realizada e preços de produtos atualizados com sucesso!', {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            cancelImport();
        }
        catch(error) {
            console.log(error);
            addToast(JSON.stringify(error), {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
        }
    }

    return (
        <>
			<Container fluid className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle 
						title='Importação de Preços' 
						subtitle='Cadastro de Produtos' 
						className='text-sm-left'
					/>
					<Col sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>

					<Col sm="4" className="d-flex justify-content-end">
                        {(!filedSelected) ? (
                            <>
                                <Button outline theme="secondary" style={{ lineHeight: '2.4em' }}
                                    onClick={downloadModelFile}>
                                    <i className="material-icons">download</i> Baixar arquivo modelo
                                </Button>
                                &nbsp;
                                <FileUploadInput handleFileUpload={onFileSelected} />
                            </>
                        ) : (isReadingFile) && (
                            <span style={{ lineHeight: 3.4, verticalAlign: 'middle' }}>
                                <i className="fa fa-spin fa-spinner"/> Aguarde, lendo arquivo...
                            </span>
                        )}
					</Col>
				</Row>

                <Card small className='mb-3'>
					<CardHeader className="border-bottom">
						<Row>
                            <Col sm="6" className="col d-flex align-items-center">
                                <h6 className="m-0">
                                    Produtos p/ Atualização
                                    {(products && products.length > 0) && (
                                        <>
                                            <br/>
                                            <small><strong>{products.length} produtos</strong> encontrados no arquivo <strong>{filedSelected && filedSelected.name}</strong></small>
                                        </>
                                    )}
                                </h6>
                            </Col>
                            <Col sm="6" className="d-flex justify-content-end">
                                {(configs && configs.flg_modo_preco === 'plataforma') && (
                                    <Form ref={formRef}>
                                        <Select
                                            name='view'
                                            label='Plataforma'
                                            data={integratedPlatforms}
                                            noMargin
                                            onChange={(e) => {setPlatformSelected(e.target.value)}}
                                        />
                                    </Form>
                                )}
                                {(products && products.length > 0) && (
                                    <>
                                        {(isSavingImport) ? (
                                            <span style={{ lineHeight: 3.4, verticalAlign: 'middle' }}>
                                                <i className="fa fa-spin fa-spinner"/> Aguarde, salvando importação...
                                            </span>
                                        ) : (
                                            <>
                                                &nbsp;
                                                <Button outline theme="secondary" style={{ lineHeight: '2.4em' }}
                                                    onClick={cancelImport}>
                                                    <i className="material-icons">cancel</i> Cancelar
                                                </Button>
                                                &nbsp;
                                                <Button outline theme="primary" 
                                                    style={{ lineHeight: '2.4em' }}
                                                    onClick={() => modalToggle()}
                                                >
                                                    <i className="material-icons">add_circle</i> Adicionar Produto
                                                </Button>
                                                &nbsp;
                                                <Button 
                                                    theme="success" style={{ lineHeight: '2.4em' }}
                                                    onClick={() => saveImport()}
                                                >
                                                    <i className="material-icons">save</i> Salvar Importação
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
					</CardHeader>
					
					<CardBody>
						<Container fluid>
                            <table className="table table-bordered table-condensed table-hover mb-0">
                                <thead>
                                    <tr>
                                        <th rowSpan={3} className="text-center" width="50">#</th>
                                        <th rowSpan={3} className="text-center" width="90">Código</th>
                                        <th rowSpan={3}>Produto</th>
                                        {(activeColumns.vlr_custo) && (<th rowSpan={2} colSpan={2} className="text-center">Custo</th>)}
                                        {(activeColumns.vlr_venda_atacado || activeColumns.vlr_venda_intermediario || activeColumns.vlr_venda_intermediario_ii || activeColumns.vlr_venda_varejo || activeColumns.vlr_venda_original || activeColumns.vlr_venda_promocional) && (
                                            <th colSpan={12} className="text-center">Valores de Venda</th>
                                        )}
                                    </tr>
                                    <tr>
                                        {(activeColumns.vlr_venda_atacado) && (<th colSpan={2} className="text-center">Atacado</th>)}
                                        {(activeColumns.vlr_venda_intermediario) && (<th colSpan={2} className="text-center">Intermediário</th>)}
                                        {(activeColumns.vlr_venda_intermediario_ii) && (<th colSpan={2} className="text-center">Intermediário II</th>)}
                                        {(activeColumns.vlr_venda_varejo) && (<th colSpan={2} className="text-center">Varejo</th>)}
                                        
                                        {(activeColumns.vlr_venda_original) && (<th colSpan={2} className="text-center" width="130">Original</th>)}
                                        {(activeColumns.vlr_venda_promocional) && (<th colSpan={2} className="text-center" width="130">Final</th>)}
                                    </tr>
                                    <tr>
                                        {(activeColumns.vlr_custo) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_custo) && (<th className="text-center" width="130">Novo</th>)}

                                        {(activeColumns.vlr_venda_atacado) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_atacado) && (<th className="text-center" width="130">Novo</th>)}
                                        {(activeColumns.vlr_venda_intermediario) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_intermediario) && (<th className="text-center" width="130">Novo</th>)}
                                        {(activeColumns.vlr_venda_intermediario_ii) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_intermediario_ii) && (<th className="text-center" width="130">Novo</th>)}
                                        {(activeColumns.vlr_venda_varejo) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_varejo) && (<th className="text-center" width="130">Novo</th>)}
                                        
                                        {(activeColumns.vlr_venda_original) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_original) && (<th className="text-center" width="130">Novo</th>)}
                                        {(activeColumns.vlr_venda_promocional) && (<th className="text-center" width="130">Atual</th>)}
                                        {(activeColumns.vlr_venda_promocional) && (<th className="text-center" width="130">Novo</th>)}
                                    </tr>
                                </thead>
                                <tbody>
                                    {products && products.map((product, index) => {
                                        return (
                                            <tr key={product.id_produto.toString()} className={(!product.db_info) ? 'bg-danger' : ''}>
                                                <td className={`text-center ${(!product.db_info) ? 'text-white' : ''}`}>
                                                    {(index+1)}
                                                </td>

                                                <td className={`text-center ${(!product.db_info) ? 'text-white' : ''}`}>
                                                    #{product.id_produto}
                                                    <br/>
                                                    <small>
                                                        <a href="#" 
                                                            className={(!product.db_info) ? 'text-white' : 'text-danger'}
                                                            onClick={() => removeProduct(product, index)}
                                                        >
                                                            <i className="material-icons">delete</i> Remover
                                                        </a>
                                                    </small>
                                                </td>

                                                <td className={`${(!product.db_info) ? 'text-white' : ''}`}>
                                                    <strong>{product.db_info && product.db_info.nome}</strong>
                                                    <br/>
                                                    <small>
                                                        {product.db_info && product.db_info.nome_fabricante && (
                                                            <Badge theme="secondary" style={{ padding: '3px', borderRadius: '3px', fontSize: '100%' }}>
                                                                {product.db_info.nome_fabricante}
                                                            </Badge>
                                                        )}
                                                        {product.db_info && product.db_info.nome_tamanho && (
                                                            <>&nbsp;•&nbsp; {product.db_info.nome_tamanho}</>
                                                        )}
                                                        {product.db_info && product.db_info.nome_cor_sabor && (
                                                            <>&nbsp;•&nbsp; {product.db_info.nome_cor_sabor}</>
                                                        )}
                                                    </small>
                                                </td>

                                                {(activeColumns.vlr_custo) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_custo, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_custo = value.floatValue }}
                                                                defaultValue={product ? product.vlr_custo : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}

                                                {(activeColumns.vlr_venda_atacado) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_atacado, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_atacado = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_atacado : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}

                                                {(activeColumns.vlr_venda_intermediario) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_intermediario, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_intermediario = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_intermediario : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                                
                                                {(activeColumns.vlr_venda_intermediario_ii) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_intermediario_ii, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_intermediario_ii = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_intermediario_ii : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}

                                                {(activeColumns.vlr_venda_varejo) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_varejo, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_varejo = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_varejo : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}

                                                {(activeColumns.vlr_venda_original) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_original, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_original = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_original : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}

                                                {(activeColumns.vlr_venda_promocional) && (
                                                    <>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <FormInput 
                                                                className="text-right" 
                                                                disabled={true}
                                                                style={{ backgroundColor: '#dcdcdc' }}
                                                                value={product.db_info && `R$ ${numberFormat(product.db_info.vlr_venda_varejo, 2, ',', '.')}`} 
                                                            />
                                                        </td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            <NumberFormat 
                                                                className="text-right" 
                                                                disabled={(!product.db_info)}
                                                                style={{ backgroundColor: (!product.db_info) ? '#dcdcdc' : '#fff' }}
                                                                decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                                allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                onValueChange={(value) => { product.vlr_venda_varejo = value.floatValue }}
                                                                defaultValue={product ? product.vlr_venda_varejo : 0}
                                                                customInput={FormInput}
                                                            />
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </Container>
                    </CardBody>
                </Card>
            </Container>
            
            {(isShowProductsModal) && (
				<CustomModal
					type={'ListProduct'}
					size="lg"
					open={true}
					toggle={() => {modalToggle()}}
					selectCallback={handleSelectProduct}
					filterParams={modalFilterParams}
				/>
			)}
        </>
    )
}
