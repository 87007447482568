import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, NavLink, Link } from 'react-router-dom';
import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Badge,
	FormInput,
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Button,
	ButtonGroup,
	CardHeader,
	ListGroup,
	ListGroupItem
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications'
import moment from 'moment';
import _ from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input, InputGroup, InputMask } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import CustomModal from '../components/modal';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
	ProductsService, 
	ConfigurationsService, 
	WarehousesService,
	OPService,
	OPPlaningService
} from '~/services/WebliniaERPAPI';
import { numberFormat } from '~/utils/formatValue';
import { useAuth } from '~/hooks/Auth';

function PageView(props) {
	const formRef = useRef(null);
	const userLoggedData = useAuth();
	const { addToast } = useToasts();

	const [modalsOpen, setModalsOpen] = React.useState({});
	const [modalFilterParams, setModalFilterParams] = React.useState([]);
	const [products, setProducts] = useState(null);
	const [productInputs, setProductInputs] = useState(null);
	const [shoppingList, setShoppingList] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
	const [isOpenModalConfirmation, setIsOpenModalConfirmation] = useState(false);
	const [isShowProductsModal, setIsShowProductsModal] = useState(false);
	const [vlrTotalInvestimento, setVlrTotalInvestimento] = useState(0);
	const [planingNameSearch, setPlaningNameSearch] = useState(null);
	const [planings, setPlanings] = useState(null);
	const [planingName, setPlaningName] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [planingData, setPlaningData] = useState(null);
	const [packingMaterial, setPackingMaterial] = useState([]);
	const [feedStock, setFeedStock] = useState([]);
	
	useEffect(()=>{
		let urlParams = queryString.parse(props.location.search);
		loadPlanings(0);
		if (urlParams && urlParams.id) {
			loadPlaningById(urlParams.id);
			
		}
	},[]);

	const loadPlaningById = async (id_plan_op) => {
		const opPlaningService = new OPPlaningService();
		try {
			const { data } = await opPlaningService.getById(id_plan_op);
			setPlaningData(data);
			setPlaningName(data.dsc_planejamento);
			setStartDate(moment(data.dta_inicio_vigencia).format('YYYY-MM-DD'));
			
			let _products = [];

			data.itens.forEach(item => {
				item.qtd_produzir = (item.qtd_produzir) ? parseFloat(item.qtd_produzir) : 0;

				if(!item.img || item.img.length === 0)
					item.img = 'https://via.placeholder.com/100x100.png?text=PRODUCT';
				else
					item.img = `https://crn.ngweb.net.br/${item.img.replace('/home/webliniaerp/www/', '')}`;
				
				_products.push(item);
				
				// if (data.insumos_other_planings && data.insumos_other_planings.length > 0) {
				// 	item.insumos = item.insumos.map(_input => {
				// 		data.insumos_other_planings.map(_insOther => {
				// 			if(parseInt(_input.id,10) === parseInt(_insOther.id_insumo, 10)) {
				// 				if (_input.qtd_estoque_disponivel && parseFloat(_input.qtd_estoque_disponivel) > 0) {
				// 					_input.qtd_estoque_disponivel = (_input.qtd_estoque_disponivel - _insOther.qtd_necessidade);
				// 				}
				// 			}
				// 		});

				// 		return _input;
				// 	});
				// }

				updateProductInputs(_products, item, item.insumos);
				updateProductQuantity(item, item.qtd_produzir, _products);
			});
			
			setProducts(_products);
			window.scrollTo(0, 0);
		}
		catch(error) {
			console.log(error);
		}
	}

	const loadPlanings = async (offset) => {
		try {
			setPlanings([]);
			let params = [{
				key: 'tpp->id_empreendimento',
				value: userLoggedData.enterprise.id_empreendimento
			}, {
				key: 'tpp->flg_excluido',
				value: 0
			}];
			if (planingNameSearch && planingNameSearch.length > 0) {
				params.push({
					key: '(tpp->dsc_planejamento[exp]',
					value: `LIKE '%${planingNameSearch}%')`
				});
			}
			const opPlaningService = new OPPlaningService();
			const { data } = await opPlaningService.getAll(offset, 5, params);
			setPlanings(data);
		}
		catch(error) {
			setPlanings(null);
			console.log(error);
		}
	}

	const modalToggle = useCallback(() => {
		setModalFilterParams([{
			key: 'pro->flg_produto_composto',
			value: 1
		},{
			key: 'pro->flg_utiliza_balanca',
			value: 1
		},{
			key: 'withCombinacoes',
			value: 'true'
		}]);
    	setIsShowProductsModal(!isShowProductsModal);
  	}, [isShowProductsModal]);

	const handleSelectProduct = useCallback((list, product, _products) => {
		_products = (_products) ? _products : _.clone(products);
		
		if(!_products) {
			_products = [];
		}

		let can_add = true;

		_products.forEach(_product => {
			if (_product.id_produto === product.id_produto) {
				can_add = false;
			}
		});

		if (can_add) {
			if(!product.img || product.img.length === 0)
				product.img = 'https://via.placeholder.com/100x100.png?text=PRODUCT';
			else
				product.img = `https://crn.ngweb.net.br/${product.img.replace('/home/webliniaerp/www/', '')}`;
			
			product.qtd_produzir = (product.qtd_produzir) ? parseFloat(product.qtd_produzir) : 0;

			_products.push(product);
			setProducts(_products);
			if(!product.insumos || product.insumos.length === 0)
				loadProductInputs(_products, product);
			else {
				updateProductInputs(_products, product, product.insumos);
				updateProductQuantity(product, product.qtd_produzir, _products);
			}
		}
		else {
			addToast('O produto selecionado já foi incluído na lista de produção!', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
		}
  	}, [products, productInputs]);

	const clearFormData = (message, appearance) => {
		setIsLoading(false);
		setPlaningName(null);
		setStartDate(null);
		setProducts(null);
		setProductInputs(null);
		setShoppingList(null);
		setVlrTotalInvestimento(0);
		loadPlanings(0);
		if(message) {
			addToast(message, {
				placement: 'bottom-right',
				appearance: appearance,
				autoDismiss: true
			});
		}
	}

	const loadProductInputs = useCallback(async (_products, product) => {
		let params = [{
			key: 'tpe->id_empreendimento',
			value: userLoggedData.enterprise.id_empreendimento
		}, {
			key: 'deduce_production_plannings',
			value: true
		}];

		if (planingData && planingData.id) {
			params.push({
				key: 'id_ordem_producao',
				value: planingData.id
			})
		}
		
		const productsService = new ProductsService();
		
		try {
			const { data: inputs } = await productsService.getProductInputs(product.id, params);
			updateProductInputs(_products, product, inputs);
		}
		catch(error) {
			addToast('O produto selecionado não possúi nenhum insumo cadastrado!', {
				placement: 'bottom-right',
				appearance: 'warning',
				autoDismiss: true
			});
			let idx = -1;
			_products.forEach((_product, index) => {
				if (_product.id === product.id)
					idx = index;
			});
			removeProduct(idx);
		}
	}, [products, productInputs]);

	const updateProductInputs = useCallback((_products, product, inputs) => {
		_products = _.clone(_products);
		_products = _products.map(_product => {
			if (_product.id_produto === product.id_produto) {
				_product.inputs = inputs.map(input => {
					if (!input.qtd_estoque_disponivel)
						input.qtd_estoque_disponivel = 0;
					if (!input.vlr_custo)
						input.vlr_custo = 0;
					return input;
				});
			}
			return _product;
		});
		setProducts(_products);
		generateGeneralInputsList(_products);
	}, [products, productInputs]);

	const updateProductQuantity = useCallback((product, quantity, _products) => {
		let _prds = _products.map(_product => {
			if (_product.id_produto === product.id_produto) {
				_product.qtd_produzir = parseFloat(quantity);
				_product.inputs = recalcProductInputsNeedle(product);
			}
			return _product;
		});
		setProducts(_prds);
		generateGeneralInputsList(_prds);
	}, [products, productInputs]);

	const updateProductShowInputs = useCallback(product => {
		let _products = products.map(_product => {
			if (_product.id_produto === product.id_produto) {
				_product.isShowInputs = (!_product.isShowInputs);
			}
			return _product;
		});
		setProducts(_products);
	}, [products]);

	const recalcProductInputsNeedle = (product) => {
		if (product.qtd_produzir) {
			let inputs = product.inputs.map(_productInput => {
				_productInput.qtd_necessidade = (_productInput.qtd * product.qtd_produzir);
				if (_productInput.prc_perda_producao) {
					let qtd_adicional = (_productInput.qtd_necessidade * _productInput.prc_perda_producao);
					// Fração
					if (_productInput.flg_unidade_fracao && parseInt(_productInput.flg_unidade_fracao, 10) === 1) {
						_productInput.qtd_necessidade += qtd_adicional;
					}
					// Unidade
					else {
						_productInput.qtd_necessidade += Math.ceil(qtd_adicional);
					}
				}
				return _productInput;
			});
			return inputs;
		}
		else
			return product.inputs;
	}

	const generateGeneralInputsList = (_products) => {
		let _productInputs = [];

		_products.forEach(_product => {
			_product.inputs.forEach(_input => {
				let can_add = true;
				_productInputs.forEach(_pi => {
					if ( _pi.id === _input.id )
						can_add = false;
				});
				if ( can_add )
					_productInputs.push(_.clone(_input));
				else {
					for(let i = 0; i < _productInputs.length; i++) {
						if ( _productInputs[i].id === _input.id ) {
							if (_input.qtd_necessidade && _input.qtd_necessidade > 0)
								_productInputs[i].qtd_necessidade += _input.qtd_necessidade;
						}
					}
				}
			});
		});

		setProductInputs(_productInputs);
		generateShoppingList(_productInputs);
	}

	const generateShoppingList = (_productInputs) => {
		let shopping_list = [];
		let vlr_total_investimento = 0;
		_productInputs.forEach(_productInput => {
			if(_productInput.qtd_estoque_disponivel < _productInput.qtd_necessidade) {
				vlr_total_investimento += (_productInput.vlr_custo * _productInput.qtd_necessidade);
				shopping_list.push(_.clone(_productInput));
			}
		});

		setShoppingList(shopping_list);
		setVlrTotalInvestimento(vlr_total_investimento);
	}

	const validateData = () => {
		if (products && products.length > 0) {
			let has_error_qtd = false;
			products.forEach(product => {
				if (!product.qtd_produzir || parseFloat(product.qtd_produzir) === 0) {
					has_error_qtd = true;
				}
			});
			if(has_error_qtd) {
				addToast('Você deve informar a quantidade que deseja produzir p/ gerar o planejamento', {
					placement: 'bottom-right',
					appearance: 'warning',
					autoDismiss: true
				});
				return false;
			}
			else
				return true;
		}
		else {
			if(planingData.id){
				addToast('Você salvou um planejamento sem produtos', {
					placement: 'bottom-right',
					appearance: 'warning',
					autoDismiss: true
				});
				return true;
			}else{
				addToast('Você deve selecionar ao menos um produto p/ gerar o planejamento', {
					placement: 'bottom-right',
					appearance: 'warning',
					autoDismiss: true
				});
				return false;
			}
		}
	}

	const savePlaning = async (isDraftMode=false) => {
		if(validateData()) {
			setIsLoading(true);
			try {
				
				const opPlaningService = new OPPlaningService();
				
				let payload = {
					id_usuario: userLoggedData.user.id,
					id_empreendimento: userLoggedData.enterprise.id_empreendimento,
					dsc_planejamento: planingName ? planingName : 'N/D',
					dta_inicio_vigencia: startDate ? startDate : null,
					itens: products.map(product => {
						return {
							id_produto: product.id_produto,
							qtd_produzir: product.qtd_produzir
						}
					})
				};

				if (!planingData) {
					const { data: response } = await opPlaningService.save(payload);

					if( !isDraftMode )
						publishPlaning(response.id);
					else
						clearFormData('Rascunho do planejamento gerado com sucesso!', 'success');
						setPlaningData(null);
				}
				else {
					const { data: response } = await opPlaningService.update(planingData.id, payload);
					if( !isDraftMode )
						publishPlaning(planingData.id);
					else
						clearFormData('Rascunho do planejamento atualizado com sucesso!', 'success');
						setPlaningData(null);
				}
				setIsLoading(false);
				loadPlanings(0);
 			}
			catch(error) {
				console.log(error);
				setIsLoading(false);
			}
		}
	}

	const deletPlaning = async (planing_id,planingData) => {
		if(validateData()) {
			setIsLoading(true);
			try {
				setIsOpenModalDelete(false);
				if(planing_id){
					const opPlaningService = new OPPlaningService();
					await opPlaningService.delete(planing_id,planingData);
						addToast(`Ação realizada com sucesso!`, {
							placement: 'bottom-right',
							appearance: 'success',
							autoDismiss: true
						});
					setIsLoading(false);
					loadPlanings(0);
					clearFormData();
				}
				else{
					addToast(`Erro sem planejamento informado!`, {
						placement: 'bottom-right',
						appearance: 'danger',
						autoDismiss: true
					})
				}
			}
			catch(error) {
				console.log(error);
			}
		
		}
	}

	const publishPlaning = async (planing_id) => {
		setIsLoading(true);
		try {
			let payload = {
				id: planing_id,
				id_usuario: userLoggedData.user.id,
				id_empreendimento: userLoggedData.enterprise.id_empreendimento,
				itens: products.map(product => {
					return {
						id_produto: product.id_produto,
						qtd_produzir: product.qtd_produzir
					}
				})
			};
			
			if(shoppingList && shoppingList.length > 0){
				payload.purchaseOrder = shoppingList.map(product => {
						return {
							cod_produto: product.id,
							qtd_produto: (product.qtd_necessidade - product.qtd_estoque_disponivel),
							vlr_ultimo_custo: product.vlr_custo,
							dsc_unidade_medida: product.dsc_unidade_medida
						}
					});
			}

			const opPlaningService = new OPPlaningService();
			const { data: response } = await opPlaningService.publish(planing_id, payload);
			clearFormData('Planejamento publicado com sucesso!', 'success');
			setIsOpenModalConfirmation(false);
		}
		catch({ response: { data, status } }) {
			// console.log(error);
			let msg = ((typeof data == "string") ? data : 'Ocorreu um erro ao tentar criar o pedido de compra');
			addToast(msg, {
				placement: 'bottom-right',
				appearance: 'error',
				autoDismiss: true
			});
		}
	}

	const removeProduct = (index) => {
		let _products = _.clone(products);
		
		if (_products) {
			_products.splice(index, 1);
		}

		if (_products && _products.length > 0) {
			setProducts(_products);
			generateGeneralInputsList(_products);
		}
		else
			clearFormData();
	}

	return (
		<>
			<Container fluid className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle 
						title='Planejamento Mensal' 
						subtitle='Controle de Produção' 
						className='text-sm-left'
					/>
				</Row>
		
				<Form ref={formRef}>
					<Row>
						<Col lg="9" md="12">
							<Row>
								<Col lg="12">
									<Card small className='mb-3'>
										<CardHeader>
											<Row>
												<Col sm="8">
													<h6 className="m-0">Produtos a Produzir</h6>
												</Col>
												<Col sm="4" className="d-flex justify-content-end">
													{(!planingData || (planingData && !planingData.dta_emissao)) && (
														<Button outline theme="accent"
															onClick={() => modalToggle()}
														>
															<i className="material-icons">add_circle</i> Adicionar Produto
														</Button>
													)}
												</Col>
											</Row>
										</CardHeader>

										<table className="table table-condensed table-hover mb-0">
											<thead className="bg-light">
												<th scope="col" className="text-center" width="120">Código</th>
												<th scope="col" colSpan={2}>Nome do Produto</th>
												<th scope="col">Marca</th>
												<th scope="col" className="text-center" width="150">Tamanho</th>
												<th scope="col" className="text-center" width="150">Cor/Sabor</th>
												<th scope="col" className="text-center" width="120">Qtd. Produzir</th>
											</thead>
											<tbody>
												{products && products.map((product, indexProduct) => {
													let status_color = '';
							
													switch(parseInt(product.id_status_op, 10)) {
														case 4:	// Rascunho
															status_color = 'secondary';
															break;
														case 1:	// Pendente / Aguardando Produção
															status_color = 'warning';
															break;
														case 5:	// Iniciada / Pendente Separação de Insumos
															status_color = 'info';
															break;
														case 6:	// Iniciada / Em Separação de Insumos
															status_color = 'java';
															break;
														case 2:	// Iniciada / Em Produção
															status_color = 'royal-blue';
															break;
														case 3:	// Produção Finalizada
															status_color = 'success';
															break;
														case 7:	// Produção Cancelada
															status_color = 'danger';
															break;
													}

													return (
														<>
															<tr>
																<td style={{ verticalAlign: 'middle' }} className="text-center">
																	<i className="material-icons" onClick={() => updateProductShowInputs(product)} style={{ cursor: 'pointer' }}>
																		{(!product.isShowInputs) ? 'expand_more' : 'expand_less'}
																	</i>
																	&nbsp;
																	{product.id_produto}
																</td>
																<td className="text-center" style={{ width: 30 }}>
																	<img
																		className="border rounded"
																		src={product.img}
																		style={{ height: 40 }}
																	/>
																</td>
																<td>
																	<span>{product.nome}</span>
																	<br/>
																	{(!planingData || (planingData && !planingData.dta_emissao)) ? (
																		<small>
																			<a href="#" className="text-danger" onClick={() => removeProduct(indexProduct)}>
																				<i className="material-icons">delete</i> Remover
																			</a>
																		</small>
																	): (
																		<Badge pill theme={status_color} style={{ fontSize: '60%' }}>
																			{`OP #${product.id_ordem_producao} | ${product.dsc_status_op}`}
																		</Badge>
																	)}
																</td>
																<td style={{ verticalAlign: 'middle' }}>{product.nome_fabricante}</td>
																<td style={{ verticalAlign: 'middle' }} className="text-center">{product.nome_tamanho}</td>
																<td style={{ verticalAlign: 'middle' }} className="text-center">{product.nome_cor}</td>
																<td style={{ verticalAlign: 'middle' }} className="text-center">
																	{(!planingData || (planingData && !planingData.dta_emissao)) ? (
																		<FormInput 
																			className="text-center" 
																			defaultValue={product.qtd_produzir} 
																			value={product.qtd_produzir} 
																			onChange={(e) => updateProductQuantity(product, e.target.value, products)}
																		/>
																	) : product.qtd_produzir}
																</td>
															</tr>
															
															{product.isShowInputs && (
																<tr>
																	<td colSpan={7} className="p-0">
																		<table className="table table-condensed table-hover mb-0">
																			<thead className="bg-light">
																				<th scope="col" className="text-center" width="100">Código</th>
																				<th scope="col">Nome do Insumo</th>
																				<th scope="col" className="text-right" width="140">Qtd</th>
																				<th scope="col" className="text-right" width="100">% Perda</th>
																				<th scope="col" className="text-right" width="140">Necessidade</th>
																				<th scope="col" className="text-right" width="120"></th>
																			</thead>
																			<tbody>
																				{product.inputs && product.inputs.map(input => {
																					return (
																						<tr>
																							<td className="text-center">{input.id}</td>
																							<td>{input.nome}</td>
																							<td className="text-right">
																								{(parseInt(input.flg_unidade_fracao, 10) === 1) ? (
																									<span>{numberFormat(input.qtd, 10, ',', '.')}</span>
																								) : (
																									<span>{input.qtd}</span>
																								)}
																							</td>
																							<td className="text-right">
																								{input.prc_perda_producao && numberFormat((input.prc_perda_producao * 100), 2, ',', '.')}
																							</td>
																							<td className="text-right">
																								{(input.qtd_necessidade > 0) && (parseInt(input.flg_unidade_fracao, 10) === 1) ? (
																									<span>{numberFormat(input.qtd_necessidade, 10, ',', '.')}</span>
																								) : (input.qtd_necessidade > 0) ? (
																									<span>{input.qtd_necessidade}</span>
																								) : (
																									<span>N/D</span>
																								)}
																							</td>
																							<td></td>
																						</tr>
																					)
																				})}
																			</tbody>
																		</table>
																	</td>
																</tr>
															)}
														</>
													)
												})}
											</tbody>
										</table>
									</Card>
								</Col>
							</Row>

							<Row>
								<Col lg="12">
									<Card small className='mb-3'>
										<CardHeader>
											<h6 className="m-0">Insumos p/ Produção</h6>
										</CardHeader>

										<CardBody className="p-0">
											<table className="table table-condensed table-hover mb-0">
												<thead className="bg-light">
													<th scope="col" className="text-center" width="100">Código</th>
													<th scope="col">Nome do Insumo</th>
													<th scope="col" className="text-right" width="140">Qtd</th>
													<th scope="col" className="text-right" width="100">% Perda</th>
													<th scope="col" className="text-right" width="140">Necessidade</th>
													{(!planingData || (planingData && !planingData.dta_emissao)) && (
														<th scope="col" className="text-right" width="170">Disponível</th>
													)}
												</thead>
												<tbody>
													{(productInputs && productInputs.length === 0) && (
														<tr>
															<td colSpan={6} className="text-center">
																<i className="fa fa-spinner fa-spin"></i>
																&nbsp;
																Aguarde, carregando insumos...
															</td>
														</tr>
													)}
													{productInputs && productInputs.map(productInput => {
														let color_text = 'default';
														
														if (!planingData || (planingData && !planingData.dta_emissao)) {
															if (productInput.qtd_necessidade > 0) {
																if (productInput.qtd_estoque_disponivel > productInput.qtd_necessidade) {
																	color_text = 'success';
																}
																else if (productInput.qtd_estoque_disponivel < productInput.qtd_necessidade) {
																	color_text = 'danger';
																}
																else {
																	color_text = 'warning';
																}
															}
														}

														return (
															<tr key={productInput.id.toString()}>
																<td className="text-center">{productInput.id}</td>
																<td>{productInput.nome}</td>
																<td className="text-right">
																	{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																		<span>{numberFormat(productInput.qtd, 10, ',', '.')}</span>
																	) : (
																		<span>{productInput.qtd}</span>
																	)}
																</td>
																<td className="text-right">
																	{productInput.prc_perda_producao && (
																		`${numberFormat(productInput.prc_perda_producao * 100, 2, ',', '.')}%`
																	)}
																</td>
																<td className={`text-right text-${color_text}`}>
																	{(productInput.qtd_necessidade > 0) && (parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																		<span>{numberFormat(productInput.qtd_necessidade, 10, ',', '.')}</span>
																	) : (productInput.qtd_necessidade > 0) ? (
																		<span>{productInput.qtd_necessidade}</span>
																	) : (
																		<span>N/D</span>
																	)}
																	{(!planingData || (planingData && !planingData.dta_emissao)) && (
																		<span>
																			{(productInput.qtd_necessidade > 0) && (productInput.qtd_estoque_disponivel > productInput.qtd_necessidade) ? (
																				<i className="material-icons mr-1">check_circle</i>
																			) : (productInput.qtd_necessidade > 0) && ((productInput.qtd_estoque_disponivel < productInput.qtd_necessidade) ? (
																				<i className="material-icons mr-1">local_fire_department</i>
																			) : (productInput.qtd_necessidade > 0) && (
																				<i className="material-icons mr-1">warning</i>
																			))}
																		</span>
																	)}
																</td>
																{(!planingData || (planingData && !planingData.dta_emissao)) && (
																	<td className="text-right">
																		{(parseInt(productInput.flg_unidade_fracao, 10) === 1) ? (
																			<span>{numberFormat(productInput.qtd_estoque_disponivel, 10, ',', '.')}</span>
																		) : (
																			<span>{productInput.qtd_estoque_disponivel}</span>
																		)}
																		{productInput.qtd_dedudizo && productInput.qtd_dedudizo > 0 && (
																			<span>
																				<i className="material-icons mr-1 text-warning">warning</i>
																			</span>
																		)}
																	</td>
																)}
															</tr>
														)
													})}
												</tbody>
											</table>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Col>

						<Col lg="3" md="12">
							<Row>
								<Col lg="12">
									<Card small className='mb-3'>
										<CardHeader className="border-bottom">
											<h6 className="m-0">Ações</h6>
										</CardHeader>

										<CardBody className="p-0">
											<ListGroup flush>
												<ListGroupItem className="p-3">
													<span className="d-flex mb-2">
														<i className="material-icons mr-1">calendar_today</i>
														<strong className="mr-1">Data de Criação:</strong>
														<span className="ml-auto">
															{
																(!planingData) ? 
																moment().format('DD/MM/YYYY') : 
																moment(planingData.dta_emissao).format('DD/MM/YYYY')
															}
														</span>
													</span>
													{planingData && planingData.dta_atualizacao && (
														<span className="d-flex mb-2">
															<i className="material-icons mr-1">calendar_today</i>
															<strong className="mr-1">Data de Atualização:</strong>
															<span className="ml-auto">
																{
																	(!planingData) ? 
																	moment().format('DD/MM/YYYY') : 
																	moment(planingData.dta_atualizacao).format('DD/MM/YYYY')
																}
															</span>
														</span>
													)}
													<span className="d-flex mb-2">
														<i className="material-icons">check_circle</i>
														<strong className="mr-1 ml-1">Status:</strong>
														{
															(!planingData || (planingData && !planingData.dta_emissao)) ? 
															(<span className="ml-auto text-secondary">Rascunho</span>) : 
															(<span className="ml-auto text-success">Publicada</span>)
														}
													</span>
													<span className="d-flex mb-2">
														<Input
															type='text'
															name='planingName'
															label={`Nome/Descrição`}
															value={planingName}
															onChange={(e) => setPlaningName(e.target.value)}
															disabled={(planingData && planingData.dta_emissao)}
														/>
													</span>
													<span className="d-flex mb-2">
														<Input
															type='date'
															name='startDate'
															label={`Início da Vigência`}
															value={startDate}
															onChange={(e) => setStartDate(e.target.value)}
															disabled={(planingData && planingData.dta_emissao)}
														/>
													</span>
												</ListGroupItem>
												
												{(!planingData || (planingData && !planingData.dta_emissao)) && (
													<ListGroupItem className="d-flex px-3">
														{(isLoading) ? (
															<span>
																<i className="fa fa-spinner fa-spin"></i>
																&nbsp;
																Aguarde, gravando informações...
															</span>
														) : (
															<>
																<Button theme="secondary" size="sm" outline onClick={() => savePlaning(true)}>
																	<i className="material-icons">save</i> Salvar rascunho
																</Button>
																<Button theme="danger" size="sm" className={'ml-auto'} onClick={() => setIsOpenModalDelete(true)}>
																	<i className="material-icons">delete</i> Excluir Planejamento
																</Button>
																<Button theme="accent" size="sm" className={'ml-auto'} onClick={() => {
																	{(shoppingList && shoppingList.length > 0)  ? setIsOpenModalConfirmation(true) : savePlaning()}
																}}>
																	<i className="material-icons">save_alt</i> Publicar
																</Button>
															</>
														)}
													</ListGroupItem>
												)}
											</ListGroup>
										</CardBody>
									</Card>
								</Col>
							</Row>
							
							{(!planingData || (planingData && !planingData.dta_emissao)) && (
								<Row>
									<Col lg="12">
										<Card small className='mb-3'>
											<CardHeader className="border-bottom">
												<Row>
													<Col sm="6">
														<h6 className="m-0">
															Insumos p/ Comprar
															<br/>
															<small className="text-secondary">Lista de Compra</small>
														</h6>
													</Col>
												</Row>
											</CardHeader>

											<CardBody className="p-0">
												<table className="table table-condensed table-hover mb-0" style={{ fontSize: '80%' }}>
													<thead className="bg-light">
														<th scope="col">Insumo</th>
														<th scope="col" className="text-right" width="150">Qtd.</th>
														<th scope="col" className="text-right" width="100">Subtotal</th>
													</thead>
													<tbody>
														{shoppingList && shoppingList.map(item => (
															<tr>
																<td>
																	<strong>{item.nome}</strong>
																	<br/>
																	<small className="text-secondary">{`#${item.id}`} - R$ {numberFormat(item.vlr_custo, 2, ',', '.')}</small>
																</td>
																<td className="text-right">
																	{(item.flg_unidade_fracao && parseInt(item.flg_unidade_fracao, 10) === 1) ? (
																		<span>{numberFormat((item.qtd_necessidade - item.qtd_estoque_disponivel), 5, ',')} {item.dsc_unidade_medida}</span>
																	) : (
																		<span>{(item.qtd_necessidade - item.qtd_estoque_disponivel)} {item.dsc_unidade_medida}</span>
																	)}
																</td>
																<td className="text-right">R$ {numberFormat((item.vlr_custo * (item.qtd_necessidade - item.qtd_estoque_disponivel)), 2, ',', '.')}</td>
															</tr>
														))}
													</tbody>
													<tfoot>
														<tr>
															<td colSpan="3" className="text-right">
																<span>Investimento Total</span>
																<br/>
																<strong>R$ {numberFormat(vlrTotalInvestimento, 2, ',', '.')}</strong>
															</td>
														</tr>
													</tfoot>
												</table>
											</CardBody>
										</Card>
									</Col>
								</Row>
							)}
						
							<Row>
								<Col lg="12">
									<Card small className='mb-3'>
										<CardHeader className="border-bottom">
											<h6 className="m-0">Últimos Planejamentos</h6>
										</CardHeader>

										<CardBody className="p-0">
											<Row>
												<Col lg="12" className="m-2">
													<form onSubmit={() => loadPlanings(0)}>
														<Input
															type='text'
															name='planingNameSearch'
															placeholder="Pesquisa por Nome/Descrição"
															value={planingNameSearch}
															onChange={(e) => setPlaningNameSearch(e.target.value)}
														/>
													</form>
												</Col>
											</Row>
											<table className="table table-condensed table-hover mb-0" style={{ fontSize: '80%' }}>
												<tbody>
													{(planings && planings.dados) && planings.dados.map(planing => (
														<tr>
															<td>
																{planing.dta_emissao ? (
																	<i className="material-icons text-success">check_circle</i>
																) : (
																	<i className="material-icons text-warning">pending</i>
																)}
																&nbsp;
																<span onClick={() => loadPlaningById(planing.id)} className="text-fiord-blue">
																	{planing.dsc_planejamento}
																</span>
																<br/>
																<small className="text-secondary">
																	Por: {planing.nme_usuario}
																</small>
																<br/>
																<small className="text-secondary">
																	Criação: {moment(planing.dta_criacao).format('DD/MM/YYYY HH:mm:ss')} 
																	{planing.dta_atualizacao && (
																		<span> | Atualização: {moment(planing.dta_atualizacao).format('DD/MM/YYYY HH:mm:ss')}</span>
																	)}
																</small>
																<br/>
																<small className="text-secondary">
																	Emissão: {planing.dta_emissao ? moment(planing.dta_emissao).format('DD/MM/YYYY HH:mm:ss') : 'N/D'}
																</small>
															</td>
														</tr>
													))}
												</tbody>
												<tfoot>
													<tr>
														<td colSpan={3} className="text-center">
															{(planings && planings.paginacao && planings.paginacao.length > 0) && (
																<Row>
																	<Col sm="12" className="text-center">
																		<ButtonGroup>
																			{planings.paginacao.map((value, index) => (
																				<Button
																					size={'sm'}
																					key={index} theme={value.current ? 'danger' : 'white'}
																					onClick={() => { loadPlanings(value.offset) }}
																				>
																					{value.index}
																				</Button>
																			))}
																		</ButtonGroup>
																	</Col>
																</Row>
															)}
														</td>
													</tr>
												</tfoot>
											</table>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Col>
					</Row>
				</Form>
			</Container>

			<Modal open={isOpenModalDelete} size="md">
                <ModalHeader className="p-3">
                   Confirmar Exclusão
                </ModalHeader>
                
                <ModalBody className="p-2" style={{ maxHeight: '70vh' }}>
					<Row>
						<Col sm="12">
							Deseja excluir o planejamento selecionado?
						</Col>
					</Row>
                </ModalBody>

                <ModalFooter className="p-3">
					<Button theme="danger" size="sm" outline onClick={() => setIsOpenModalDelete(false)}>
						<i class="fa fa-times-circle" aria-hidden="true"></i> Cancelar
					</Button>
					<Button theme="success" size="sm" outline onClick={() => deletPlaning(planingData.id, planingData.data)}>
						<i class="fa fa-check-circle" aria-hidden="true"></i> Confirmar
					</Button>
                </ModalFooter>
            </Modal>

			<Modal open={isOpenModalConfirmation} size="lg">
                <ModalHeader className="p-3">
                   Confirmação da OP
                </ModalHeader>
                
                <ModalBody className="p-2" style={{ maxHeight: '70vh' }}>
					<Row>
						<Col sm="12" className='pb-2'>
							Para a publicação dessa OP, confirme a compra dos insumos faltantes. 
						</Col>
					</Row>
					<Row>
						<Col sm="12">
							{shoppingList && shoppingList.length > 0 && (
								<div style={{ maxHeight: '350px', overflowY: (shoppingList.length > 0) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
									<table className="table table-condensed table-hover mb-0">
										<thead className="bg-light">
											<th scope="col">ID</th>
											<th scope="col">INSUMO</th>
											<th scope="col" className="text-right" width="150">QTD</th>
											<th scope="col" className="text-right" width="150">VLR UNIT</th>
											<th scope="col" className="text-right" width="150">SUBTOTAL</th>
										</thead>
										<tbody>
											{shoppingList && shoppingList.map(item => (
												<tr>
													<td>
														{item.id}
													</td>
													<td>
														<strong>{item.nome}</strong>
													</td>
													<td className="text-right">
														{(item.flg_unidade_fracao && parseInt(item.flg_unidade_fracao, 10) === 1) ? (
															<span>{numberFormat((item.qtd_necessidade - item.qtd_estoque_disponivel), 5, ',')} {item.dsc_unidade_medida}</span>
														) : (
															<span>{(item.qtd_necessidade - item.qtd_estoque_disponivel)} {item.dsc_unidade_medida}</span>
														)}
													</td>
													<td className="text-right">
														R$ {numberFormat(item.vlr_custo, 2, ',', '.')}
													</td>
													<td className="text-right">R$ {numberFormat((item.vlr_custo * (item.qtd_necessidade - item.qtd_estoque_disponivel)), 2, ',', '.')}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							)}
						</Col>
					</Row>
					<Row>
						<Col sm="12">
							<div className='text-right'>
								<span>Total</span>
								<br/>
								<strong>R$ {numberFormat(vlrTotalInvestimento, 2, ',', '.')}</strong>
							</div>
						</Col>
					</Row>
                </ModalBody>

                <ModalFooter className="p-3">
					<Button theme="danger" size="sm" outline onClick={() => setIsOpenModalConfirmation(false)}>
						<i class="fa fa-times-circle" aria-hidden="true"></i> Cancelar
					</Button>
					<Button theme="success" size="sm" outline onClick={() => savePlaning()}>
						<i class="fa fa-check-circle" aria-hidden="true"></i> Confirmar
					</Button>
                </ModalFooter>
            </Modal>

			{(isShowProductsModal) && (
				<CustomModal
					type={'ListProduct'}
					size="lg"
					open={true}
					toggle={() => {modalToggle()}}
					selectCallback={handleSelectProduct}
					filterParams={modalFilterParams}
				/>
			)}
		</>
	)
}

export default PageView;