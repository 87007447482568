import api from '../api';

class OPPlaningService extends api {
    
    save = data => {
        return this.api.post('/planejamento-producao', JSON.stringify(data));
    };

    update = (id, data) => {
        return this.api.post(`/planejamento-producao/${id}`, JSON.stringify(data));
    };

    publish = (id, data) => {
        return this.api.post(`/planejamento-producao/${id}/publicar`, JSON.stringify(data));
    };
    
    delete = (id, data) => {
        return this.api.post(`/planejamento-producao/${id}/delete`, { data: JSON.stringify(data) });
    };

    getById = (id) => {
        return this.api.get(`/planejamento-producao/${id}`);
    };

    getAll = (offset, limit, params) => {
        let req_url = '/planejamentos-producao';
        
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;

        if(params)
            req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    };
}

export default OPPlaningService;
