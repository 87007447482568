import React from "react";
import PropTypes from "prop-types";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormSelect
} from "shards-react";

const ListProdutos = ({ title, categories, typeValue }) => (
  <Card small className="sc-stats h-100">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody className="p-0" style={{ height: "350px" }}>
      <Container fluid>
        {categories.map((item, idx) => (
          <Row key={idx} className="px-3">
            <Col className="sc-stats__image">
              <img
                alt={item.title}
                className="border rounded"
                src={item.image}
              />
            </Col>
            <Col className="sc-stats__title">{item.title}</Col>
            <Col className="sc-stats__value text-right" style={{ color: (typeValue == "money") ? 'green' : 'blue' }}>
              {(typeValue == "money") ? new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.value) : new Intl.NumberFormat().format(item.value)}
            </Col>
            {/* <Col className="sc-stats__percentage text-right">
              {item.percentage}
            </Col> */}
          </Row>
        ))}
      </Container>
    </CardBody>

    <CardFooter className="border-top">
      <Row>
        {/* Time Span */}
        {/* <Col>
          <FormSelect
            size="sm"
            value="last-week"
            style={{ maxWidth: "130px" }}
            onChange={() => {}}
          >
            <option value="last-week">Last Week</option>
            <option value="today">Today</option>
            <option value="last-month">Last Month</option>
            <option value="last-year">Last Year</option>
          </FormSelect>
        </Col> */}

        {/* View Full Report */}
        {/* <Col className="text-right view-report"> */}
          {/* eslint-disable-next-line */}
          {/* <a href="#">View full report &rarr;</a>
        </Col> */}
      </Row>
    </CardFooter>
  </Card>
);

ListProdutos.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The categories data.
   */
  categories: PropTypes.array
};

ListProdutos.defaultProps = {
  title: "Sales by Category",
  categories: []
};

export default ListProdutos;
