import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
    CardFooter,
    CardHeader,
	Button
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import DefaultForm from '~/components/default-form';

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
    ExpensesCentersService 
} from '~/services/WebliniaERPAPI';
import { isEmpty } from 'lodash';
import { isNumber } from 'underscore';

function PageView() {

    // API Services
    const service = new ExpensesCentersService();

    // Global Vars
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    
    // View Controls
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // Form Fields States
    const [ID, setID] = useState(null);
    const [codCentroCustos, setCodCentroCustos] = useState('');
    const [nmeCentroCustos, setNmeCentroCustos] = useState('');

    // Forms Fields
    const formFields = [
        {
            columns: [
                {
                    label: 'ID', 
                    cols: 1, 
                    name: 'ID', 
                    value: ID, 
                    isInput: true,
                    isReadOnly: true, 
                    isVisible: (ID !== null)
                }, 
                {
                    label: 'Código', 
                    cols: 2, 
                    name: 'codCentroCustos', 
                    value: codCentroCustos, 
                    onChangeCallback: e => setCodCentroCustos(e.target.value),
                    isInput: true
                }, 
                {
                    label: 'Nome', 
                    cols: 5, 
                    name: 'nmeCentroCustos', 
                    value: nmeCentroCustos, 
                    onChangeCallback: e => setNmeCentroCustos(e.target.value),
                    isInput: true
                }
            ]
        }
        
    ];

    // Payload Structure
    const payload = [{
        cod_centro_custos: codCentroCustos,
        nme_centro_custos: nmeCentroCustos,
        empreendimentos:[
            { 
                id: enterprise.id_empreendimento 
            }
        ]                
    }];

    // On Load View 
    useEffect(() => {
        getItems();
    }, []);

    // Remote Services Actions
    const saveOrUpdate = async (payload) => {
        try {

            setIsLoading(true);

            if (ID || !isEmpty(ID))
                var response = await service.update(ID, payload);           
            else
                var response = await service.save(payload);

            if(response){
                setIsLoading(false);
    
                addToast(`Ação realizada com sucesso!`, {
                    placement: 'bottom-right',
                    appearance: 'success',
                    autoDismiss: true
                });
    
                resetFormFields();
    
                getItems();
            }
        }
        catch(error) {
            setIsLoading(false);

            const { response: { status, data } } = error;

            switch (status) {
            case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
                break;
            case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
                });
                break;
            default:
                break;
            }
        }
    }

    const deleteItem = async (id) => {
        try {
            await service.delete(id);
            getItems();
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            resetFormFields();
        }
        catch(error) {
            console.log(error);
        }
    }

    const getItems = async () => {
        setItems([]);
        try {
            let params = [
                {
                key: 'cce->cod_empreendimento',
                value: enterprise.id_empreendimento
            }, {
                key: 'tcc->flg_excluido',
                value: 0
            }]
            const { data: items } = await service.getAll(params);
            setItems(items);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }

    // View Actions
    const setFormData = async (item) => {
        setID(item.id);
        setCodCentroCustos(item.cod_centro_custos);
        setNmeCentroCustos(item.nme_centro_custos);
    }

    const resetFormFields = async() => {
        setID(null);
        setCodCentroCustos('');
        setNmeCentroCustos('');
    }

    return (
        <>
            <Container fluid className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                       title='Centro de Custos' 
                       subtitle='Cadastros' 
                       className='text-sm-left' 
                    />

                    <Col xs="12" sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>
                </Row>

                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="12">
                                <Card small className='mb-3'>
                                    <Form>
                                        <CardBody>
                                            <DefaultForm rows={formFields} />                                    
                                        </CardBody>
                                            
                                        <CardFooter className="border-top">
                                            <Row>
                                                {isLoading ? (
                                                    <Col lg="12" className="text-right">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, salvando informações...
                                                    </Col>
                                                ) : (

                                                    <Col lg="12" className="text-right">
                                                        <Button outline theme="secondary" size="sm" onClick={() => resetFormFields()}>
                                                            Cancelar
                                                        </Button>
                                                        &nbsp;
                                                        <Button 
                                                            theme="accent" 
                                                            size="sm" 
                                                            className="ml-auto" 
                                                            onClick={() => saveOrUpdate(payload)}
                                                        >
                                                            <i className="material-icons">save</i>Salvar
                                                        </Button>
                                                    </Col>
                                                )}
                                            
                                            </Row>
                                        </CardFooter>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3">
                        <Card small className='mb-3'>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Listagem de Dados</h6>
                            </CardHeader>
                            <CardBody className='pt-0'>
                                <Row>
                                    <Col lg="12">
                                        <table className="table table-condensed table-hover mb-0">
                                            <tbody>
                                                {items && items.length === 0 && (
                                                    <tr>
                                                        <td className='text-center' colSpan={10}>
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                            &nbsp;
                                                            Aguarde, carregando informações...
                                                        </td>
                                                    </tr>
                                                )}

                                                {items && items.map(item => ( 
                                                    
                                                    <tr>
                                                        <td> 
                                                            <span  onClick={() => setFormData(item)}>
                                                                <small className="text-fiord-dark">{item.nme_centro_custos}</small>
                                                                <br/>
                                                                <small className="text-secondary">{item.cod_centro_custos}</small>
                                                                <br/>
                                                                <small className="text-secondary"># {item.id}&nbsp;•&nbsp;</small>
                                                            </span>
                                                            
                                                            <small>
                                                                <a href="#" className='text-danger' onClick={() => deleteItem(item.id)}>
                                                                    <i className="material-icons">delete</i> Remover
                                                                </a>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PageView
