import React, {useCallback, useEffect, useRef, useState } from 'react';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormInput,
    Row,
    Button,
    InputGroupText,
    CardFooter
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Form } from '@unform/web';
import { Input, InputGroup, InputMask } from '../components/unform';
import * as Yup from 'yup';
import getValidationErrors from '../utils/getValidationErrors';
import { useAuth } from '../hooks/Auth';
import moment from 'moment';
import { 
    CommissionAnalysis
  } from '~/services/WebliniaERPAPI';
import { isEmpty } from 'underscore';
import { numberFormat } from '~/utils/formatValue';

function PageView(props){

    const formRef = useRef(null);
    const { user, enterprise } = useAuth();

    const[representantesVendasFlgRepresentanteGrupoComissao, setRepresentantesVendasFlgRepresentanteGrupoComissao] = useState(null);
    const[representantesSemMeta, setRepresentantesSemMeta] = useState(null);
    const[representantesSemMetaComVendasNoMes, setRepresentantesSemMetaComVendasNoMes] = useState(null);
    const[representantesSemGerente, setRepresentantesSemGerente] = useState(null);
    const[representantesFlgRepresentante, setRepresentantesFlgRepresentante] = useState(null);
    const[representantesComDoisGerentes, setRepresentantesComDoisGerentes] = useState(null);
    const[vendasRepresentantesSemMeta, setVendasRepresentantesSemMeta] = useState(null);

    const [isFilled1, setIsFilled1] = useState(0);
    const [isFilled2, setIsFilled2] = useState(0);
    const [isFilled3, setIsFilled3] = useState(0);
    const [isFilled4, setIsFilled4] = useState(0);
    const [isFilled5, setIsFilled5] = useState(0);
    const [isFilled6, setIsFilled6] = useState(0);
    const [isFilled7, setIsFilled7] = useState(0);

    const [isLoadingSearch, setIsLoadingSearch] = useState(false);


    useEffect(() => {
        let data = [];
        let startDate = moment().format('YYYY-MM-DD');
        data['startDate'] = startDate;

        getAnalysisRepresentantes(data);
    }, []);

    const handleSubmitFilter = async(data) => {
        try {
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
            startDate: Yup.string().required('Selecione uma Data Inicial')
            });

            await schema.validate(data, {
            abortEarly: false,
            });

            resetVars();

            await getAnalysisRepresentantes(data);
        } catch (error) {
            if (error instanceof Yup.ValidationError) {
                formRef.current.setErrors(getValidationErrors(error));
                return;
            }
        }
    }

    const resetVars = async () =>{
        setRepresentantesVendasFlgRepresentanteGrupoComissao(null);
        setRepresentantesSemMeta(null);
        setRepresentantesSemMetaComVendasNoMes(null);
        setRepresentantesSemGerente(null);
        setRepresentantesFlgRepresentante(null);
        setRepresentantesComDoisGerentes(null);
        setVendasRepresentantesSemMeta(null);
        setIsFilled1(null);
        setIsFilled2(null);
        setIsFilled3(null);
        setIsFilled4(null);
        setIsFilled5(null);
        setIsFilled6(null);
        setIsFilled7(null);
    }

    const getAnalysisRepresentantes = async (data) => {
        try {
            
            getVendedoresVendasFlgRepresentanteGrupoComissao(data);
            getVendedoresSemMeta(data);
            getVendedoresSemMetaComVendasNoMes(data);
            getVendasVendedoresSemMeta(data);
            getVendedoresSemGerente(data);
            getVendedoresFlgRepresentante(data);
            getRepresentantesComDoisGerentes(data);

        }
        catch(err) {
            console.log(err);
        }
    }

    const getVendedoresVendasFlgRepresentanteGrupoComissao = async (data) => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesSemVendaEGrupoComissao , status } = await service.getVendedoresVendasFlgRepresentanteGrupoComissao(enterprise.id_empreendimento, data.startDate);
            if(RepresentantesSemVendaEGrupoComissao && !isEmpty(RepresentantesSemVendaEGrupoComissao)){
                RepresentantesSemVendaEGrupoComissao = RepresentantesSemVendaEGrupoComissao.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled1(1);
                setRepresentantesVendasFlgRepresentanteGrupoComissao(RepresentantesSemVendaEGrupoComissao);
            }
        }
        catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled1(2);
            }
        }
    }

    const getVendedoresSemMeta = async (data) => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesSemMeta , status } = await service.getVendedoresSemMeta(data.startDate);
            if(RepresentantesSemMeta && !isEmpty(RepresentantesSemMeta)){
                RepresentantesSemMeta = RepresentantesSemMeta.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled5(1);
                setRepresentantesSemMeta(RepresentantesSemMeta);
            }
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled5(2);
            }
        }
    }

    const getVendedoresSemMetaComVendasNoMes = async (data) => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesSemMetaComVendasNoMes , status } = await service.getVendedoresSemMetaComVendasNoMes(enterprise.id_empreendimento, data.startDate);
            if(RepresentantesSemMetaComVendasNoMes && !isEmpty(RepresentantesSemMetaComVendasNoMes)){
                RepresentantesSemMetaComVendasNoMes = RepresentantesSemMetaComVendasNoMes.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled6(1);
                setRepresentantesSemMetaComVendasNoMes(RepresentantesSemMetaComVendasNoMes);
            }
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled6(2);
            }
        }
    }

    const getVendasVendedoresSemMeta = async (data) => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : VendasRepresentantesSemMeta , status } = await service.getVendasVendedoresSemMeta(enterprise.id_empreendimento, data.startDate);
            if(VendasRepresentantesSemMeta && !isEmpty(VendasRepresentantesSemMeta)){
                VendasRepresentantesSemMeta = VendasRepresentantesSemMeta.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled7(1);
                setVendasRepresentantesSemMeta(VendasRepresentantesSemMeta);
            }
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled7(2);
            }
        }
    }

    const getVendedoresSemGerente = async () => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesSemGerente , status } = await service.getVendedoresSemGerente(null, null);
            if(RepresentantesSemGerente && !isEmpty(RepresentantesSemGerente)){
                RepresentantesSemGerente = RepresentantesSemGerente.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled2(1);
                setRepresentantesSemGerente(RepresentantesSemGerente);
            }
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled2(2);
            }
        }
    }

    const getVendedoresFlgRepresentante = async () => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesFlgRepresentante , status } = await service.getVendedoresFlgRepresentante(null, null);
            if(RepresentantesFlgRepresentante && !isEmpty(RepresentantesFlgRepresentante)){
                RepresentantesFlgRepresentante = RepresentantesFlgRepresentante.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled3(1);
                setRepresentantesFlgRepresentante(RepresentantesFlgRepresentante);
            }
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled3(2);
            }
        }
    }

    const getRepresentantesComDoisGerentes = async () => {
        try {
            const service = new CommissionAnalysis();
            setIsLoadingSearch(true);
            let { data : RepresentantesComDoisGerentes, status} = await service.getRepresentantesComDoisGerentes(null, null);
            if(RepresentantesComDoisGerentes && !isEmpty(RepresentantesComDoisGerentes)){
                RepresentantesComDoisGerentes = RepresentantesComDoisGerentes.map(_item => {
                    return _item;
                });
                setIsLoadingSearch(false);
                setIsFilled4(1);
                setRepresentantesComDoisGerentes(RepresentantesComDoisGerentes);
            } 
        } catch({ response: { status } }){
            switch (status) {
                case 404:
                    setIsFilled4(2);
            }
        }
    }


    return (
        <>
            <Container className='main-content-container px-4 pb-4'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Análise de Apuração Comissão Vendedores'
                        subtitle='Relatórios' 
                        className='text-md-left'
                    />
                </Row>

                <Row style={{ fontSize:'90%' }}>
                    <Col md="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom">
                                    {(isFilled1 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled1 === 1) &&
                                        <i class="fa fa-exclamation-circle mr-1 text-warning" aria-hidden="true"/>
                                    }
                                    {(isFilled1 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Cadastro</strong>
                                    <br/>
                                    <small className='text-muted'>Sem flg_representante OU grupo Comissionamento</small>
                                </CardHeader>
                                
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0' >
                                            <tbody>
                                                    <div  
                                                        style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                    >
                                                    {representantesVendasFlgRepresentanteGrupoComissao && representantesVendasFlgRepresentanteGrupoComissao.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <small className='text-muted'> # {obj.id_usuario} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom">
                                    {(isFilled2 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled2 === 1) &&
                                        <i class="fa fa-exclamation-circle mr-1 text-warning" aria-hidden="true"/>
                                    }
                                    {(isFilled2 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Cadastro</strong>
                                    <br/>
                                    <small className='text-muted'>Representantes que estão sem gerente definido</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >

                                                    {representantesSemGerente && representantesSemGerente.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nme_representante}</span>
                                                                <small className='text-muted'> # {obj.cod_representante} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom">
                                    {(isFilled3 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled3 === 1) &&
                                        <i class="fa fa-exclamation-circle mr-1 text-warning" aria-hidden="true"/>
                                    }
                                    {(isFilled3 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Cadastro</strong>
                                    <br/>
                                    <small className='text-muted'> Usuarios que não deveriam estar marcados como representantes</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >
                                                    {representantesFlgRepresentante && representantesFlgRepresentante.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <br/>
                                                                <small className='text-muted'> # {obj.id} </small>
                                                                <br/>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>

                    <Col className=' ml-auto justify-content-end'>
                        <Card small className='mb-3'>
                            <Form ref={formRef} onSubmit={handleSubmitFilter}>

                                <CardHeader className="border-bottom">
                                    <h5><strong>Parâmetros de Análise</strong></h5>
                                </CardHeader>
                                
                                <CardBody className={'pb-0'}>
                                    <Input 
                                        type='date' 
                                        name='startDate' 
                                        label='Período de Apuração'
                                    />
                                </CardBody>

                                <CardFooter className={'text-right pb-2 pt-0'}>
                                    <Button theme="primary" 
                                        type='submit'
                                        size="md" >
                                        <i className="material-icons">search</i> Aplicar Filtros
                                    </Button>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>

                </Row>

                <Row  style={{ fontSize:'90%' }}>

                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom pb-1">
                                    {(isFilled4 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled4 === 1) &&
                                        <i class="fa fa-exclamation-circle mr-1 text-warning" aria-hidden="true"/>
                                    }
                                    {(isFilled4 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Cadastro</strong>
                                    <br/>
                                    <small className='text-muted'>Representantes com mais de 2 gerentes vinculados</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >
                                                    {representantesComDoisGerentes && representantesComDoisGerentes.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <br/>
                                                                <small className='text-muted'> # {obj.id} </small>
                                                                <br/>
                                                                <small className='text-muted'> Gerentes: {obj.qtd} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>

                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom pb-4">
                                    {(isFilled5 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled5 === 1) &&
                                        <i class="fa fa-times-circle mr-1 text-danger" aria-hidden="true"/>
                                    }
                                    {(isFilled5 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Meta</strong>
                                    <br/>
                                    <small className='text-muted'>Representantes sem meta de vendas</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>                                    
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (

                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >
                                                    {representantesSemMeta && representantesSemMeta.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <br/>
                                                                <small className='text-muted'> # {obj.id} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}                                
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>                                

                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom pb-1">
                                    {(isFilled6 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled6 === 1) &&
                                        <i class="fa fa-times-circle mr-1 text-danger" aria-hidden="true"/>
                                    }
                                    {(isFilled6 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Meta</strong>
                                    <br/>
                                    <small className='text-muted'>Representantes sem meta, mas contem vendas no mês de referência</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >
                                                    {representantesSemMetaComVendasNoMes && representantesSemMetaComVendasNoMes.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <br/>
                                                                <small className='text-muted'> # {obj.id_usuario} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>    
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col sm="3">
                        <Card small className='mb-3'>
                            <Form>
                                <CardHeader className="border-bottom">
                                    {(isFilled7 === 0) &&
                                        <i class="fa fa-check-circle mr-1 text-secundary" aria-hidden="true"/>
                                    }
                                    {(isFilled7 === 1) &&
                                        <i class="fa fa-times-circle mr-1 text-danger" aria-hidden="true"/>
                                    }
                                    {(isFilled7 === 2) &&
                                        <i class="fa fa-check-circle mr-1 text-success" aria-hidden="true"/>
                                    }
                                    <strong>Repres. c/ Erro de Venda</strong>
                                    <br/>
                                    <small className='text-muted'> Vendas dos representantes sem meta</small>
                                </CardHeader>
                                <CardBody className={'p-0'}>
                                    {isLoadingSearch ? (
                                        <span>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, buscando informações...
                                        </span>
                                    ) : (
                                        <table className='table table-condensed table-hover mb-0'>
                                            <tbody>
                                                <div  
                                                    style={{ minHeight: '176px' ,maxHeight: '176px', overflowY: 'scroll' }}
                                                >
                                                    {vendasRepresentantesSemMeta && vendasRepresentantesSemMeta.map(obj => (
                                                        <tr>
                                                            <td>
                                                                <span className='font-weight-bold'>{obj.nome}</span>
                                                                <br/>
                                                                <small className='font-weight-bold'> # {obj.id} </small>
                                                                <small className='text-muted'>{obj.dta_venda} </small>
                                                                <br/>
                                                                <small className='text-muted'>R$ {numberFormat(obj.vlr_venda, 2, ',', '.')} </small>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </div>
                                            </tbody>
                                        </table>
                                    )}
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PageView