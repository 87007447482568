import React,{useEffect, useRef, useState} from 'react';
import {
    Card,
    CardBody
} from "shards-react";
import { isEmpty } from 'underscore';
import { numberFormat } from '~/utils/formatValue';
import SmallStats from "../../common/SmallStats";
import shortid from "shortid";
import Chart from "~/utils/chart";

function Component({ title, value, chart_config, prc_data, color_prc, quebra, hasDecimal, isLoading, prcIcon}) {
    const canvasRef = useRef();
    
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
      if(chart_config && chart_config.datasets && chart_config.datasets.length > 0)
        buildGraph();
    }, [chart_config, canvasRef])

    const buildGraph = () => {
      if (chartInstance) {
        chartInstance.destroy();
      }

      const chartOptions = {
        ...{
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            display: false
          },
          tooltips: {
            enabled: false,
            custom: false
          },
          elements: {
            point: {
              radius: 0
            },
            line: {
              tension: 0.33
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: false,
                ticks: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                gridLines: false,
                scaleLabel: false,
                ticks: {
                  display: false,
                  isplay: false,
                  // Avoid getting the graph line cut of at the top of the canvas.
                  // Chart.js bug link: https://github.com/chartjs/Chart.js/issues/4790
                  suggestedMax: Math.max(...chart_config.datasets[0].data) + 1
                }
              }
            ]
          }
        },
        ...chartOptions
      };
  
      const chartConfig = {
        ...{
          type: "line",
          data: {
            ...{ labels: chart_config.labels },
            ...{ datasets: chart_config.datasets }
          },
          options: chartOptions
        },
        ...chartConfig
      };
  
      if (canvasRef && canvasRef.current) {
          let chart = new Chart(canvasRef.current, chartConfig);
          setChartInstance(chart);
      }
    }

    return (
        <Card className='mb-3'>
            <CardBody className={'text-center p-0'}>
                <h6 className="text-muted mb-3 pt-4" style={{fontSize : '15px', position:'relative',zIndex:'10'}}>{title && title}&nbsp;
                    {quebra && (
                        <small type='button'>(de cliente)</small>
                    )}
                </h6>
                {(isLoading == true) && (
                    <h4 className="font-weight-bold" style={{fontSize : '20px', position:'relative',zIndex:'10'}}>
                        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                    </h4>
                )}

                {(isLoading == false) && (
                  <>
                    <span style={{position:'relative',zIndex:'10'}}>
                      <></>
                        {hasDecimal && (
                            <h4 className="font-weight-bold">{numberFormat(parseFloat(value), 2, ',', '.')}</h4>
                        )}
                        {!hasDecimal && (
                            <h4 className="font-weight-bold">{parseInt(value, 10)}</h4>
                        )}

                        {prc_data && (
                          <p><i className={prcIcon}></i> {numberFormat(parseFloat(prc_data), 2, ',', '.')}%</p>
                        )}

                        {!prc_data && (
                          <br/>
                        )}
                    </span>
                  </>
                )}

                <canvas
                  height={100}
                  ref={canvasRef}
                  className={`stats-small-${shortid()}`}
                  style={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    minWidth:'100%', 
                    borderBottomRightRadius: '10px', 
                    borderBottomLeftRadius: '10px',
                    display: (!chart_config) ? 'none' : undefined
                  }}
                />
            </CardBody>
        </Card>
    );
}

export default Component;