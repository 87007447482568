import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams} from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
    CardFooter,
    CardHeader,
	Button,
    ButtonGroup,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import DefaultForm from '~/components/default-form';

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import {
    ImportersService
} from '~/services/WebliniaERPAPI';
import { isEmpty } from 'lodash';
import { isNumber } from 'underscore';
import _ from 'lodash';
import CustomModal from '../components/modal';

function PageView() {

    // API Services
    const service = new ImportersService();

    // Global Vars
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    
    // View Controls
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisplayed, setIsDisplay] = useState(false);
    const [pagination, setPagination] = useState(null);

    // Form Fields States
    const [ID, setID] = useState(null);
    const [listEnterprises, setListEnterprise] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(null);
    const [activeTab, setActiveTab] = useState("form-info");
    const [description, setDescription] = useState('');
    

    // Forms Fields
    const formFields = [
        
        {
            columns: [
                {
                    label: 'ID', 
                    cols: 1, 
                    name: 'ID', 
                    value: ID, 
                    isInput: true,
                    isReadOnly: true, 
                    isVisible: (ID !== null)
                },
                {
                    isInput: true, 
                    label: 'Descrição',
                    cols: 10,
                    name: 'description',
                    value: description,
                    onChangeCallback: e => setDescription(e.target.value),
                },
                
            ]
        },
       
    ];

    // Payload Structure
    const payload = {
        id: ID,
        empreendimentos: listEnterprises,
        nome_importador : description,
        id_empreendimento: enterprise.id_empreendimento,

        
    };

    // On Load View 
    useEffect(() => {
        getItems(0);
    }, []);

    // Modal View
    const toggleModal = (modal) => {
        switch(modal) {
            case 'enterprises':
                setIsOpenModal(!isOpenModal);
                break;
        }
    }

    // Remote Services Actions
    const saveOrUpdate = useCallback(async (payload) => {
        try {

            let canSave = true;

            if(!(listEnterprises) || listEnterprises.length <= 0){
                canSave = false;
                addToast('Por favor adicionar um empreendimento', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
            }

            if(canSave){
                setIsLoading(true);
    
                if (ID || !isEmpty(ID))
                    var response = await service.update(ID, payload);          
                else
                    var response = await service.save(payload);
    
                if(response){
                    setIsLoading(false);
        
                    addToast(`Ação realizada com sucesso!`, {
                        placement: 'bottom-right',
                        appearance: 'success',
                        autoDismiss: true
                    });
        
                    resetFormFields();
    
                    getItems(0);
                }
            }

        }
        catch(error) {
            setIsLoading(false);

            const { response: { status, data } } = error;

            switch (status) {
            case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
                break;
            case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
                });
                break;
            default:
                break;
            }
        }
    },[payload]);

    const deleteItem = async (id) => {
        try {
            await service.delete(id);
            getItems(0);
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            resetFormFields();
        }
        catch(error) {
            console.log(error);
        }
    }

    const getItems = async (offset) => {
        setItems([]);
        try {
            let params = [{
                key: 'tie->id_empreendimento',
                value: enterprise.id_empreendimento
            }];
            const { data: { importadores, paginacao } } = await service.getAll(offset, 5, params);
            setItems(importadores);
            setPagination(paginacao);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }

    // View Actions
    const updateForm = async (item) => {
        setID(item.id);
        getEnterprisesById(item.id);
        setDescription(item.nome_importador);
    }

    const resetFormFields = async () => {
        setID(null);
        setListEnterprise([]);
        setDescription('');
        
    }

    const addItemModal = useCallback(async (name, item) => {
        if(item) {
          try{
            // clonando p/ poder manipular
            let _listEnterprises = _.clone(listEnterprises);
            
            // inicializando o array, caso esteja null ou undefined
            if(!_listEnterprises)
              _listEnterprises = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if(_listEnterprises.length > 0) {
              _listEnterprises.forEach(emp => {
                if(emp.id === item.id) {
                    can_add = false;
                    addToast(`Esse item já contem na listagem!`, {
                        placement: 'bottom-right',
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
              })
            }
    
            // add apenas se não estiver no array
            if(can_add)
              _listEnterprises.push(item);

            _listEnterprises = _listEnterprises
            _listEnterprises = _.sortBy(_listEnterprises, (_emp) => { return _emp.id });
    
            // atualiza o state
            setListEnterprise(_listEnterprises);

          }catch(err){
              console.log(err);
          }
        }else{
          setIsOpenModal(false);
        }
    },[listEnterprises]);
    
    const delItemModal = async (index) =>{
        let _listEnterprises = _.clone(listEnterprises);
        if(_listEnterprises)
            _listEnterprises.splice(index, 1);
        setListEnterprise(_listEnterprises);
    }

    const getEnterprisesById =  async (id) => {
        try {
            let {data : _enterprise} = await service.getEnterprisesById(id);

            if(_enterprise && _enterprise.length > 0){
                setListEnterprise(_enterprise);
            }
        } catch (error) {
            console.log(error);
        }
    }

    


    return (
        <>
            <Container fluid className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                       title=' Importadores' 
                       subtitle='Cadastros' 
                       className='text-sm-left' 
                    />
                    <Col xs="12" sm="" className="col d-flex align-items-center">
						&nbsp;
					</Col>
                </Row>

                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="12">
                                <Card small className='mb-3'>
                                    <Form>
                                        <CardBody>
                                            <Row>
                                                <Col lg='12' className=' pb-1'>
                                                    <div className="border-bottom clearfix d-flex ">
                                                            <Nav tabs className="border-0 mt-auto ml-0 pt-2">
                                                                <NavItem>
                                                                    <NavLink 
                                                                        active={(activeTab && activeTab === 'form-info')}
                                                                        onClick={() => setActiveTab('form-info')}
                                                                    >
                                                                        Importador
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink 
                                                                        active={(activeTab && activeTab === 'enterprise-info')}
                                                                        onClick={() => setActiveTab('enterprise-info')}
                                                                    >
                                                                        Empreendimentos
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                    </div>
                                                </Col>
                                            
                                                {activeTab && activeTab === 'form-info' && (
                                                    
                                                    <Col sm='12'>
                                                        <DefaultForm rows={formFields} />    
                                                    </Col>
                                                                                
                                                )}
                                                {activeTab && activeTab === 'enterprise-info' && (
                                                    <>
                                                        <Col lg='4'>
                                                            <div style={{ maxHeight: '100%', overflowY: (listEnterprises.length > 5) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                <table className='table table-bordered table-hover'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ verticalAlign: 'middle' }}>
                                                                                Empreendimentos
                                                                            </th>
                                                                            <th width='100' className='text-center'>
                                                                                <Button 
                                                                                    size='xs'
                                                                                    theme='success'
                                                                                    onClick={() => toggleModal('enterprises')}
                                                                                >
                                                                                    <i class="fa fa-plus-circle"></i> Incluir
                                                                                </Button>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {listEnterprises && listEnterprises.map((item, index) =>(
                                                                        <tr>
                                                                            <td>
                                                                                <small>{item.nome_empreendimento}</small>
                                                                            </td>
                                                                            <td className='text-right'>
                                                                                <Button 
                                                                                    size='xs'
                                                                                    theme='danger'
                                                                                    onClick={() => delItemModal(index)}
                                                                                >
                                                                                    <i className="material-icons">delete</i> Remover
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>  
                                                        </Col>  
                                                    </>                            
                                                )}
                                            </Row>
                                        </CardBody>
                                            
                                        <CardFooter className="border-top">
                                            <Row>
                                                {isLoading ? (
                                                    <Col lg="12" className="text-right">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, salvando informações...
                                                    </Col>
                                                ) : (

                                                    <Col lg="12" className="text-right">
                                                        <Button outline theme="secondary" size="sm" onClick={() => resetFormFields()}
                                                        >
                                                            <i class="fa-solid fa-circle-x"></i>Cancelar
                                                        </Button>
                                                        &nbsp;
                                                        <Button 
                                                            theme="accent" 
                                                            size="sm" 
                                                            className="ml-auto" 
                                                            onClick={() => saveOrUpdate(payload)}
                                                        >
                                                            <i className="material-icons">save</i>Salvar
                                                        </Button>
                                                    </Col>
                                                )}
                                            
                                            </Row>
                                        </CardFooter>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3">
                        <Row>
                           <Col sm='12'>
                                <Card small className='mb-3'>
                                    <CardHeader className="border-bottom">
                                        <h6 className="m-0">
                                            <i className="fas fa-list"></i> Itens Cadastrados
                                        </h6>
                                    </CardHeader>

                                    <CardBody className='pt-0'>
                                        <Row>
                                            <Col lg="12">
                                                <table className="table table-condensed table-hover mb-0">
                                                    <tbody>
                                                        {items && items.length === 0 && (
                                                            <tr>
                                                                <td className='text-center' colSpan={5}>
                                                                    <i className="fa fa-spinner fa-spin"></i>
                                                                    &nbsp;
                                                                    Aguarde, carregando informações...
                                                                </td>
                                                            </tr>
                                                        )}

                                                        {items && items.map(item => ( 
                                                            <tr>
                                                                <td> 
                                                                    <span  onClick={() => updateForm(item)}>
                                                                        <small className="text-fiord-dark">{item.nome_importador}</small>
                                                                        <br/>
                                                                        <small className="text-secondary"># {item.id}&nbsp;•&nbsp;</small>
                                                                        </span>
                                                                    <small>
                                                                        <a href="#" className='text-danger' onClick={() => deleteItem(item.id)}>
                                                                            <i className="material-icons">delete</i> Remover
                                                                        </a>
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>

                                        {pagination && (
                                            <Row>
                                                <Col sm="12" className="text-center">
                                                    <ButtonGroup>
                                                        {pagination.map((value, index) => (
                                                            <Button
                                                                key={index} 
                                                                size='xs'
                                                                theme={value.current ? 'primary' : 'white'}
                                                                onClick={() => getItems(value.offset)}
                                                            >
                                                                {value.index}
                                                            </Button>
                                                        ))}
                                                    </ButtonGroup>
                                                </Col>
                                            </Row>
                                        )}
                                    </CardBody>
                                </Card>
                           </Col>
                        </Row>
                    </Col>
                </Row>


                {isOpenModal && 
                    <CustomModal
                        type='ListEnterprises'
                        size="lg"
                        open={isOpenModal}
                        toggle={() => toggleModal('enterprises')}
                        selectCallback={(type, item) => addItemModal(type, item)} 
                        autoClose={false}
                    />
                }
                
            </Container>
        </>
    );
}

export default PageView
