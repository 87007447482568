import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from "prop-types";
import classNames from "classnames";

import { 
    FormGroup, 
    FormFeedback, 
    FormInput,
    InputGroup, 
	InputGroupAddon, 
	InputGroupText, 
} from "shards-react";

function CustomInput({ name, ...rest }) {
  const inputRef = useRef(null);

  const {
    fieldName,
    registerField,
    defaultValue,
    error
  } = useField(name);

  const {
    invalid,
    label,
		onClick,
    disabled
  } = {...rest};

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value'
    })
  }, [fieldName, registerField]);

  return (
		<FormGroup>
			{label && (
				<label htmlFor={fieldName}>{label}</label>
			)}
			<InputGroup 
				className="mb-3" 
				style={{cursor: 'pointer'}}
				onClick={onClick}
			>
				<InputGroupAddon type="prepend">
					<InputGroupText>
						<i className='fa fa-bars'></i>
					</InputGroupText>
				</InputGroupAddon>
				<FormInput
          id={fieldName}
          innerRef={inputRef}
          defaultValue={defaultValue}
          invalid={invalid || !!error}
          style={{cursor: (disabled) ? 'pointer' : undefined}}
          {...rest}
        />
				{error && (
					<FormFeedback>{error}</FormFeedback>
				)}
			</InputGroup>
		</FormGroup>
  );
}

CustomInput.propTypes = {
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The input type.
   */
  type: PropTypes.string,
  // type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Whether it is plaintext, or not.
   */
  plaintext: PropTypes.bool,
  /**
   * The input's size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ])
};

export default CustomInput;
