import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
    CardFooter,
    CardHeader,
	Button,
    ButtonGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    FormInput
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';
import { Select } from '../components/unform';
import NumberFormat from 'react-number-format';
import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import DefaultForm from '~/components/default-form';

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
    BanksService,
    CreditCardMachineService,
    ConfigurationsService
    
} from '~/services/WebliniaERPAPI';
import { isEmpty } from 'lodash';
import { isNumber } from 'underscore';
import _ from 'lodash';
import e from 'cors';
import CustomModal from '../components/modal'
import { numberFormat } from '~/utils/formatValue';

function PageView() {

    // API Services
    const service = new CreditCardMachineService();

    // Global Vars
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    const formRef = useRef(null);
    
    // View Controls
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState(null);
    const [listModal, setListModal] = useState([]);
    const [isOpenModal, setIsOpenModal] = useState(null);
    const [activeTab, setActiveTab] = useState("form-info");
   

    // Form Fields States
    const [ID, setID] = useState(null);
    const [numSerie, setNumSerie] = useState('');
    const [accountBank, setAccountBank] = useState('');
    const [debitPosting, setDebitPosting] = useState('');
    const [creditPosting, setCreditPosting] = useState('');
    const [debitRate, setDebitRate] = useState('');
    const [externalID, setExternalID] = useState('');
    const [configuration, setConfiguration] = useState(null);
    const [selectedFlags, setSelectedFlags] = useState('');


    // Aux State Lists
    const [banks, setBanks] = useState(null);
    const [flags, setFlags] = useState(null);
    const [dobbyFee, setDobbyFee]= useState([]);
    const [isOpenModalDobbyFee, setIsOpenModalDobbyFee]= useState(false);
    const [qtdParcelasInicio, setQtdParcelasInicio]= useState(null);
    const [qtdParcelasFim, setQtdParcelasFim]= useState(null);
    const [prcTaxa, setPrcTaxa] = useState(null);
    const [prcDebitRate, setPrcDebitRate] = useState(null);
    const [qtdDebitPostingDays, setQtdDebitPostingDays] = useState(null);
    const [prcCreditRate, setPrcCreditRate] = useState(null);
    const [qtdCreditPostingDays, setQtdCreditPostingDays]= useState(null);
    const [flagControll, setFlagControll] = useState(false);
    const [cutDay, setCutDay] = useState(null);
    const [qtdCalculationDays, setQtdCalculationDays] = useState(null);
    const [qtdAccumulatedDays, setQtdAccumulatedDays] = useState(null);
    const [week, setWeek] = useState([
    	{id: 1, nome: "Domingo"},
        {id: 2, nome: "Segunda-Ferira"},
    	{id: 3, nome: "Terça-Feira"},
    	{id: 4, nome: "Quarta-Feira"},
    	{id: 5, nome: "Quinta-Feira"},
    	{id: 6, nome: "Sexta-Feira"},
    	{id: 7, nome: "Sábado"}
    ])


    // Forms Fields
    const formFields = [
        {
            columns: [
                {
                    isInput: true,
                    label: 'ID', 
                    cols: 1, 
                    name: 'ID', 
                    value: ID, 
                    isReadOnly: true, 
                    isVisible: (ID !== null)
                }, 
                {
                    isInput: true,
                    label: 'Número de série', 
                    cols: 2, 
                    name: 'numSerie', 
                    value: numSerie, 
                    onChangeCallback: e => setNumSerie(e.target.value),
                }, 
                {
                    isSelect: true,
                    label: 'Conta', 
                    cols: 5, 
                    name: 'accountBank', 
                    value: accountBank,
                    onChangeCallback: e => setAccountBank(e.target.value),
                    options: banks
                    
                    
                },
                
            ]
        },
        {
            columns: [
                {
                    isInput: true,
                    label: 'Dias p/ Lanc. Débito/Voucher', 
                    cols: 3, 
                    name: 'debitPosting', 
                    value: debitPosting, 
                    onChangeCallback: e => setDebitPosting(e.target.value),
                    
                }, 
                {
                    isInput: true,
                    label: 'Dias p/ Lanc. Crédito',
                    cols: 3,
                    name: 'creditPosting',
                    value: creditPosting,
                    onChangeCallback: e => setCreditPosting(e.target.value),
                    
                },
                {
                    isInput: true,
                    label: 'Taxa Débito',
                    cols: 2,
                    name: 'debitRate',
                    value: debitRate,
                    onChangeCallback: e => setDebitRate(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'ID Externo',
                    cols: 2,
                    name: 'externalID',
                    value: externalID,
                    onChangeCallback: e => setExternalID(e.target.value),
                }
            ]
        },
        
    ];

    const formFields2 = [
        {
            columns: [
                {
                    isInput: true,
                    label: 'ID', 
                    cols: 1, 
                    name: 'ID', 
                    value: ID, 
                    isReadOnly: true, 
                    isVisible: (ID !== null)
                }, 
                {
                    isInput: true,
                    label: 'Número de série', 
                    cols: 2, 
                    name: 'numSerie', 
                    value: numSerie, 
                    onChangeCallback: e => setNumSerie(e.target.value),
                }, 
                {
                    isSelect: true,
                    label: 'Conta', 
                    cols: 5, 
                    name: 'accountBank', 
                    value: accountBank,
                    onChangeCallback: e => setAccountBank(e.target.value),
                    options: banks
                    
                    
                },
                {
                    isInput: true, 
                    label: 'ID Externo',
                    cols: 2,
                    name: 'externalID',
                    value: externalID,
                    onChangeCallback: e => setExternalID(e.target.value),
                }
                
            ]
        }
    ];

    // Payload Structure
    const payload = {
        num_serie_maquineta: numSerie,
        id_conta_bancaria: accountBank,
        qtd_dias_lancamento_debito: debitPosting,
        qtd_dias_lancamento_credito: creditPosting,
        taxa_debito: debitRate,
        id_externo : externalID,
        empreendimentos:[
            { 
                id: enterprise.id_empreendimento 
            }
        ]                
    };

    // On Load View 
    useEffect(() => {
        getConfiguration();
        getItems(0);
        getBanks();
        getFlags();
        
    }, []);

    //Modal View
    const toggleModal = (modal) => {
        switch(modal) {
            case 'enterprises':
                setIsOpenModal(!isOpenModal);
            break;
        }
    }

    // Remote Services Actions
    const saveOrUpdate = async (payload) => {
        try {

            setIsLoading(true);

            if (ID || !isEmpty(ID))
                var response = await service.update(ID, payload);           
            else
                var response = await service.save(payload);

            if(response){
                setIsLoading(false);
    
                addToast(`Ação realizada com sucesso!`, {
                    placement: 'bottom-right',
                    appearance: 'success',
                    autoDismiss: true
                });
    
                resetFormFields();
    
                getItems(0);
            }
        }
        catch(error) {
            setIsLoading(false);

            const { response: { status, data } } = error;

            switch (status) {
            case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
                break;
            case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
                });
                break;
            default:
                break;
            }
        }
    }

    const deleteItem = async (id) => {
        try {
            await service.delete(id);
            getItems(0);
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            resetFormFields();
        }
        catch(error) {
            console.log(error);
        }
    }

    const getItems = async (offset) => {
        setItems([]);
        try {
            let params = [
                {
                key: 'maq->id_empreendimento',
                value: enterprise.id_empreendimento
            }, {
                key: 'maq->flg_excluido',
                value: 0
            }]
            const { data: { maquinetas, paginacao } } = await service.getAll(params, offset, 5);
            setItems(maquinetas);
            setPagination(paginacao);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }

    const getBanks = async () => { // Função carregar os bancos
        setBanks(null);
        const srv = new BanksService();

        let params = [
            {
            key: 'id_empreendimento',
            value: enterprise.id_empreendimento
        }, {
            key: 'id_tipo_conta[exp]',
            value: 'IN (1,2)'
        }]

        try {
            const { data } = await srv.getAcounts(params);

            setBanks(data.contas.map(i => {
                return {
                    id: i.id,
                    nome: i.dsc_conta_bancaria
                }
            }));
        }
        catch(error) {
            console.log(error);
        }
    }

    const getConfiguration = async () => { // Função carregar configurações
        const srv = new ConfigurationsService();

        try {
            const { data } = await srv.getConfigurations(enterprise.id_empreendimento);
            if(data) {
                setConfiguration(data);
            }
        }
        catch(error) {
            console.log(error);
        }
    }

    const getFlags = async () => {
        setFlags(null);
        let params = [
             {
                key: 'bfp->id_forma_pagamento[exp]',
                value: 'IN (5,6,10)'
            }
        ]

        try {
            const { data } = await service.getFlags(params);

            setFlags(data.map(i => {
                return {
                    id: i.id,
                    nome: i.nome,
                    flg_tipo_lancamento: i.flg_tipo_lancamento
                }
            }));
        }
        catch(error) {
            console.log(error);
        }
    }

    // View Actions
    const setFormData = async (item) => {
        setID(item.id_maquineta);
        setNumSerie(item.num_serie_maquineta);
        setAccountBank(item.id_conta_bancaria);
        setDebitPosting(item.qtd_dias_lancamento_debito);
        setCreditPosting(item.qtd_dias_lancamento_credito);
        setDebitRate(item.taxa_debito);
        setExternalID(item.id_externo);
        setDobbyFee(item.taxas);
    }

    const resetFormFields = async() => {
        setID(null);
        setNumSerie('');
        setAccountBank('');
        setDebitPosting('');
        setCreditPosting('');
        setDebitRate('');
        setExternalID('');
    }

    const addDobbyFee = async() => {

        if(configuration.taxa_maquineta_por_bandeira == 0){
             var item = {
                qtd_parcelas_inicio: qtdParcelasInicio,
                qtd_parcelas_fim: qtdParcelasFim,
                prc_taxa: prcTaxa
            }
        }
    
        if( configuration.taxa_maquineta_por_bandeira == 1){
            var item = {
                selectFlag: selectedFlags,
                prcCreditRate: prcCreditRate,
                prcDebitRate: prcDebitRate
            }
            if(configuration.flg_lanc_auto_voucher == 1){
                item.qtd_dias_apuracao = qtdCalculationDays;
                item.qtd_dias_pag_acumulado = qtdAccumulatedDays;
                item.dia_corte = cutDay;
            }
            else{
                item.qtdCreditPostingDays = qtdCreditPostingDays;
                item.qtdDebitPostingDays = qtdDebitPostingDays;
            }
        }

        if(dobbyFee.length == 0){
            setDobbyFee([item]);
        }else{
            dobbyFee.push(item);
        }

        setIsOpenModalDobbyFee(false);
        
    }

    const delDobbyFee = async (index) =>{
        let dobbyFee = _.clone(listModal);
        if(dobbyFee)
            dobbyFee.splice(index, 1);
        setDobbyFee(dobbyFee);
    }

    const selectedFlag = async (id_bandeira) => {
        let disappearFields = false;
        flags.forEach((f) => {
            if(f.id == id_bandeira){
                if(f.flg_tipo_lancamento == 'voucher'){
                    disappearFields = true;
                }
                setSelectedFlags(f.id);
            }
        })
        if(disappearFields){
            setFlagControll(true);
        }
        else{
            setFlagControll(false);
        }
    }

    const diaSemana = async (dia) => {
        var StringDia = "";
		week.forEach((d) => {
			if(d.id == dia)
				StringDia = d.nome;
		});
		return StringDia;
    }

    return (
        <>
            <Container fluid className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                       title='Maquinetas' 
                       subtitle='Cadastros' 
                       className='text-sm-left' 
                    />

                    <Col xs="12" sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>
                </Row>

                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="12">
                                <Card small className='mb-3'>
                                    <Form>
                                        <CardBody rows={formFields}>
                                            <Row>
                                                <Col lg='12' className=' pb-1'>
                                                    <div className="border-bottom clearfix d-flex ">
                                                        <Nav tabs className="border-0 mt-auto ml-0 pt-2">
                                                            <NavItem>
                                                                <NavLink 
                                                                    active={(activeTab && activeTab === 'form-info')}
                                                                    onClick={() => setActiveTab('form-info')}
                                                                >
                                                                    Maquineta
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink 
                                                                    active={(activeTab && activeTab === 'tax-info')}
                                                                    onClick={() => setActiveTab('tax-info')}
                                                                >
                                                                    Taxas de crédito
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </Col>
                                            
                                                {activeTab && activeTab === 'form-info' && (
                                                    <>
                                                        <Col sm='12'>
                                                            {configuration && configuration.taxa_maquineta_por_bandeira == 0 && (
                                                                <DefaultForm rows={formFields} />    
                                                            )}
                                                            {configuration && configuration.taxa_maquineta_por_bandeira !== 0 && (
                                                                <DefaultForm rows={formFields2} />    
                                                            )}
                                                        </Col>
                                                    </>                     
                                                )}
                                                {activeTab && activeTab === 'tax-info' && (
                                                    <>
                                                        <Col lg='12'>
                                                            {/* TAXA PADRAO */}
                                                            {configuration && configuration.taxa_maquineta_por_bandeira == 0 && (
                                                                <div style={{ maxHeight: '100%', overflowY: (listModal.length > 5) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                    <table className='table table-bordered table-hover'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ verticalAlign: 'middle' }}>
                                                                                    Qtd. parcelas início
                                                                                </th>
                                                                                <th  style={{ verticalAlign: 'middle'  }}>
                                                                                    Qtd. parcelas fim 
                                                                                </th>
                                                                                <th  style={{ verticalAlign: 'middle'  }}>
                                                                                    Taxa 
                                                                                </th>
                                                                                <th className='text-center '>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='success'
                                                                                        onClick={() => setIsOpenModalDobbyFee(true)}
                                                                                    >
                                                                                        <i class="fa fa-plus-circle"></i> Incluir
                                                                                    </Button>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dobbyFee && dobbyFee.map((item, index) =>(
                                                                            <tr>
                                                                                <td>
                                                                                    {item.qtd_parcelas_inicio}
                                                                                </td>
                                                                                <td>
                                                                                    {item.qtd_parcelas_fim}
                                                                                </td>
                                                                                <td>
                                                                                    {numberFormat(item.prc_taxa * 100, 2, ',', '.')} 
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='danger'
                                                                                        onClick={() => delDobbyFee(index)}
                                                                                    >
                                                                                        <i className="material-icons">delete</i> Remover
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>  
                                                            )}

                                                            {/* TAXA POR BANDEIRA */}
                                                            {configuration  && configuration.taxa_maquineta_por_bandeira == 1 && configuration.flg_trabalha_parcelamento !== 1 && (
                                                                <div style={{ maxHeight: '100%', overflowY: (listModal.length > 5) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                    <table className='table table-bordered table-hover'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className='text-center'style={{ verticalAlign: 'middle' }} rowSpan='2'>Bandeira</th>
                                                                                <th className='text-center'style={{ verticalAlign: 'middle' }} colSpan='2'>Taxas</th>
                                                                                {configuration && configuration.flg_lanc_auto_voucher == 0 ? (
                                                                                    <>
                                                                                        <th className='text-center'style={{ verticalAlign: 'middle' }} colSpan='2'>Dias P/ Lanc.</th>  
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                        <th className='text-center'style={{ verticalAlign: 'middle' }} colSpan='2'>Qtd Dias</th>
                                                                                        <th className='text-center'style={{ verticalAlign: 'middle' }} rowSpan='2'>Dia de Corte</th>
                                                                                    </>
                                                                                )}
                                                                            </tr>
                                                                            <tr>
                                                                                <th className='text-center'>Crédito</th>
                                                                                <th className='text-center'>Débito</th>
                                                                                {configuration && configuration.flg_lanc_auto_voucher == 0 ? (
                                                                                    <>
                                                                                        <th className='text-center'>Crédito</th>
                                                                                        <th className='text-center'>Débito</th>
                                                                                    </>
                                                                                ):(
                                                                                    <>
                                                                                        <th className='text-center'>Apuração</th>
                                                                                        <th className='text-center'>Valor acumulado</th>
                                                                                    </>
                                                                                )}
                                                                                 <th className='text-center'>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='success'
                                                                                        onClick={() => setIsOpenModalDobbyFee(true)}
                                                                                    >
                                                                                        <i class="fa fa-plus-circle"></i> Incluir
                                                                                    </Button>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dobbyFee && dobbyFee.map((item, index) =>(
                                                                            <tr>
                                                                                <td>{ item.nome_bandeira }</td>
                                                                                <td>{ numberFormat(item.prc_taxa,2 ,',','.')}%</td>
                                                                                <td>{ numberFormat(item.prc_taxa_debito,2 ,',','.')}%</td>
                                                                                <td>{ item.qtd_dias_lancamento_credito }</td>
                                                                                <td>{ item.qtd_dias_lancamento_debito }</td>
                                                                                {configuration && configuration.flg_lanc_auto_voucher == 1 && (
                                                                                    <>
                                                                                        <td>{ item.qtd_dias_apuracao }</td>
                                                                                        <td>{ item.qtd_dias_pag_acumulado }</td>
                                                                                        <td>{ diaSemana(item.dia_corte) }</td>
                                                                                    </>
                                                                                )}
                                                                                <td className='text-center'>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='danger'
                                                                                        onClick={() => delDobbyFee(index)}
                                                                                    >
                                                                                        <i className="material-icons">delete</i> Remover
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>  
                                                            )}

                                                            {/* TAXA POR BANDEIRA E PARCELAMENTO */}
                                                            {configuration && configuration.taxa_maquineta_por_bandeira == 1 && configuration.flg_trabalha_parcelamento == 1 && (
                                                                <div style={{ maxHeight: '100%', overflowY: (listModal.length > 5) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                    <table className='table table-bordered table-hover'>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Bandeira</th>
                                                                                <th>Taxa crédito</th>
                                                                                <th>Taxa débito</th>
                                                                                <th className='text-center '>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='success'
                                                                                        onClick={() => setIsOpenModalDobbyFee(true)}
                                                                                    >
                                                                                        <i class="fa fa-plus-circle"></i> Incluir
                                                                                    </Button>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {dobbyFee && dobbyFee.map((item, index) =>(
                                                                            <tr>
                                                                                <td>
                                                                                    {item.nome_bandeira}
                                                                                </td>
                                                                                <td>
                                                                                    {item.taxas_parceladas && item.taxas_parceladas.map((tax, index) => (
                                                                                        <div>
                                                                                            qtd parcelas inicio: {numberFormat(tax.qtd_parcelas_inicio, ',', '.')}
                                                                                        </div>
                                                                                    ))}
                                                                                </td>
                                                                                <td>
                                                                                    {numberFormat(item.prc_taxa_debito, 2, ',', '.')} 
                                                                                </td>
                                                                                <td className='text-center'>
                                                                                    <Button 
                                                                                        size='xs'
                                                                                        theme='danger'
                                                                                        onClick={() => delDobbyFee(index)}
                                                                                    >
                                                                                        <i className="material-icons">delete</i> Remover
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>  
                                                            )}
                                                        </Col>  
                                                    </>                            
                                                )}
                                            </Row>          
                                        </CardBody>
                                            
                                        <CardFooter className="border-top">
                                            <Row>
                                                {isLoading ? (
                                                    <Col lg="12" className="text-right">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, salvando informações...
                                                    </Col>
                                                ) : (

                                                    <Col lg="12" className="text-right">
                                                        <Button outline theme="secondary" size="sm" onClick={() => resetFormFields()}>
                                                            Cancelar
                                                        </Button>
                                                        &nbsp;
                                                        <Button 
                                                            theme="accent" 
                                                            size="sm" 
                                                            className="ml-auto" 
                                                            onClick={() => saveOrUpdate(payload)}
                                                        >
                                                            <i className="material-icons">save</i>Salvar
                                                        </Button>
                                                    </Col>
                                                )}
                                            
                                            </Row>
                                        </CardFooter>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3">
                        <Card small className='mb-3'>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Listagem de Dados</h6>
                            </CardHeader>
                            <CardBody className='pt-0'>
                                <Row>
                                    <Col lg="12">
                                        <table className="table table-condensed table-hover mb-0">
                                            <tbody>
                                                {items && items.length === 0 && (
                                                    <tr>
                                                        <td className='text-center' colSpan={10}>
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                            &nbsp;
                                                            Aguarde, carregando informações...
                                                        </td>
                                                    </tr>
                                                )}

                                                {items && items.map(item => ( 
                                                    
                                                    <tr>
                                                        <td> 
                                                            <span  onClick={() => setFormData(item)}>
                                                                <small className="text-fiord-dark">{item.num_serie_maquineta}</small>
                                                                <br/>
                                                                <small className="text-secondary">{item.dsc_conta_bancaria}</small>
                                                                <br/>
                                                                <small className="text-secondary"># {item.id_maquineta}&nbsp;•&nbsp;</small>
                                                            </span>
                                                            
                                                            <small>
                                                                <a href="#" className='text-danger' onClick={() => deleteItem(item.id)}>
                                                                    <i className="material-icons">delete</i> Remover
                                                                </a>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                {pagination && (
                                    <Row>
                                        <Col sm="12" className="text-center">
                                            <ButtonGroup>
                                                {pagination.map((value, index) => (
                                                    <Button
                                                        key={index} 
                                                        size='xs'
                                                        theme={value.current ? 'primary' : 'white'}
                                                        onClick={() => getItems(value.offset)}
                                                    >
                                                        {value.index}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Modal open={isOpenModalDobbyFee} size="md">
                <ModalHeader className="p-3">
                    Adicionar Nova Taxas
                </ModalHeader>
                
                <ModalBody className="p-2" style={{ maxHeight: '70vh' }}>
                    <Form ref={formRef} className='p-2'>
                        {configuration && configuration.taxa_maquineta_por_bandeira == 0 && (
                            <Row>
                                <Col sm="4">
                                    <Input
                                        type='text'
                                        name='qtdParcelasInicio'
                                        label='Qtd. Parcelas Início'
                                        defaultValue={qtdParcelasInicio && qtdParcelasInicio}
                                        value={qtdParcelasInicio && qtdParcelasInicio}
                                        onChange={(e) => setQtdParcelasInicio(e.target.value)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Input
                                        type='text'
                                        name='qtdParcelasFim'
                                        label='Qtd. Parcelas Fim'
                                        defaultValue={qtdParcelasFim && qtdParcelasFim}
                                        value={qtdParcelasFim && qtdParcelasFim}
                                        onChange={(e) => setQtdParcelasFim(e.target.value)}
                                    />
                                </Col>
                                <Col sm="4">
                                    <NumberFormat 
                                        className="text-right"
                                        name='prcTaxa'
                                        label='Taxa'
                                        decimalScale={2} thousandSeparator="." decimalSeparator=","
                                        allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                        customInput={Input}
                                        value={prcTaxa && prcTaxa}
                                        defaultValue={prcTaxa && prcTaxa}
                                        onChange={(e) => setPrcTaxa(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        )}
                        {configuration && configuration.taxa_maquineta_por_bandeira == 1 && (
                            <>
                                <Row>
                                    <Col sm="4">
                                        <Select
                                            name='select_id_bandeira'
                                            label='Bandeira'
                                            defaultValue={selectedFlags && selectedFlags}
                                            value={selectedFlags && selectedFlags}
                                            data={flags}
                                            noMargin
                                            onChange={(e) => selectedFlag(e.target.value)}
                                        />
                                    </Col>
                                    <Col sm="4">
                                        <NumberFormat
                                            className="text-right"
                                            type='text'
                                            name='prc_taxa_debito'
                                            label='Taxa de Débito'
                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                            customInput={Input}
                                            defaultValue={prcDebitRate && prcDebitRate}
                                            value={prcDebitRate && prcDebitRate}
                                            onChange={(e) => setPrcDebitRate(e.target.value)}
                                        />
                                    </Col>
                                    {flagControll == false && (
                                        <Col sm="4">
                                            <Input 
                                                className="text-right"
                                                name='qtd_dias_lancamento_debito'
                                                label='Dias p/ Lanc. Débito'
                                                value={qtdDebitPostingDays && qtdDebitPostingDays}
                                                defaultValue={qtdDebitPostingDays && qtdDebitPostingDays}
                                                onChange={(e) => setQtdDebitPostingDays(e.target.value)}
                                            />
                                        </Col>
                                    )}
                                    <Col sm="4">
                                        <NumberFormat 
                                            className="text-right"
                                            name='prc_taxa_credito'
                                            label='Taxa de Crédito'
                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                            customInput={Input}
                                            value={prcCreditRate && prcCreditRate}
                                            defaultValue={prcCreditRate && prcCreditRate}
                                            onChange={(e) => setPrcCreditRate(e.target.value)}
                                        />
                                    </Col>
                                    {flagControll == false && (
                                        <Col sm="5">
                                            <Input 
                                                className="text-right"
                                                name='qtd_dias_lancamento_credito'
                                                label='Dias p/ Lanc. Crédito'
                                                value={qtdCreditPostingDays && qtdCreditPostingDays}
                                                defaultValue={qtdCreditPostingDays && qtdCreditPostingDays}
                                                onChange={(e) => setQtdCreditPostingDays(e.target.value)}
                                            />
                                        </Col>
                                    )}
                                </Row>
                                {flagControll == true && configuration.flg_lanc_auto_voucher == 1 && (
                                    <>
                                        <Row>
                                            <Col sm="4">
                                                <Select
                                                    name='dia_corte'
                                                    label='Dia de Corte'
                                                    value={cutDay && cutDay}
                                                    defaultValue={cutDay && cutDay}
                                                    data={week}
                                                    noMargin
                                                    onChange={(e) => setCutDay(e.target.value)}
                                                />
                                            </Col>
                                            <Col sm="4">
                                                <Input 
                                                    className="text-right"
                                                    name='qtd_dias_apuracao'
                                                    label='Qtd Dias Apuração'
                                                    value={qtdCalculationDays && qtdCalculationDays}
                                                    defaultValue={qtdCalculationDays && qtdCalculationDays}
                                                    onChange={(e) => setQtdCalculationDays(e.target.value)}
                                                    />
                                            </Col>
                                            <Col sm="4">
                                                <Input 
                                                    className="text-right"
                                                    name='qtd_dias_pag_acumulado'
                                                    label='Dias p/ Pagamento'
                                                    value={qtdAccumulatedDays && qtdAccumulatedDays}
                                                    defaultValue={qtdAccumulatedDays && qtdAccumulatedDays}
                                                    onChange={(e) => setQtdAccumulatedDays(e.target.value)}
                                                    />
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}
                       
                    </Form>
                </ModalBody>

                <ModalFooter className="p-3">
                    <>
                        <Button outline theme="secondary" size="md" onClick={() => setIsOpenModalDobbyFee(false)}>
                            <i className="material-icons">close</i> Fechar
                        </Button>

                        <Button theme="success" size="md" onClick={() => addDobbyFee()}>
                            <i className="material-icons">save</i> Adicionar
                        </Button>
                    </>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default PageView
