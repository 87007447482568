import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, NavLink } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
    CardFooter,
    CardHeader,
	Button,
    ButtonGroup
} from "shards-react";
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { useToasts } from 'react-toast-notifications';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import queryString from 'query-string';

import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import DefaultForm from '~/components/default-form';

import { useAuth } from '~/hooks/Auth';
import getValidationErrors from '~/utils/getValidationErrors';
import { 
    BankAccountService,
    BanksService,
    SubEnterpriseService
} from '~/services/WebliniaERPAPI';
import { isEmpty } from 'lodash';
import { isNumber } from 'underscore';

function PageView() {

    // API Services
    const service = new BankAccountService();

    // Global Vars
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    
    // View Controls
    const [items, setItems] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState(null);

    // Form Fields States
    const [ID, setID] = useState(null);
    const [description, setDescription] = useState('');
    const [typeAccount, setTypeAccount] = useState('');
    const [subEnterprise, setSubEnterprise] = useState('');
    const [nmeBanco, setNmeBanco] = useState('');
    const [numAgencia, setNumAgencia] = useState('');
    const [numConta, setNumConta] = useState('');

    // Aux State Lists
    const [banks, setBanks] = useState(null);
    const [subsEnterprises, setSubsEnterprises] = useState(null);
    const [types, setTypes] = useState(null); // Cria variavel
 

    // Forms Fields
    const formFields = [
        {
            columns: [
                {
                    isInput: true,
                    label: 'ID', 
                    cols: 1, 
                    name: 'ID', 
                    value: ID, 
                    isReadOnly: true, 
                    isVisible: (ID !== null)
                }, 
                {
                    isInput: true,
                    label: 'Descrição', 
                    cols: 6, 
                    name: 'description', 
                    value: description, 
                    onChangeCallback: e => setDescription(e.target.value),
                }, 
                {
                    isSelect: true,
                    label: 'Tipo', 
                    cols: 2, 
                    name: 'typeAccount', 
                    value: typeAccount,
                    onChangeCallback: e => setTypeAccount(e.target.value),
                    options: types // com valor armazenado passa valor para options
                    
                },
                {
                    isSelect: true,
                    label: 'Sub-Empreendimento', 
                    cols: 3, 
                    name: 'subEnterprise', 
                    value: subEnterprise, 
                    onChangeCallback: e => setSubEnterprise(e.target.value),
                    options: subsEnterprises
                }
            ]
        },
        {
            columns: [
                {
                    isSelect: true,
                    label: 'Banco',
                    cols: 5,
                    name: 'nmeBanco',
                    value: nmeBanco,
                    onChangeCallback: e => setNmeBanco(e.target.value),
                    options: banks
                },
                {
                    isInput: true,
                    label: 'Agência',
                    cols: 2,
                    name: 'numAgencia',
                    value: numAgencia,
                    onChangeCallback: e => setNumAgencia(e.target.value),
                },
                {
                    isInput: true, 
                    label: 'C/C',
                    cols: 2,
                    name: 'numConta',
                    value: numConta,
                    onChangeCallback: e => setNumConta(e.target.value),
                }
            ]
        },
        
    ];

    // Payload Structure
    const payload = {
        dsc_conta_bancaria: description,
        id_sub_empreendimento: subEnterprise,
        id_tipo_conta: typeAccount,
        id_banco: nmeBanco,
        num_agencia: numAgencia,
        num_conta_corrente: numConta,
        id_empreendimento: enterprise.id_empreendimento,
        empreendimentos:[
            { 
                id: enterprise.id_empreendimento 
            }
        ]                
    };

    // On Load View 
    useEffect(() => {
        getItems(0);
        loadBanks();
        loadTypes();
        loadSubsEnterprises();
        
    }, []);

    // Remote Services Actions
    const saveOrUpdate = async (payload) => {
        try {

            setIsLoading(true);

            if (ID || !isEmpty(ID))
                var response = await service.update(ID, payload);           
            else
                var response = await service.save(payload);

            if(response){
                setIsLoading(false);
    
                addToast(`Ação realizada com sucesso!`, {
                    placement: 'bottom-right',
                    appearance: 'success',
                    autoDismiss: true
                });
    
                resetFormFields();
    
                getItems(0);
            }
        }
        catch(error) {
            setIsLoading(false);

            const { response: { status, data } } = error;

            switch (status) {
            case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
                });
                break;
            case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
                });
                break;
            default:
                break;
            }
        }
    }

    const deleteItem = async (id) => {
        try {
            await service.delete(id);
            getItems(0);
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
            resetFormFields();
        }
        catch(error) {
            console.log(error);
        }
    }

    const getItems = async (offset) => {
        setItems([]);
        try {
            let params = [
                {
                    key: 'cnt->id_empreendimento',
                    value: enterprise.id_empreendimento
                },
                {
                    key: 'cnt->id_tipo_conta[exp]', 
                    value: '!=5'
                }
            ]
            const { data: { contas, paginacao } } = await service.getAll(params, offset, 5);
            setItems(contas);
            setPagination(paginacao);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }

    // View Actions
    const setFormData = async (item) => {
        setID(item.id);
        setDescription(item.dsc_conta_bancaria);
        setSubEnterprise(item.id_sub_empreendimento);
        setTypeAccount(item.id_tipo_conta);
        setNmeBanco(item.id_banco);
        setNumAgencia(item.num_agencia);
        setNumConta(item.num_conta_corrente);

    }

    const resetFormFields = async() => {
        setID(null);
        setDescription('');
        setSubEnterprise('');
        setTypeAccount('');
        setNmeBanco('');
        setNumAgencia('');
        setNumConta('');
    }

    const loadBanks = async () => { // Função carregar os bancos
        setBanks(null);
        const srv = new BanksService();
        try {
            const { data } = await srv.getAll();
            setBanks(data.bancos); //  armazeno valor em uma variavel
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadTypes = async () => {
        setTypes(null);
        const srv = new BankAccountService();

        let params = [
            {
                key: 'id[exp]', 
                value: '!=5'
            }
        ];

        try {
            const { data } = await srv.getAccounTypes(params);
            setTypes(data.tipos.map(i => {
                return {
                    id: i.id,
                    nome: i.nme_tipo_conta_bancaria
                }
            }));
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadSubsEnterprises = async () => {
        setSubsEnterprises(null);
        const srv = new SubEnterpriseService();
        try {
            const { data } = await srv.getAll();
            setSubsEnterprises(data.map(i => {
                return {
                    id: i.id,
                    nome: i.nme_fantasia
                }
            }));
        }
        catch(error) {
            console.log(error);
        }
    }
 

    return (
        <>
            <Container fluid className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                       title='Contas Bancárias' 
                       subtitle='Cadastros' 
                       className='text-sm-left' 
                    />

                    <Col xs="12" sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>
                </Row>

                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="12">
                                <Card small className='mb-3'>
                                    <Form>
                                        <CardBody>
                                            <DefaultForm rows={formFields} />                                    
                                        </CardBody>
                                            
                                        <CardFooter className="border-top">
                                            <Row>
                                                {isLoading ? (
                                                    <Col lg="12" className="text-right">
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, salvando informações...
                                                    </Col>
                                                ) : (

                                                    <Col lg="12" className="text-right">
                                                        <Button outline theme="secondary" size="sm" onClick={() => resetFormFields()}>
                                                            Cancelar
                                                        </Button>
                                                        &nbsp;
                                                        <Button 
                                                            theme="accent" 
                                                            size="sm" 
                                                            className="ml-auto" 
                                                            onClick={() => saveOrUpdate(payload)}
                                                        >
                                                            <i className="material-icons">save</i>Salvar
                                                        </Button>
                                                    </Col>
                                                )}
                                            
                                            </Row>
                                        </CardFooter>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg="3">
                        <Card small className='mb-3'>
                            <CardHeader className="border-bottom">
                                <h6 className="m-0">Listagem de Dados</h6>
                            </CardHeader>
                            <CardBody className='pt-0'>
                                <Row>
                                    <Col lg="12">
                                        <table className="table table-condensed table-hover mb-0">
                                            <tbody>
                                                {items && items.length === 0 && (
                                                    <tr>
                                                        <td className='text-center' colSpan={10}>
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                            &nbsp;
                                                            Aguarde, carregando informações...
                                                        </td>
                                                    </tr>
                                                )}

                                                {items && items.map(item => ( 
                                                    
                                                    <tr>
                                                        <td> 
                                                            <span  onClick={() => setFormData(item)}>
                                                                <small className="text-fiord-dark">{item.dsc_conta_bancaria}</small>
                                                                <br/>
                                                                <small className="text-secondary">Tipo de conta: {item.id_tipo_conta}</small>
                                                                <br/>
                                                                <small className="text-secondary"># {item.id}&nbsp;•&nbsp;</small>
                                                            </span>
                                                            
                                                            <small>
                                                                <a href="#" className='text-danger' onClick={() => deleteItem(item.id)}>
                                                                    <i className="material-icons">delete</i> Remover
                                                                </a>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                {pagination && (
                                    <Row>
                                        <Col sm="12" className="text-center">
                                            <ButtonGroup>
                                                {pagination.map((value, index) => (
                                                    <Button
                                                        key={index} 
                                                        size='xs'
                                                        theme={value.current ? 'primary' : 'white'}
                                                        onClick={() => getItems(value.offset)}
                                                    >
                                                        {value.index}
                                                    </Button>
                                                ))}
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PageView
