import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardFooter,
  Row,
  Col,
  FormSelect
} from "shards-react";

const ListItems = ({ title, referralData, typeValue }) => (
  <Card small>
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody 
      className="p-0" 
      style={{ 
        overflowY: 'scroll', 
        height: "350px" 
      }}>
      <ListGroup small flush className="list-group-small">
        {referralData.map((item, idx) => (
          <ListGroupItem key={idx} className="d-flex px-3">
            <span 
              className="text-semibold text-fiord-blue"
              style={{
                width: "60%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}>
              {item.title}
            </span>
            <span className="ml-auto text-right text-semibold" style={{ color: (typeValue == "money") ? 'green' : 'blue' }}>
              {(typeValue == "money") ? new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.value) : new Intl.NumberFormat().format(item.value)}
            </span>
          </ListGroupItem>
        ))}
      </ListGroup>
    </CardBody>

    <CardFooter className="border-top">
      <Row>
        {/* Time Span */}
        {/* <Col>
          <FormSelect
            size="sm"
            value="last-week"
            style={{ maxWidth: "130px" }}
            onChange={() => {}}
          >
            <option value="last-week">Last Week</option>
            <option value="today">Today</option>
            <option value="last-month">Last Month</option>
            <option value="last-year">Last Year</option>
          </FormSelect>
        </Col> */}

        {/* View Full Report */}
        {/* <Col className="text-right view-report"> */}
          {/* eslint-disable-next-line */}
          {/* <a href="#">Full report &rarr;</a> */}
        {/* </Col> */}
      </Row>
    </CardFooter>
  </Card>
);

ListItems.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  referralData: PropTypes.array
};

ListItems.defaultProps = {
  title: "Top Referrals",
  referralData: []
};

export default ListItems;
