export function handleInputChange(event, scope) {
  const target = event.target;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const name = target.name;

  scope.setState({
    ...scope.state,
    [name]: value
  });
}

export function generateUUID() {
  var d = new Date().getTime();
  if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
      d += performance.now(); //use high-precision timer if available
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export function generateQueryString(params) {
  var request_url = "";
  params.forEach((param, key) => {
    if ((param.key) === 'columns'){
      let columns = this.mountArrayOfColumns(param.value);
      if (key === 0)
        request_url += '?' + param.key + '=' + columns;
      else
        request_url += '&' + param.key + '=' + columns;
    }else {
      if (key === 0)
        request_url += '?' + param.key + '=' + param.value;
      else
        request_url += '&' + param.key + '=' + param.value;
    }
  });
  return request_url;
}

export function getBaseUrlWebOld() {
  if(
    (window.location.hostname == 'localhost' )
    || 
    (window.location.hostname.indexOf("192.168.") != -1 )
    || 
    (window.location.hostname.indexOf("192.193.") != -1)
    || 
    (window.location.hostname.indexOf("120.1.") != -1)
  ) {
    return `${window.location.protocol}//localhost/webliniaerp-web`;
  }
  else {
    let client_group = 'clientes';
    if (window.location.hostname === 'admin.crngroup.com.br')
      client_group = 'crn';
    return `${window.location.protocol}//${client_group}.ngweb.net.br`;
  }
}

export function getBaseUrlApiOld() {
  if(
    (window.location.hostname == 'localhost' )
    || 
    (window.location.hostname.indexOf("192.168.") != -1 )
    || 
    (window.location.hostname.indexOf("192.193.") != -1)
    || 
    (window.location.hostname.indexOf("120.1.") != -1)
  ) {
    return `${window.location.protocol}//localhost/webliniaerp-api`;
  }
  else {
    let client_group = 'clientes';
    if (window.location.hostname === 'admin.crngroup.com.br')
      client_group = 'crn';
    return `${window.location.protocol}//api.${client_group}.ngweb.net.br`;
  }
}

export function getBaseUrlReportsOld() {
  if(
    (window.location.hostname == 'localhost' )
    || 
    (window.location.hostname.indexOf("192.168.") != -1 )
    || 
    (window.location.hostname.indexOf("192.193.") != -1)
    || 
    (window.location.hostname.indexOf("120.1.") != -1)
  ) {
    return `${window.location.protocol}//localhost/ng-reports`;
    // return `http://ngclientes.srvngtst1.virtuaserver.com.br/reports`;
  }
  else {
    let client_group = 'clientes';
    if (window.location.hostname === 'admin.crngroup.com.br')
      client_group = 'crn';
    return `${window.location.protocol}//${client_group}.ngweb.net.br/reports`;
  }
}

export function removerAcentos( newStringComAcento ) {
  var string = newStringComAcento.toLowerCase();
    var mapaAcentosHex  = {
        a : /[\xE0-\xE6]/g,
        e : /[\xE8-\xEB]/g,
        i : /[\xEC-\xEF]/g,
        o : /[\xF2-\xF6]/g,
        u : /[\xF9-\xFC]/g,
        c : /\xE7/g,
        n : /\xF1/g
    };
 
    for ( var letra in mapaAcentosHex ) {
        var expressaoRegular = mapaAcentosHex[letra];
        string = string.replace( expressaoRegular, letra );
    }
 
    return string.replace( /\s/g, '' ).toUpperCase();
}