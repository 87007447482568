import api from '../api';

class DashboardAPIService extends api {

  getRequest_url(params) {
    var request_url = "";
    params.forEach((param, key) => {
      if (key === 0)
        request_url += '?' + param.key + '=' + param.value;
      else
        request_url += '&' + param.key + '=' + param.value;
    });
    return request_url;
  }

  getVendasProduto(id_empreendimento, params) {
    let request_url = this.getRequest_url(params);
    return this.api.get('/produtos/by_venda/' + id_empreendimento + "/" + request_url);
  }

  getVendasFabricate(params) {
    let request_url = this.getRequest_url(params);
    return this.api.get('/dashboard/vendas/top10/fabricante' + request_url);
  }

  getVendasHoraHoraSemanalLine(id_empreendimento, firt_date, last_date) {
    return this.api.get('/relatorio/venda_hora_a_hora/' + id_empreendimento + "/" + firt_date + "/" + last_date);
  }

  getTotalFaturamento(params) {
    let request_url = this.getRequest_url(params);
    return this.api.get('/relatorio/faturamento_diario_consolidado' + request_url);
  }

  getTotalVendasDia(firt_date, last_date, params) {
    let request_url = this.getRequest_url(params);
    return this.api.get('/count_vendas_dia/dashboard/' + firt_date + "/" + last_date + request_url);
  }

  getNewClientesDiario(firt_date, last_date, params) {
    let request_url = this.getRequest_url(params);
    return this.api.get('/count_new_cliente_dia/' + firt_date + "/" + last_date + request_url);
  }
}

export default DashboardAPIService;
