export default () => [{
  id: 1,
  name: 'Projects',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '2 days',
}, {
  id: 2,
  name: 'Movies',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '3 days',
}, {
  id: 3,
  name: 'Backups',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '7 days',
}, {
  id: 4,
  name: 'Photos',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '18 days',
}, {
  id: 5,
  name: 'Old Files',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '3 days',
}, {
  id: 6,
  name: 'New Folder',
  size: '7 GB',
  type: 'Directory',
  lastOpened: '10 minutes',
}, {
  id: 7,
  name: '01_project_description.doc',
  size: '23 KB',
  type: 'File',
  lastOpened: '1 day',
}, {
  id: 8,
  name: 'client_feedback.doc',
  size: '120 KB',
  type: 'File',
  lastOpened: '2 months',
}, {
  id: 9,
  name: 'wedding_photos_001.zip',
  size: '1.2 GB',
  type: 'File',
  lastOpened: '1 year',
}, {
  id: 10,
  name: 'old_files.zip',
  size: '24.2 GB',
  type: 'File',
  lastOpened: '2 years',
}, {
  id: 11,
  name: '2010_birthday_photos.zip',
  size: '3.2 GB',
  type: 'File',
  lastOpened: '3 years',
}, {
  id: 12,
  name: '2011_work_projects.zip',
  size: '820 MB',
  type: 'File',
  lastOpened: '3 years',
}, {
  id: 13,
  name: 'filename.txt',
  size: '29 B',
  type: 'File',
  lastOpened: '1 day',
}]
