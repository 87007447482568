import api from '../api';

class ConfigurationsService extends api {
  getConfigurations(enterprise_id) {
    let req_url = `/empreendimento/${enterprise_id}/configuracoes`;
    return this.api.get(req_url);
  }
  getConfigurationByKey(enterprise_id, configuration_key) {
    let req_url = `/empreendimento/${enterprise_id}/configuracoes/${configuration_key}`;
    return this.api.get(req_url);
  }
}

export default ConfigurationsService;
