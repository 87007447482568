import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';

import routes from "./routes";
import withTracker from "./withTracker";
import AppProvider from './hooks';


import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/main.scss";

export default () => (
  <ToastProvider placement={"bottom-right"}>
    <AppProvider>
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker(props => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
    </AppProvider>
  </ToastProvider>
);
