import api from '../api';

class Service extends api {

    getAll = (params, offset, limit) => {
        let req_url = '/bancos';
        if (offset && limit) {
            req_url += `/${offset}/${limit}`;
        }
        if(params)
            req_url += this.generateQueryString(params);
        return this.api.get(req_url);
    }

    getAcounts = (params , offset, limit) => {
        let req_url = '/contas_bancarias';
        if (offset && limit) {
            req_url += `/${offset}/${limit}`;
        }
        if(params)
            req_url += this.generateQueryString(params);
        return this.api.get(req_url);
    }

}

export default Service;