import React, {useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    FormInput,
    Row,
    Button,
    InputGroupText,
    ListGroup,
    ListGroupItem,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink
    
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Form } from '@unform/web';
import { Input, InputGroup, InputMask } from '../components/unform';
import NumberFormat from 'react-number-format';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';
import CustomModal from '../components/modal';
import * as Yup from 'yup';
import moment from 'moment';
import getValidationErrors from '../utils/getValidationErrors';
import { isEmpty } from 'underscore';
import { numberFormat } from '~/utils/formatValue';

import { 
	StoreCheckService,
    ProductsService
} from '~/services/WebliniaERPAPI';

import { useAuth } from '~/hooks/Auth';
import { set } from 'react-ga';

function PageView(props){

    const service = new StoreCheckService();

    const formRef = useRef(null);

    const { addToast } = useToasts();

    const { user, enterprise } = useAuth();

    const [modalFilterParams, setModalFilterParams] = React.useState([]);
    const [products, setProducts ] = useState([]);
    const [manufacturers, setManufacturers ] = useState([]);
    const [enterprises, setEnterprises ] = useState([]);
    const [produtosList, setProdutosList] = useState([]);
    const [installments, setInstallments] = useState([]);
    const [isOpenModalPurchase, setIsOpenModalPurchase] = useState(false);
    const [isOpenModalEmp, setOpenModalEmp ] = useState(false);
    const [isOpenModalProd, setOpenModalProd ] = useState(false);
    const [isOpenModalProv, setOpenModalProv ] = useState(false);
    const [isOpenModalManu, setOpenModalManu ] = useState(false);
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [replenishmentList, setReplenishmentList] = useState(null);
    const [canFinishOrder, setCanFinishOrder] = useState(false);
    const [activeTab, setActiveTab] = useState("product-info");
    const [qtdInstallments, setQtdInstallments] = useState(null);
    const [periodicidade, setPeriodicidade] = useState(null);
    const [provider, setProvider] = useState(null);
    const [dtaPrevista, setDtaPrevista] = useState(null);
    const [hideFilters, setHideFilters ] = useState(false);
    const [leadTime, setLeadTime] = useState(null);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [prodOrManuList, setProdOrManuList] = useState(false);

    useEffect(() => {
    }, []);

    const toggleModal = (modal) => {
        switch(modal) {
            case 'products':
                if (!isOpenModalProd) {
                    setModalFilterParams([
                        {
                            key: 'withCombinacoes',
                            value: 'true'
                        }
                    ]);
                }
                setOpenModalProd(!isOpenModalProd);
                break;
            case 'enterprises':
                if (!isOpenModalEmp) {
                    setModalFilterParams([
                        // {
                        //     key: 'emp->id[exp]',
                        //     value: 'IN (345,375,382)'
                        // }
                    ]);
                }
                setOpenModalEmp(!isOpenModalEmp);
                break;
            case 'providers':
                setOpenModalProv(!isOpenModalProv);
                break
            case 'manufacturer':
                setOpenModalManu(!isOpenModalManu);
                break;
        }
    }

    const handleSubmitFilter = async(data) => {
        try {
            formRef.current.setErrors({});
            const schema = Yup.object().shape({
            startDate: Yup.string().required('Selecione uma Data Inicial'),
            endDate: Yup.string().test("is-greater", "Deve ser maior que a inicial", function(value) {
                const { startDate } = this.parent;
                return moment(value, "yyyy-mm-dd").isSameOrAfter(moment(startDate, "yyyy-mm-dd"));
            }).required('Selecione uma Data Final')
            });
            
            await schema.validate(data, {
            abortEarly: false,
            });

            // resetVars();

            await getPurchaseForecast(data);

        } catch (error) {
            if(error instanceof Yup.ValidationError) {
                formRef.current.setErrors(getValidationErrors(error));
                return;
            }
        }
    }

    const handleResetFilter = useCallback(() => {
        formRef.current.reset();
        setEnterprises([]);
        setProducts([]);
        setCanFinishOrder(false);
      }, []);

    const addManufacturer = useCallback(async (name, item) => {
        if(item) {
          try{
            // clonando p/ poder manipular
            let _manufacturers = _.clone(manufacturers);
            
            // inicializando o array, caso esteja null ou undefined
            if(!_manufacturers)
              _manufacturers = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if(_manufacturers.length > 0) {
              _manufacturers.forEach(man => {
                if(man.id === item.id) {
                  can_add = false;
                  addToast(`Esse item já contem na listagem!`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                  });
                };
              })
            }
    
            // add apenas se não estiver no array
            if(can_add)
              _manufacturers.push(item);
    
            // atualiza o state
            setManufacturers(_manufacturers);
          }
          catch(err){
            console.log(err);
          }

        }else{
          setOpenModalManu(false);
        }
    },[manufacturers])
    
    const delManufacturer = async (index) => {
        let _manufacturers = _.clone(manufacturers);
            
            if(_manufacturers) {
                _manufacturers.splice(index, 1);
            }
            setManufacturers(_manufacturers);
    }

    const addProduct = useCallback(async (name, item) => {
        if(item) {
          try{
            // clonando p/ poder manipular
            let _products = _.clone(products);
            
            // inicializando o array, caso esteja null ou undefined
            if(!_products)
              _products = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if(_products.length > 0) {
              _products.forEach(pro => {
                if(pro.id === item.id) {
                  can_add = false;
                  addToast(`Esse item já contem na listagem!`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                  });
                };

              })
            }
    
            // add apenas se não estiver no array
            if(can_add)
              _products.push(item);
    
            // atualiza o state
            setProducts(_products);
          }
          catch(err){
            console.log(err);
          }

        }else{
          setOpenModalProd(false);
        }
    },[products])
    
    const delProduct = async (index) => {
        let _products = _.clone(products);
            
            if(_products) {
                _products.splice(index, 1);
            }
                setProducts(_products);
            
    }

    const addEnterprise = useCallback(async (name, item) => {
        if(item) {
          try{
            // clonando p/ poder manipular
            let _enterprises = _.clone(enterprises);
            
            // inicializando o array, caso esteja null ou undefined
            if(!_enterprises)
              _enterprises = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if(_enterprises.length > 0) {
              _enterprises.forEach(emp => {
                if(emp.id === item.id) {
                    can_add = false;
                    addToast(`Esse item já contem na listagem!`, {
                        placement: 'bottom-right',
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
              })
            }
    
            // add apenas se não estiver no array
            if(can_add)
              _enterprises.push(item);

            _enterprises = _enterprises
            _enterprises = _.sortBy(_enterprises, (_emp) => { return _emp.id });
    
            // atualiza o state
            setEnterprises(_enterprises);

            // atualiza os enterprises de cada produto
            updateEnterpriseProducts(_enterprises);
          }catch(err){
              console.log(err);
          }
        }else{
          setOpenModalEmp(false);
        }
    },[enterprises]);
    
    const delEnterprise = async (index) =>{
        let _enterprises = _.clone(enterprises);
        if(_enterprises)
            _enterprises.splice(index, 1);
        setEnterprises(_enterprises);
        updateEnterpriseProducts(_enterprises);
    }

    const updateEnterpriseProducts = useCallback((_enterprises) => {
        _enterprises = (_enterprises) ? _enterprises : enterprises;
        let _produtosList = _.clone(produtosList);
        if(_produtosList){
            _produtosList = _produtosList.map(pro => {
                if (!pro.enterprises || pro.enterprises.length === 0) {
                    pro.enterprises = _.clone(_enterprises);
                }
                else {
                    // valida se tem que adicionar
                    _enterprises.forEach(_empx => {
                        let can_add = true;
                        pro.enterprises.forEach(_empp => {
                            if (_empx.id === _empp.id) {
                                can_add = false;
                            }
                        });
                        if (can_add)
                            pro.enterprises.push(_empx);
                    });
                    // valida se tem que remover
                    pro.enterprises.forEach((_empx, index) => {
                        let can_remove = true;
                        _enterprises.forEach(_empp => {
                            if (_empx.id === _empp.id) {
                                can_remove = false;
                            }
                        });
                        if (can_remove)
                            pro.enterprises.splice(index, 1);
                    });
                }
                return pro;
            });
            setProdutosList(_produtosList);
        }
    }, [enterprises]);

    const getPurchaseForecast = useCallback(async(data) => {
        try {

            if(isEmpty(enterprises) || !(enterprises) && enterprises.length === 0){
                addToast(`Por favor, selecione ao menos um empreendimento`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
                setIsLoadingSearch(false);
            }

            setIsLoadingSearch(true);

            setLeadTime(data.leadtime);

            setDtaPrevista(moment().add(parseInt(data.leadtime, 10), 'd').format('DD/MM/YYYY'));

            let _prodx = _.clone(products);

            let prodIds = [];

            _prodx = _prodx.forEach(_prodxx => {
                prodIds.push(_prodxx.id);
            });

            let _manux = _.clone(manufacturers);

            let manuIds = [];

            _manux = _manux.forEach(_manuxx => {
                manuIds.push(_manuxx.id);
            });

            let promises = [];
            
            
            enterprises.forEach( emp => {
                let params = [{
                        key: 'ids_empreendimentos', 
                        value: emp.id
                    },
                    {
                        key: 'ultimos_x_dias',
                        value: (parseFloat(data.leadtime) + parseFloat(data.vendaDiaXDia))
                        // value: 75
                    }
                ];

                if(products && products.length > 0){
                    params.push({
                        key: 'produto',
                        value: prodIds
                    })
                }

                if(manufacturers && manufacturers.length > 0){
                    params.push({
                        key:'prd->id_fabricante',
                        value: manuIds 
                    })
                };

                promises.push(service.getStoreCheck(params));

            });


            Promise.allSettled(promises)
            .then(async result => {
                setIsLoadingSearch(false);

                let _temp = [];

                result.forEach(res => {
                    if(res.value){
                        // identifica o empreendimento da requisição...
                        let id_empreendimento = null;
                        let url = res.value.config.url;
                        let qs = url.split("?")[1];
                        let params = qs.split("&");
                        params.forEach(param => {
                            let kv = param.split("=");
                            if (kv[0] === "ids_empreendimentos") {
                                id_empreendimento = kv[1];
                            }
                        });
    
                        let _emp = _.filter(enterprises, {id: id_empreendimento})[0];
                        res.value.data = res.value.data.map(d => {
                            d.empreendimento = _.clone(_emp);
                            return d;
                        });
                        _temp = [..._temp, ...res.value.data];
                    }
                });
                
                _temp = _.groupBy(_temp, 'id_produto');
                
                let _products = [];

                Object.keys(_temp).forEach((id_produto) => {
                    _products.push({
                        data: _temp[id_produto][0],
                        fornecedor: {
                            is_loading: true
                        },
                        enterprises: _temp[id_produto].map(p => {
                            let obj = {
                                empreendimento: p.empreendimento,
                                vlr_ultimo_custo: p.vlr_ultimo_custo
                            };

                            Object.keys(p).forEach(key => {
                                if (key.includes('qtd_')) {
                                    obj[key] = p[key];
                                }
                            });

                            // Função de conta qtd a comprar
                            obj = calculateForecastByItem(obj, p, id_produto, data);

                            return obj;
                        })
                    });
                });

                _products = _products.map(_prod => {

                    _prod.quantityDemanded = 0;

                    enterprises.forEach(_emp => {
                        let can_add_emp = true;
                        _prod.enterprises.forEach(_pe => {
                            if (_pe.empreendimento.id === _emp.id) {
                                can_add_emp = false;
                            }
                            _prod.quantityDemanded += parseFloat(_emp.qtd_comprar_sem_transito);
                        })
                        if(can_add_emp) {
                            _prod.enterprises.push({
                                empreendimento: _.clone(_emp),
                                vlr_ultimo_custo: _prod.data.vlr_ultimo_custo,
                                qtd_estoque: 0,
                                qtd_comprar_sem_transito: 0,
                                qtd_vendida_ultimos_dias: 0
                            });
                        }
                    });

                    _prod.HaveDemandedStock = true;
                    _prod.enterprises = _.sortBy(_prod.enterprises, (_emp) => { return _emp.empreendimento.id });
                    
                    return _prod;
                });

                setProdutosList(_products);

                try {

                    let params = [
                        { key: 'withCombinacoes', value: true },
                        { key: 'tpe->id_empreendimento', value: enterprise.id_empreendimento },
                        { key: 'pro->flg_controlar_estoque', value: 1 }
                    ];

                    if (manufacturers && manufacturers.length > 0) {
                        params.push({
                            key: 'pro->id_fabricante[exp]',
                            value: `IN (${manufacturers.map(e => e.id).join(',')})`
                        });
                    }

                    if (products && products.length > 0) {
                        params.push({
                            key: 'pro->id[exp]',
                            value: `IN (${products.map(e => e.id_produto).join(',')})`
                        });
                    }

                    const prdService = new ProductsService();

                    let { data: { produtos: _providerStockProducts } } = await prdService.getAll(null, null, params);
                    
                    _products = _products.map(p => {
                        _providerStockProducts.forEach(psp => {
                            if (psp.id_produto === p.data.id_produto) {
                                p.fornecedor.is_loading = false;
                                p.fornecedor.qtd_estoque = psp.qtd_item;
                            }
                        });

                        if(parseFloat(p.fornecedor.qtd_estoque) < parseFloat(p.quantityDemanded)){
                            p.HaveDemandedStock = false;
                        }
                        else{
                            p.HaveDemandedStock = true;
                        };

                        return p;
                    });

                    setCanFinishOrder(true);
                    setProdutosList(_products);

                } catch (error) {
                    console.log(error);
                    setIsLoadingSearch(false);
                }

            })
            .catch(error => {
                console.log(error);
                setIsLoadingSearch(false);
            });
        }
        catch(data){
            console.log(data);
            setIsLoadingSearch(false);
        }
    }, [enterprises, products, manufacturers, produtosList]);

    const calculateForecastByItem = useCallback((obj, p, id_produto, data)=> {

        let ultimos_x_dias = parseFloat(data.vendaDiaXDia);
        let leadtime_fornecedor = parseInt(data.leadtime, 10);

        // quanto vendi por dia
        obj.vmd_sem_transito = ((obj.qtd_vendida_ultimos_dias + obj.qtd_transferida_ultimos_dias) / (ultimos_x_dias + leadtime_fornecedor)); //obj.ultimos_x_dias

        if ((obj.vmd_sem_transito * (ultimos_x_dias + leadtime_fornecedor)) >= obj.qtd_estoque) {
            obj.qtd_comprar_sem_transito = (obj.vmd_sem_transito * (ultimos_x_dias + leadtime_fornecedor)) - obj.qtd_estoque;

            if(p.id_produto_embalagem){     
                let unid = Math.round((parseFloat(obj.qtd_comprar_sem_transito) / parseFloat(obj.qtd_embalagem_unitaria_embalagem)));

                if(unid < 1){
                    obj.qtd_comprar_sem_transito = 0;
                }
                else {
                    obj.qtd_comprar_sem_transito = (unid * parseFloat(obj.qtd_embalagem_unitaria_embalagem));
                }
            }
        } 
        else{
            obj.qtd_comprar_sem_transito = 0;
        }

        obj.vmd_com_transito = (((obj.qtd_vendida_ultimos_dias + obj.qtd_transferida_ultimos_dias) + obj.qtd_em_orcamento) / (ultimos_x_dias + leadtime_fornecedor));

        if ((obj.vmd_com_transito * (ultimos_x_dias + leadtime_fornecedor)) >= obj.qtd_estoque) {
            obj.qtd_comprar_com_transito = (obj.vmd_com_transito * (ultimos_x_dias + leadtime_fornecedor)) - obj.qtd_estoque;
        }
        else{
            obj.qtd_comprar_com_transito = 0;
        }

        return obj;
    }, [enterprises, products, manufacturers, produtosList]);

    const openSumaryModal = useCallback(() => {
        var canOpen = true;

        var error = 0;
    
        resetModalReplenishment();

        let l = [];
        produtosList.forEach(p => {
            if(p.HaveDemandedStock == false){
                error = 1;
                canOpen = false;
            }
            p.enterprises.forEach(e => {
                l.push({
                    data: _.clone(p.data),
                    empreendimento: _.clone(e.empreendimento),
                    values: e,
                    qtd_comprar_subtotal: 0,
                    qtd_a_comprar: 0
                }); 
            });
        });

        l = l.map(z =>{
            produtosList.forEach(p => {
                if (p.data.id_produto === z.data.id_produto) {
                    p.enterprises.forEach(e => {
                        if(z.empreendimento.id == e.empreendimento.id){
                            z.qtd_comprar_subtotal += e.qtd_comprar_sem_transito;
                            z.qtd_a_comprar += e.qtd_comprar_sem_transito;
                        }
                    });
                }
            });
            return z;
        });

        l = Object.values(_.groupBy(l, e => e.empreendimento.id));
        
        for(let i in l) {
            l[i] = _.filter(l[i], e => (e.values.qtd_comprar_sem_transito !== 0));
        }

        // l.forEach(z => {
        //     if(z.length <= 0){
        //         error = 2;
        //         canOpen = false;
        //     }
        // });

        l.forEach((z, idx) => {
            if(z.length <= 0){
                // z.splice(idx, 1);
                l = l.filter(z => z.length > 0);
                error = 2;
            }
        });

        if(canOpen){
            setIsOpenModalPurchase(true);
            setReplenishmentList(l);
        }
        else{
            switch (error) {
                case 1:
                    addToast(`A Lista de compras contem produtos com quantidade pedida acima do estoque do fornecedor!`, {
                        placement: 'bottom-right',
                        appearance: 'warning',
                        autoDismiss: true
                    });
                    break;
                case 2:
                    addToast(`A lista contem empreendimentos com lista vazia!`, {
                        placement: 'bottom-right',
                        appearance: 'warning',
                        autoDismiss: true
                    });
                    break;
            }
            
        }
    }, [produtosList]);

    const updateQuantityBuy = async(prod_id, emp_prod, qtd_compra_modificada) =>{

        let _produtosList = _.clone(produtosList);

        _produtosList = _produtosList.map(prod => {

            let quantityDemanded = 0;

            prod.enterprises = prod.enterprises.map(emp => {
                if((prod.data.id_produto == prod_id)){
                    if((emp.empreendimento.id == emp_prod.empreendimento.id)){
                        emp.qtd_comprar_sem_transito = parseFloat(qtd_compra_modificada.replace(".","").replace(",","."));
                    }
                    quantityDemanded += parseFloat(emp.qtd_comprar_sem_transito);
                }

                return emp;
            });

            if(parseFloat(prod.fornecedor.qtd_estoque) < parseFloat(quantityDemanded)){
                prod.HaveDemandedStock = false;
            }
            else{
                prod.HaveDemandedStock = true;
            };
            
            return prod;
        });

        setProdutosList(_produtosList);
    }

    const updateTotalPurchaseAmount = async(prod_id, emp_id ,qtd_compra_modificada) => {
        let _replenishmentList = _.clone(replenishmentList);
        _replenishmentList = _replenishmentList.map(prod => {
            prod = prod.map(x => {
                if(x.empreendimento.id == emp_id){
                    if(x.data.id_produto == prod_id){
                        x.qtd_a_comprar = parseFloat(qtd_compra_modificada.replace(".","").replace(",","."));
                    }
                }
                return x;
            })
            return prod;
        });
        setReplenishmentList(_replenishmentList);
    }
    
    const updateVlrTotal = async(prod_id, emp_id ,value) => {
        let _replenishmentList = _.clone(replenishmentList);
        _replenishmentList = _replenishmentList.map(prod => {
            prod = prod.map(x => {
                if(x.data.id_produto == prod_id && x.empreendimento.id == emp_id){
                    x.enterprises.vlr_ultimo_custo = parseFloat(value.replace(".","").replace(",","."));
                }
                return x;
            })
            return prod;
        });
        setReplenishmentList(_replenishmentList);
    }

    const hideListEnterprise = async(emp_id) => {

        let _replenishmentList = _.clone(replenishmentList);

        _replenishmentList = _replenishmentList.map(prod => {
            prod = prod.map(x => {
                if(x.empreendimento.id == emp_id){
                    if(x.empreendimento.hideList && x.empreendimento.hideList == true){
                        x.empreendimento.hideList = false;
                    }
                    else if(!x.empreendimento.hideList || x.empreendimento.hideList == false){
                        x.empreendimento.hideList = true;
                    }
                }
                return x;
            })
            return prod;
        });

        setReplenishmentList(_replenishmentList);
    }

    const getTotalAmount = (_replenishmentList) => {
        var totalPedido = 0;
        _replenishmentList = _replenishmentList ? _replenishmentList : _.clone(replenishmentList);
        if (_replenishmentList) {
            _replenishmentList.forEach(prod => {
                prod.forEach(x => {
                    if(!(x.qtd_a_comprar)){
                        x.qtd_a_comprar = prod.qtd_comprar_subtotal;
                    }
                    let vlrTotal = parseFloat(x.qtd_a_comprar ? x.qtd_a_comprar : 0) * parseFloat(x.values.vlr_ultimo_custo);
                    totalPedido += parseFloat(vlrTotal);
                })
            });
        }
        return totalPedido;
    }

    const getSubtotalEnterprise = (items) => {
        let total = 0;
        items.forEach(i => {
            if (!isNaN(i.qtd_a_comprar) && !isNaN(i.values.vlr_ultimo_custo)) {
                total += (i.qtd_a_comprar * i.values.vlr_ultimo_custo);
            }
        });
        return total;
    }

    const calcInstallments = useCallback((_qtdInstallments) => {
        setQtdInstallments(parseInt(_qtdInstallments, 10));
        let _installments = [];
        let total_pedido = getTotalAmount(replenishmentList);
        let valor_parcela = (total_pedido / parseInt(_qtdInstallments, 10));
        for (let index = 0; index < parseInt(_qtdInstallments, 10); index++) {
            _installments.push({
                dia_parcela: 0,
                vlr_parcela: valor_parcela
            });
        }

        setInstallments(_installments);
        if (periodicidade && parseInt(periodicidade, 10) > 0) {
            calcFrequency(_installments, _qtdInstallments, periodicidade);
        }
    }, [periodicidade, installments, qtdInstallments, replenishmentList]);

    const calcFrequency = useCallback((_installments, _qtdInstallments, _periodicidade) => {
        _qtdInstallments = _qtdInstallments ? _qtdInstallments : qtdInstallments;
        _periodicidade  = _periodicidade ? _periodicidade : periodicidade;
        setPeriodicidade(_periodicidade);
        if(_qtdInstallments && parseInt(_qtdInstallments, 10) > 0 && _periodicidade && parseInt(_periodicidade, 10) > 0){
            var dias = _.clone(parseInt(_periodicidade, 10));
            _installments = _installments.map((parcela) => {
                parcela.dia_parcela = parseInt(dias, 10);
                let leadtime_periodo = (parseInt(leadTime, 10) + parseInt(parcela.dia_parcela, 10));
                parcela.dta_parcela_br = moment().add(leadtime_periodo, 'd').format('DD/MM/YYYY');
                parcela.dta_parcela = moment(parcela.dta_parcela_br, 'DD/MM/YYYY').format('YYYY-MM-DD');
                dias += parseInt(_periodicidade, 10);
                return parcela;
            });
            setInstallments(_installments);
        }
    }, [periodicidade, installments, qtdInstallments, replenishmentList]);

    const generateOrder = async() => {
        try {
            setIsLoadingSave(true);
            if(provider && !isEmpty(provider)){
                const payload = {
                    empreendimentos: replenishmentList.map(e => {
                            return {
                                empreendimento: e[0].empreendimento,
                                items: e.map(item => {
                                    return {
                                        produto: item.data,
                                        vlr_custo: item.values.vlr_ultimo_custo,
                                        qtd_comprar: item.qtd_comprar_subtotal
                                    }
                                })
                            }
                        }),
                        dados_pedido: {
                            fornecedor: provider,
                            usuario: user,
                            dta_prevista: dtaPrevista,
                            parcelas: installments,
                            qtd_parcelas: qtdInstallments,
                            periodicidade: periodicidade,
                            totalPedido: numberFormat(getTotalAmount(replenishmentList), 2, ',', '.')
                        }
                    };
        
                var response = await service.save(payload);
        
                if(response){
                    setIsOpenModalPurchase(false);
                    resetModalReplenishment();
                    setIsLoadingSave(false);
                    addToast(`Ação realizada com sucesso!`, {
                        placement: 'bottom-right',
                        appearance: 'success',
                        autoDismiss: true
                    });
                }
            }else{
                setIsLoadingSave(false);
                addToast(`Por favor adicionar um fornecedor!`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        }catch({ response: { status } }){
            switch (status) {
                case 406:
                    setIsLoadingSave(false);
                    addToast(`A lista contem produtos sem valor de custo!`, {
                        placement: 'bottom-right',
                        appearance: 'warning',
                        autoDismiss: true
                    });
                break;
                case 500:
                    setIsLoadingSave(false);
                    addToast(`Erro ao salvar!`, {
                        placement: 'bottom-right',
                        appearance: 'warning',
                        autoDismiss: true
                    });
                break;
            }
        }
    }

    const resetModalReplenishment = async() => {
        setActiveTab("product-info");
        setQtdInstallments(null);
        setPeriodicidade(null);
    }
    
    return (
        <>
            <Container fluid className='main-content-container px-6 pb-6'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Reposição de Lojas'
                        subtitle='Produtos / Controle de Estoque' 
                        className='text-md-left'
                    />

                    <Col xs="12" sm="4" className="col d-flex align-items-center">
                        &nbsp;
                    </Col>

                    <Col sm="4" className="d-flex justify-content-end">
                        {canFinishOrder && (
                            <Button 
                                theme='success'
                                outline
                                className="ml-3"
                                onClick={() => {
                                    openSumaryModal();
                                }}
                            >
                                <i class="fa fa-cart-plus"></i> Finalizar Compra
                            </Button>
                        )}
					</Col>

                </Row>

                <Row>
                    <Col lg = '9'>
                        <Card className='mb-3'>
                            <CardBody>
                                <table 
                                    className="table table-sm table-bordered table-condensed table-houver mb-0"
                                >
                                    <thead className='bg-light'>
                                        <tr>
                                            <th class="text-center" rowSpan="2" className="text-center" style={{ verticalAlign: 'middle' }}>#</th>
                                            <th class="text-left" rowSpan="2" style={{ verticalAlign: 'middle' }}>Produto</th>
                                            <th class="text-center" width="150" rowSpan="2" style={{ verticalAlign: 'middle' }}>Qtd. Estoque Fornecedor</th>
                                            {enterprises && enterprises.map(obj => (
                                                <th colspan="3"class="text-center">
                                                    {obj.nome_empreendimento}
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            {enterprises && enterprises.map(obj => (
                                                <>
                                                    <th class="text-center">Qtd. Estoque</th>
                                                    <th class="text-center">Qtd. Vendidos</th>
                                                    <th class="text-center">Qtd. Reposição</th>
                                                </>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoadingSearch && (
                                            <>
                                                <tr className='text-center'>
                                                    <td colSpan={(enterprises.length * 3) + 3}>
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, Carregando as informações
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                        
                                        {!isLoadingSearch && (        
                                            <>    
                                                {produtosList && produtosList.map((prod, idx) => (
                                                    <tr className={(prod.HaveDemandedStock) ? '' : 'text-white bg-danger'}>
                                                        <td className="text-center" style={{ verticalAlign: 'middle' }}>{(idx+1)}</td>
                                                        <td style={{ verticalAlign: 'middle' }}>
                                                            {prod.data.nme_produto}
                                                            <br/>
                                                            <small className={(prod.HaveDemandedStock) ? 'text-muted' : 'text-white'}># {prod.data.id_produto} &nbsp;•&nbsp; {prod.data.nme_fabricante} {prod.data.nme_cor ? ' • ' + prod.data.nme_cor : ''} {prod.data.nme_tamanho ? ' • ' + prod.data.nme_tamanho : ''}</small>
                                                        </td>
                                                        <td className='text-center' style={{ verticalAlign: 'middle' }}>
                                                            {prod.fornecedor.is_loading && (
                                                                <i className="fa fa-spinner fa-spin"></i>
                                                            )}
                                                            {prod.fornecedor.qtd_estoque && prod.fornecedor.qtd_estoque}
                                                        </td>

                                                        {prod.enterprises && prod.enterprises.map(obj => (
                                                            <>
                                                                <td width="80" class="text-center" style={{ verticalAlign: 'middle' }} >
                                                                    {obj.qtd_estoque}
                                                                </td>
                                                                <td width="80" class="text-center" style={{ verticalAlign: 'middle' }} >
                                                                    {obj.qtd_vendida_ultimos_dias}
                                                                </td>
                                                                <td width="80" class="text-center" style={{ verticalAlign: 'middle' }} >
                                                                    <NumberFormat
                                                                        className="text-right"
                                                                        decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                                        allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                        customInput={FormInput}
                                                                        onChange={e => updateQuantityBuy(prod.data.id_produto, obj, e.target.value)}
                                                                        defaultValue={obj.qtd_comprar_sem_transito}
                                                                    />
                                                                </td>
                                                            </>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="3">
                        <Card className='p-0'>
                            <CardHeader className="border-bottom p-3">
                                <Row>
                                    <Col sm="6">
                                        <h6 class="m-0">
                                            <i class="fa fa-filter"></i>
                                             Opções de Filtro
                                        </h6>
                                    </Col>
                                    <Col sm="6" className={'text-right'}>
                                        <a href="#" className="text-primary"  onClick={() => setHideFilters(!hideFilters)}>
                                            {(hideFilters) && 
                                                <span>
                                                    Exibir&nbsp;
                                                    <i class="fa fa-chevron-down"></i>
                                                </span>
                                            }
                                            {(!hideFilters) && 
                                                <span>
                                                    Ocultar&nbsp;
                                                    <i class="fa fa-chevron-up"></i>
                                                </span>
                                            }
                                        </a>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className="p-3" style={{ display: (hideFilters) ? 'none' : ''}}>
                                
                                    <Form ref={formRef} onSubmit={handleSubmitFilter}>
                                        <Row>
                                            <Col sm="6">
                                                <Input
                                                    type='date'
                                                    name='startDate'
                                                    label='Data Inicial'
                                                />
                                            </Col>
                                            <Col sm="6">
                                                <Input
                                                    type='date'
                                                    name='endDate'
                                                    label='Data Final'
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6'>
                                                <Input
                                                    type='text'
                                                    name='vendaDiaXDia'
                                                    label='Qtd. Dias Cobertura'
                                                    defaultValue= '30'
                                                />
                                            </Col>
                                            <Col sm='6'>
                                                <Input
                                                    type='text'
                                                    name='leadtime'
                                                    label='LT Fornecedor (Dias)'
                                                    defaultValue= '7'
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg='12'>
                                                <InputGroup
                                                    name="fornecedor" 
                                                    label="Fornecedor" 
                                                    onClick={() => {
                                                        toggleModal('providers');
                                                    }}
                                                    value={provider && provider.nome_fornecedor}
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        {provider && (
                                            <> 
                                                <Row>
                                                    <Col sm='12'>
                                                        <ListGroup flush>
                                                            <ListGroupItem className="pl-0 pt-3 pr-0 pb-0">
                                                                <span className="d-flex mb-2">
                                                                <span className="mr-1">Empreendimentos</span>
                                                                <span className="ml-auto">
                                                                    <small>
                                                                        <a href="#" className="text-success" onClick={() => toggleModal('enterprises')}>
                                                                        <i class="fa fa-plus-circle"></i> Incluir
                                                                        </a>
                                                                    </small>
                                                                </span>
                                                                </span>
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                        <div style={{ maxHeight: '350px', overflowY: (enterprises.length > 0) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                            <table className='table table-condensed table-hover'>
                                                                <tbody>
                                                                    {enterprises && enterprises.map((item, index) =>(
                                                                    <tr>
                                                                        <td width='165'>
                                                                        <small>{item.nome_empreendimento}</small>
                                                                        </td>
                                                                        <td className='text-right'>
                                                                        <small>
                                                                            <a href="#" className="text-danger" onClick={() => delEnterprise(index)}>
                                                                                <i className="material-icons">delete</i> Remover
                                                                            </a>
                                                                        </small>
                                                                        </td>
                                                                    </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>

                                                {enterprises.length > 0 && (
                                                    <>
                                                        <Row>
                                                            <Col sm='12'>
                                                                <ListGroup flush>
                                                                    <ListGroupItem className="pl-0 pt-3 pr-0 pb-0">
                                                                        <span className="d-flex mb-2">
                                                                            <span className="mr-1"> Fabricantes </span>
                                                                            <span className="ml-auto">
                                                                                <small>
                                                                                    <a href="#" className="text-success" onClick={() => toggleModal('manufacturer')}>
                                                                                    <i class="fa fa-plus-circle"></i> Incluir
                                                                                    </a>
                                                                                </small>
                                                                            </span>
                                                                        </span>
                                                                    </ListGroupItem>
                                                                </ListGroup>
                                                                <div style={{ maxHeight: '350px', overflowY: (manufacturers.length > 0) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                    <table className='table table-condensed table-hover'>
                                                                        <tbody>
                                                                            {manufacturers && manufacturers.map((item, index) =>(
                                                                                <tr>
                                                                                    <td width='290'>
                                                                                        <small>
                                                                                            {item.nome_fabricante}
                                                                                        </small>
                                                                                        <br/>
                                                                                        <small className="text-secondary">
                                                                                            #{item.id}
                                                                                        </small>
                                                                                    </td>
                                                                                    <td className='text-right' style={{ verticalAlign: 'middle' }}>
                                                                                        <small>
                                                                                            <a href="#" className="text-danger" onClick={() => delManufacturer(index)}>
                                                                                                <i className="material-icons">delete</i> Remover
                                                                                            </a>
                                                                                        </small>    
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row>
                                                            <Col sm='12'>
                                                                <ListGroup flush>
                                                                    <ListGroupItem className="pl-0 pt-3 pr-0 pb-0">
                                                                        <span className="d-flex mb-2">
                                                                            <span className="mr-1">Produtos</span>
                                                                            <span className="ml-auto">
                                                                                <small>
                                                                                    <a href="#" className="text-success" onClick={() => toggleModal('products')}>
                                                                                    <i class="fa fa-plus-circle"></i> Incluir
                                                                                    </a>
                                                                                </small>
                                                                            </span>
                                                                        </span>
                                                                    </ListGroupItem>
                                                                </ListGroup>
                                                                <div style={{ maxHeight: '350px', overflowY: (products.length > 0) ? 'scroll' : 'hidden' , overflowX: 'hidden'}}>
                                                                    <table className='table table-condensed table-hover'>
                                                                        <tbody>
                                                                            {products && products.map((item, index) =>(
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                <small>{item.nome}</small>
                                                                                <br/>
                                                                                <small className="text-secondary">
                                                                                    {item && item.nome_fabricante && (
                                                                                        <>{item.nome_fabricante}</>
                                                                                    )}
                                                                                    {item && item.nome_tamanho && (
                                                                                        <>&nbsp;•&nbsp; {item.nome_tamanho}</>
                                                                                    )}
                                                                                    {item && item.nome_cor_sabor && (
                                                                                        <>&nbsp;•&nbsp; {item.nome_cor_sabor}</>
                                                                                    )}
                                                                                </small>
                                                                                <br/>
                                                                                <small className="text-secondary">
                                                                                    #{item.id}&nbsp;•&nbsp;
                                                                                </small>
                                                                                <small>
                                                                                    <a href="#" className="text-danger" onClick={() => delProduct(index)}>
                                                                                        <i className="material-icons">delete</i> Remover
                                                                                    </a>
                                                                                </small>
                                                                                </td>
                                                                            </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                )}  
                                            </>
                                        )}
                                        
                                        <Row>
                                            <Col sm="6" className={'text-center'}>
                                                <Button 
                                                    type="Button" 
                                                    outline theme='secondary' 
                                                    className="ml-2"
                                                    onClick={() => handleResetFilter()                                                                                                                                                                                                            }
                                                >
                                                    <i className='fa fa-times'></i> Limpar Filtro
                                                </Button>
                                            </Col>
                                            <Col sm="6" className={'text-center'}>
                                                {isLoadingSearch && (
                                                    <>
                                                        <Button 
                                                        theme='accent' className="ml-3"
                                                        disabled
                                                        >
                                                        <i className="fa fa-spinner fa-spin"></i>
                                                        &nbsp;
                                                        Aguarde, carregando ..
                                                        </Button>
                                                    </>
                                                )}
                                                {!isLoadingSearch && (
                                                    <>
                                                        <Button 
                                                            type='submit' 
                                                            theme='accent' 
                                                            className="ml-3"
                                                        >
                                                        <i className='fa fa-filter'></i> Aplicar Filtro
                                                        </Button>
                                                    </>
                                                )}
                                            </Col>
                                        </Row>
                                    </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {isOpenModalManu &&
                    <CustomModal
                        type='ListManufacturer'
                        size="lg"
                        open={isOpenModalManu}
                        toggle={() => toggleModal('manufacturer')}
                        selectCallback={(type, item) => addManufacturer(type, item)}
                        // autoClose={false}
                    />
                }
                {isOpenModalProd &&
                    <CustomModal
                        type='ListProduct'
                        size="lg"
                        open={isOpenModalProd}
                        toggle={() => toggleModal('products')}
                        selectCallback={(type, item) => addProduct(type, item)}
                        filterParams={modalFilterParams}
                        autoClose={false}
                    />
                }
                {isOpenModalEmp && 
                    <CustomModal
                        type='ListEnterprises'
                        size="lg"
                        open={isOpenModalEmp}
                        toggle={() => toggleModal('enterprises')}
                        selectCallback={(type, item) => addEnterprise(type, item)} 
                        filterParams={modalFilterParams}
                        autoClose={false}
                    />
                }
                {isOpenModalProv &&
                    <CustomModal
                        type='ListProviders'
                        size="lg"
                        open={isOpenModalProv}
                        toggle={() => toggleModal('providers')}
                        selectCallback={(type, item) => {
                            setProvider(item);
                            setOpenModalProv(false);
                        }}
                    />
                }
            </Container>

            <Modal open={isOpenModalPurchase} size="xxl">
                <ModalHeader className="p-3">
                    Listagem de Compras
                </ModalHeader>
                
                <ModalBody className="p-2" style={{ maxHeight: '70vh' }}>
                    <Row>
                        <Col lg='12'>
                            <div className="border-bottom clearfix d-flex">
                                    <Nav tabs className="border-0 mt-auto ml-0 pt-2">
                                        <NavItem>
                                            <NavLink 
                                                active={(activeTab && activeTab === 'product-info')}
                                                onClick={() => setActiveTab('product-info')}
                                            >
                                                Produtos
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink 
                                                active={(activeTab && activeTab === 'request-info')}
                                                onClick={() => setActiveTab('request-info')}
                                            >
                                                Dados do Pedido
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                            </div>
                        </Col>

                        <Col lg='12'>
                            {activeTab && activeTab === 'product-info' && (
                                <div style={{ maxHeight: '60vh', overflowY: 'scroll' , overflowX: 'hidden'}}>
                                    <table className="table table-sm table-bordered table-condensed table-houver mb-0">
                                        <thead>
                                            <tr>
                                                <th>
                                                    Produto
                                                </th>
                                                <th className='text-center'>
                                                    Qtd Prevista
                                                </th>
                                                <th className='text-center'>
                                                    Qtd Compra
                                                </th>
                                                <th className='text-center'>
                                                    Custo
                                                </th>
                                                <th className='text-center'>
                                                    Subtotal
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {replenishmentList && replenishmentList.map(obj =>(
                                                <>
                                                    <tr>
                                                        <th colSpan={2} className='text-white' style={{ backgroundColor: '#3D5170' }}>
                                                            {obj[0].empreendimento.nome_empreendimento}
                                                        </th>
                                                        <th className='text-white text-center' style={{ backgroundColor: '#3D5170' }}>
                                                            Qtd Itens: {obj.length}
                                                        </th>
                                                        <th className='text-white text-right' style={{ backgroundColor: '#3D5170' }}>
                                                            {numberFormat(getSubtotalEnterprise(obj), 2, ',', '.')}
                                                        </th>
                                                        <th className='text-white text-right' style={{ backgroundColor: '#3D5170' }}>
                                                            <a href="#" className="text-white"  onClick={() => hideListEnterprise(obj[0].empreendimento.id)}>
                                                                {(obj[0].empreendimento.hideList) && 
                                                                    <span>
                                                                        Exibir&nbsp;
                                                                        <i class="fa fa-angle-down"></i>
                                                                    </span>
                                                                }
                                                                {(!obj[0].empreendimento.hideList) && 
                                                                    <span>
                                                                        Ocultar&nbsp;
                                                                        <i class="fa fa-angle-up"></i>
                                                                    </span>
                                                                }
                                                            </a>
                                                        </th>
                                                    </tr>
                                                    {obj && obj.map(o => (
                                                        <tr style={{ display: (obj[0].empreendimento.hideList) ? 'none' : ''}}>
                                                            <td>
                                                                {o.data.nme_produto}
                                                                <br/>
                                                                <small className='text-muted'># {o.data.id_produto}</small>
                                                            </td>
                                                            <td width='100' className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                {numberFormat(o.qtd_comprar_subtotal, 2, ',', '.')}
                                                            </td>
                                                            <td width='100' className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                <NumberFormat
                                                                    className="text-center"
                                                                    decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                    onChange={e => updateTotalPurchaseAmount(o.data.id_produto, o.empreendimento.id, e.target.value)}
                                                                    customInput={FormInput}
                                                                    defaultValue={o.qtd_a_comprar ? o.qtd_a_comprar : 0}
                                                                />
                                                            </td>
                                                            <td width='100' className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                <NumberFormat
                                                                    readOnly
                                                                    className="text-right"
                                                                    decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                                    customInput={FormInput}
                                                                    onChange={e => updateVlrTotal(o.data.id_produto, o.empreendimento.id, e.target.value)}
                                                                    defaultValue={o.values.vlr_ultimo_custo ? o.values.vlr_ultimo_custo : 0}
                                                                />
                                                            </td>
                                                            <td width='120' className='text-right' style={{ verticalAlign: 'middle' }}>
                                                                {numberFormat((o.qtd_a_comprar * o.values.vlr_ultimo_custo), 2, ',', '.')}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th colSpan={4} class="text-right">Total:</th>
                                                <th class="text-right">{numberFormat(getTotalAmount(replenishmentList), 2, ',', '.')}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            )}

                            {activeTab && activeTab === 'request-info' && (
                                <div className='pt-3 pb-2'>
                                    <Form ref={formRef} className='p-2'>
                                        <Row>
                                            <Col sm="2">
                                                <Input
                                                    type='text'
                                                    name='dataPrevista'
                                                    label='Prev. Entrega'
                                                    defaultValue={dtaPrevista && dtaPrevista}
                                                    value={dtaPrevista && dtaPrevista}
                                                    disabled
                                                />
                                            </Col>

                                            <Col lg='2'>
                                                <Input
                                                    defaultValue={qtdInstallments && qtdInstallments}
                                                    value={qtdInstallments && qtdInstallments}
                                                    name='qtdInstallments' 
                                                    label='Qtd. Parcelas'
                                                    onChange={(e) => calcInstallments(e.target.value)}
                                                />
                                            </Col>

                                            <Col lg='2'>
                                                <Input
                                                    defaultValue={periodicidade && periodicidade}
                                                    value={periodicidade && periodicidade}
                                                    name='periodicidade' 
                                                    label='Periodicidade'
                                                    onChange={(e) => calcFrequency(installments, qtdInstallments, e.target.value)} 
                                                />
                                            </Col>

                                            <Col lg='2'>
                                                <Input
                                                    readOnly
                                                    defaultValue={0}
                                                    value={numberFormat(getTotalAmount(replenishmentList), 2, ',', '.')}
                                                    name='totalPedido' 
                                                    label='Total Pedidos'
                                                    className='text-right'
                                                />
                                            </Col>

                                            {installments && installments.length > 0 && (
                                                <Col lg='6'>
                                                    <FormGroup>
                                                        <label>Parcelamento</label>
                                                        <table className="table table-sm table-bordered table-condensed table-houver mb-0">
                                                            <thead>
                                                                <th className='text-center' width={30}>#</th>
                                                                <th className='text-center' width={100}>Vencimento</th>
                                                                <th className='text-center' width={100}>Valor</th>
                                                            </thead>
                                                            <tbody>
                                                                {installments && installments.map((parcela, idx) => (
                                                                    <tr>
                                                                        <td className='text-center'>
                                                                            {idx+1}
                                                                        </td>
                                                                        <td className='text-center'>
                                                                            {parcela.dta_parcela ? moment(parcela.dta_parcela, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'N/D'}
                                                                        </td>
                                                                        <td className='text-right'>
                                                                            {numberFormat(parcela.vlr_parcela, 2, ',', '.')}
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </FormGroup>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form>
                                </div>
                            )}
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter className="p-3">
                    {isLoadingSave && (
                        <>
                            <i className="fa fa-spinner fa-spin"></i>
                            &nbsp;
                            Aguarde, Salvando o Pedido
                        </>
                    )}
                    {!isLoadingSave && (
                        <>
                            <Button outline theme="secondary" size="md" onClick={() => setIsOpenModalPurchase(false)}>
                                <i className="material-icons">close</i> Fechar
                            </Button>

                            <Button theme="success" size="md" onClick={generateOrder}>
                                <i className="material-icons">save</i> Gerar Pedido
                            </Button>
                        </>
                    )}

                </ModalFooter>
            </Modal>
        </>
    )
}

export default PageView 