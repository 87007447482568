import React, { useRef, useState } from 'react'
import { Button } from 'shards-react';

function component({ handleFileUpload }) {
    const [fileSelected, setFileSelected] = useState(null);

    const hiddenFileInput = useRef();

    const handleClick = (event) => {
        hiddenFileInput.current.click();
    }

    const handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        setFileSelected(fileUploaded);
        handleFileUpload(fileUploaded);
    }

    return (
        <>
            <Button 
                theme="accent" 
                style={{ lineHeight: '2.4em' }} 
                onClick={handleClick}
            >
                <i className="material-icons">upload</i> Fazer upload de arquivo
            </Button>
            <input 
                type="file"
                style={{ display: 'none' }}
                ref={hiddenFileInput}
                onChange={handleChange}
            />
        </>
    )
}

export default component
