import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardHeader, CardBody, CardFooter, FormSelect } from "shards-react";

import Chart from "../../utils/chart";

import moment from 'moment';

import { DashboardAPIService } from './../../services/WebliniaERPAPI/index';

class UsersOverview extends React.Component {
  state = {
    selected_date: moment().format('YYYY-MM-DD')
  }
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.BlogUsersOverview = null;
  }

  componentDidMount = async() =>  {
    await this.getVendasHoraHoraLine({
      dia: this.state.selected_date,
      previsto: 100000,
      color: 'blue',
      chart_label: moment(this.state.selected_date).format('DD/MM/YYYY'),
      chart_type: 'line'
    });

    setInterval(this.init.bind(this), 30000);
  }

  init = async() => {
    await this.getVendasHoraHoraLine({
      dia: this.state.selected_date,
      previsto: 100000,
      color: 'blue',
      chart_label: moment(this.state.selected_date).format('DD/MM/YYYY'),
      chart_type: 'line'
    });
  }

  getVendasHoraHoraLine = async(previsto) => {
    let api = new DashboardAPIService();
    var initial30date = moment(previsto.dia).format("YYYY-MM-DD");
    var last30date = moment(previsto.dia).subtract(1, 'months').format("YYYY-MM-DD");

    let data30dias = await api.getVendasHoraHoraSemanalLine(274,last30date, initial30date);
    var labels = [];
    var totaisPrevistos = [];
    var dataTotal30dias = [];
    var hora = data30dias['data']['horas'];

    totaisPrevistos.push(previsto);

    Object.keys(hora).map(function(key, index) {
      labels.push(key);
      var diaSemana = hora[key];
      var qtdValor = 0;
      diaSemana.forEach(qtds => {
        qtdValor += qtds['total'];
      });
      qtdValor =  (qtdValor / 7);
      dataTotal30dias.push({
        hora: key,
        valor: qtdValor,
        peso: 0
      });
    });

    let vlrTotalHorarios = 0;

    dataTotal30dias.forEach((horario) => {
      vlrTotalHorarios += horario.valor;
    });

    dataTotal30dias.forEach((horario) => {
      horario.peso = (horario.valor/ vlrTotalHorarios) * 100;
    });

    let datasets = [];

    var previsto_dataset = [];
    totaisPrevistos.forEach((diaPrevisto) => {
      dataTotal30dias.forEach((horario) => {
        horario['previsto_'+ diaPrevisto.dia] = (diaPrevisto.previsto * horario.peso) / 100;
        previsto_dataset.push(horario['previsto_'+ diaPrevisto.dia].toFixed(2));
      });


      datasets.push({
        label: "Previsto",
        fill: "start",
        data: previsto_dataset,
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: diaPrevisto.color,
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3,
        type: diaPrevisto.chart_type
      });
    });

    let dataDiaAtual = await api.getVendasHoraHoraSemanalLine(274, previsto.dia, previsto.dia);
    let horaAtual = dataDiaAtual['data']['horas'];
    let dataSetAtual = [];
    Object.keys(horaAtual).map(function(key, index) {
      let diaSemana = horaAtual[key];
      let qtdValor = 0;
      diaSemana.forEach(qtds => {
        qtdValor += qtds['total'];
      });
      dataSetAtual.push(qtdValor.toFixed(2));
    });

    let colors = []
    let backColor = [];
    dataSetAtual.forEach((valueAtual, i) => {
        if(parseFloat(dataSetAtual[i]) > parseFloat(previsto_dataset[i])){
          colors.push("rgb(23, 198, 113)");
          backColor.push("rgb(23, 198, 113,0.1)");
        }
        else{
          colors.push("rgb(255, 52, 52)");
          backColor.push("rgb(255, 52, 52,0.1)");
        }
    });

    datasets.push({
      label: "Realizado",
      fill: "start",
      data: dataSetAtual,
      backgroundColor: backColor,
      borderColor: colors,
      pointBackgroundColor: "#ffffff",
      pointHoverBackgroundColor: "rgb(0,123,255)",
      borderWidth: 1.5,
      pointRadius: 0,
      pointHoverRadius: 3,
      type: "bar"
    });

    var chartData = {
      labels: labels,
      datasets: datasets
    }

    const chartOptions = {
      ...{
        responsive: true,
        legend: {
          position: "top"
        },
        elements: {
          line: {
            // A higher value makes the line look skewed at this ratio.
            tension: 0.3
          },
          point: {
            radius: 0
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: false,
              ticks: {
                callback(tick, index) {
                  // Jump every 7 values on the X axis labels to avoid clutter.
                  return index % 7 !== 0 ? "" : tick;
                }
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                suggestedMax: 45,
                callback(tick) {
                  if (tick === 0) {
                    return tick;
                  }
                  // Format the amounts using Ks for thousands.
                  return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
                }
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: false
        },
        tooltips: {
          custom: false,
          mode: "nearest",
          intersect: false
        },
        color: (context) => {
          let index = context.dataIndex;
          let value = context.dataset.data[index];
          return value;
        }
      },
      ...this.props.chartOptions
    };

    if(this.BlogUsersOverview != null)
      this.BlogUsersOverview.destroy();

    this.BlogUsersOverview = new Chart(this.canvasRef.current, {
      type: "bar",
      data: chartData,
      options: chartOptions
    });


    // They can still be triggered on hover.
    const buoMeta = this.BlogUsersOverview.getDatasetMeta(0);
    buoMeta.data[0]._model.radius = 0;
    buoMeta.data[
      chartData.datasets[0].data.length - 1
    ]._model.radius = 0;

    // Render the chart.
    this.BlogUsersOverview.render();
  }

  changeSelectDays = (event) => {
    var date = event.target.value;
    this.setState({ selected_date: event.target.value });

    var previsto = [];
    previsto["2019-11-28"] = 100000;
    previsto["2019-11-29"] = 150000;
    previsto["2019-11-30"] = 150000;
    previsto["2019-12-01"] = 120000;
    previsto["2019-12-02"] = 80000;
    previsto["2019-12-03"] = 5000;

    var data = {
      dia: date,
      previsto: previsto[date],
      color: 'blue',
      chart_label: '29/11/19',
      chart_type: 'line'
    };
    this.getVendasHoraHoraLine(data);
  }

  render() {
    const { title } = this.props;
    const { selected_date } = this.state;
    return (
      <Card small className="h-100">
        <CardHeader className="border-bottom">
          <h6 className="m-0">{title}</h6>
        </CardHeader>
        <CardBody className="pt-0">
          <canvas
            height="120"
            ref={this.canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
        </CardBody>
        <CardFooter className="border-top">
          <Row>
            {/* Time Span */}
            <Col className="text-right">
              <FormSelect
                  size="sm"
                  value={ selected_date }
                  style={{ maxWidth: "130px" }}
                  onChange={this.changeSelectDays}
                >
                <option value="2019-11-28">28/11/2019</option>
                <option value="2019-11-29">29/11/2019</option>
                <option value="2019-11-30">30/11/2019</option>
                <option value="2019-12-01">01/12/2019</option>
                <option value="2019-12-02">02/12/2019</option>
                <option value="2019-12-03">03/12/2019</option>
                <option value="2019-12-04">04/12/2019</option>
                <option value="2019-12-05">05/12/2019</option>
                <option value="2019-12-06">06/12/2019</option>
                <option value="2019-12-07">07/12/2019</option>
                <option value="2019-12-08">08/12/2019</option>
              </FormSelect>
            </Col>

            {/* </Col> */}
          </Row>
        </CardFooter>
      </Card>
    );
  }
}

UsersOverview.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The chart dataset.
   */
  chartData: PropTypes.object,
  /**
   * The Chart.js options.
   */
  chartOptions: PropTypes.object
};

UsersOverview.defaultProps = {
  title: "Users Overview",
  chartData: {
    labels: Array.from(new Array(30), (_, i) => (i === 0 ? 1 : i)),
    datasets: [
      {
        label: "Current Month",
        fill: "start",
        data: [
          500,
          800,
          320,
          180,
          240,
          320,
          230,
          650,
          590,
          1200,
          750,
          940,
          1420,
          1200,
          960,
          1450,
          1820,
          2800,
          2102,
          1920,
          3920,
          3202,
          3140,
          2800,
          3200,
          3200,
          3400,
          2910,
          3100,
          4250
        ],
        backgroundColor: "rgba(0,123,255,0.1)",
        borderColor: "rgba(0,123,255,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgb(0,123,255)",
        borderWidth: 1.5,
        pointRadius: 0,
        pointHoverRadius: 3
      },
      {
        label: "Past Month",
        fill: "start",
        data: [
          380,
          430,
          120,
          230,
          410,
          740,
          472,
          219,
          391,
          229,
          400,
          203,
          301,
          380,
          291,
          620,
          700,
          300,
          630,
          402,
          320,
          380,
          289,
          410,
          300,
          530,
          630,
          720,
          780,
          1200
        ],
        backgroundColor: "rgba(255,65,105,0.1)",
        borderColor: "rgba(255,65,105,1)",
        pointBackgroundColor: "#ffffff",
        pointHoverBackgroundColor: "rgba(255,65,105,1)",
        borderDash: [3, 3],
        borderWidth: 1,
        pointRadius: 0,
        pointHoverRadius: 2,
        pointBorderColor: "rgba(255,65,105,1)"
      }
    ]
  }
};

export default UsersOverview;
