import api from '../api';

class FlavorsService extends api {

  getFlavors (offset, limit, params) {
    let req_url = `/cores_produto/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default FlavorsService;
