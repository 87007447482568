import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardFooter,
    CardHeader,
    ListGroup,
    Progress,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    FormInput,
    InputGroup,
    InputGroupAddon,
    InputGroupText
} from "shards-react";
import { Form } from '@unform/web';
import { Select } from '../components/unform';
import { Input, InputMask } from '../components/unform';
import { useAuth } from '~/hooks/Auth';
import { numberFormat } from '~/utils/formatValue';
import PageTitle from "../components/common/PageTitle";
import CustomModal from '../components/modal';
import ProgressBars from "../components/components-overview/ProgressBars";
import Sliders from "../components/components-overview/Sliders";
import _ from 'lodash';
import moment from 'moment';

import { 
    ReportsService
  } from '~/services/WebliniaERPAPI';


function PageView(props){
    const {user ,enterprise } = useAuth();

    const VLR_MIN_BOX = 24.9;
    const VLR_MAX_BOX = 95.4;
    
    const VLR_MIN_LINE = 26.9;
    const VLR_MAX_LINE = 97.3;

    const [years, setYears] = useState([
        { id: 2021, nome: 2021},
        { id: 2022, nome: 2022},
        { id: 2023, nome: 2023},
        { id: 2024, nome: 2024},
        { id: 2025, nome: 2025},
        { id: 2026, nome: 2026},
        { id: 2027, nome: 2027},
        { id: 2028, nome: 2028},
        { id: 2029, nome: 2029},
        { id: 2030, nome: 2030},
        { id: 2031, nome: 2031}
    ]);
    const [months, setMonths] = useState([
        { id: '01', nome: 'Janeiro'},
        { id: '02', nome: 'Fevereiro'},
        { id: '03', nome: 'Março'},
        { id: '04', nome: 'Abril'},
        { id: '05', nome: 'Maio'},
        { id: '06', nome: 'Junho'},
        { id: '07', nome: 'Julho'},
        { id: '08', nome: 'Agosto'},
        { id: '09', nome: 'Setembro'},
        { id: '10', nome: 'Outubro'},
        { id: '11', nome: 'Novembro'},
        { id: '12', nome: 'Dezembro'}
    ]);
    
    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    
    const [dayOfMonth, setDayOfMonth] = useState(parseInt(moment().format('D'),10));
    const [boxPosition, setBoxPosition] = useState(VLR_MIN_BOX);
    const [linePosition, setLinePosition] = useState(VLR_MIN_LINE);
    
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);

    const [pagination, setPagination] = useState(null);
    
    const [representantes, setRepresentantes] = useState(null);
    const [isOpenModalRepresentante, setIsOpenModalRepresentante] = useState(false);
    const [salesmanSelected, setSalesmanSelected] = useState(null);

    const [sales, setSales] = useState(null);

    useEffect(() => {
        
        // var date = new Date();
        // var m = date.getMonth() + 1;
        // m = new String(m).toString().padStart(2,'0');
        // var y = date.getFullYear();

        setSelectedMonth(moment().format('MM'));
        setSelectedYear(moment().format('YYYY'));
        calculaPercentualTempo();
        getVendasAnaliticoVendedorPorAno(null, null, null, moment().format('MM'), moment().format('YYYY'));
    }, []);

    const getVendasAnaliticoVendedorPorAno = async (_year, _month, _salesman, mes, ano) => {
        try {
            setSales(null);
            setIsLoadingSearch(true);
            let params = [];
            
            _salesman = (_salesman) ? _salesman : salesmanSelected;

            if (_salesman)
                params.push({key: 'usu->id', value: _salesman.id});
            
            let activeYear = (_year) ? _year : ano;
            let activeMonth = (_month) ? _month : mes;

            const service = new ReportsService();
            let { data: vendedores } = await service.getVendasAnaliticoVendedorPorAno(
                enterprise.id_empreendimento, 
                activeYear, 
                activeMonth, 
                null, 
                null, 
                params
            );
            vendedores = vendedores.map(vendedor => {
                // if (vendedor.prepostos) {
                //     vendedor.prepostos.map(preposto => {
                //         vendedores.forEach((_vendedor, idx) => {
                //             if (_vendedor.id === preposto.id)
                //                 vendedores.splice(idx, 1);
                //         });
                //         return defineMeta(preposto);
                //     });
                // }
                return defineMeta(vendedor);
            });
            setSales(vendedores);
            setIsLoadingSearch(false);
        }
        catch(err) {
            setSales(null);
            console.log(err);
        }
    }

    const defineMeta = (vendedor) => {
        if(vendedor.vlr_meta_mes > 0){
            vendedor.prc_meta = (vendedor.vlr_total_vendido_mes/vendedor.vlr_meta_mes)*100;
            if (vendedor.prc_meta >= 100) {
                vendedor.color = 'success';
            }
            else if (vendedor.prc_meta >= 45 && vendedor.prc_meta < 100) {
                vendedor.color = 'warning';
            }
            else if (vendedor.prc_meta < 45) {
                vendedor.color = 'danger';
            }
        }else{
            vendedor.prc_meta = 0
        }
        
        return vendedor;
    }

    const calculaPercentualTempo = () => {
        let daysInMonth = moment().daysInMonth();
        let prc_referencia = ((dayOfMonth / daysInMonth) * 100);
        let posLine = calculaPosicaoIndicadorTempo(VLR_MIN_LINE, VLR_MAX_LINE, prc_referencia);
        setLinePosition(posLine);
        let posBox = calculaPosicaoIndicadorTempo(VLR_MIN_BOX, VLR_MAX_BOX, prc_referencia);
        setBoxPosition(posBox);
    }

    const calculaPosicaoIndicadorTempo = (vlr_minimo, vlr_maximo, prc_referencia) => {
        let parcial_max = (vlr_maximo * prc_referencia) / 100;
        let parcial_min = (vlr_minimo * prc_referencia) / 100;
        let resultado = (parcial_max + (parcial_min + vlr_minimo));
        return (parcial_max >= vlr_minimo) ? parcial_max : resultado;
    }

    const countMounth = (operation) =>{
        let year =  selectedYear;
        let month = selectedMonth;
        let date = moment(`${year}-${month}`, 'YYYY-MM')
        date[operation](1, 'month');
        setSelectedYear(date.format('YYYY'));
        setSelectedMonth(date.format('MM'));
        getVendasAnaliticoVendedorPorAno(date.format('YYYY'), date.format('MM'));
    }

    const isMounth = () =>{
        let isMonthActual = moment().format('MM');
        if(isMonthActual == selectedMonth){
            return true;
        }else{
            return false;
        }
    }

    const getActiveMonth = (activeMonthNumber) => {
        if (activeMonthNumber != null)
            return _.find(months, {id: activeMonthNumber}).nome;
        return '';
    }
    
    return (
        <>
            <Container className="main-content-container px-6 pb-6">
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title={`Acompanhamento de Meta`}
                        subtitle='Relatórios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12">
                        <Card>
                            <Form >
                                <CardBody>
                                    <Row>
                                        <Col lg="6">
                                                <label>Selecione o Representante</label>
                                                <br></br>
                                            <InputGroup className="mb-3">
                                                <InputGroupAddon 
                                                    style={{ cursor: 'pointer' }}
                                                    name='vendedor' type="prepend"
                                                    onClick={() => setIsOpenModalRepresentante(true)}>
                                                    <InputGroupText>
                                                        <i className="fa fa-bars"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <FormInput placeholder="Vendedor" readOnly
                                                    style={{ cursor: 'pointer' }}
                                                    value={salesmanSelected ? salesmanSelected.nome : ''}
                                                    defaultValue={salesmanSelected ? salesmanSelected.nome : ''}
                                                    onClick={() => setIsOpenModalRepresentante(true)}
                                                />
                                            </InputGroup>
                                        </Col>

                                        <Col lg="6" className="text-right m-auto" >
                                            <Button
                                                outline
                                                theme="primary" 
                                                onClick={() => countMounth('subtract')}
                                            >
                                                <i className="fa fa-arrow-left fa-3"></i>
                                            </Button>
                                            &nbsp;
                                            <Button disabled theme= "primary" outline>
                                                <strong>{getActiveMonth(selectedMonth)}/{selectedYear}</strong>
                                            </Button>
                                            &nbsp;
                                            <Button 
                                                outline
                                                theme="primary" 
                                                onClick={() => countMounth('add')}
                                            >
                                                <i className="fa fa-arrow-right fa-4"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>

                <Row className="mt-2">
                    <Col lg="12">
                        <Card small className='mb-3'>
                            <Form>
                                <CardBody>
                                        {isMounth(true) ? (
                                            <>
                                            <div class="BoxLine" style={{ left: `${boxPosition}%` }}>
                                            <div class="txtBoxLine">Hoje</div>
                                            </div>
                                            <div class="AbsoluteLine" style={{ left: `${linePosition}%`}}></div>
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        )}
                                    <Row>
                                        {isLoadingSearch ? (
                                            <Col lg="12" className="text-center">
                                                <i className="fa fa-spinner fa-spin"></i>
                                                &nbsp;
                                                Aguarde, buscando informações...
                                            </Col>
                                        ) : (
                                            <Col lg="12">
                                                <table className='table table-bordered table-hover mb-0'>
                                                    <tbody>
                                                        {sales && sales.map(sale => (
                                                            <>
                                                                <tr>
                                                                    <td width={'5%'} className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                        <img 
                                                                            width="25" height="25"
                                                                            class="rounded-circle" 
                                                                            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"> 
                                                                        </img>
                                                                    </td>
                                                                    <td width={'20%'}>
                                                                        <strong>{sale.nme_vendedor}</strong>
                                                                        <br/>
                                                                        <small className='text-default'>Vendas/Faturamento</small>
                                                                        <h5 className={`m-0 text-${sale.color}`}>
                                                                            <strong>R$ {numberFormat(sale.vlr_total_vendido_mes, 2, ',', '.')}</strong>
                                                                        </h5>
                                                                        <small className='text-default'>Meta</small>
                                                                        <h6 className='m-0'>R$ {numberFormat(sale.vlr_meta_mes, 2, ',', '.')}</h6>
                                                                    </td>
                                                                    <td width="75%" className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                        <Progress 
                                                                            className={`mt-0 `} 
                                                                            style={{ height: "10px"}} 
                                                                            value={sale.prc_meta}
                                                                            theme={sale.color}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                
                                                                {/* {sale.prepostos && sale.prepostos.map(preposto => (
                                                                    <tr>
                                                                        <td 
                                                                            width={'5%'} 
                                                                            className='text-center' 
                                                                            style={{ 
                                                                                verticalAlign: 'middle',
                                                                            }}
                                                                        >
                                                                            <img 
                                                                                width="25" height="25"
                                                                                class="rounded-circle" 
                                                                                src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"> 
                                                                            </img>
                                                                        </td>
                                                                        <td width={'20%'}>
                                                                            <strong>{preposto.nme_vendedor}</strong>
                                                                            <br/>
                                                                            <small className='text-default'>Vendas/Faturamento</small>
                                                                            <h5 className={`m-0 text-${preposto.color}`}>
                                                                                <strong>R$ {numberFormat(preposto.vlr_total_vendido_mes, 2, ',', '.')}</strong>
                                                                            </h5>
                                                                            <small className='text-default'>Meta</small>
                                                                            <h6 className='m-0'>R$ {numberFormat(preposto.vlr_meta_mes, 2, ',', '.')}</h6>
                                                                        </td>
                                                                        <td width="75%" className='text-center' style={{ verticalAlign: 'middle' }}>
                                                                            <Progress 
                                                                                className={`mt-0 `} 
                                                                                style={{ height: "10px"}} 
                                                                                value={preposto.prc_meta}
                                                                                theme={preposto.color}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))} */}
                                                            </>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
            {isOpenModalRepresentante && (
                <CustomModal
                    type={'ListVendors'}
                    size="lg"
                    open={true}
                    toggle={(isClearItemSelected) => {
                        setIsOpenModalRepresentante(false);
                        if (isClearItemSelected) {
                            setSalesmanSelected(null);
                            getVendasAnaliticoVendedorPorAno(null, null, null, moment().format('MM'), moment().format('YYYY'));
                        }
                    }}
                    selectCallback={(listType, itemSelected) => {
                        setSalesmanSelected(itemSelected);
                        getVendasAnaliticoVendedorPorAno(null, null, itemSelected,  moment().format('MM'), moment().format('YYYY'));
                        console.log(itemSelected);
                    }}
                    filterParams={[{key: 'usu->flg_representante', value: 1}]}
                />
            )}
        </>
    )
}
export default PageView
