import api from '../api';

class OPService extends api {
    
    getStatusList = () => {
        return this.api.get(`/status/ordem-producao`);
    }

    getById = (op_id) => {
        return this.api.get(`/ordem-producao/${op_id}`);
    };

    getAll = (offset, limit, params) => {
        let req_url = '/ordens-producao';
        
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;

        if(params)
            req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    };
    
    save = data => {
        return this.api.post('/ordem-producao', JSON.stringify(data));
    };

    schedule = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/agendar`, JSON.stringify(data));
    };

    publish = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/publicar`, JSON.stringify(data));
    };

    startPicking = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/iniciar-separacao`, JSON.stringify(data));
    };

    startProduction = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/iniciar-producao`, JSON.stringify(data));
    };

    finishProduction = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/finalizar-producao`, JSON.stringify(data));
    };
    
    rollback = (op_id, data) => {
        return this.api.post(`/ordem-producao/${op_id}/estornar`, JSON.stringify(data));
    };
}

export default OPService;
