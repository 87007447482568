import React, { useState, useRef, useEffect } from "react";
import ReactTable from "react-table";
import dateFormat from "dateformat";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Col,
  ButtonGroup,
  Button,
  FormGroup,
  FormRadio,
  FormCheckbox,
  Nav,
  NavItem,
  NavLink,
} from "shards-react";
import { Form } from '@unform/web';
import { Input, Radio } from '../../unform';
import { CampaignAPIService } from '../../../services/WebliniaERPAPI/index';
let formExtRef;

async function getInfoCampaign(campaign_id) {
  const campaignService = new CampaignAPIService();
  let { data } = await campaignService.getInfoCampaign(campaign_id);
  return data;
}

async function getProductsCampaign(campaign_id){
  const campaignService = new CampaignAPIService();
  let { data } = await campaignService.getProductsCampaign(campaign_id);
  return data;
}

async function getEnterprisesCampaign(campaign_id){
  const campaignService = new CampaignAPIService();
  let { data } = await campaignService.getEnterpisesCampaign(campaign_id);
  return data;
}

async function getSchedulesCampaign(campaign_id){
  const campaignService = new CampaignAPIService();
  let { data } = await campaignService.getSchedulesCampaign(campaign_id);
  return data;
}

function TabContent(props) {
  const formRef = useRef(null);
  formExtRef = formRef;
  const [ campaign, setCampaign ] = useState({
    name: '',
    start_date: '',
    end_date: '',
    type: 'produto',
    format: '',
    price_tables: [
      { table: "atacado", value: true },
      { table: "intermediario", value: true },
      { table: "intermediario_ii", value: true },
      { table: "varejo", value: true }
    ],
    enterprises: [],
    schedules: []
  });

  async function getCampaign(campaign_id) {
    let campaign = {};
    const info = await getInfoCampaign(campaign_id);
    const products = await getProductsCampaign(campaign_id);
    const enterprises = await getEnterprisesCampaign(campaign_id);
    const schedules = await getSchedulesCampaign(campaign_id);

    campaign.name = info.nme_campanha;
    campaign.start_date = dateFormat(new Date(info.dta_inicial_campanha), "yyyy-mm-dd");
    campaign.end_date = dateFormat(new Date(info.dta_final_campanha), "yyyy-mm-dd");
    campaign.type = info.tipo_campanha;
    campaign.format = info.formato_campanha;
    campaign.products = products;
    campaign.enterprises = enterprises;
    campaign.schedules = schedules;

    formRef.current.setData(campaign);
  }

  useEffect(() => {
    if(props.campaign){
      getCampaign(props.campaign)
    }
  }, []);

  function saveCampaign(dataObject) {
    console.log(dataObject)
  }

  return (
    <Form ref={formRef} initialData={campaign} onSubmit={saveCampaign}>
      { props.tab === 'inf' &&
        <Row>
          <Col sm="12" lg="12">
            <Row>
              <Col sm="12" lg="6">
                <Input type="text" name="name" label="Nome da Campanha" />
              </Col>
              <Col sm="12" lg="3">
                <Input type="date" name="start_date" label="Data de Inicio" />
              </Col>
              <Col sm="12" lg="3">
                <Input type="date" name="end_date" label="Data de Fim" />
              </Col>
            </Row>
            <Row>
              <Col sm="12" lg="12">
                <Radio
                  inline
                  name="type"
                  label="Tipo da Campanha"
                  defaultValue={0}
                  options={[
                    { id: "produto", label: "Produtos" },
                    { id: "categoria", label: "Categorias" },
                    { id: "ambos", label: "Ambos" }
                  ]}
                />
              {/* options={[
        { label: "% de Desconto", value: "perc_desconto" },
        { label: "Valor Fixo Produto (R$) ", value: "vlr_fixo_produto" },
        { label: "Valor Fixo Desconto (R$)", value: "vlr_fixo_desconto" },
        { label: "Qtd. Múltipla", value: "qtd_multipla" }
    ]} */}
              </Col>
            </Row>
          </Col>
        </Row>
      }

      { props.tab === 'ent' &&
        <Row>
          <Col sm="12" lg="12">
            empreendimentos
          </Col>
        </Row>
      }

      { props.tab === 'sch' &&
        <Row>
          <Col sm="12" lg="12">
            agendamentos
          </Col>
        </Row>
      }
    </Form>
  );
}

function CampaignModal(props) {

  const [tabs, setTabs] = useState([
    { value: 'inf', label: 'Informações' },
    { value: 'ent', label: 'Empreendimentos' },
    { value: 'sch', label: 'Agendamentos' },
  ]);

  const [ activeTab, setActiveTab ] = useState('inf');

  function submitForm() {
    formExtRef.current.submitForm()
  }

  return (
    <>
      <Modal {...props}>
        <ModalHeader {...props.modalHeader}>Nova Campanha</ModalHeader>
        <ModalBody {...props.modalBody} className="p-3">
          <Nav tabs>
            {tabs.map(tab => (
              <NavItem key={tab.value}>
                <NavLink className={(activeTab === tab.value) ? 'active': ''} onClick={(e) => { setActiveTab(tab.value) }}>{tab.label}</NavLink>
              </NavItem>
            ))}
          </Nav>
          <div className="p-3">
            {activeTab && <TabContent tab={activeTab} campaign={props.campaign} action={props.action}/>}
          </div>
        </ModalBody>
        <ModalFooter {...props.modalFooter}>
          <Button theme='white' onClick={() => { props.toggle('cancel') }}>
            Cancelar
          </Button>
          <Button onClick={() => { submitForm() }}>
            Salvar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default CampaignModal;
