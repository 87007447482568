import api from '../api';

class Service extends api {

    getApuracaoComissaoRepresentantes = (id_empreendimento, date_period, params) => {
        let req_url = '/relatorio/apuracao/comissao/liquido/vendedores';

        if (!params)
            params = [];

        params.push({
            key: 'id_empreendimento',
            value: id_empreendimento
        });

        params.push({
            key: 'date_period',
            value: date_period
        });

        req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

    saveComissaoRepresentante = (data) => {
        return this.api.post(`/apuracao/comissao/representante`, JSON.stringify(data));
    }

    // calcularComissaoRepresentantes = (id_empreendimento, ano_referencia, mes_referencia, id_vendedor) => {
    //     let params = [{
    //         key: 'id_empreendimento',
    //         value: id_empreendimento
    //     }, {
    //         key: 'ano_referencia',
    //         value: ano_referencia
    //     }, {
    //         key: 'mes_referencia',
    //         value: mes_referencia
    //     }];

    //     if(id_vendedor) {
    //         params.push({
    //             key: 'id_vendedor',
    //             value: id_vendedor
    //         });
    //     }

    //     let url_params = this.generateQueryString(params);
        
    //     return this.api.get(`/vendas/calcular-comissao-represetantes${url_params}`);
    // }
}

export default Service;