import api from '../api';

class CategoriesService extends api {
  getCategories (offset, limit, params) {
    let req_url = `/categorias/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default CategoriesService;
