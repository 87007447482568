import api from '../api';

class Service extends api {

    save = (data) => {
        return this.api.post(`/subempreendimento`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/subempreendimento/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.post(`/subempreendimento/${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/subempreendimento/${id}`);
    }

    getAll = (params, offset, limit) => {
        let req_url = '/subempreendimentos';
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;
        
        if(params)
          req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

}

export default Service;