import React, {useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Button,
    CardFooter,
    ListGroup,
    ListGroupItem,
   
    
} from "shards-react";
import NumberFormat from 'react-number-format';
import { Form } from '@unform/web';
import { useParams, NavLink } from "react-router-dom";
import { 
    getBaseUrlWebOld,
    generateQueryString 
} from '~/utils/functions';
import PageTitle from "../components/common/PageTitle";
import { Input, InputGroup, InputMask, Select } from '../components/unform';
import IndicatorCard from '../components/dashboard/indicator-card';
import IndicatorGraphPie from '../components/dashboard/indicator-graphs-pie';
import IndicatorCarrosel from '../components/dashboard/indicator-carrosel';
import { 
    DashboardService
  } from '~/services/WebliniaERPAPI';
  import { isEmpty, isNull } from 'underscore';
import moment from 'moment';
import { useAuth } from '../hooks/Auth';
import _, { isArray } from 'lodash';
import * as Yup from 'yup';
import getValidationErrors from '../utils/getValidationErrors';
import { numberFormat } from '~/utils/formatValue';
import Chart from "~/utils/chart";
import CustomModal from '../components/modal';
import { useToasts } from 'react-toast-notifications';

import Carousel, { consts } from 'react-elastic-carousel'
import ReactApexCharts from 'react-apexcharts';

function PageView(props){

    const { user, enterprise } = useAuth();
    const { addToast } = useToasts();

    const formRef = useRef(null);
    const canvasRef = useRef();

    const[totalFaturamento, setTotalFaturamento] = useState(null);
    const[totalCPV, setTotalCPV] = useState(null);
    const[totalEstoque, setTotalEstoque] = useState(null);
    const[totalTicketMedio, setTotalTicketMedio] = useState(null);
    const[totalClientes, setTotalClientes] = useState(null);
    const[topFaturamentosEmp, setTopFaturamentosEmp] = useState(null);
    const[totalHistoricosGraficos, setTotalHistoricosGraficos] = useState(null);
    const[top10Produtos, setTop10Produtos] = useState(null);
    const[top10Vendedores, setTop10Vendedores] = useState(null);
    const[top10Fabricantes, setTop10Fabricantes] = useState(null);
    const[totalTaxMaquineta, setTotalTaxaMaquineta] = useState(null);
    const[totalSaldoDevedor, setTotalSaldoDevedor] = useState(null);
    const[totalQtdVenda, setTotalQtdVenda] = useState(null);
    const[totalQtdVendaDesconto, setTotalQtdVendaDesconto] = useState(null);
    const[totalVendaDesconto, setTotalVendaDesconto] = useState(null);
    const[formaPagamento, setFormaPagamento] = useState(null);
    const[vendaDiaDia, setVendaDiaDia] = useState(null);

    const [empreendimentos, setEmpreendimentos ] = useState([]);
    const [isOpenModalEmp, setOpenModalEmp ] = useState(false);

    const [hideFilters, setHideFilters ] = useState(false);
    

    const [isLoadingFaturamento, setIsLoadingFaturamento] = useState(false);
    const [isLoadingCPV, setIsLoadingCPV] = useState(false);
    const [isLoadingCustoEstoque, setIsLoadingCustoEstoque] = useState(false);
    const [isLoadingTicketMedio, setIsLoadingTicketMedio] = useState(false);
    const [isLoadingClientesAtendidos, setIsLoadingClientesAtendidos] = useState(false);
    const [isLoadingTopFaturamentoPorEmpreendimento, setIsLoadingTopFaturamentoPorEmpreendimento] = useState(false);
    const [isLoadingHistoricIndicatorGraphs, setIsLoadingHistoricIndicatorGraphs] = useState(false);
    const [isLoadingTaxMaquineta, setIsLoadingTaxMaquineta] = useState(false);
    const [isLoadingQtdVenda, setIsLoadingQtdVenda] = useState(false);
    const [isLoadingTotalVendaDesconto, setIsLoadingTotalVendaDesconto] = useState(false);
    const [isLoadingVendaHoraHora, setIsLoadingVendaHoraHora] = useState(false);


    const [isLoadingGraphsFaturamento, setIsLoadingGraphsFaturamento] = useState(false);
    const [isLoadingGraphsVendedores, setIsLoadingGraphsVendedores] = useState(false);
    const [isLoadingGraphsFormasPagamento, setIsLoadingGraphsFormasPagamento] = useState(false);


    const [graphFaturamento, setGraphFaturamento] = useState([]);
    const [graphCPV, setGraphCPV] = useState([]);
    const [graphMarkup, setGraphMarkup] = useState([]);
    const [graphQtdVenda, setGraphQtdVenda] = useState([]);
    const [graphTicket, setGraphTicket] = useState([]);
    const [graphQtdCliente, setGraphQtdCliente] = useState([]);
    const [graphTopEmpreendimentos, setGraphTopEmpreendimentos] = useState([]);
    const [graphFormaPagamento, setGraphFormaPagamento] = useState([]);

    const [graphHoraAHora, setGraphHoraAHora] = useState([]);

    const [options, setOption] = useState([
        { id: moment().subtract(7, 'days').format('YYYY-MM-DD'), nome: 'Ult. 7 dias'},
        { id: moment().subtract(15, 'days').format('YYYY-MM-DD'), nome: 'Ult. 15 dias'},
        { id: moment().subtract(30, 'days').format('YYYY-MM-DD'), nome: 'Ult. 30 dias'},
        { id: moment().subtract(60, 'days').format('YYYY-MM-DD'), nome: 'Ult. 60 dias'},
        { id: moment().subtract(90, 'days').format('YYYY-MM-DD'), nome: 'Ult. 90 dias'}
    ]);

    
    useEffect(() => {

        var data_inicial = moment().format('YYYY-MM-01');
        var data_final = moment().endOf('month').format('YYYY-MM-DD');

        if(user.flg_representante === 1){
            getPrepostos(data_inicial,data_final);
        }

        if(user.flg_representante !== 1){

            getHistoricIndicatorGraphs(null,null);
            getFaturamento(data_inicial,data_final);
            getCPV(data_inicial,data_final);
            getClientesAtendidos(data_inicial,data_final);
            getTopFaturamentoPorEmpreendimento(data_inicial,data_final);
            getTicketMedio(data_inicial,data_final);
            top10Products(data_inicial,data_final);
            top10Vendors(data_inicial,data_final);
            getTop10Fabricantes(data_inicial,data_final);
            getQtdVenda(data_inicial, data_final);
            getVendaDesconto(data_inicial, data_final);
            getFormasPagamento(data_inicial, data_final);
            getVendaHoraHora(data_inicial, data_final);
            getDobbyFee(data_inicial, data_final);
            getCustoEstoque();
            getFaturamentoAtualAnterior(data_inicial, data_final);
        }
    }, []);

    const getPrepostos = async(data_inicial,data_final) => {

        const service = new DashboardService();

        let params =  [
            {
                key: 'flg_dashboard_preposto', 
                value: 1
            }
        ];
        
        let {data : listaPrepostos} = await service.getByIdGerente(user.id, params);
        if(listaPrepostos && listaPrepostos.length > 0 && isArray(listaPrepostos)){
            var objPrepostos = 
                {
                    key: 'ven->id_usuario[exp]', 
                    value: ` IN (${listaPrepostos.map(rep => rep.cod_representante)})`
                };
        }
        else{
            var objPrepostos = 
            {
                key: 'ven->id_usuario', 
                value: `${user.id}`
            };
        }

        getHistoricIndicatorGraphs(null,null, objPrepostos);
        getFaturamento(data_inicial,data_final, objPrepostos);
        getCPV(data_inicial,data_final, objPrepostos);
        getClientesAtendidos(data_inicial,data_final, objPrepostos);
        getTopFaturamentoPorEmpreendimento(data_inicial,data_final, objPrepostos);
        getTicketMedio(data_inicial,data_final, objPrepostos);
        top10Products(data_inicial,data_final, objPrepostos);
        top10Vendors(data_inicial,data_final, objPrepostos);
        getTop10Fabricantes(data_inicial,data_final, objPrepostos);
        getQtdVenda(data_inicial, data_final, objPrepostos);
        getVendaDesconto(data_inicial, data_final, objPrepostos);
        getFormasPagamento(data_inicial, data_final, objPrepostos);
        getVendaHoraHora(data_inicial, data_final, objPrepostos);
        getFaturamentoAtualAnterior(data_inicial, data_final, objPrepostos);

    }

    const modalTogglesEmp = useCallback(() => {
        setOpenModalEmp(!isOpenModalEmp);
    }, [isOpenModalEmp]);

    const handleSubmitFilter = async(data) => {
        try {
            formRef.current.setErrors({});

            await getDataFilters(data);

        } catch (error) {
            if(error instanceof Yup.ValidationError) {
                formRef.current.setErrors(getValidationErrors(error));
                return;
            }
        }
    }

    const getDataFilters = async (data) => {

        if(!isNull(data)){
            if((data.startDate && !isNull(data.startDate))){
                var data_inicial = data.startDate;
            }
            else{
                var data_inicial = moment().format('YYYY-MM-01');
            }
            if((data.endDate && !isNull(data.endDate))){
                var data_final = data.endDate;
            }
            else{
                var data_final = moment().endOf('month').format('YYYY-MM-DD');
            }
        }
        else{
            var data_inicial = moment().format('YYYY-MM-01');
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        if(user.flg_representante === 1){
            getPrepostos(data_inicial,data_final);
        }

        if(user.flg_representante !== 1){

            getHistoricIndicatorGraphs(null,null);
            getFaturamento(data_inicial,data_final);
            getCPV(data_inicial,data_final);
            getClientesAtendidos(data_inicial,data_final);
            getTopFaturamentoPorEmpreendimento(data_inicial,data_final);
            getTicketMedio(data_inicial,data_final);
            top10Products(data_inicial,data_final);
            top10Vendors(data_inicial,data_final);
            getTop10Fabricantes(data_inicial,data_final);
            getQtdVenda(data_inicial, data_final);
            getVendaDesconto(data_inicial, data_final);
            getFormasPagamento(data_inicial, data_final);
            getVendaHoraHora(data_inicial, data_final);
            getDobbyFee(data_inicial, data_final);
            getCustoEstoque();
            getFaturamentoAtualAnterior(data_inicial, data_final);
        }

        setHideFilters(false);
    }

    const getFaturamento = async(data_inicial,data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingFaturamento(true);

        let params = [
            {
                key: 'data[exp]', 
                value: decodeURI(`BETWEEN '${data_inicial} 00:00:00' AND '${data_final} 23:59:59'`)
            }
        ];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let { data: total_Fat } = await service.getTotalFaturamento(params);
    
            
            setTotalFaturamento(total_Fat.total_faturamento);
            setIsLoadingFaturamento(false);
            
        }
        catch(error){
            console.log(error);
        }
    }

    const getCPV = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingCPV(true);
        
        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try{
            let {data : totalCPV} = await service.getCustoProdutoVendido(params, data_inicial, data_final);
            setTotalCPV(totalCPV.totalCustoProdutosVendidos);
            setIsLoadingCPV(false);
        }
        catch(error){
            console.log(error);
        }
    }

    const getCustoEstoque = async() => {
        const service = new DashboardService();
        setIsLoadingCustoEstoque(true);

        var params = [];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'emp->id[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'emp->id[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        try{
            let {data : totalCustoEStoque} = await service.getCustoTotalEstoque(params);
            setTotalEstoque(totalCustoEStoque.vlrCustoTotalEstoque);
            setIsLoadingCustoEstoque(false);
        }
        catch(error){
            console.log(error);
        }
        
    }

    const getTicketMedio = async(data_inicial,data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingTicketMedio(true);

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : custoVenda} = await service.getCountVenda(params, data_inicial,data_final);
            
            setTotalTicketMedio(custoVenda.total_vendas);
            setIsLoadingTicketMedio(false);
            
        } catch (error) {
            console.log(error);
        }
    }

    const getClientesAtendidos = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingClientesAtendidos(true);

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : clientesAtendidos} = await service.getClientesAtendidos(params,data_inicial, data_final);
            clientesAtendidos = clientesAtendidos.map(_clientes => {
                setTotalClientes(_clientes.qtd_clientes);
                return _clientes;
            });
            setIsLoadingClientesAtendidos(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getTopFaturamentoPorEmpreendimento = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingTopFaturamentoPorEmpreendimento(true);

        if((!data_final) || isEmpty(data_final)){
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            
            let {data : topFaturamento} = await service.getTopFaturamentoPorEmpreendimento(params, data_inicial, data_final);
    
            var dsTopEmp = [];
            var dsTopEmpLabel = [];
    
            topFaturamento = topFaturamento.map(fat => {
                dsTopEmp.push(parseFloat(fat.vlr_faturamento));
                dsTopEmpLabel.push(fat.nome_empreendimento);
                setTopFaturamentosEmp(topFaturamento);
                return topFaturamento;
            });
    
            setIsLoadingTopFaturamentoPorEmpreendimento(false);
    
            if(dsTopEmp && dsTopEmp.length > 0){
                var bgTopEmp = [
                    'rgb(253,202,162)',
                    'rgb(191,228,118)',
                    'rgb(134,207,190)',
                    'rgb(148,168,208)',
                    'rgb(251,182,209)',
                    'rgb(192,179,215)',
                    'rgb(255,250,129)',
                    'rgb(200,112,126)',
                    'rgb(255,105,97)',
                    'rgb(200,194,189)'
                ];
                if(empreendimentos && empreendimentos.length > 10){
                    dsTopEmp = dsTopEmp.map(obj =>{
                        let str = '#';
                        while (str.length < 7) {
                            str += Math.floor(Math.random() * 0x10).toString(16);
                        }
            
                        if(str === str) {
                            let str = '#';
                            while (str.length < 7) {
                                str += Math.floor(Math.random() * 0x10).toString(16);
                            }
                        }
                        bgTopEmp.push(str);
        
                        return obj;
                    })
                }
            }
    
            setGraphTopEmpreendimentos({
                labels: dsTopEmpLabel,
                datasets: [
                    {
                        // borderColor: '#fff',
                        backgroundColor: bgTopEmp,
                        data: dsTopEmp
                    },
                ]
            });

        } catch (error) {
            console.log(error);
        }

    }

    const getFormasPagamento = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingGraphsFormasPagamento(true);

        if((!data_final) || isEmpty(data_final)){
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        let params =[{
                key:'data_inicial',
                value: data_inicial
            },{
                key:'data_final',
                value: data_final
            }];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : formaPagamento} = await service.getFormasPagamento(params, data_inicial, data_final);
            
    
            var dsFormaPag = [];
            var dsFormaPagLabel = [];

            formaPagamento = formaPagamento.map(pag => {
                dsFormaPag.push(parseFloat(pag.vlr_pagamentos));
                dsFormaPagLabel.push(pag.descricao_forma_pagamento);
                return formaPagamento;
            });
            setFormaPagamento(formaPagamento);
            
    
            setIsLoadingGraphsFormasPagamento(false);
    
            if(dsFormaPag && dsFormaPag.length > 0){
                var bgFormaPag = [
                    'rgb(134,207,190)',
                    'rgb(148,168,208)',
                    'rgb(191,228,118)',
                    'rgb(192,179,215)',
                    'rgb(200,112,126)',
                    'rgb(200,194,189)',
                    'rgb(237,170,125)',
                    'rgb(251,182,209)',
                    'rgb(253,202,162)',
                    'rgb(255,250,129)'
                ];
                
                dsFormaPag = dsFormaPag.map(obj =>{
                    let str = '#';
                    while (str.length < 7) {
                        str += Math.floor(Math.random() * 0x10).toString(16);
                    }
        
                    if(str === str) {
                        let str = '#';
                        while (str.length < 7) {
                            str += Math.floor(Math.random() * 0x10).toString(16);
                        }
                    }
                    bgFormaPag.push(str);
    
                    return obj;
                })
                
    
                // console.log(bgFormaPag);
            }
    
            setGraphFormaPagamento({
                labels: dsFormaPagLabel,
                datasets: [
                    {
                        // borderColor: '#fff',
                        backgroundColor: bgFormaPag,
                        data: dsFormaPag
                    },
                ]
            });
        } catch (error) {
            console.log(error);
        }

    }

    const top10Products =  async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();

        let params =[{
            key: 'fd',
            value: data_inicial
        },{
            key: 'ld',
            value: data_final
        }]

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : topProducts} = await service.getTop10Products(params, data_inicial, data_final);
            topProducts = topProducts.map(prod => {
                return prod;
            });
            setTop10Produtos(topProducts);
        } catch (error) {
            console.log(error);
        }

    }

    const top10Vendors =  async(data_inicial, data_final, objPrepostos = null) => {
        setIsLoadingGraphsVendedores(true);
        const service = new DashboardService();

        if((!data_inicial) || isEmpty(data_inicial)){
            var data_inicial = moment().format('YYYY-MM-01');
        }

        if((!data_final) || isEmpty(data_final)){
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        let params =[{
            key: 'data_inicial',
            value: data_inicial
        },{
            key: 'data_final',
            value: data_final
        }];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : topVendedores} = await service.getTop10Vendedores(params);

            setTop10Vendedores(topVendedores);
            setIsLoadingGraphsVendedores(false);
        } catch (error) {
            console.log(error);
        }

    }

    const getTop10Fabricantes =  async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();


        let params =[{
            key: 'fd',
            value: data_inicial
        },{
            key: 'ld',
            value: data_final
        }];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let { data } = await service.getTop10Fabricantes(params, data_inicial, data_final);
            setTop10Fabricantes(data);
        } catch (error) {
            console.log(error);
        }

    }

    const getDebitBalance = async(data_inicial, data_final) => {
        const service = new DashboardService();

        let params =[{
            key: '',
            value: data_inicial
        },{
            key: '',
            value: data_final
        }];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        try {
            let { data } = await service.getSaldoDevedor(params, data_inicial, data_final);
            setTotalSaldoDevedor(data);
        } catch (error) {
            console.log(error);
        }

    }

    const getDobbyFee = async(data_inicial, data_final) => {
        const service = new DashboardService();
        setIsLoadingTaxMaquineta(true);

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        let { data } = await service.getDobbyFee(params, data_inicial, data_final);
        if(data && !isEmpty(data)){
            setTotalTaxaMaquineta(data.totalTaxaMaquinetas);
            setIsLoadingTaxMaquineta(false);
        }
    }

    const getQtdVenda = async(data_inicial,data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingQtdVenda(true);

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : custoVenda} = await service.getCountVenda(params, data_inicial,data_final);
            
            setTotalQtdVenda(custoVenda.total_vendas);
            setIsLoadingQtdVenda(false);
        } catch (error) {
            console.log(error);
        }
    }

    const getVendaDesconto = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingTotalVendaDesconto(true);

        let params =[];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }

        try {
            let {data : desconto} = await service.getVendaDesconto(params, data_inicial, data_final);
            desconto = desconto.map(desc => {
                setTotalVendaDesconto(desc.vlr_desconto);
                setTotalQtdVendaDesconto(desc.qtd_vendas);
                return desc;
            });
            setIsLoadingTotalVendaDesconto(false);
        } catch (error) {
            console.log(error);
        }

    }

    const getVendaHoraHora = async(data_inicial, data_final, objPrepostos = null) => {
        const service = new DashboardService();
        setIsLoadingVendaHoraHora(true);

        if((!data_final) || isEmpty(data_final)){
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        let params = [{
                        key: 'data_inicial',
                        value: data_inicial
                    },{
                        key: 'data_final',
                        value: data_final
                    }];

        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'tv->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'tv->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante === 1){
            params.push({
                key: 'tv->id_usuario', 
                value: `${user.id}`
            });
        }

        try {
            let { data : { horas } } = await service.getVendaHoraHora(params);
            let series = [];
            Object.keys(horas).forEach((key) => {
                let _data = horas[key];
                series.push({
                    name: key,
                    data: _data.map(d => {
                        return {
                            x: d.dia,
                            y: d.qtd
                        }
                    })
                })
            });
            setGraphHoraAHora(series);
            setIsLoadingVendaHoraHora(false);
        }
        catch(error){
            console.log(error);
        }
    }

    const getHistoricIndicatorGraphs = async(data_inicial,data_final, objPrepostos = null) => {

        const service = new DashboardService();
    
        let params =[];
    
        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }
    
        if((data_inicial && !isEmpty(data_inicial))){
            var data_inicial = moment(data_inicial).subtract(1, 'year').format('YYYY-MM-01');
        }else{
            var data_inicial = moment().subtract(1, 'year').format('YYYY-MM-01');
        }
    
        if((data_final && !isEmpty(data_final))){
            var data_final = moment(data_final).endOf('month').format('YYYY-MM-DD');
        }else{
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        try {
            let {data : historico} = await service.getHistoricoIndicadoresGrafico(params, data_inicial, data_final);   
        
            let dsFaturamento = [];
            let dsCPV = [];
            let dsMarkup = [];
            let dsQtdVenda = [];
            let dsTicket = [];
            let dsQtdCliente = [];
    
            historico = historico.forEach(hist => {
                
                dsFaturamento.push(parseFloat(hist.vlr_faturamento));
                dsCPV.push(parseFloat(hist.vlr_custo));
                dsMarkup.push(parseFloat(hist.vlr_markup));
                dsQtdVenda.push(parseFloat(hist.qtd_vendas));
                dsTicket.push(parseFloat(hist.vlr_ticket_medio));
                dsQtdCliente.push(parseFloat(hist.qtd_clientes));
            });
    
            setTotalHistoricosGraficos(historico);
    
            setGraphFaturamento({
                labels: Array.from(new Array(dsFaturamento.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#f3f6fc",
                        borderColor: "#d3def3",
                        data: dsFaturamento
                    },
                ]
            });
    
            setGraphCPV({
                labels: Array.from(new Array(dsCPV.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#ffe5e5",
                        borderColor: "#ffb8b8",
                        data: dsCPV
                    },
                ]
            });
    
            setGraphMarkup({
                labels: Array.from(new Array(dsMarkup.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#effbef",
                        borderColor: "#cbf1cb",
                        data: dsMarkup
                    },
                ]
            });
    
            setGraphQtdVenda({
                labels: Array.from(new Array(dsQtdVenda.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#fff9eb",
                        borderColor: "#ffedc2",
                        data: dsQtdVenda
                    },
                ]
            });
    
            setGraphTicket({
                labels: Array.from(new Array(dsTicket.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#f5f5f5",
                        borderColor: "#dbdbdb",
                        data: dsTicket
                    },
                ]
            });
    
            setGraphQtdCliente({
                labels: Array.from(new Array(dsQtdCliente.length), (_, i) => (i === 0 ? 1 : i)),
                datasets: [
                    {
                        label: "Today",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#effbef",
                        borderColor: "#cbf1cb",
                        data: dsQtdCliente
                    },
                ]
            });
            
            setIsLoadingHistoricIndicatorGraphs(false);

            console.log(dsFaturamento);
            console.log(dsCPV);
            console.log(dsMarkup);
            console.log(dsQtdVenda);
            console.log(dsTicket);
            console.log(dsQtdCliente);
            
        } catch (error) {
            console.log(error);
        }
    }
    
    const getFaturamentoAtualAnterior = useCallback(async(data_inicial,data_final, objPrepostos = null) => {

        setIsLoadingGraphsFaturamento(true);
    
        const service = new DashboardService();
    
        let params =[];
    
        if (empreendimentos && empreendimentos.length > 0) {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: `IN (${empreendimentos.map(emp => emp.id)})`
            });
        }
        else {
            params.push({
                key: 'ven->id_empreendimento[exp]', 
                value: ` IN (${enterprise.id_empreendimento})`
            });
        }

        if(user.flg_representante == 1){
            params.push(objPrepostos);
        }
    
        if((data_inicial && !isEmpty(data_inicial))){
            var data_inicial = moment(data_inicial).subtract(1, 'year').format('YYYY-MM-01');
        }else{
            var data_inicial = moment().subtract(1, 'year').format('YYYY-MM-01');
        }
    
        if(data_final && !isEmpty(data_final)){
            var data_final = moment(data_final).endOf('month').format('YYYY-MM-DD');
        }else{
            var data_final = moment().endOf('month').format('YYYY-MM-DD');
        }

        try {
            
            let dsFaturamento = [];
            let dsFaturamentoAnterior = [];
            let dsFaturamentoDatas = [];
    
            let {data : historico} = await service.getHistoricoIndicadoresGrafico(params, data_inicial, data_final);
    
            var data_inicial_anterior = moment(data_inicial).subtract(1, 'year').format('YYYY-MM-01');
            var data_final_anterior = moment(data_final).subtract(1, 'year').endOf('month').format('YYYY-MM-DD');
        
            let {data : historicoAnterior} = await service.getHistoricoIndicadoresGrafico(params, data_inicial_anterior, data_final_anterior);   
    
            setIsLoadingGraphsFaturamento(false);
    
            historico = historico.forEach(fat => {
                //grafico de faturamento labels e valores
                if(!isNull(fat.mes_competencia)){
                    dsFaturamentoDatas.push(moment(fat.mes_competencia).format('MMM-Y'));
                }
                else{
                    dsFaturamentoDatas.push('0');
                }
        
                dsFaturamento.push(parseFloat(fat.vlr_faturamento));
            })
        
            historicoAnterior = historicoAnterior.forEach(histA => {
                dsFaturamentoAnterior.push(parseFloat(histA.vlr_faturamento));
            });
            
        
            var graphFat = {
                labels: dsFaturamentoDatas,
                datasets: [
                    {
                        label: "Periodo Atual",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "rgba(0,123,255,0.1)",
                        borderColor: "#83a2dd",
                        data: dsFaturamento
                    },
                    {
                        label: "Periodo Anterior",
                        fill: "start",
                        borderWidth: 1.5,
                        backgroundColor: "#f5f5f5",
                        borderColor: "#b3b3b3",
                        data: dsFaturamentoAnterior
                    }
                    
                ],
            };
            buildGraph(graphFat);
        } catch (error) {
            console.log(error);
        }
    
    },[totalHistoricosGraficos])

    const buildGraph = useCallback(async(graphFaturamento) => {
        const chartOptions = {
          ...{
            maintainAspectRatio: true,
            responsive: true,
            legend: {
              display: "top"
            },
            tooltips: {
              enabled: false,
              custom: false
            },
            elements: {
              point: {
                radius: 0
              },
              line: {
                tension: 0.33
              }
            },
            scales: {
              xAxes: [
                {
                  gridLines: false,
                  ticks: {
                    callback(tick, index) {
                        // Jump every 7 values on the X axis labels to avoid clutter.
                        return index % 1 !== 0 ? "" : tick;
                    }
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: false,
                  scaleLabel: false,
                  ticks: {
                    display: false
                  }
                }
              ]
            },
            hover: {
                mode: "nearest",
                intersect: false
            },
            tooltips: {
                custom: false,
                mode: "nearest",
                intersect: false
            },
            color: (context) => {
                let index = context.dataIndex;
                let value = context.dataset.data[index];
                return value;
            }
          },
          ...chartOptions
        };
    
        const chartConfig = {
          ...{
            type: "line",
            data: {
              ...{ labels: graphFaturamento.labels },
              ...{ datasets: graphFaturamento.datasets }
            },
            options: chartOptions
          },
          ...chartConfig
        };
    
        if (canvasRef && canvasRef.current) {
            let chart = new Chart(canvasRef.current, chartConfig);
            // console.log(chart);
        }

    }, [graphFaturamento]);

    const getEmpreendimento = useCallback(async (name, item) => {
        if (item) {
          try{
            // clonando p/ poder manipular
            let _empreendimentos = _.clone(empreendimentos);
            
            // inicializando o array, caso esteja null ou undefined
            if (!_empreendimentos)
              _empreendimentos = [];
    
            // garantindo que não vou add o mesmo objeto na lista
            let can_add = true;
            if (_empreendimentos.length > 0) {
              _empreendimentos.forEach(emp => {
                if (emp.id === item.id) {
                  can_add = false;
                  addToast(`Esse item já contem na listagem!`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
                }
              })
            }
    
            // add apenas se não estiver no array
            if (can_add)
              _empreendimentos.push(item);
    
            // atualiza o state
            setEmpreendimentos(_empreendimentos);
          }catch(err){
              console.log(err);
          }
        }else{
          setOpenModalEmp(false);
        }
      },[empreendimentos])
    
    const delEmpreendimento = async (index) => {
        let _empreendimentos = _.clone(empreendimentos);
            
            if (_empreendimentos) {
                _empreendimentos.splice(index, 1);
            }
    
            setEmpreendimentos(_empreendimentos);
            
    }

    const urlTopVendedores = async (dtaInicial,dtaFinal) => {

        if((!dtaInicial) || isEmpty(dtaInicial)){
            var dtaInicial = moment().format('01/MM/YYYY');
        }
        if((!dtaFinal) || isEmpty(dtaFinal)){
            var dtaFinal = moment().endOf('month').format('DD/MM/YYYY');
        }


        let params =[{
            key:'dtaInicial',
            value: dtaInicial
        },{
            key:'dtaFinal',
            value: dtaFinal
        }];

        let qs = generateQueryString(params);

		window.open(`${getBaseUrlWebOld()}/relatorio-total-vendas-vendedor.php${qs}`, '_blank');
    }
    const urlTopEmpreendimento  = async () => {
        
        window.open(`${getBaseUrlWebOld()}/relatorio-faturamento-por-empreendimento.php`, '_blank');
    }
    
    const urlFormasPagamento = async () => {
        
        window.open(`${getBaseUrlWebOld()}/relatorio-vendas-forma-pagamento.php`, '_blank');
    }

    const urlRelatorioHoraHora = async () => {

        if((!dtaInicial) || isEmpty(dtaInicial)){
            var dtaInicial = moment().format('01/MM/YYYY');
        }
        if((!dtaFinal) || isEmpty(dtaFinal)){
            var dtaFinal = moment().endOf('month').format('DD/MM/YYYY');
        }

        let params =[{
            key:'dtaInicial',
            value: dtaInicial
        },{
            key:'dtaFinal',
            value: dtaFinal
        }];

        let qs = generateQueryString(params);

        window.open(`${getBaseUrlWebOld()}/relatorio-vendas-hora-a-hora.php${qs}`, '_blank');
    }
    

    return(
        <>            
            
            <Container fluid className="main-content-container px-4 pb-4" >
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Dashboard Integrado  de Vendas'
                        subtitle='RELATÓRIOS'
                        className='text-md-left'
                    />


                    <Col xs="12" sm="4" className="col d-flex align-items-center">
                        &nbsp;
                    </Col>

                    <Col sm="4" className="d-flex justify-content-end">
						<Button className="pb-0 pt-0" outline theme="primary" style={{ lineHeight: '2.4em' }} onClick={() => setHideFilters(!hideFilters)}>
                            <i class="fa fa-search" aria-hidden="true"></i> Filtros
						</Button>
                        &nbsp;
                        <Button className="pb-0 pt-0" theme="success" style={{ lineHeight: '2.4em' }} onClick={() => getDataFilters(null)}>
                            <i class="fas fa-sync-alt"></i> Atualizar
						</Button>
					</Col>
                    

                </Row>
                
                <Row>
                    <Col md='2'>
                        <IndicatorCard
                            title="FATURAMENTO" 
                            value={totalFaturamento} 
                            // prc_data={'3,4'} 
                            hasDecimal={true} 
                            isLoading={isLoadingFaturamento} 
                            chart_config={graphFaturamento && graphFaturamento}
                            // prcIcon={"fas fa-caret-up"}
                        />
                    </Col>
                    <Col md='2'>
                        <IndicatorCard
                            title="MARKUP" 
                            value={(totalFaturamento && totalCPV) && (totalFaturamento / totalCPV)} 
                            // prc_data={'3,4'} 
                            hasDecimal={true} 
                            isLoading = {isLoadingFaturamento } 
                            chart_config={graphMarkup && graphMarkup}
                        />
                    </Col>
                    <Col md='2'>
                        <IndicatorCard
                            title="CPV/CMV" 
                            value={totalCPV} 
                            // prc_data={'3,4'} 
                            hasDecimal={true} 
                            isLoading = {isLoadingCPV} 
                            chart_config={graphCPV && graphCPV}
                        />
                    </Col>
                    <Col md='2'>
                        <IndicatorCard
                            title="QUANTIDADE DE VENDA" 
                            value={totalQtdVenda}
                            // prc_data={'3,4'} 
                            isLoading = {isLoadingQtdVenda} 
                            chart_config={graphQtdVenda && graphQtdVenda}
                            hasDecimal={false}
                        />
                    </Col>
                    <Col lg='2'>
                        <IndicatorCard
                            title="TICKET MÉDIO" 
                            value={(totalFaturamento && totalTicketMedio) && (totalFaturamento / totalTicketMedio)} 
                            // prc_data={'3,4'} 
                            hasDecimal={true} 
                            isLoading={isLoadingFaturamento} 
                            chart_config={graphTicket && graphTicket}
                        />
                    </Col>
                    <Col md='2'>
                        <IndicatorCard
                            title="CLIENTES ATENDIDOS" 
                            value={totalClientes} 
                            // prc_data={'3,4'} 
                            hasDecimal={false} 
                            isLoading = {isLoadingClientesAtendidos} 
                            chart_config={graphQtdCliente && graphQtdCliente}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col md='6'>
                        <Card className='mb-3'>
                            <Form>
                                <CardHeader className='border-bottom pl-3 pr-3 pb-0'>
                                    <Row>
                                        <Col>
                                            <h6><b>Histórico de Faturamento</b></h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="3">
                                            <Select
                                                data={options}
                                                name='view'
                                                onChange={(e) => getFaturamentoAtualAnterior()}
                                            />
                                        </Col>
                                    </Row>
                                </CardHeader>
                                {isLoadingGraphsFaturamento && (
                                    <Row>
                                        <Col sm="12" className='text-center'>
                                            <h4 className="font-weight-bold" style={{fontSize : '20px', position:'relative',zIndex:'10'}}>
                                                <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            </h4>
                                        </Col>
                                    </Row>
                                )}

                                {!isLoadingGraphsFaturamento && (    
                                    <CardBody style={{ verticalAlign: 'middle' ,minHeight:' 400px'  ,maxHeight: '489px' }} className='pl-3 pr-3'>
                                        <canvas
                                            height="120"
                                            ref={canvasRef}
                                            style={{ maxWidth: "100 !important" }}
                                        />
                                    </CardBody>
                                )}
                            </Form>
                        </Card>
                    </Col>
                    <Col md='3'>
                        <IndicatorGraphPie 
                            title='Top Empreendimentos'
                            itens={topFaturamentosEmp && topFaturamentosEmp}
                            chart_config={graphTopEmpreendimentos && graphTopEmpreendimentos}
                            options={options}
                            isLoading={isLoadingTopFaturamentoPorEmpreendimento}
                            onOptionSelected={(e) => getTopFaturamentoPorEmpreendimento(e.target.value)}
                            onButtonClick={() => urlTopEmpreendimento()}
                            buttonText={'Relatorio Completo'}
                        />
                    </Col>
                    <Col md='3'>
                        <Card className='mb-3'>
                            <Form>
                                <CardHeader className='border-bottom pl-3 pr-3 ' style={{height: '73px'}}>
                                    <h6>Vendas Dia a Dia</h6>
                                </CardHeader>
                                <CardBody style={{ verticalAlign: 'middle' ,minHeight:' 356px'  ,maxHeight: '356px' }} className='pl-3 pr-3 pt-0 pb-0'>
                                    {isLoadingVendaHoraHora && (
                                        <Row>
                                            <Col sm="12" className='text-center'>
                                                <h4 className="font-weight-bold" style={{fontSize : '20px', position:'relative',zIndex:'10'}}>
                                                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                                </h4>
                                            </Col>
                                        </Row>
                                    )}
                                    {!isLoadingVendaHoraHora && (

                                        
                                            <ReactApexCharts 
                                                series={graphHoraAHora}
                                                options={{
                                                    chart:{
                                                        height:300,
                                                        type:'heatmap'
                                                    },
                                                    dataLabels:{
                                                        enabled:false
                                                    },
                                                    colors:[
                                                        '#006bff'
                                                    ]
                                                }}
                                                type="heatmap"
                                                height={350}
                                            />
                                       
                                    )}

                                </CardBody>
                                <CardFooter className='pt-0'>
                                    <Row className={'pt-2'}>
                                        <Col lg='6'>
                                            <Select
                                                data={options}
                                                onChange={(e) => getVendaHoraHora(e.target.value)}
                                                name='status'
                                            />
                                        </Col>
                                        <Col lg='6'>
                                            <Button 
                                                type='button'
                                                theme="outline-secondary"  
                                                className="pvtDropdownIcon"
                                                onClick={(e) => urlRelatorioHoraHora(e.target.data_inicial,e.target.data_final)}
                                            >
                                                Relatório Completo
                                                <i className="material-icons"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Form>
                        </Card>                       
                    </Col>
                </Row>

                <Row>
                    <Col sm='12'>
                        <h5>TOP PRODUTOS + VENDIDOS</h5>
                        <Row>
                            <Col sm='12'>
                                <Carousel 
                                    itemsToShow={5} 
                                    itemsToScroll={1} 
                                    itemPadding={[0, 10]}
                                    renderPagination={(e) => <></>}
                                >
                                    {top10Produtos && top10Produtos.map(item => {
                                        let imagePath = null;

                                        if (item.img && item.img.length > 0) {
                                            let tmp = item.img.substring(item.img.indexOf('assets'), item.img.length);
                                            imagePath = `https://crn.ngweb.net.br/${tmp}`;
                                        }
                                        else{
                                            imagePath= 'https://via.placeholder.com/600/d3d3d3/00000?text=PRODUTO';
                                        }

                                        return (
                                            <IndicatorCarrosel 
                                                imagePath={imagePath}
                                                itemTitle={item.nome} 
                                                itemSubtitle={item.nome_fabricante}
                                                totalAmount={item.vlr_total_vendas}
                                                unitSold={item.qtd_total_vendas}
                                                markup={(item.vlr_total_vendas / item.vlr_total_custo).toFixed(2)}
                                                averagePrice={item.vlr_medio}
                                            />
                                        )
                                    })}
                                </Carousel>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col sm='4'>
                        <Card className='mb-3'>
                            <Form>
                                <CardHeader className='border-bottom '>
                                    <h6>Top 10 vendedores</h6>
                                </CardHeader>
                                <CardBody style={{ verticalAlign: 'middle'}} className='pt-1 pb-0'>
                                    {isLoadingGraphsVendedores && (
                                        <Row>
                                            <Col sm="12" className='text-center'>
                                                <h4 className="font-weight-bold" style={{fontSize : '20px', position:'relative',zIndex:'10'}}>
                                                    <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                                </h4>
                                            </Col>
                                        </Row>
                                    )} 
                                    {!isLoadingGraphsVendedores && (
                                        <Row>
                                            <Col sm="12">
                                                <table class="table">
                                                    <tbody>
                                                        {top10Vendedores && top10Vendedores.map(ven => (
                                                            <tr>
                                                                <td width="400" className='font-weight-bold'>
                                                                    {ven.nome.toUpperCase()}
                                                                    <br/>
                                                                    <small className='text-muted'>
                                                                        {ven.nome_empreendimento}
                                                                    </small>
                                                                </td>
                                                                <td width="100" className='text-right'>
                                                                    {numberFormat(ven.vlr_total_vendas, 2, ',', '.')}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>   
                                    )}
                                </CardBody>
                                <CardFooter className='pt-0'>
                                    <Row className={'pt-2'}>
                                        <Col lg='6'>
                                            <Select
                                                data={options}
                                                name='status'
                                                onChange={(e) => top10Vendors(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg='6'>
                                            <Button 
                                                type='button'
                                                theme="outline-secondary"  
                                                className="pvtDropdownIcon"
                                                onClick={() => urlTopVendedores()}
                                            >
                                                Relatório Completo
                                                <i className="material-icons"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                
                    <Col sm='4'>
                        <IndicatorGraphPie 
                            title='Formas de Pagamento'
                            itens={formaPagamento && formaPagamento}
                            chart_config={graphFormaPagamento && graphFormaPagamento}
                            options={options}
                            isLoading={isLoadingGraphsFormasPagamento}
                            onOptionSelected={(e) => getFormasPagamento(e.target.value)}
                            onButtonClick={() => urlFormasPagamento()}
                            buttonText={'Relatorio Completo'}
                        />
                    </Col>
                   <Col sm='4'>
                       <Row>
                            {(user.flg_representante !== 1)  && (
                                    <Col md='6'>
                                        <IndicatorCard
                                            title="CUSTO DE ESTOQUE" 
                                            value={totalEstoque} 
                                            // prc_data={'3,4'} 
                                            hasDecimal={true} 
                                            isLoading={isLoadingCustoEstoque} 
                                        />
                                    </Col>
                            )}                   
                            <Col md='6'>
                                <IndicatorCard
                                    title="CUSTOS C/ DESCONTOS" 
                                    value={totalVendaDesconto} 
                                    prc_data={(totalQtdVenda && totalQtdVenda) && (totalQtdVenda / totalQtdVendaDesconto)}
                                    hasDecimal={true} 
                                    isLoading={isLoadingTotalVendaDesconto}
                                />
                            </Col>
                        </Row>
                        <Row>
                            {(user.flg_representante !== 1)  && (

                                <Col md='6'>
                                    <IndicatorCard
                                        title="TAXA DE MAQUINETA" 
                                        value={totalTaxMaquineta} 
                                        isLoading = {isLoadingTaxMaquineta} 
                                        hasDecimal={true} 
                                        // prc_data={'3,4'}
                                    />
                                </Col>
                            )}
                            {/* <Col md='6'>
                                <IndicatorCard
                                    title="SALDO DEVEDOR" 
                                    value={232.232} 
                                    // prc_data={'3,4'} 
                                    hasDecimal={true} 
                                    quebra={'sim'}
                                />
                            </Col> */}
                       </Row>
                       {/* <Row>
                            <Col md='6'>
                                <IndicatorCard
                                    title="ITENS POR VENDA" 
                                    value={232.232}
                                    // prc_data={'3,4'}
                                    hasDecimal={true}
                                />
                            </Col>
                            <Col md='6'>
                                <IndicatorCard
                                    title="TAXA DE RECOMPRA" 
                                    value={3756.36} 
                                    // prc_data={'3,4'}
                                    hasDecimal={true} 
                                />
                            </Col>
                       </Row> */}
                   </Col>
                </Row>

                <Row>
                    <Col sm='12'>
                        <h5>TOP MARCAS + VENDIDAS</h5>
                        <Row>
                            <Carousel 
                                itemsToShow={5} 
                                itemsToScroll={1} 
                                itemPadding={[0, 10]}
                                renderPagination={(e) => <></>}
                            >
                                {top10Fabricantes && top10Fabricantes.map(item => {
                                    let imagePath = null;

                                    if (item.img && item.img.length > 0) {
                                        let tmp = item.img.substring(item.img.indexOf('assets'), item.img.length);
                                        imagePath = `https://crn.ngweb.net.br/${tmp}`;
                                    }
                                    else{
                                        imagePath= 'https://via.placeholder.com/1200x300/d3d3d3/00000?text=MARCA';
                                    }

                                    return (
                                        <IndicatorCarrosel 
                                            imagePath={imagePath}
                                            itemTitle={item.nome_fabricante} 
                                            totalAmount={item.vlr_total_vendas}
                                            unitSold={item.qtd_total_vendas}
                                            markup={(item.vlr_total_vendas / item.vlr_total_custo).toFixed(2)}
                                            averagePrice={item.vlr_medio}
                                        />
                                    )
                                })}
                            </Carousel>
                        </Row>
                    </Col>
                </Row>
                {/* <Row>
                    <Col md='4'>
                        <IndicatorGraphProducts title='Produtos que ganharam visibilidade'/>
                    </Col>
                    <Col md='4'>
                        <IndicatorGraphProducts title='Produtos que perderam visibilidade'/>
                    </Col>
                    <Col md='4'>
                        <IndicatorGraphProducts title='Produtos C/ potencial competitivo'/>
                    </Col>
                    
                </Row> */}

                <Modal open={hideFilters} size="md">
                    <ModalHeader className="p-3">
                        <Row>
                            <Col sm='12'>
                                <i className='fa fa-filter'></i> Filtros
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody className="p-3" style={{ maxHeight: '65vh' }}>
                        <Col lg='12'>
                            <Form ref={formRef} onSubmit={handleSubmitFilter}>
                                <Row>
                                    <Col lg="6">
                                        <Input
                                            type='date'
                                            name='startDate'
                                            label='Data Inicial'
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <Input
                                            type='date'
                                            name='endDate'
                                            label='Data Final'
                                        />
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <ListGroup flush>
                                            <ListGroupItem className="pl-0 pt-3 pr-0 pb-0">
                                                <span className="d-flex mb-2">
                                                <span className="mr-1">Empreendimentos</span>
                                                <span className="ml-auto">
                                                    <small>
                                                        <a href="#" className="text-success" onClick={() => modalTogglesEmp('enterprises')}>
                                                        <i class="fa fa-plus-circle"></i> Incluir
                                                        </a>
                                                    </small>
                                                </span>
                                                </span>
                                            </ListGroupItem>
                                        </ListGroup>
                                        <table className='table table-condensed table-hover'>
                                            <tbody>
                                                {empreendimentos && (empreendimentos.length > 6 ) && (
                                                    <>
                                                        <div  
                                                            style={{ minHeight: '183px' ,maxHeight: '183px', overflowY: 'scroll' }}
                                                        >
                                                            {empreendimentos && empreendimentos.map((item, index) =>(
                                                                <tr>
                                                                    <td width='400'>
                                                                        <small>{item.nome_empreendimento}</small>
                                                                    </td>
                                                                    <td className='text-right'>
                                                                        <small>
                                                                            <a href="#" className="text-danger" onClick={() => delEmpreendimento(index)}>
                                                                                <i className="material-icons">delete</i> Remover
                                                                            </a>
                                                                        </small>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </div>
                                                    </>
                                                )}
                                                {empreendimentos && (empreendimentos.length < 6 ) && (
                                                    <>
                                                        {empreendimentos && empreendimentos.map((item, index) =>(
                                                            <tr>
                                                                <td width='165'>
                                                                <small>{item.nome_empreendimento}</small>
                                                                </td>
                                                                <td className='text-right'>
                                                                <small>
                                                                    <a href="#" className="text-danger" onClick={() => delEmpreendimento(index)}>
                                                                        <i className="material-icons">delete</i> Remover
                                                                    </a>
                                                                </small>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}       
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                                <Row className="pt-2">
                                    <Col lg="12" className='text-right'>
                                        <Button 
                                            type='button' 
                                            theme='secondary' 
                                            className="ml-3"
                                            onClick={() => setHideFilters(false)}
                                        >
                                            <i class="fa fa-times" aria-hidden="true"></i> Fechar
                                        </Button>
                                        <Button 
                                            type='submit' 
                                            theme='accent' 
                                            className="ml-3"
                                        >
                                            <i className='fa fa-filter'></i> Aplicar Filtro
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </ModalBody>
                </Modal>

                {isOpenModalEmp && 
                    <CustomModal
                        type='ListEnterprises'
                        size="lg"
                        open={isOpenModalEmp}
                        toggle={modalTogglesEmp}
                        selectCallback={(type, item) => getEmpreendimento(type, item)} 
                        autoClose={false}
                    />
                }

            </Container>
        </>
    )
}

export default PageView;