import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';

const PlotlyRenderers = createPlotlyRenderers(Plot);
const rendersPivot = Object.assign({}, TableRenderers, PlotlyRenderers);

const renders = {
  'Tabela': rendersPivot['Table'],
  'Mapa de calor da coluna da tabela': rendersPivot['Table Col Heatmap'],
  'Mapa de calor da tabela': rendersPivot['Table Heatmap'],
  'Mapa de calor da linha da tabela': rendersPivot['Table Row Heatmap'],
  'TSV exportável': rendersPivot['Exportable TSV'],
  'Gráfico de Área': rendersPivot['Area Chart'],
  'Gráfico de pontos': rendersPivot['Dot Chart'],
  'Gráfico de Barras Agrupadas': rendersPivot['Grouped Bar Chart'],
  'Gráfico de colunas agrupadas': rendersPivot['Grouped Column Chart'],
  'Gráfico de linha': rendersPivot['Line Chart'],
  'Gráfico de pizza': rendersPivot['Multiple Pie Chart'],
  'Gráfico de dispersão': rendersPivot['Scatter Chart'],
  'Gráfico de Barras Empilhadas': rendersPivot['Stacked Bar Chart'],
  'Gráfico de colunas empilhadas': rendersPivot['Stacked Column Chart'],
};

export default renders;
