import React from 'react';
import LayoutModal from './layouts';


const Modal = ({ type, ...rest }) => {
  const ComponentModal = LayoutModal[type]

  if(ComponentModal){
    return (
      <ComponentModal {...rest}/>
    );
  }
  else {
    return <></>;
  }
}

export default Modal;
