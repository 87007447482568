import { aggregatorTemplates, numberFormat } from 'react-pivottable/Utilities';

var usFmt = numberFormat({
  thousandsSep: '.',
  decimalSep: ',',
});

var usFmtInt = numberFormat({
  digitsAfterDecimal: 0,
  thousandsSep: '.',
  decimalSep: ',',
});

var usFmtPct = numberFormat({
  digitsAfterDecimal: 1,
  scaler: 100,
  suffix: '%',
  thousandsSep: '.',
  decimalSep: ',',
});

const aggregators = function (tpl) {
  return {
    'Contagem': tpl.count(usFmtInt),
    'Contar valores únicos': tpl.countUnique(usFmtInt),
    'Listar Valores Únicos': tpl.listUnique(', '),
    'Soma': tpl.sum(usFmt),
    'Soma Inteira': tpl.sum(usFmtInt),
    'Média': tpl.average(usFmt),
    'Mediana': tpl.median(usFmt),
    'Variância de Amostra': tpl.var(1, usFmt),
    'Desvio Padrão de Amostra': tpl.stdev(1, usFmt),
    'Mínima': tpl.min(usFmt),
    'Máxima': tpl.max(usFmt),
    'Primeira': tpl.first(usFmt),
    'Última': tpl.last(usFmt),
    'Soma sobre Soma': tpl.sumOverSum(usFmt),
    'Soma como fração do total': tpl.fractionOf(tpl.sum(), 'total', usFmtPct),
    'Soma como fração de linhas': tpl.fractionOf(tpl.sum(), 'row', usFmtPct),
    'Soma como fração de colunas': tpl.fractionOf(tpl.sum(), 'col', usFmtPct),
    'Contar como fração do total': tpl.fractionOf(tpl.count(), 'total', usFmtPct),
    'Contar como fração de linhas': tpl.fractionOf(tpl.count(), 'row', usFmtPct),
    'Contar como fração de colunas': tpl.fractionOf(tpl.count(), 'col', usFmtPct)
  };
}(aggregatorTemplates);

export default aggregators;
