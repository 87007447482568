/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Badge
} from "shards-react";

import { Form } from '@unform/web';
import { Input } from '../components/unform';
import * as Yup from 'yup';
import getValidationErrors from '../utils/getValidationErrors';
import Modal from "../components/modal";
import { useAuth } from '../hooks/Auth';
import { VERSION } from '~/utils/constants';

import { 
  ModuleAPIService
} from '~/services/WebliniaERPAPI';

import logo from '~/images/logo-novo-icon.png';
import Service from "~/services/WebliniaERPAPI/endpoints/sales-commission.service";
import { isNull } from "lodash";

function Login({ history }){
  const formRef = useRef(null);

  const [ loginError, setLoginError ] = useState(false);
  const [ openModal, setOpenModal ] = useState(false);
  const { signIn, selectEnterprise, user } = useAuth();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isShowPassword, setIsShowPassword ] = useState(false);

  const handleSubmitLogin = useCallback(async (data) => {
    try {
      formRef.current.setErrors({});

      let fields = {
        login: Yup.string().required('O login é obrigatório'),
        password: Yup.string().required('A senha é obrigatório'),
      };
      
      if (window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168') !== -1)
        fields['cod_enterprise'] = Yup.string().required('O numero do ambiente é obrigatório');

      const schema = Yup.object().shape(fields);

      await schema.validate(data, {
        abortEarly: false,
      });

      await login(data);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formRef.current.setErrors(getValidationErrors(error));
        return;
      }
    }
  }, []);

  const login = useCallback(async (loginData) => {
    try {
      setIsLoading(true);
      const loginSuccess = await signIn(loginData);
      console.log(user);
      setLoginError(!loginSuccess);
      setIsLoading(false);

      if(loginSuccess){
        setOpenModal(true);
      }
    } catch (erro) {
      console.log(erro);
    }
  }, []);

  const modalToggle = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleSelectEnterprise = useCallback(async (name, item) => {
    if (item) {
      await selectEnterprise(item.id);
      
      try {

        const service = new ModuleAPIService();

        let params = [
          {
            key: 'cod_versao_sistema',
            value: '2.0.0'
          }
        ];

        let {data : modulos} = await service.getModulesByUserId(item.id, null, user.id, params);

        try {

          let moduleRedirect = null;

          modulos.forEach((mod,idx) => {
            if(moduleRedirect == null && mod.url_modulo && (mod.url_modulo.length > 0)){
              moduleRedirect = mod.url_modulo;
            }
            if(mod.nme_modulo == 'Dashboard'){
              moduleRedirect = '/dashboard';
            }
          });

          history.push(moduleRedirect);

        } catch (error) {

          console.log(error);

        }
      } catch (error) {
        console.log(error.message);
        }
      
    } else {
      setOpenModal(false);
    }
  }, [user]);

  return (
    <Container fluid className="main-content-container h-100 px-4">
      <Row noGutters className="h-100">
        <Col lg="3" md="5" className="auth-form mx-auto my-auto">
          <Card>
            <CardBody>
              {/* Logo */}
              <img
                className="auth-form__logo d-table mx-auto mb-1"
                src={logo}
                alt="NG Cloud ERP"
              />

              {/* Title */}
              <h5 className="auth-form__title text-center mb-4">
                <strong style={{ fontWeight: 'bold' }}>NG Cloud ERP</strong>
                <br/>
                <Badge theme="danger" style={{padding: '3px', borderRadius: '3px'}}>beta</Badge>
              </h5>

              {/* Form Fields */}
              <Form ref={formRef} onSubmit={handleSubmitLogin}>
                {(window.location.hostname === 'localhost' || window.location.hostname.indexOf('192.168') !== -1 || window.location.hostname.indexOf('25.41') !== -1) && (
                  <Input name="cod_enterprise" type="text" label="Ambiente" />
                )}
                <Input name="login" type="text" label="Login" />
                <Input name="password" type={(!isShowPassword) ? "password" : "text"} label="Senha" />
                {/* <Input name="showPassword" type="checkbox" label="Mostrar a senha" 
                  onClick={() => {setIsShowPassword(!isShowPassword)}} 
                /> */}

                { loginError &&
                  <div className='text-center'>
                    <Badge theme="danger" className='mb-2'> Login/Senha digitados estão incorretos. </Badge>
                  </div>
                }
                { !isLoading ?
                    <Button
                      type='submit'
                      pill
                      theme="accent"
                      className="d-table mx-auto">
                      Entrar
                    </Button>
                  :
                    <Button
                        disabled
                        type='submit'
                        pill
                        theme="accent"
                        className="d-table mx-auto">
                      <i className="fa fa-spinner fa-spin"></i> &nbsp; Logando
                    </Button>
                }
              </Form>
              <div className="text-center mt-3">
                <small>v.{VERSION}</small>
                <br />
                <small style={{ fontSize: '70%' }}>
                  Powered by <i className="text-danger"><strong style={{ fontWeight: 'bold' }}>CRN Group</strong></i>
                </small>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {openModal &&
        <Modal
          type='ListEnterprises'
          size="lg"
          open={openModal}
          toggle={modalToggle}
          selectCallback={handleSelectEnterprise} />
      }
    </Container>
  )
}

export default Login;
