import React from 'react';

const ButtonClose = ({...rest }) => {
  return (
    <div
      {...rest}
      style={{height: '30px', width: '30px', textAlign: 'center', position: 'absolute', top: '10px', right: '15px', cursor: 'pointer'}}>
      <i className="fa fa-times text-dark"></i>
    </div>
  );
}

export default ButtonClose;


