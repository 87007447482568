import api from '../api';

class WarehousesService extends api {
  getWarehouses = (offset, limit, params) => {
    let req_url = `/depositos/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
  
  getWarehouseById = (warehouse_id) => {
    let req_url = `/deposito/${warehouse_id}`;
    return this.api.get(req_url);
  }
}

export default WarehousesService;
