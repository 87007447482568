import api from '../api';

class WeightsService extends api {

  getWeights (offset, limit, params) {
    let req_url = `/tamanhos/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
}

export default WeightsService;
