import React from 'react';
import PropTypes from "prop-types";

import { InputGroup, InputGroupAddon, InputGroupText, FormInput, Button } from "shards-react";

function InputAdvancedFilter({ onClick, value, label, ...rest }) {
  return (
    <>
        {/* <InputGroup seamless className="mb-12">
          <FormInput name='search' onChange={(e) => setSearch(e.target.value)}/>
          <InputGroupAddon type="append">
            <Button type='button' theme='primary' size="sm" onClick={handleSubmit}>
              Buscar
            </Button>
          </InputGroupAddon>
        </InputGroup> */}
        {label && (
          <label>{label}</label>
        )}
        <InputGroup className="mb-3" onClick={onClick} style={{cursor: 'pointer'}}>
          <InputGroupAddon type="prepend">
            <InputGroupText><i className='fa fa-bars'></i></InputGroupText>
          </InputGroupAddon>
          <FormInput disabled value={value} style={{cursor: 'pointer'}}/>
        </InputGroup>
    </>
  );
}

InputAdvancedFilter.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * The children nodes.
   */
  children: PropTypes.node,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * The input type.
   */
  type: PropTypes.string,
  // type: PropTypes.oneOf(INPUT_TYPES),
  /**
   * Whether it is plaintext, or not.
   */
  plaintext: PropTypes.bool,
  /**
   * The input's size.
   */
  size: PropTypes.string,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ])
};

export default InputAdvancedFilter;
