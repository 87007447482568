import api from '../api';

class ProductsPriceService extends api {
  
  readFileProductPrices = (data) => {
    return this.api.post('/produto/precos/read-file', data);
  };
  
  saveProductPriceImport = (data) => {
    return this.api.post('/produto/precos/save', JSON.stringify(data));
  };
  
}

export default ProductsPriceService;
