import api from '../api';

class BIAPIService extends api {
  getViewData (id_view, params) {
    let req_url = `/bi/view/${id_view}/data`
    if(params)
      req_url += this.generateQueryString(params);
    return this.api.get(req_url);
  }

  getViews (params) {
    let req_url = `/bi/views`
    if(params)
      req_url += this.generateQueryString(params);
    return this.api.get(req_url);
  }

  getAdvancedFilter (id_view, params) {
    let req_url = `/bi/view/${id_view}/filters`
    if(params)
      req_url += this.generateQueryString(params);
    return this.api.get(req_url);
  }

  saveTemplate = (id_view, data) => {
    return this.api.post(`/bi/view/${id_view}/template`, JSON.stringify(data));
  };
  
  deleteTemplate = (id_view, id_template) => {
    return this.api.post(`/bi/view/${id_view}/template/${id_template}/delete`, null);
  };

  getTemplates (id_view, params) {
    let req_url = `/bi/view/${id_view}/templates`
    if(params)
      req_url += this.generateQueryString(params);
    return this.api.get(req_url);
  }

  // getEmpreendimento () {
  //   let offset = 10;
  //   let limit = 0;
  //   let req_url = `/empreendimentos/${offset}/${limit}`
  //   return this.api.get(req_url);
  // }
}

export default BIAPIService;
