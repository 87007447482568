import React from 'react';
import {
	Row,
	Col,
    FormGroup,
    FormCheckbox,
    FormRadio,
    Button
} from "shards-react";
import { 
    Input, 
    Select 
} from '~/components/unform';
import _ from 'lodash';

function DefaultForm({ rows }) {
    return (
        <>
            {rows && rows.map((row, rowIndex) => (
                <Row>
                    {row.columns && row.columns.map((column, columnIndex) => {
                        column.isVisible = (typeof(column.isVisible) === "undefined") ? true : column.isVisible;
                        if (column.isVisible) {
                            return (
                                <Col lg={column.cols ? column.cols : 1}>
                                    {column.isFieldset && (
                                        <fieldset>
                                            <legend>{column.label && column.label}</legend>
                                        </fieldset>
                                    )}
                                    {column.isCheckBox && (
                                        <FormGroup style={{ width: "100%" }}>
                                            <label style={{ display: 'block' }}>
                                                {column.label && column.label}
                                            </label>
                                            {column.options && column.options.map(option => (
                                                <FormRadio 
                                                    inline 
                                                    checked={column.value === option.value}
                                                    onClick={() => column.onChangeCallback(option.value)}
                                                >
                                                    {option.label && option.label}
                                                </FormRadio>
                                            ))}
                                        </FormGroup>
                                    )}
                                    {column.isRadioButton && (
                                        <>
                                            {column.options && column.options.map(option => (
                                                <FormGroup style={{ width: "100%" }} onClick={option.onClickCallback && option.onClickCallback}>
                                                    <label style={{ display: 'block' }}>
                                                        {option.label}
                                                    </label>
                                                    <FormCheckbox 
                                                        checked={option.isChecked ? option.isChecked : false} 
                                                        onClick={option.onClickCallback && option.onClickCallback}
                                                    >
                                                        {option.isChecked ? 'Sim' : 'Não'}
                                                    </FormCheckbox>
                                                </FormGroup>
                                            ))}
                                        </>
                                    )}
                                    {(column.isInput || column.isFileInput) && (
                                        <>
                                            <Input
                                                type={column.isFileInput ? 'file' : 'text'}
                                                name={column.name}
                                                label={column.label}
                                                value={column.value && column.value}
                                                defaultValue={column.value && column.value}
                                                onChange={column.onChangeCallback && (!column.isFileInput) ? column.onChangeCallback : (e) => {
                                                    if(e.target.files && e.target.files.length > 0) {
                                                        // get file metadata
                                                        var file = e.target.files[0];
                                                        var name = file.name;
                                                        var type = file.type.substring(file.type.indexOf('/')+1, file.type.length);
                                                        if(!type){
                                                            type = file.name.substring((file.name.lastIndexOf('.')+1), file.name.length);
                                                        }
                                                        var size = file.size;
                                                        
                            
                                                        // reading file contents
                                                        var reader = new FileReader();
                                                        reader.onload = (e) => {
                                                            console.log(e);
                                                            var path = e.target.result;
                                                            var fileObject = {name, type, size, path};
                                                            column.onChangeCallback(fileObject);
                                                            console.log(fileObject);
                                                        }
                                                        reader.readAsDataURL(file);
                                                    }
                                                }}
                                                disabled={column.isReadOnly}
                                                readOnly={column.isReadOnly}
                                            />
                                            {column.file && <img src={column.file.base64}/>}
                                        </>
                                    )}
                                    {column.isSelect && (
                                        <Select
                                            name={column.name}
                                            label={column.label}
                                            data={column.options && column.options}
                                            value={column.value && column.value}
                                            defaultValue={column.value && column.value}
                                            onChange={column.onChangeCallback && column.onChangeCallback}
                                        />
                                    )}
                                </Col>
                                
                            )
                        }
                        else return undefined;
                    })}
                </Row>
            ))}
        </>
    )
}

export default DefaultForm;