import React, { useCallback, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { sortBy, template } from 'underscore';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { TableExport } from 'tableexport';
import getValidationErrors from '../utils/getValidationErrors';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';
import { removerAcentos } from '~/utils/functions';

import { useAuth } from '../hooks/Auth';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Badge,
  ListGroup,
  ListGroupItem
} from "shards-react";

import { Input, Select, InputAdvancedFilter } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import PivotTable from '../components/pivot-table';
import CustomModal from '../components/modal';
import formatValue, { numberFormat } from '../utils/formatValue';

import { 
  BIService,
  EmpreendimentoService
} from '../services/WebliniaERPAPI';

import {
  ExportService
} from '../services/ExporterFileAPI';

import { Local, KEYS } from '../services/Storage/storage.service';
import SidebarNavItem from '~/components/layout/MainSidebar/SidebarNavItem';

const BIView = () => {
  const formRef = useRef(null);
  const { user, enterprise } = useAuth();
  const { addToast } = useToasts();
  
  const [views, setViews] = useState([]);
  const [selectView, setSelectView] = useState(null);
  const [selectViewName, setSelectViewName] = useState(null);
  const [enterpriseViewTemplate, setEnterpriseViewTemplate] = useState(null);
  const [selectedEnterpriseViewTemplate, setSelectedEnterpriseViewTemplate] = useState(null);
  const [selectedUserViewTemplate, setSelectedUserViewTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNewTemplate, setIsLoadingNewTemplate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [pivotData, setPivotData] = useState([]);
  const [pivotTemplate, setPivotTemplate] = useState([]);
  const [enterpriseViewTemplates, setEnterpriseViewTemplates] = useState([]);
  const [userViewTemplates, setUserViewTemplates] = useState([]);
  const [advancedFiltersView, setAdvancedFiltersView] = useState([]);
  const [modalsOpen, setModalsOpen] = React.useState({});
  const [advancedFilters, setAdvancedFilters] = useState();
  const [buttonsExport, setButtonsExport] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tableExport, setTableExport] = useState(null);
  const [pivotTableConfig, setPivotTableConfig] = useState(null);
  const [isShowModalSaveTemplate, setIsShowModalSaveTemplate] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [isOpenModalEmp, setOpenModalEmp ] = useState(false);
  const [isOpenModalProd, setOpenModalProd ] = useState(false);
  const [empreendimentos, setEmpreendimentos ] = useState([]);
  const [hideFilters, setHideFilters ] = useState(false);
  const [produtos, setProdutos ] = useState([]);
  const [dataExcel, setDataExcel]= useState(null);

  const [modalFilterParams, setModalFilterParams] = React.useState([]);

  const [modalAutoClose, setModalAutoClose] = useState();

  const [isLoadingBI, setIsLoadingBI ] = useState(false);


  const loadViews = useCallback(async () => {
    const biService = new BIService();
    const response = await biService.getViews();
    setViews(response.data);
  });

  const handleSubmitFilter = useCallback(async(data) => {
    try {

      if(data.view === '16' || data.view === '17' ||  data.view === '18' ||  data.view === '19'){
        data.startDate = moment().format('YYYY-MM-DD');
        data.endDate = moment().format('YYYY-MM-DD');
      };

      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        view: Yup.string().required('Selecione uma Visão'),
        startDate: Yup.string().required('Selecione uma Data Inicial'),
        endDate: Yup.string().test("is-greater", "Deve ser maior que a inicial", function(value) {
          const { startDate } = this.parent;
          return moment(value, "yyyy-mm-dd").isSameOrAfter(moment(startDate, "yyyy-mm-dd"));
        }).required('Selecione uma Data Final')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await getDataView(data);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        formRef.current.setErrors(getValidationErrors(error));
        return;
      }
    }
  }, [advancedFilters, selectedEnterpriseViewTemplate, selectedUserViewTemplate, empreendimentos, produtos]);

  const getAdvancedFilter = useCallback(async (id_view) => {
    const biService = new BIService();
    if(id_view != ''){
      try{
        const response = await biService.getAdvancedFilter(id_view);
        setAdvancedFiltersView(response.data);
      } catch(error){
        setAdvancedFiltersView([]);
      }
    } else {
      setAdvancedFiltersView([]);
    }
  }, []);
  
  const getUserTemplates = useCallback(async (id_view, selected_template_id) => {
    const biService = new BIService();
    if (id_view != '') {
      try {
        let params = [{
          key: 'id_empreendimento',
          value: enterprise.id_empreendimento
        },{
          key: 'id_usuario',
          value: user.id
        },{
          key: 'flg_excluido',
          value: 0
        }];
        const { data } = await biService.getTemplates(id_view, params);
        setUserViewTemplates(data);
        setSelectedEnterpriseViewTemplate(null);
        formRef.current.setFieldValue('userViewTemplate', selected_template_id);
        setSelectedUserViewTemplate(selected_template_id);
      }
      catch(error) {
        setUserViewTemplates([]);
      }
    }
    else {
      setUserViewTemplates([]);
    }
  }, []);

  const getEnterpriseTemplates = useCallback(async (id_view) => {
    const biService = new BIService();
    if (id_view != '') {
      try {
        let params = [{
          key: 'id_empreendimento',
          value: enterprise.id_empreendimento,
        }, {
          key: 'id_usuario[exp]',
          value: 'IS NULL'
        },{
          key: 'flg_excluido',
          value: 0
        }];
        const { data } = await biService.getTemplates(id_view, params);
        setEnterpriseViewTemplates(data);
      }
      catch(error) {
        setEnterpriseViewTemplates([]);
      }
    }
    else {
      setEnterpriseViewTemplates([]);
    }
  }, []);

  const modalToggle = useCallback((name) => {
    setModalsOpen({
      ...modalsOpen,
      [name]: !modalsOpen[name]
    });
  }, [modalsOpen]);

  const modalTogglesEmp = useCallback(() => {
    setOpenModalEmp(!isOpenModalEmp);
  }, [isOpenModalEmp]);

  const modalTogglesProd = useCallback(() => {
    setModalFilterParams([{
			key: 'withCombinacoes',
			value: 'true'
		}]);
    setOpenModalProd(!isOpenModalProd);
  }, [isOpenModalProd]);

  const handleSelect = useCallback((name, value) => {
    setAdvancedFilters({
      ...advancedFilters,
      [name]: value
    });
  }, [advancedFilters]);

  const getValueAdvancedFilter = useCallback((property, name) => {
    if(advancedFilters){
      if(advancedFilters[property]){
        return advancedFilters[property][name];
      }
      else {
        return '';
      }
    }
    return '';
  }, [advancedFilters]);

  const getDataView = useCallback(async (params) => {
    setPivotData([]);
    setIsError(false);
    setIsLoading(true);

    const urlParams = [{
      key: 'id_view', 
      value: params.view 
    },{
      key: "data[exp]", 
      value: decodeURI(`BETWEEN '${params.startDate} 00:00:00' AND '${params.endDate} 23:59:59'`)
    }];

    if (empreendimentos && empreendimentos.length > 0) {
      urlParams.push({
        key: 'id_empreendimento[exp]', 
        value: `IN (${empreendimentos.map(emp => emp.id)})`
      });
    }
    else {
      urlParams.push({
        key: 'id_empreendimento', 
        value: enterprise.id_empreendimento
      });
    }

    if(produtos && produtos.length > 0){
      urlParams.push({
        key: 'itv->id_produto[exp]', 
        value: `IN (${produtos.map(prd => prd.id)})`
      });
    }

    if (selectedEnterpriseViewTemplate || selectedUserViewTemplate) {
      let template_id = null;
      let template_name = '';

      if (selectedEnterpriseViewTemplate) {
        template_id = selectedEnterpriseViewTemplate;
        template_name = enterpriseViewTemplates.filter(tmpl => (tmpl.id == template_id))[0].nome;
      }
      else if (selectedUserViewTemplate) {
        template_id = selectedUserViewTemplate;
        template_name = userViewTemplates.filter(tmpl => (tmpl.id == template_id))[0].nome;
      }

      urlParams.push({
        key: 'template',
        value: template_name
      });
    }

    if(advancedFilters){
      Object.keys(advancedFilters).map((key) => {
        advancedFiltersView.map((filter) => {
          const item = advancedFilters[key];
          if(key == filter.type){
            const param = {
              key: filter.colum_bd,
              value: item[filter.colum_value]
            }

            urlParams.push(param);
          }
        });
      });
    };

    try {
      const biService = new BIService();
      const response = await biService.getViewData(params.view, urlParams);
      const template = formatTemplatePivotTable(response.data.template);
      const data = formatDataPivotTable(response.data.data, template.colunas);

      setPivotTemplate(template);
      setDataExcel(response.data.data);
      setPivotData(data);
      setIsLoading(false);
      testHandleExport();
      setHideFilters(true);
    } catch (error) {
      console.warn(error)
      setIsLoading(false);
      setIsError(true);
      setHideFilters(false);
    }
  }, [advancedFilters, selectedEnterpriseViewTemplate, selectedUserViewTemplate, empreendimentos, produtos]);

  const formatDataPivotTable = useCallback((data, template) => {
    const formatData = [];

    data.map((item) => {
      const line = {};
      if(template){
        template.map((colum) => {
          if(colum.format == 'date')
            line[colum.nome] = moment(item[colum.coluna]).format('DD/MM/YYYY');
          else if(colum.format == 'number')
            line[colum.nome] = (item[colum.coluna]) ? item[colum.coluna].toFixed(2) : 0;
          else
            line[colum.nome] = item[colum.coluna];
        });

        formatData.push(line);
      } else {
        formatData.push(item);
      }
    });

    return formatData;
  }, []);

  const doExportExcel = async () => {
    let colunas = [];

    let template_name = removerAcentos(selectViewName.replace(/ /g,'_'));
    
    pivotTemplate.colunas.map((colum, idx) => {
      if(colum.ordination !== null) {
        if(colum.format == 'date'){
          colunas.push({
            nome_coluna:colum.coluna,
            index:idx,
            titulo: colum.nome,
            tipo: 'Date'
          });
        }
        else if(colum.format == 'number'){
          colunas.push({
            nome_coluna:colum.coluna,
            index:idx,
            titulo: colum.nome,
            tipo: 'Money'
          });
        }
        else{
          colunas.push({
            nome_coluna:colum.coluna,
            index:idx,
            titulo: colum.nome,
            tipo: 'String'
          });
        }
      }
    });

    pivotTemplate.colunas.filter(function(colum) { 
      if(colum.ordination == null){
        colunas.push({
          nome_coluna: colum.coluna,
          index: colunas[colunas.length - 1].index + 1,
          titulo: colum.nome,
          tipo: 'String'
        });
      }
    });

    var payload = {
      template_name: template_name,
      colunas: colunas,
      dados: dataExcel
    };
    
    try {
      const api = new ExportService();
      const { data: file } = await api.doExportExcel(payload);
      api.downloadGeneratedFile(file);
    }
    catch(error) {
      console.log(error);
    }
  }

  const formatTemplatePivotTable = useCallback((template) => {
    if(template){
      template.cols = [];
      template.rows = [];

      template.colunas = sortBy(template.colunas, 'ordination');

      template.colunas.map((coluna) => {
        if(coluna.type === 'col')
          template.cols.push(coluna.nome)
        else if (coluna.type === 'row')
          template.rows.push(coluna.nome)
      });
    }

    return template;
  });

  const handleResetFilter = useCallback(() => {
    formRef.current.reset();
    setAdvancedFiltersView([]);
    setAdvancedFilters({});
    setSelectedEnterpriseViewTemplate(null);
    setSelectedUserViewTemplate(null);
    setEmpreendimentos([]);
    setProdutos([]);
  }, []);

  const dropdownToggle = useCallback(() => {
    setDropdownOpen(!dropdownOpen);
  }, [dropdownOpen]);

  const handleExportTable = useCallback(() => {
    const view = views.filter(view => view.id == selectView);
    if(view.length > 0){
      const nameView = view[0].nome;

      var table = new TableExport(document.getElementsByClassName("pvtTable"), {
        formats: ['xlsx', 'csv', 'txt'],
        filename: nameView,
        sheetname: nameView,
        trimWhitespace: false,
        exportButtons: false,
      });

      setTableExport(table);

      const htmlTable = document.getElementsByClassName('pvtTable');

      if(htmlTable.length > 0){
        const tableId = htmlTable[0].getAttribute('tableexport-key');

        const exportData = table.getExportData()[tableId];
        console.log(exportData);

        const data = [];
        Object.keys(exportData).map(item => {
          data.push({ ...exportData[item], type: item });
        });

        setButtonsExport(data);
      }
    }
  }, [selectView]);

  const exportTable = useCallback((item) => {

    const { data, mimeType, filename, fileExtension, merges, RTL, sheetname } = item;
    if(tableExport)
      tableExport.export2file(data, mimeType, filename, fileExtension, merges, RTL, sheetname);

  }, [tableExport]);

  const testHandleExport = useCallback(() => {
    const htmlTable = document.getElementsByClassName('pvtTable');
    console.log(selectView);
    if(htmlTable.length > 0){
      htmlTable[0].setAttribute("id", "pivotTableExcel");
    }
  }, [])

  useEffect(() => {
    getAdvancedFilter(selectView);
    getUserTemplates(selectView);
    getEnterpriseTemplates(selectView);
    const view = views.filter(view => view.id == selectView);
    if(view.length > 0){
      setSelectViewName(view[0].nome);
    }
  }, [selectView]);

  useEffect(() => {
    loadViews();
  }, []);

  const onChangePivot = (data) => {
    console.log(data);
    setPivotTableConfig(data);
    setTimeout(() => {
      testHandleExport();
    }, 100)
  };

  const saveActiveTemplate = useCallback(async () => {
    setIsLoadingNewTemplate(true);
    try {
      let columns = [];
      
      pivotTemplate.colunas.forEach(coluna => {
        let column = {
          nome: coluna.nome,
          coluna: coluna.coluna,
          type: null,
          format: coluna.format,
          ordination: null
        };
        pivotTableConfig.rows.forEach(row => {
          if (row === coluna.nome)
            column.type = 'row';
        });
        pivotTableConfig.cols.forEach(col => {
          if (col === coluna.nome)
            column.type = 'col';
        });
        columns.push(column);
      });
      
      let ordination = 0;

      pivotTableConfig.rows.forEach(row => {
        columns.map(column => {
          if (column.nome === row) {
            ordination++
            column.ordination = ordination;
          }
        });
      });

      pivotTableConfig.cols.forEach(col => {
        columns.map(column => {
          if (column.nome === col) {
            ordination++
            column.ordination = ordination;
          }
        });
      });
  
      let payload = {
        nome: newTemplateName.toString(),
        aggregator_name: pivotTableConfig.aggregatorName,
        aggregator_val: pivotTableConfig.vals[0],
        renderer_name: pivotTableConfig.rendererName,
        id_empreendimento: enterprise.id_empreendimento,
        id_usuario: user.id,
        columns: columns
      };

      const view = views.filter((view) => view.id == selectView)[0];

      const biService = new BIService();
      const { data, status } = await biService.saveTemplate(view.id, payload);

      addToast('Modelo criado com sucesso!', {
        placement: 'bottom-right',
        appearance: 'success',
        autoDismiss: true
      });
      clearNewTemplate();
      getUserTemplates(selectView, data.id);
    }
    catch(error) {
      console.log(error);
      setIsLoadingNewTemplate(false);
      addToast(error.toString(), {
        placement: 'bottom-right',
        appearance: 'error',
        autoDismiss: true
      });
    }
  }, [pivotTableConfig, newTemplateName, selectView]);

  const deleteTemplate = useCallback(async () => {
    setIsLoadingNewTemplate(true);
    try {
      const view = views.filter((view) => view.id == selectView)[0];
      const template = userViewTemplates.filter((tmpl) => tmpl.id == selectedUserViewTemplate)[0];

      const biService = new BIService();
      const { data, status } = await biService.deleteTemplate(view.id, template.id);

      addToast('Modelo excluído com sucesso!', {
        placement: 'bottom-right',
        appearance: 'success',
        autoDismiss: true
      });
      
      setIsLoadingNewTemplate(false);
      getUserTemplates(view.id);
    }
    catch(error) {
      console.log(error);
      setIsLoadingNewTemplate(false);
      addToast(error.toString(), {
        placement: 'bottom-right',
        appearance: 'error',
        autoDismiss: true
      });
    }
  },[selectView, selectedUserViewTemplate]);

  const clearNewTemplate = () => {
    setIsShowModalSaveTemplate(false);
    setIsLoadingNewTemplate(false);
    setNewTemplateName(null);
    setPivotTableConfig(null);
  }

  const getEmpreendimento = useCallback(async (name, item) => {
    if (item) {
      try{
        // clonando p/ poder manipular
        let _empreendimentos = _.clone(empreendimentos);
        
        // inicializando o array, caso esteja null ou undefined
        if (!_empreendimentos)
          _empreendimentos = [];

        // garantindo que não vou add o mesmo objeto na lista
        let can_add = true;
        if (_empreendimentos.length > 0) {
          _empreendimentos.forEach(emp => {
            if (emp.id === item.id) {
              can_add = false;
              addToast(`Esse item já contem na listagem!`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
            });
            }
          })
        }

        // add apenas se não estiver no array
        if (can_add)
          _empreendimentos.push(item);

        // atualiza o state
        setEmpreendimentos(_empreendimentos);
      }catch(err){
          console.log(err);
      }
    }else{
      setOpenModalEmp(false);
    }
  },[empreendimentos])

  const delEmpreendimento = async (index) =>{
    let _empreendimentos = _.clone(empreendimentos);
		
		if (_empreendimentos) {
			_empreendimentos.splice(index, 1);
		}

		setEmpreendimentos(_empreendimentos);
		
  }

  const getProdutos = useCallback(async (name, item) => {
    if (item) {
      try{
        // clonando p/ poder manipular
        let _produtos = _.clone(produtos);
        
        // inicializando o array, caso esteja null ou undefined
        if (!_produtos)
          _produtos = [];

        // garantindo que não vou add o mesmo objeto na lista
        let can_add = true;
        if (_produtos.length > 0) {
          _produtos.forEach(emp => {
            if (emp.id === item.id) {
              can_add = false;
              addToast(`Esse item já contem na listagem!`, {
                placement: 'bottom-right',
                appearance: 'error',
                autoDismiss: true
              });
            }
          })
        }

        // add apenas se não estiver no array
        if (can_add)
          _produtos.push(item);

        // atualiza o state
        setProdutos(_produtos);

      }catch(err){
          console.log(err);
      }
    }else{
      setOpenModalProd(false);
    }
  },[produtos])

  const delProdutos = async (index) =>{
    let _produtos = _.clone(produtos);
		
		if (_produtos) {
			_produtos.splice(index, 1);
		}
			setProdutos(_produtos);
		
  }

  
  return (
    <>
      <Container fluid className='main-content-container px-4 pb-4'>
        <Row noGutters className='page-header py-4'>
          <PageTitle title='Business Intelligence' subtitle='Relatórios' className='text-sm-left mb-3' />
        </Row>

        <Row>
          <Col sm='9'>
            {(isLoadingBI) && (
              <>
                <Card className='p-0'>
                  <CardBody className='p-0'>
                    <Container fluid className='file-manager__filters border-bottom'>
                      <Form>           
                        <Col sm = '12'>
                          {pivotData.length > 0 &&
                            <CardBody className='p-2'>
                              <PivotTable
                                data={pivotData}
                                rows={pivotTemplate.rows}
                                cols={pivotTemplate.cols}
                                aggregatorName={(pivotTemplate.aggregator_name) ? pivotTemplate.aggregator_name : 'Contagem'}
                                vals={[pivotTemplate.aggregator_val]}
                                rendererName={pivotTemplate.renderer_name}
                                onChangePivot={onChangePivot}
                              />
                            </CardBody>
                          }
                          {isLoading &&
                            <CardBody className='p-10 text-center'>
                              <i className="fa fa-spinner fa-spin text-dark"></i> Carregando Registros
                            </CardBody>
                          }
                          {isError &&
                            <CardBody className='p-10 text-center'>
                              <i className="fa fa-times text-dark"></i> Nenhum Registro Encontrado.
                            </CardBody>
                          }
                        </Col>
                      </Form>
                    </Container>
                  </CardBody>
                </Card>
              </>
            )}
            {(!isLoadingBI) && (
              <>
                <Card className='p-0'>
                  <CardBody className='p-10 text-center'>
                    <Container fluid className='file-manager__filters border-bottom'>
                      <Form>           
                        <Col sm = '12'>
                          <i className="fa fa-search text-dark"></i> Para visualizar os dados selecione uma visão 
                        </Col>
                      </Form>
                    </Container>
                  </CardBody>
                </Card>
              </>
            )}
          </Col>

          <Col sm ='3'>
            <Card className='p-0'>
              <CardHeader className="border-bottom p-3">
                <Row>
                  <Col sm='6'>
                    <h6 className="m-0">
                      <i className='fa fa-filter'></i> Opções de Filtro
                    </h6>
                  </Col>
                  <Col sm='6' className={'text-right'}>
                      <small>
                          <a href="#" className="text-primary"  onClick={() => setHideFilters(!hideFilters)}>
                            {(hideFilters) && 
                              <span>
                                Exibir&nbsp;
                                <i class="fa fa-chevron-down"></i>
                              </span>
                            }
                            {(!hideFilters) && 
                              <span>
                                Ocultar&nbsp;
                                <i class="fa fa-chevron-up"></i>
                              </span>
                            }
                          </a>
                      </small>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className='p-0' style={{ display: (hideFilters) ? 'none' : ''}}>
                <Container fluid className='file-manager__filters border-bottom'>
                  <Form ref={formRef} onSubmit={handleSubmitFilter}>           
                    <Row>
                      <Col sm='12'>
                        <Select
                          name='view'
                          label='Visão'
                          data={views}
                          onChange={(e) => {setSelectView(e.target.value)}}
                        />
                      </Col>
                      
                      {(enterpriseViewTemplates && enterpriseViewTemplates.length > 0) && (
                        <Col sm='12'>
                          <Select
                            name='enterpriseViewTemplate'
                            label={`Modelos do Empreendimento`}
                            data={enterpriseViewTemplates}
                            onChange={(e) => {
                              setSelectedUserViewTemplate(null);
                              formRef.current.setFieldValue('userViewTemplate', '');
                              setSelectedEnterpriseViewTemplate(e.target.value);
                            }}
                          />
                        </Col>
                      )}

                      {(userViewTemplates && userViewTemplates.length > 0) && (
                        <>
                          <Col sm='12'>
                            <Select
                              name='userViewTemplate'
                              label={`Modelos Definidos por Você`}
                              data={userViewTemplates}
                              onChange={(e) => {
                                setSelectedEnterpriseViewTemplate(null);
                                formRef.current.setFieldValue('enterpriseViewTemplate', '');
                                setSelectedUserViewTemplate(e.target.value);
                              }}
                            />
                          </Col>
                          {selectedUserViewTemplate && (
                            <Col sm='12'>
                              <FormGroup>
                                <label style={{ color: '#FFF' }}>label aqui</label>
                                <Button
                                  type='button'
                                  outline theme='accent' className="ml-2"
                                  onClick={deleteTemplate}
                                  disabled={isLoadingNewTemplate}
                                >
                                  {isLoadingNewTemplate ? (
                                    <i className='fa fa-spin fa-spinner'></i>
                                  ) : (
                                    <i className='fa fa-trash'></i>
                                  )}
                                </Button>
                              </FormGroup>
                            </Col>
                          )}
                        </>
                      )}

                      <Col sm="12">
                        <Input
                          type='date'
                          name='startDate'
                          label='Data Inicial'
                        />
                        <Input
                          type='date'
                          name='endDate'
                          label='Data Final'
                        />
                      </Col>
                    </Row>

                    <Row>                        
                      {advancedFiltersView.map((item) => {
                        switch(item.type) {
                          case 'MultipleProductsSelector':
                            return (
                              <Col sm='12'>
                                <ListGroup flush>
                                  <ListGroupItem className="pl-2 pt-3 pr-2 pb-0">
                                    <span className="d-flex mb-2">
                                      <span className="mr-1">Produtos</span>
                                      <span className="ml-auto">
                                        <small>
                                            <a href="#" className="text-success" onClick={() => setOpenModalProd(true)}>
                                            <i class="fa fa-plus-circle"></i> Incluir
                                            </a>
                                        </small>
                                      </span>
                                    </span>
                                  </ListGroupItem>
                                </ListGroup>
                                <table className='table table-condensed table-hover'>
                                  <tbody>
                                    {produtos && produtos.map((item, index) =>(
                                      <tr>
                                        <td colSpan={2}>
                                          <small>{item.nome}</small>
                                          <br/>
                                          <small className="text-secondary">
                                              {item && item.nome_fabricante && (
                                                  <>{item.nome_fabricante}</>
                                              )}
                                              {item && item.nome_tamanho && (
                                                  <>&nbsp;•&nbsp; {item.nome_tamanho}</>
                                              )}
                                              {item && item.nome_cor_sabor && (
                                                  <>&nbsp;•&nbsp; {item.nome_cor_sabor}</>
                                              )}
                                          </small>
                                          <br/>
                                          <small className="text-secondary">
                                            #{item.id}&nbsp;•&nbsp;
                                          </small>
                                          <small>
                                              <a href="#" className="text-danger" onClick={() => delProdutos(index)}>
                                                <i className="material-icons">delete</i> Remover
                                              </a>
                                          </small>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </Col>
                            )
                            break;
                          case 'MultipleEnterprisesSelector':
                            return (
                              <Col sm='12'>
                                <ListGroup flush>
                                  <ListGroupItem className="pl-2 pt-3 pr-2 pb-0">
                                    <span className="d-flex mb-2">
                                      <span className="mr-1">Empreendimentos</span>
                                      <span className="ml-auto">
                                        <small>
                                            <a href="#" className="text-success" onClick={() => setOpenModalEmp(true)}>
                                            <i class="fa fa-plus-circle"></i> Incluir
                                            </a>
                                        </small>
                                      </span>
                                    </span>
                                  </ListGroupItem>
                                </ListGroup>
                                <table className='table table-condensed table-hover'>
                                  <tbody>
                                    {empreendimentos && empreendimentos.map((item, index) =>(
                                      <tr>
                                        <td width='165'>
                                          <small>{item.nome_empreendimento}</small>
                                        </td>
                                        <td className='text-right'>
                                          <small>
                                              <a href="#" className="text-danger" onClick={() => delEmpreendimento(index)}>
                                                <i className="material-icons">delete</i> Remover
                                              </a>
                                          </small>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </Col>
                            )
                            break;
                          default:
                            return (
                              <Col key={item.id} sm='12'>
                                <InputAdvancedFilter
                                  label={item.label}
                                  onClick={() => {modalToggle(item.type)}}
                                  value={getValueAdvancedFilter(item.type, item.colum_name)}
                                />
                              </Col>
                            )
                            break;
                        }
                      })}
                    </Row>

                    <Row>
                        {pivotData.length > 0 && (
                          <Col sm="6" className={'text-center'}>
                              <Button
                                type='button'
                                outline theme='info' className="ml-2 mb-2"
                                onClick={() => doExportExcel()}
                              >
                                <i class="fas fa-file-excel"></i> Exportar
                              </Button>
                          </Col>
                        )}
                        {pivotTableConfig && (
                          <Col sm="6" className={'text-center'}>
                              <Button
                                type='button'
                                outline theme='primary' className="ml-2 mb-1"
                                onClick={() => setIsShowModalSaveTemplate(true)}
                              >
                                <i className='fa fa-save'></i> Salvar Modelo/Layout
                              </Button>
                          </Col>
                        )}
                    </Row>
                    
                    <Row>
                      <Col sm = '6' className={'text-center'}>
                        <Button
                          type='button'
                          outline theme='secondary' className="ml-2"
                          onClick={handleResetFilter}
                        >
                          <i className='fa fa-times'></i> Limpar Filtro
                        </Button>
                      </Col>
                      
                      <Col sm="6" className={'text-center'}>
                        <Button 
                          type='submit' 
                          theme='accent' className="ml-3"
                          onClick={() => setIsLoadingBI(true)}
                        >
                          <i className='fa fa-filter'></i> Aplicar Filtro
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Container>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {isOpenModalEmp && 
          <CustomModal
            type='ListEnterprises'
            size="lg"
            open={isOpenModalEmp}
            toggle={modalTogglesEmp}
            selectCallback={(type, item) => getEmpreendimento(type, item)} 
            autoClose={false}
            />
        }
        {isOpenModalProd &&
          <CustomModal
            type='ListProduct'
            size="lg"
            open={isOpenModalProd}
            toggle={modalTogglesProd}
            selectCallback={(type, item) => getProdutos(type, item)}
            filterParams={modalFilterParams}
            autoClose={false}
          />
        }
      </Container>

      {advancedFiltersView.map((item) => {
        if(modalsOpen[item.type]){
          return (
            <CustomModal
              key={item.id}
              type={item.type}
              size="lg"
              open={modalsOpen[item.type]}
              toggle={() => {modalToggle(item.type)}}
              selectCallback={handleSelect} />
          )
        } else {
          return <></>
        }
      })}

      <Modal open={isShowModalSaveTemplate}>
        <ModalHeader style={{ padding: '10px 20px'}}>
          <i className="fa fa-cubes"></i> Novo Modelo/Layout de Visão
        </ModalHeader>
        <ModalBody className="p-3">
          <Row>
            <Col sm="12">
              <Form ref={formRef}>
                <Input 
                  name="template_name" 
                  label="Nome do Modelo/Layout" 
                  onChange={(e) => setNewTemplateName(e.target.value)}
                  value={newTemplateName}
                  defaultValue={newTemplateName}
                />
              </Form>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <p className="text-accent">Ao clicar em confirmar, será criado um novo Modelo/Layout atrelado ao seu usuário, neste empreendimento.</p>
            </Col>
          </Row>

        </ModalBody>
        <ModalFooter style={{ padding: '10px'}}>
          <Row>
            <Col className='d-flex ml-auto my-auto justify-content-end'>
              {(!isLoadingNewTemplate) && (
                <Button
                  type='button'
                  outline theme='secondary'
                  onClick={clearNewTemplate}
                >
                  <i className='fa fa-times-circle'></i> Cancelar
                </Button>
              )}

              <Button
                type='button'
                theme='primary'
                className="ml-2"
                onClick={saveActiveTemplate}
                disabled={isLoadingNewTemplate}
              >
                {isLoadingNewTemplate ? (
                  <><i className='fa fa-spin fa-spinner'></i> Aguarde...</>
                ) : (
                  <><i className='fa fa-save'></i> Confirmar</>
                )}
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default BIView;
