import axios from 'axios';

export const base_url = `${window.location.protocol}//exporter.sigsimple.com.br`;

export default class API {
    constructor() {
        this.api = axios.create({
            baseURL: base_url
        });
        
        this.api.interceptors.request.use(
            (config) => {
                this.api.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

    }
}