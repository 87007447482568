import React, { useCallback, useEffect, useRef, useState, } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Button,
    FormInput
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import { Form } from '@unform/web';
import { Select, InputMask } from '../components/unform';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import _ from 'lodash';
import { useAuth } from '~/hooks/Auth';
import { UsersService } from '../services/WebliniaERPAPI';
import { SalesTargetService } from '~/services/WebliniaERPAPI';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';
import getValidationErrors from '~/utils/getValidationErrors';

function PageView(props) {
    
    const [formData, setFormData] = useState(null);

    const { addToast } = useToasts();

    const history = useHistory();

    const formRef = useRef(null);    // let targetInputs = [];

    const { enterprise } = useAuth();

    const service = new SalesTargetService();

    const [isLoading, setIsLoading] = useState(false);

    const [years, setYears] = useState([
        { id: 2021, nome: 2021},
        { id: 2022, nome: 2022},
        { id: 2023, nome: 2023},
        { id: 2024, nome: 2024},
        { id: 2025, nome: 2025},
        { id: 2026, nome: 2026},
        { id: 2027, nome: 2027},
        { id: 2028, nome: 2028},
        { id: 2029, nome: 2029},
        { id: 2030, nome: 2030},
        { id: 2031, nome: 2031}
    ]);
    const [yearSelected, setYearSelected] = useState(null);

    const [users, setUsers] = useState(null);

    useEffect(() => {
        let urlParams = queryString.parse(props.location.search);
		if (urlParams && urlParams.id){
            getDataById(urlParams.id);
        }
        else {
            loadRepresentantes();
        }
    }, []);

    const getDataById = async (id) => {
        const service = new SalesTargetService();
        try {

            const { data: _formData } = await service.getById(id);
            setFormData(_formData);
            setYearSelected(_formData.ano_referencia);

            const { data: _users } = await service.getRepresentativesById(id);
            setUsers(_users);

            await loadRepresentantes(true, _users);
        }
        catch(error) {
            console.log(error);
        }
    }

    const loadRepresentantes = async (isMerge, _items) => {
        if (!isMerge)
            setUsers([]);
        const service = new UsersService();
        try {
            let params = [
                {
                    key: 'tue->id_empreendimento',
                    value: enterprise.id_empreendimento
                }, {
                    key: 'usu->flg_representante',
                    value: 1
                }, {
                    key:'usu->flg_excluido',
                    value: 0
                }
            ];
            const { data: { usuarios: items } } = await service.getAll(params);
            if (!isMerge)
                setUsers(items);
            else {
                let _users = _.cloneDeep(_items);
                items.forEach(item => {
                    let can_add = true;
                    _users.forEach(_user => {
                        if(_user.id === item.id)
                            can_add = false;
                    });
                    if (can_add) {
                        _users.push(item);
                    }
                });
                setUsers(_users);
            }
        } catch(error) {
            if (!isMerge)
                setUsers(null);
            console.log(error);
        } 
    }

    const updateUserMonthValue = (user_id, month, value) => {
        let _users = _.cloneDeep(users);
        _users = _users.map(_user => {
            if (_user.id === user_id) {
                _user[`vlr_${month}`] = value;
            }
            return _user;
        });
        setUsers(_users);
    }

    const validateForm = () => {
        return (yearSelected && !isNaN(parseInt(yearSelected, 10)));
    }
    
    const saveMeta = useCallback(async () => {
        if ( validateForm() ) {
            try {
                let payload = {
                    id: formData && formData.id ? formData.id : null,
                    ano_referencia: yearSelected,
                    empreendimentos: [{ id: enterprise.id_empreendimento }],
                    representantes: users
                };
                const service = new SalesTargetService();
                if(formData && formData.id) {
                    await service.update(formData.id, payload);
                }
                else {
                    await service.save(payload);
                }
                addToast(`Ação realizada com sucesso!`, {
                    placement: 'bottom-right',
                    appearance: 'success',
                    autoDismiss: true
                });
                goBackList();
            }
            catch(error) {
                const { response: { status, data } } = error;

                switch (status) {
                    case 406: 
                        let msg = 'Alguns campos não foram preenchidos corretamente.\nVerifique por favor!';

                        if (data && data.mensagem)
                            msg = data.mensagem;

                        addToast(msg, {
                            placement: 'bottom-right',
                            appearance: 'warning',
                            autoDismiss: true
                        });
                        break;
                    case 500: 
                        addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                            placement: 'bottom-right',
                            appearance: 'warning',
                            autoDismiss: true
                        });
                        break;
                        default:
                        break;
                        
                } 
            }
        }
  

        else {
            addToast('Você deve selecionar o ano p/ criar o planejamento de meta.\nVerifique por favor!', {
                placement: 'bottom-right',
                appearance: 'warning',
                autoDismiss: true
            });
        }
    }, [formData, yearSelected, users]);

    const goBackList = () => {
        history.push('/cadastros/metas-venda/list');
    }

    return (
        <>
            <Container fluid className='main-content-container px-4 pb-4'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle 
                        title='Nova Meta' 
                        subtitle='Cadastros' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12"> 
                        <Card small className='mb-3'>
                            <CardHeader className="border-bottom">
                                <Row>
                                    <Col sm="10" className="col text-left d-flex align-items-center">
                                        <h6 className="m-0">Metas por Representante</h6>
                                    </Col>
                                    <Col sm="2" className="text-right">
                                        <Form ref={formRef}>
                                            <Select
                                                name='view'
                                                label='Selecione o Ano'
                                                data={years}
                                                noMargin
                                                onChange={(e) => setYearSelected(e.target.value)}
                                                defaultValue={yearSelected}
                                                value={yearSelected}
                                                disabled={formData && formData.id}
                                            />
                                        </Form>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <table className="table table-sm table-bordered table-condensed table-responsive table-houver mb-0">
                                <thead className='bg-light'>
                                    <th scope="col" className='text-center'>Representante</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Janeiro</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Fevereiro</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Março</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Abril</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Maio</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Junho</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Julho</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Agosto</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Setembro</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Outubro</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Novembro</th>
                                    <th scope="col" className='text-center' style={{minWidth: 110}}>Dezembro</th>
                                </thead>
                                <tbody> 
                                    {users && users.map(user => (
                                        <tr>
                                            <td style={{ verticalAlign: 'middle', fontSize:'80%'}}>
                                                {user.nome.toUpperCase()}<br/>
                                                <small className='text-muted'>#{user.id}</small>
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_janeiro}
                                                    defaultValue={user.vlr_janeiro}
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'janeiro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_fevereiro}
                                                    defaultValue={user.vlr_fevereiro} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'fevereiro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_marco}
                                                    defaultValue={user.vlr_marco} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'marco', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_abril}
                                                    defaultValue={user.vlr_abril} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'abril', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_maio}
                                                    defaultValue={user.vlr_maio} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'maio', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_junho}
                                                    defaultValue={user.vlr_junho} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'junho', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_julho}
                                                    defaultValue={user.vlr_julho} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'julho', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_agosto}
                                                    defaultValue={user.vlr_agosto} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'agosto', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_setembro}
                                                    defaultValue={user.vlr_setembro} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'setembro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_outubro}
                                                    defaultValue={user.vlr_outubro} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'outubro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_novembro}
                                                    defaultValue={user.vlr_novembro} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'novembro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                            <td style={{ verticalAlign: 'middle' }}>
                                                <NumberFormat
                                                    className="text-right" 
                                                    decimalScale={4} thousandSeparator="." decimalSeparator=","
                                                    allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                    value={user.vlr_dezembro}
                                                    defaultValue={user.vlr_dezembro} 
                                                    onValueChange={({floatValue}) => updateUserMonthValue(user.id, 'dezembro', floatValue)}
                                                    customInput={FormInput}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <CardFooter className="border-top">
                                <Row>
                                    {isLoading ? (
                                        <Col lg="12" className="text-right">
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, salvando informações...
                                        </Col> 
                                    ) : (
                                        <Col lg="12" className="text-right">
                                            <Button outline theme="secondary" size="sm" onClick={goBackList}>
                                                Cancelar
                                            </Button>
                                            &nbsp;
                                            <Button type={'submit'} theme="accent" size="sm" className="ml-auto"
                                                onClick={e => saveMeta()}
                                            >
                                                <i className="material-icons">save</i> Salvar
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>                
                </Row>
            </Container>
        </>
    )
}

export default PageView;