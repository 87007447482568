import React, { useCallback, useEffect, useRef, useState } from 'react';
import {Container,
        Row,
	    Col,
	    Card,
	    CardBody,
        CardFooter,
	    Button
} from 'shards-react';
import { useAuth } from '~/hooks/Auth';
import { useHistory } from 'react-router-dom';
import PageTitle from '~/components/common/PageTitle';
import { useParams, NavLink } from "react-router-dom";
import { SalesTargetService, UsersService } from '~/services/WebliniaERPAPI';
import { numberFormat } from '~/utils/formatValue';

const { useToasts } = require("react-toast-notifications");

function PageView(props) {
    const { enterprise } = useAuth();
    const { addToast } = useToasts();
    const history = useHistory();
    const [items, setItems] = useState(null);

    const getItems = async () => {
        setItems([]);
        const service = new SalesTargetService();
        try {
            let params = [
                {
                    key: 'mve->cod_empreendimento',
                    value: enterprise.id_empreendimento
                }, {
                    key: 'tmv->flg_excluido',
                    value: 0
                }
            ];
            const { data: items } = await service.getAll(params);
            setItems(items);
        }
        catch(error) {
            setItems(null);
            console.log(error);
        }
    }
    
    const deleteItem = async (id) => {
        const service = new SalesTargetService();
        try {
            await service.delete(id);
            getItems();
            addToast(`Ação realizada com sucesso!`, {
                placement: 'bottom-right',
                appearance: 'success',
                autoDismiss: true
            });
        }
        catch(error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getItems();
    }, []);

    return (
        <>
            <Container className='main-content-container px-4 pb-4'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle 
                        title='Metas de Vendas por Ano'
                        subtitle='Cadastros'
                        className='text-sm-left'
                    />

                    <Col xs="12" sm="4" className="col d-flex align-items-center">
                        &nbsp;
                    </Col>

                    <Col sm="4" className="d-flex justify-content-end">
						<Button outline theme="success" tag={NavLink} to={`/cadastros/metas-venda/new`} style={{ lineHeight: '2.4em' }}>
							<i className="material-icons">add_circle</i> Cadastrar Novo
						</Button>
					</Col> 
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                            <CardBody>
                                <Row>
                                    <Col lg="12">
                                        <table className="table table-bordered table-condensed table-houver b-0">
                                            <thead>
                                                <th className='text-center' width="100">Ano</th>
                                                <th>Qtd. Representantes</th>
                                                <th className='text-center' width="200">Valor Total</th>
                                                <th className='text-center' width="100">Ações</th>
                                            </thead>
                                            <tbody>
                                                {items && items.length === 0 && (
                                                    <tr>
                                                        <td className='text-center' colSpan={1}>
                                                            <i className="fa fa-spinner fa-spin"></i>
                                                            &nbsp;
                                                            Aguarde, carregando informações...
                                                        </td>
                                                    </tr>
                                                )}

                                                {items && items.map(item => (
                                                    <tr>
                                                        <td style={{ verticalAlign: 'middle' }} className='text-center'>{item.ano_referencia}</td>
                                                        <td style={{ verticalAlign: 'middle' }}>{item.qtd_representantes}</td>
                                                        <td style={{ verticalAlign: 'middle' }} className='text-right'>R$ {numberFormat(item.vlr_total_meta, 2, ',', '.')}</td>
                                                        <td style={{ verticalAlign: 'middle' }} className='text-center'>
                                                            <Button theme="accent" size="sm" tag={NavLink} to={`/cadastros/metas-venda/new?id=${item.id}`}>
                                                                <i className="material-icons">edit</i> Editar
                                                            </Button>
                                                            <br/>
                                                            <small>
                                                                <a href="#" className="text-danger" onClick={() => deleteItem(item.id)}>
                                                                    <i className="material-icons">delete</i> Remover
                                                                </a>
                                                            </small>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PageView;