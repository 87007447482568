import api from '../api';

class Service extends api {

  save = (data) => {
    return this.api.post(`/fabricante`, JSON.stringify(data));
  }

  update = (id, data) => {
    return this.api.post(`/fabricante/${id}/update`, JSON.stringify(data));
  }

  delete = (id, data) => {
    return this.api.post(`/fabricante/${id}/delete`, JSON.stringify(data));
  }

  getById = (id) => {
    return this.api.get(`/fabricante/${id}`);
  }

  getAll (offset, limit, params) {
    let req_url = `/fabricantes/${offset}/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }

  getEnterprisesById = (id) => {
    return this.api.get(`/fabricante/${id}/empreendimentos`);
  }
}

export default Service;
