import api from '../api';

class Service extends api {
    getStoreCheck = (params) => {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/previsao-compra${url_params}`);
    }

    save = (data) => {
        return this.api.post(`/pedido/reposicao`, JSON.stringify(data));
    }
}

export default Service;