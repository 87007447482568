import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FormGroup } from "shards-react";

function Radio({ name, options, ...rest }) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue } = useField(name);

  const {
    className,
    inline,
    valid,
    invalid,
    label,
  } = {...rest};

  const labelClasses = classNames(
    "custom-control",
    "custom-radio",
    inline && "custom-control-inline",
    valid && "is-valid",
    invalid && "is-invalid"
  );

  const inputClasses = classNames(
    className,
    "custom-control-input",
    valid && "is-valid",
    invalid && "is-invalid"
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      path: "value",
      ref: inputRefs.current,
      getValue(refs) {
        return refs.find(ref => ref.checked).value;
      }
    });
  }, [fieldName, registerField]);

  return (
    <>
      <label>{label}</label>
      <FormGroup>
      {options.map((option, index) => (
          <label key={option.id} className={labelClasses}>
            <input
              defaultValue={defaultValue}
              ref={elRef => (inputRefs.current[index] = elRef)}
              type="radio"
              className={inputClasses}
              name={fieldName}
              value={option.id}
            />
            <label className="custom-control-label" />
            <span className="custom-control-description">{option.label}</span>
          </label>
      ))}
      </FormGroup>
    </>
  );
}

Radio.propTypes = {
  /**
   * The class name.
   */
  className: PropTypes.string,
  /**
   * Whether it is inline, or not.
   */
  inline: PropTypes.bool,
  /**
   * Whether it is valid, or not.
   */
  valid: PropTypes.bool,
  /**
   * Whether it is invalid, or not.
   */
  invalid: PropTypes.bool,
  /**
   * The inner ref.
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.string
  ])
};

export default Radio;
