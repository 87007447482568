import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import HeaderNavbar from "../components/layout/HeaderNavbar/HeaderNavbar";
import MainFooter from "../components/layout/MainFooter";

import { LAYOUT_TYPES } from "../utils/constants";

import { UsersService } from '~/services/WebliniaERPAPI';
import { useAuth } from '~/hooks/Auth';

const HeaderNavigation = ({ children }) => {
  const { enterprise, user } = useAuth();

  const [menuItens, setMenuItens] = useState(null);

  useEffect(() => {
    loadMenuItens();
  }, []);

  const loadMenuItens = async () => {
    const apiService = new UsersService();
    try {
      let params = [{
        key: 'id_empreendimento',
        value: enterprise.id_empreendimento
      }, {
        key: 'cod_versao',
        value: '2.0.0'
      }];
      const { data } = await apiService.getMenuItens(user.id, params);
      setMenuItens(data);
    }
    catch(error) {
      console.log(error);
    }
  }

  return (
    <Container fluid>
      <Row>
        <Col tag="main" className="main-content p-0" lg="12" md="12" sm="12">
          <MainNavbar layout={LAYOUT_TYPES.HEADER_NAVIGATION} />
          <HeaderNavbar items={menuItens} />
            {children}
          <MainFooter />
        </Col>
      </Row>
    </Container>
  );
}

export default HeaderNavigation;