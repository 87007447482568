import api from '../api';

class Service extends api {
    getVendasAnaliticoVendedorPorAno = (id_empreendimento, year, month, offset, limit, params) => {
        let url_params = [{
            key: 'ven->id_empreendimento',
            value: id_empreendimento
        }];
        if (params && params.length > 0)
            url_params = [...url_params, ...params];
        let offsetLimit = (offset && limit) ? `/${offset}/${limit}` : '';
        let qs = this.generateQueryString(url_params);
        return this.api.get(`/relatorio/vendas/consolidado/vendedor/mensal/${year}/${month}${offsetLimit}${qs}`);
    }

    getEntryTaxes = (url_params, id_empreendimento, offset, limit) => {
        let offsetLimit = (offset && limit) ? `/${offset}/${limit}` : '';
        let qs = this.generateQueryString(url_params);
        return this.api.get(`/estoque/entradas/${id_empreendimento}${offsetLimit}${qs}`);
    }
}

export default Service;
