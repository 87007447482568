import api from '../api';

class Service extends api {

    getAllNotasRecebidas = (offset, limit, params) => {

        // let offsetLimit = (offset && limit) ? `/${offset}/${limit}` : '';

        let url_params = this.generateQueryString(params);

        return this.api.get(`/nfe/recebidas/get/${offset}/${limit}${url_params}`);
    }

    validateNotaFiscal = (key_note,params) => {
        
        let url_params = this.generateQueryString(params);

        return this.api.get(`/nfe/recebida/arquivo/${key_note}/validar${url_params}`);
    }

    getUrlArquivoNotaRecebida = (key_note,tipo_arquivo,params) => {
        
        let url_params = this.generateQueryString(params);

        return this.api.get(`/nfe/recebida/arquivo/${key_note}/url/${tipo_arquivo}${url_params}`);
    }

    getDetailsArquivoNotaRecebida = (key_note,params) => {
        
        let url_params = this.generateQueryString(params);

        return this.api.get(`/nfe/recebida/arquivo/${key_note}/details${url_params}`);
    }

    getByFlgImportada = (id) => {
        return this.api.get(`/nfe/recebida/${id}/get/import`);
    }
}

export default Service;