import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    ButtonGroup,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    CardHeader,
    ListGroupItem
} from "shards-react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form } from '@unform/web';
import { Input } from '../components/unform';
import PageTitle from "../components/common/PageTitle";
import { Select } from '../components/unform';
import { numberFormat } from '~/utils/formatValue';
import { TableExport } from 'tableexport';
import _ from 'lodash';

import { 
    getBaseUrlWebOld, 
    generateQueryString 
} from '~/utils/functions';

import * as Yup from 'yup';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';
import getValidationErrors from '../utils/getValidationErrors';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { useAuth } from '~/hooks/Auth';

import { 
    BIService,
    ConfigurationsService
} from '~/services/WebliniaERPAPI';


function PageView(props) {
    const formRef = useRef(null);
    
    const { addToast } = useToasts();
    const { user, enterprise } = useAuth();

    const service = new BIService();
    const serviceConf = new ConfigurationsService();

    const [configurations, setConfigurations] = useState(null);

    const [hideFilters, setHideFilters ] = useState(false);
    const [hideBillsReceived, setHideBillsReceived ] = useState(false);
    const [hideBillsPay, setHideBillsPay ] = useState(false);
    const [isLoadingBillsPay, setIsLoadingBillsPay ] = useState(false);
    const [isLoadingBillsReceived, setisLoadingBillsReceived ] = useState(false);

    const [billsPay, setBillsPay] = useState(null);
    const [billsReceived, setBillsReceived] = useState(null);
    const [dtaInicial, setDtaInicial] = useState(null);
    const [dtaFinal, setDtaFinal] = useState(null);

    const [total30DaysPay, setTotal30DaysPay] = useState(0);
    const [total60DaysPay, setTotal60DaysPay] = useState(0);
    const [total90DaysPay, setTotal90DaysPay] = useState(0);
    const [total120DaysPay, setTotal120DaysPay] = useState(0);
    const [total150DaysPay, setTotal150DaysPay] = useState(0);
    const [total150MoreDaysPay, setTotal150MoreDaysPay] = useState(0);

    const [total30DaysReceived, setTotal30DaysReceived] = useState(0);
    const [total60DaysReceived, setTotal60DaysReceived] = useState(0);
    const [total90DaysReceived, setTotal90DaysReceived] = useState(0);
    const [total120DaysReceived, setTotal120DaysReceived] = useState(0);
    const [total150DaysReceived, setTotal150DaysReceived] = useState(0);
    const [total150MoreDaysReceived, setTotal150MoreDaysReceived] = useState(0);

    const handleResetFilter = () => {
        setDtaInicial(null);
        setDtaFinal(null);
    };

    const handleApplyFilters = () => {
        getBillsPay();
        getBillsReceive();
    };

    useEffect(() => {
        getConfiguration();
    }, []);

    const getConfiguration =  async () => {
        try {

            const conf = await serviceConf.getConfigurations(enterprise.id_empreendimento);

            setConfigurations(conf);
           
        }catch (err) {
            console.log(err)
        }
    }

    const getBillsPay = useCallback(async () => {
        try {

            setIsLoadingBillsPay(true);

            let paramsView = configurations.data.id_bi_view_bills_pay;

            let urlParams = [{
                key:'id_view',
                value: paramsView
            },{
                key: "data[exp]",
                value: `BETWEEN '${dtaInicial} 00:00:00' AND '${dtaFinal} 23:59:59'`
            },{
                key: 'id_empreendimento', 
                value: enterprise.id_empreendimento
            }]

            const{ data : response }= await service.getViewData(paramsView, urlParams);

            let total30 = 0;
            let total60 = 0;
            let total90 = 0;
            let total120 = 0;
            let total150 = 0;
            let totalMais150 = 0;

            response.data.forEach(res => {
                total30 += res.vlr_30_dias;
                total60 += res.vlr_60_dias;
                total90 += res.vlr_90_dias;
                total120 += res.vlr_120_dias;
                total150 += res.vlr_150_dias;
                totalMais150 += res.vlr_acima_150_dias;
            })

            setTotal30DaysPay(total30);
            setTotal60DaysPay(total60);
            setTotal90DaysPay(total90);
            setTotal120DaysPay(total120);
            setTotal150DaysPay(total150);
            setTotal150MoreDaysPay(totalMais150);

            setBillsPay(response.data);

            setIsLoadingBillsPay(false);
           
        }catch (err) {
            console.log(err);
            setIsLoadingBillsPay(false);
        }
    }, [configurations,dtaInicial,dtaFinal]);

    const getBillsReceive = useCallback(async () => {
        try {
            
            setisLoadingBillsReceived(true);

            let paramsView = configurations.data.id_bi_view_bills_received;

            let urlParams = [{
                key:'id_view',
                value: paramsView
            },{
                key: "data[exp]",
                value: `BETWEEN '${dtaInicial} 00:00:00' AND '${dtaFinal} 23:59:59'`
            },{
                key: 'id_empreendimento', 
                value: enterprise.id_empreendimento
            }]

            const{ data : response } = await service.getViewData(paramsView, urlParams);

            let total30 = 0;
            let total60 = 0;
            let total90 = 0;
            let total120 = 0;
            let total150 = 0;
            let totalMais150 = 0;

            response.data.forEach(res => {
                total30 += res.vlr_30_dias;
                total60 += res.vlr_60_dias;
                total90 += res.vlr_90_dias;
                total120 += res.vlr_120_dias;
                total150 += res.vlr_150_dias;
                totalMais150 += res.vlr_acima_150_dias;
            })

            setTotal30DaysReceived(total30);
            setTotal60DaysReceived(total60);
            setTotal90DaysReceived(total90);
            setTotal120DaysReceived(total120);
            setTotal150DaysReceived(total150);
            setTotal150MoreDaysReceived(totalMais150);

            setBillsReceived(response.data);

            setisLoadingBillsReceived(false);
           
        }catch (err) {
            console.log(err);
            setisLoadingBillsReceived(false);
        }
    }, [configurations,dtaInicial,dtaFinal]);

    const hideList = async(list, n) => {
    
            let _bills = _.clone(list);

        
            if(list.hideList && list.hideList == true){
                _bills.hideList = false;
            }
            else if(!list.hideList || list.hideList == false){
                _bills.hideList = true;
            }

        if(n == 1){

            if(_bills.hideList == true){
                setHideBillsReceived(true);
            }
            else{
                setHideBillsReceived(false);
            }

            setBillsReceived(_bills);
        }else{

            if(_bills.hideList == true){
                setHideBillsPay(true);
            }
            else{
                setHideBillsPay(false);
            }
            setBillsPay(_bills);
        }
    }

    return (
        <>
            <Container fluid>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Fluxo de Caixa' 
                        subtitle='Relátorios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="9">
                        <Card small className='mb-3'>
                            <Form ref={formRef}>
                                <CardBody>
                                    <table id="table-taxes" className='table table-bordered table-condensed table-hover mb-0'>
                                        <thead>
                                            <tr>
                                                <th width='400'></th>
                                                <th width='50'></th>
                                                <th className='text-right'>30 dias</th>
                                                <th className='text-right'>60 dias</th>
                                                <th className='text-right'>90 dias</th>
                                                <th className='text-right'>120 dias</th>
                                                <th className='text-right'>150 dias</th>
                                                <th className='text-right'>Acima 150 dias</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className='text-white' style={{ backgroundColor: '#3D5170' }}>
                                                <th>
                                                    RECEITAS
                                                </th>
                                                <th className='text-center'>
                                                    {billsReceived && (
                                                        <a href="#" className="text-white text-center" onClick={() => hideList(billsReceived,1)}>
                                                            {(hideBillsReceived) && 
                                                                <span>
                                                                    &nbsp;
                                                                    <i class="fa fa-chevron-down"></i>
                                                                </span>
                                                            }
                                                            {(!hideBillsReceived) && 
                                                                <span>
                                                                    &nbsp;
                                                                    <i class="fa fa-chevron-up"></i>
                                                                </span>
                                                            }
                                                        </a>
                                                    )}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total30DaysReceived && numberFormat(total30DaysReceived,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total60DaysReceived && numberFormat(total60DaysReceived,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total90DaysReceived && numberFormat(total90DaysReceived,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total120DaysReceived && numberFormat(total120DaysReceived,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total150DaysReceived && numberFormat(total150DaysReceived,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total150MoreDaysReceived && numberFormat(total150MoreDaysReceived,2 ,',','.')}
                                                </th>
                                            </tr>
                                            {isLoadingBillsReceived ? (
                                                <span className='text-center'>
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;
                                                    Aguarde, buscando informações...
                                                </span>
                                            ) : (
                                                <>
                                                    {billsReceived && billsReceived.map(received => (
                                                        <tr style={{ display: (billsReceived && billsReceived.hideList) ? 'none' : ''}}>
                                                            <td colSpan={2}>{received.nome}</td>
                                                            <td className='text-right'>R$ {received.vlr_30_dias ? numberFormat(received.vlr_30_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {received.vlr_60_dias ? numberFormat(received.vlr_60_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {received.vlr_90_dias ? numberFormat(received.vlr_90_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {received.vlr_120_dias ? numberFormat(received.vlr_120_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {received.vlr_150_dias ? numberFormat(received.vlr_150_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {received.vlr_acima_150_dias ? numberFormat(received.vlr_acima_150_dias,2 ,',','.') : 0}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                        <tbody>
                                            <tr className='text-white' style={{ backgroundColor: '#3D5170' }}>
                                                <th>
                                                    DESPESAS
                                                </th>
                                                <th className='text-center'>
                                                    {billsPay && (
                                                        <a href="#" className="text-white text-center"  onClick={() => hideList(billsPay,2)}>
                                                        {(hideBillsPay) && 
                                                            <span>
                                                                &nbsp;
                                                                <i class="fa fa-chevron-down"></i>
                                                            </span>
                                                        }
                                                        {(!hideBillsPay) && 
                                                            <span>
                                                                &nbsp;
                                                                <i class="fa fa-chevron-up"></i>
                                                            </span>
                                                        }
                                                    </a>
                                                    )}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total30DaysPay && numberFormat(total30DaysPay,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total60DaysPay && numberFormat(total60DaysPay,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total90DaysPay && numberFormat(total90DaysPay,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total120DaysPay && numberFormat(total120DaysPay,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total150DaysPay && numberFormat(total150DaysPay,2 ,',','.')}
                                                </th>
                                                <th className='text-right'>
                                                    R$ {total150MoreDaysPay && numberFormat(total150MoreDaysPay,2 ,',','.')}
                                                </th>
                                            </tr>
                                            {isLoadingBillsPay ? (
                                                <span className="text-center">
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;
                                                    Aguarde, buscando informações...
                                                </span>
                                            ) : (
                                                <>
                                                    {billsPay && billsPay.map(pay => (
                                                        <tr style={{ display: (billsPay && billsPay.hideList) ? 'none' : ''}}>
                                                            <td colSpan={2}>{pay.dsc_natureza_operacao}</td>
                                                            <td className='text-right'>R$ {pay.vlr_30_dias ? numberFormat(pay.vlr_30_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {pay.vlr_60_dias ? numberFormat(pay.vlr_60_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {pay.vlr_90_dias ? numberFormat(pay.vlr_90_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {pay.vlr_120_dias ? numberFormat(pay.vlr_120_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {pay.vlr_150_dias ? numberFormat(pay.vlr_150_dias,2 ,',','.') : 0}</td>
                                                            <td className='text-right'>R$ {pay.vlr_acima_150_dias ? numberFormat(pay.vlr_acima_150_dias,2 ,',','.') : 0}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className='text-right' colSpan={2}>Totais :</th>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: (parseFloat(numberFormat((total30DaysReceived - total30DaysPay) ,2,',','.')) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total30DaysPay && total30DaysReceived) && numberFormat((total30DaysReceived - total30DaysPay) ,2,',','.')}
                                                </td>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: (parseFloat(numberFormat((total60DaysReceived - total60DaysPay) ,2,',','.')) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total60DaysPay && total60DaysReceived) && numberFormat((total60DaysReceived - total60DaysPay) ,2,',','.')}
                                                </td>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: (parseFloat(numberFormat((total90DaysReceived - total60DaysPay) ,2,',','.')) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total90DaysPay && total90DaysReceived) && numberFormat((total90DaysReceived - total90DaysPay) ,2,',','.')}
                                                </td>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: (parseFloat(numberFormat((total120DaysReceived - total120DaysPay) ,2,',','.')) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total120DaysPay && total120DaysReceived) && numberFormat((total120DaysReceived - total120DaysPay) ,2,',','.')}
                                                </td>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: ((total150DaysReceived - total150DaysPay) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total150DaysPay && total150DaysReceived) && numberFormat((total150DaysReceived - total150DaysPay) ,2,',','.')}
                                                </td>
                                                <td 
                                                    className='text-right'
                                                    style={{ color: (parseFloat(numberFormat((total150MoreDaysReceived - total150MoreDaysPay) ,2,',','.')) >= 0) ? '#008000' : '#ff0000'}}
                                                >
                                                    R$ {(total150MoreDaysPay && total150MoreDaysReceived) && numberFormat((total150MoreDaysReceived - total150MoreDaysPay) ,2,',','.')}
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>

                    <Col md="3">
                        <Card className='p-0'>
                            <CardHeader className="border-bottom p-3">
                                <Row>
                                    <Col md='6'>
                                        <h6 className="M-0">
                                            <i className='fa fa-filter'></i> Opções de Filtro
                                        </h6>
                                    </Col>
                                    <Col sm='6' className={'text-right'}>
                                        <small>
                                            <a href="#" className="text-primary"  onClick={() => setHideFilters(!hideFilters)}>
                                                {(hideFilters) && 
                                                    <span>
                                                        Exibir&nbsp;
                                                        <i class="fa fa-chevron-down"></i>
                                                    </span>
                                                }
                                                {(!hideFilters) && 
                                                    <span>
                                                        Ocultar&nbsp;
                                                        <i class="fa fa-chevron-up"></i>
                                                    </span>
                                                }
                                            </a>
                                        </small>
                                    </Col>
                                </Row>
                            </CardHeader>

                            <CardBody className='p-0' style={{ display: (hideFilters) ? 'none' : ''}}>
                                <Container fluid className='file-manager__filters border-bottom'>
                                    <Form ref={formRef}>
                                        <Row>
                                            <Col md='6'>
                                                <Input
                                                    type='date'
                                                    name='dtaInicial'
                                                    label='Data Inicial'
                                                    defaultValue={dtaInicial}
                                                    value={dtaInicial}
                                                    onChange={e => setDtaInicial(e.target.value)}
                                                />
                                            </Col>
                                            <Col md='6'>
                                                <Input
                                                    type='date'
                                                    name='dtaFinal'
                                                    label='Data Final'
                                                    defaultValue={dtaFinal}
                                                    value={dtaFinal}
                                                    onChange={e => setDtaFinal(e.target.value)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm='6' className={'text-center'}>
                                                <Button 
                                                    outline theme='secondary' 
                                                    className="ml-2"
                                                    onClick={handleResetFilter}
                                                >
                                                    <i className="fa fa-times"></i> Limpar Filtro
                                                </Button>
                                            </Col>
                                            <Col sm='6' className={'text-center'}>
                                                <Button 
                                                    theme="accent" 
                                                    className="ml-2"
                                                    onClick={handleApplyFilters}
                                                    
                                                >
                                                    <i className="fa fa-filter"></i> Aplicar Filtro
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Container>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default PageView
