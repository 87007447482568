export const KEYS = {
  USER_LOGGED: 'br.net.ngweb.active_user.profile',
  USER_LOGGED_ENTERPRISE: 'br.net.ngweb.active_user.enterprises',
  USER_LOGGED_COD_ENTERPRISE: 'br.net.ngweb.active_user.cod_enterprises',
  USER_LOGGED_MODULES: 'br.net.ngweb.active_user.modules',
}

export class Session {
  static saveData = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
      return true;
    } catch (error) {
      alert('Erro ao ao gravar ' + key);
      return false;
    }
  }

  static getData = (key) => {
    try {
      const value = sessionStorage.getItem(key);
      return JSON.parse(value);
    } catch (error) {
      alert('Erro ao ao buscar ' + key);
      return false;
    }
  }
}

export class Local {
  static saveData = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      alert('Erro ao ao gravar ' + key);
    }
  }

  static getData = (key) => {
    try {
      const value = localStorage.getItem(key);
      return JSON.parse(value);
    } catch (error) {
      alert('Erro ao ao buscar ' + key);
    }
  }
}
