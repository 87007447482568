import api from '../api';

class ProductsService extends api {

  getAll = (offset, limit, params) => {
    let req_url = `/produtos`;

    if(typeof(offset) !== 'undefined' && offset !== null)
      req_url += `/${offset}`;

    if(typeof(limit) !== 'undefined' && limit !== null)
      req_url += `/${limit}`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
  
  getProductInputs = (id_produto, params) => {
    let req_url = `/produto/${id_produto}/insumos`;

    if(params)
      req_url += this.generateQueryString(params);

    return this.api.get(req_url);
  }
  
  readFileProductSKUNetshoes = (data) => {
    return this.api.post('/produtos/sku-netshoes/read-file', data);
  };
  
  saveProductSKUNetshoes = (data) => {
    return this.api.post('/produtos/sku-netshoes/save', JSON.stringify(data));
  };

}

export default ProductsService;