import axios from 'axios';

import { KEYS } from '../Storage/storage.service';


export const app_bases_url = {
  '999': `http://${window.location.hostname}/webliniaerp-api/`,
  '001': "https://api.clientes.ngweb.net.br/",
  '002': "https://api.crn.ngweb.net.br/",
  '020': "http://ngclientes.srvngtst1.virtuaserver.com.br/api/",
}

export default class WebliniaERPAPI {
  constructor(cod_enterprise) {
    if(!cod_enterprise)
      cod_enterprise = localStorage.getItem(KEYS.USER_LOGGED_COD_ENTERPRISE);

    let baseURL = app_bases_url[cod_enterprise];
    
    if(window.location.hostname !== 'localhost' && window.location.hostname.indexOf('192.168') === -1 && window.location.hostname.indexOf('25.41') === -1)
      baseURL = app_bases_url['002']

    if(baseURL){
      this.api = axios.create({ baseURL });
      
      this.api.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';

      this.api.interceptors.request.use(
        (config) => {
          if (!config.url.endsWith('/user/auth') && config.method.toLowerCase() !== 'options') {
            let user_logged = localStorage.getItem(KEYS.USER_LOGGED);
            if (user_logged) {
              user_logged = JSON.parse(user_logged);
              config.headers['Authorization'] = `Bearer ${user_logged.access_token}`;
              config.headers['x-id-usuario'] = user_logged.id;
              let enterprise = localStorage.getItem(KEYS.USER_LOGGED_ENTERPRISE);
              if(enterprise) {
                enterprise = JSON.parse(enterprise);
                config.headers['x-id-empreendimento'] = enterprise.id_empreendimento;
              }
            }
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
      );

    } else {
      console.warn('codigo do empreendimento errado');
    }

  }

  mountArrayOfColumns = (params) => {
    let columns = '';
    params.forEach((param, key) => {
      if ((key+1) === params.length)
        columns += param.dataBaseColumn + ' AS ' + param.accessor
      else
        columns += param.dataBaseColumn + ' AS ' + param.accessor + ','
    });
    return columns;
  }

  generateQueryString(params) {
    var request_url = "";
    params.forEach((param, key) => {
      if ((param.key) === 'columns'){
        let columns = this.mountArrayOfColumns(param.value);
        if (key === 0)
          request_url += '?' + param.key + '=' + columns;
        else
          request_url += '&' + param.key + '=' + columns;
      }else {
        if (key === 0)
          request_url += '?' + param.key + '=' + param.value;
        else
          request_url += '&' + param.key + '=' + param.value;
      }
    });
    return request_url;
  }
}
