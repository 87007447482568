import api from '../api';

class Service extends api {
    save = (data) => {
        return this.api.post(`/maquineta`, JSON.stringify(data));
    }

    update = (id, data) => {
        return this.api.post(`/maquineta/${id}/update`, JSON.stringify(data));
    }

    delete = (id) => {
        return this.api.get(`maquineta${id}/delete`);
    }

    getById = (id) => {
        return this.api.get(`/maquineta/${id}`)
    }

    getAll = (params, offset, limit) => {
        let req_url = '/maquinetas';
        if(typeof(offset) !== 'undefined' && offset !== null)
            req_url += `/${offset}`;

        if(typeof(limit) !== 'undefined' && limit !== null)
            req_url += `/${limit}`;
        
        if(params)
          req_url += this.generateQueryString(params);
        
        return this.api.get(req_url);
    }

    getFlags = (params) => {
        let req_url = 'bandeiras';

        if(params)
          req_url += this.generateQueryString(params);

        return this.api.get(req_url);

    }
}

export default Service;