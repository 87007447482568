import api from '../api';

class DashboardService extends api {

    //Total Faturado
    getTotalFaturamento(params) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/total_faturamento/dashboard${url_params}`);
    }

    //CPV
    getCustoProdutoVendido(params, first_date, last_date) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/total_custo_produtos_vendidos/dashboard/${first_date}/${last_date}${url_params}`);
    }

    //Custo de Estoque
    getCustoTotalEstoque(params) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/custo/total/estoque/dashboard${url_params}`);
    }

    //Ticket Medio
    getCountVenda(params, first_date, last_date) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/count_vendas/dashboard/${first_date}/${last_date}${url_params}`);
    }

    //Clientes Atendidos
    getClientesAtendidos(params, data_inicial, data_final) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/clientes/atendidos/dashboard/${data_inicial}/${data_final}${url_params}`);
    }

    //Top Empreendimentos que mais faturaram
    getTopFaturamentoPorEmpreendimento(params, data_inicial, data_final) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/faturamento/empreendimento/dashboard/${data_inicial}/${data_final}${url_params}`);
    }

    //Buscar as configurações do empreendimento
    getConfiguracaoEmpreendimento(id_empreendimento, nme_chave_configuracao = null){
        return this.api.get(`/configuracoes/${id_empreendimento}/${nme_chave_configuracao}`);
    }

    //Historio para os graficos dos indicadores
    getHistoricoIndicadoresGrafico(params, data_inicial, data_final){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/historico/indicadores/dashboard/${data_inicial}/${data_final}${url_params}`);
    }

    //Top 10 Produtos
    getTop10Products(params){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/dashboard/vendas/top10/produto${url_params}`);
    }

    //Top 10 Fabricantes
    getTop10Fabricantes(params){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/dashboard/vendas/top10/fabricante${url_params}`);
    }

    //Saldo Devedor
    getSaldoDevedor(params){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/usuarios/saldodevedor${url_params}`);
    }

    //Taxa Maquineta
    getDobbyFee(params, data_inicial, data_final){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/total_taxa_maquinetas/dashboard/${data_inicial}/${data_final}${url_params}`);
    }

    //Vendas de Desconto
    getVendaDesconto(params, data_inicial, data_final) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/desconto/vendas/dashboard/${data_inicial}/${data_final}${url_params}`);
    }

    //Top 10 Vendedores
    getTop10Vendedores(params) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/dashboard/vendas/top10/vendedores${url_params}`);
    }

    //Formas Pagamento
    getFormasPagamento(params) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/formas/pagamento/dashboard${url_params}`);
    }

    //Venda Hora a Hora
    getVendaHoraHora(params) {
        let url_params = this.generateQueryString(params);
        return this.api.get(`/venda-a-venda/indicadores/dashboard${url_params}`);
    }

    getByIdGerente(id,params){
        let url_params = this.generateQueryString(params);
        return this.api.get(`/cliente/${id}/preposto${url_params}`);
    }

}

export default DashboardService;
