import React, { useCallback, useRef, useEffect, useState } from 'react'
import { NavLink, Link } from "react-router-dom";
import { Form } from '@unform/web';
import {
	Container,
	Row,
	Col,
	Card,
	Button,
	Badge,
	CardHeader,
	CardBody,
	CardFooter,
	ListGroup,
	ListGroupItem,
	ButtonGroup,
	FormGroup
} from 'shards-react';
import moment from 'moment';
import { Input, Select } from '../components/unform';
import PageTitle from '../components/common/PageTitle';
import { useToasts } from 'react-toast-notifications';

import { 
	OPService
} from '~/services/WebliniaERPAPI';
import { useAuth } from '~/hooks/Auth';

function PageView() {
	const formRef = useRef(null);
	const userLoggedData = useAuth();
	const { addToast } = useToasts();

	const [isLoading, setIsLoading] = useState(false);
	const [pagination, setPagination] = useState(null);
	const [statusList, setStatusList] = useState([]);
	const [orders, setOrders] = useState(null);
	const [numOP, setNumOP] = useState(null);
	const [numLote, setNumLote] = useState(null);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [selectedDateField, setSelectedDateField] = useState(null);
	const [selectedStatus, setSelectedStatus] = useState(null);

	const dateFieldsFilter = [{
		id: 'dta_create', 
		nome: 'Data de Criação da OP'
	},{
		id: 'dta_emissao', 
		nome: 'Data de Emissão da OP'
	},{
		id: 'dta_inicio_previsto', 
		nome: 'Data de Início Prevista da OP'
	},{
		id: 'dta_inicio_realizado', 
		nome: 'Data de Início Real da OP'
	},{
		id: 'dta_conclusao_previsto', 
		nome: 'Data de Conclusão Prevista da OP'
	},{
		id: 'dta_conclusao_realizado', 
		nome: 'Data de Conclusão Real da OP'
	}];

	useEffect(()=>{
		loadOrders(0,15);
		loadStatusOPList();
	},[]);

	const loadStatusOPList = async() => {
		const opService = new OPService();
		try {
			const { data } = await opService.getStatusList();
			setStatusList(data.map(status => {
				status.nome = status.nome_status;
				return status;
			}));
		}
		catch(error) {
			console.log(error);
		}
	}

	const loadOrders = async (offset, limit) => {
		try {
			let params = [{
				key: 'top->id_empreendimento',
				value: userLoggedData.enterprise.id_empreendimento
			}, {
				key: 'top->flg_excluido',
				value: 0
			}, {
				key: 'order_by',
				value: 'top.dta_create DESC'
			}];

			if ( numOP ) {
				params.push({
					key: `top->id`,
					value: numOP
				});
			}
			
			if ( numLote ) {
				params.push({
					key: `top->num_lote`,
					value: numLote
				});
			}

			if ( startDate && !endDate ) {
				addToast('Você deve informar a data final que deseja realizar o filtro', {
					placement: 'bottom-right',
					appearance: 'warning',
					autoDismiss: true
				});
				return false;
			}
			else if ( !startDate && endDate ) {
				addToast('Você deve informar a data inicial que deseja realizar o filtro', {
					placement: 'bottom-right',
					appearance: 'warning',
					autoDismiss: true
				});
				return false;
			}
			else if ( startDate && endDate ) {
				if ( selectedDateField ) {
					params.push({
						key: `(${selectedDateField}[exp]`,
						value: `BETWEEN '${startDate}' AND '${endDate}')`
					});
				}
				else {
					addToast('Você deve informar o campo de data que deseja realizar o filtro', {
						placement: 'bottom-right',
						appearance: 'warning',
						autoDismiss: true
					});
					setIsLoading(false);
					return false;
				}
			}

			if ( selectedStatus ) {
				params.push({
					key: 'top->id_status',
					value: selectedStatus
				});
			}
			else {
				params.push({
					key: 'top->id_status[exp]',
					value: '<> 7'
				});
			}

			setOrders(null);
			setPagination(null);
			setIsLoading(true);
			
			const opService = new OPService();
			const { data: { pagination: _pagination, records } } = await opService.getAll(offset, limit, params);
			
			setIsLoading(false);
			setOrders(records);
			setPagination(_pagination);
		} 
		catch (error) {
			setIsLoading(false);
			console.log(error);
			const { response: { status } } = error;
			if (status === 404) {
				addToast('Nenhum registro encontrado para o filtro informado!', {
					placement: 'bottom-right',
					appearance: 'info',
					autoDismiss: true
				});
			}
		}
	}

	const clearFilters = () => {
		setNumOP(null);
		setNumLote(null);
		setStartDate(null);
		setEndDate(null);
		setSelectedDateField(null);
		setSelectedStatus(null);
		formRef.current.reset();
		loadOrders(0,15);
	}

	const handleSubmitFilter = useCallback(async formData => {
		
	}, [startDate, endDate, selectedStatus]);

	const openOPSheet = (op_data) => {
		window.open(`https://crn.ngweb.net.br/reports?template=production-order-sheet&id=${op_data.id}`, '_blank');
	}

	return (
		<>
			<Container fluid className='main-content-container px-4 pb-4'>
				<Row noGutters className='page-header py-4'>
					<PageTitle 
						title='Grid de Ordens de Produção' 
						subtitle='Controle de Produção' 
						className='text-sm-left'
					/>
					<Col xs="12" sm="4" className="col d-flex align-items-center">
						&nbsp;
					</Col>

					<Col sm="4" className="d-flex justify-content-end">
						<Button outline theme="success" tag={NavLink} to={`/pcp/op/new/`} style={{ lineHeight: '2.4em' }}>
							<i className="material-icons">add_circle</i> Abrir Nova O.P.
						</Button>
					</Col>
				</Row>

				<Card small className='mb-3'>
					<CardHeader className="border-bottom">
						<h6 className="m-0">
							<i className='fa fa-filter'></i> Opções de Filtro
						</h6>
					</CardHeader>
					
					<CardBody>
						<Container fluid>
							<Form ref={formRef} onSubmit={handleSubmitFilter}>
								<Row>
									<Col sm="2">
										<Input
											type='text'
											name='num_op'
											label='Nº da O.P.'
											onChange={(e) => setNumOP(e.target.value)}
										/>
									</Col>

									<Col sm="2">
										<Input
											type='text'
											name='num_lote'
											label='Nº do Lote'
											onChange={(e) => setNumLote(e.target.value)}
										/>
									</Col>

									<Col sm="2">
										<Input
											type='date'
											name='startDate'
											label='Data Inicial'
											onChange={(e) => setStartDate(e.target.value)}
										/>
									</Col>

									<Col sm='2'>
										<Input
											type='date'
											name='endDate'
											label='Data Final'
											onChange={(e) => setEndDate(e.target.value)}
										/>
									</Col>

									{(startDate && endDate) && (
										<Col sm='2'>
											<Select
												name='date_field'
												label='Campo de Data'
												data={dateFieldsFilter}
												onChange={(e) => {setSelectedDateField(e.target.value)}}
											/>
										</Col>
									)}

									<Col sm='2'>
										<Select
											name='status'
											label='Status'
											data={statusList}
											onChange={(e) => {setSelectedStatus(e.target.value)}}
										/>
									</Col>

									<Col className='d-flex ml-auto my-auto justify-content-end'>
										<FormGroup className="pt-4">
											<Button
												type='button' theme='secondary' outline
												onClick={() => clearFilters()}
											>
												<i className='fa fa-times'></i> Limpar
											</Button>

											<Button 
												type='submit' theme='accent' className="ml-2"
												onClick={() => loadOrders(0,15)}
											>
												<i className='fa fa-filter'></i>
											</Button>
										</FormGroup>
									</Col>
								</Row>
							</Form>
						</Container>
					</CardBody>
				</Card>

				{isLoading && (
					<Row className="mt-4">
						<Col lg={12} className="text-center">
							<i className="fa fa-spinner fa-spin"></i> Aguarde, carregando...
						</Col>
					</Row>
				)}

				<Row className="mt-4">
					{orders && orders.map(order => {
						let status_color = 'primary';
						let action_button = null;

						switch(parseInt(order.id_status, 10)) {
							case 4:	// Rascunho
								status_color = 'secondary';
								break;
							case 1:	// Pendente / Aguardando Produção
								status_color = 'warning';
								break;
							case 5:	// Iniciada / Pendente Separação de Insumos
								status_color = 'info';
								break;
							case 6:	// Iniciada / Em Separação de Insumos
								status_color = 'java';
								break;
							case 2:	// Iniciada / Em Produção
								status_color = 'royal-blue';
								break;
							case 3:	// Produção Finalizada
								status_color = 'success';
								break;
							case 7:	// Produção Cancelada
								status_color = 'danger';
								break;
						}
						
						if (!order.itens[0].img_produto)
							order.itens[0].img_produto = '';

						let img_url = `https://crn.ngweb.net.br/${order.itens[0].img_produto.replace('/home/webliniaerp/www/', '')}`;

						return (
							<Col lg="4" className="mb-3">
								<Card small className="card-post card-post--aside card-post--1">
									<div
										className="card-post__image"
										style={{ backgroundImage: `url('${img_url}')`, backgroundSize: 'contain' }}
									>
										{/* <div className="card-post__author d-flex">
											<a
												href="#"
												className="card-post__author-avatar card-post__author-avatar--small"
												style={{ backgroundImage: `url('https://pbs.twimg.com/profile_images/1092469192878546944/t2Dm8o2p_400x400.jpg')` }}
											>
												Written by Anna Ken
											</a>
										</div> */}
									</div>
									<CardBody>
										<p className="mb-2">
											<Badge pill theme={status_color}>
												{`#${order.id} | ${order.dsc_status}`}
											</Badge>
										</p>
										<h6 className="card-title mb-1">
											<Link to={`/pcp/op/new?id=${order.id}`} className="text-fiord-blue">
												{order.itens[0].qtd_item}x&nbsp;
												{order.itens[0].nme_produto}
											</Link>
											<br/>
											<small style={{ fontSize: '60%' }}>
												{order.itens[0].nme_fabricante ? order.itens[0].nme_fabricante : 'N/D'}
												&nbsp;•&nbsp;
												{order.itens[0].nme_tamanho ? order.itens[0].nme_tamanho : 'N/D'}
												&nbsp;•&nbsp;
												{order.itens[0].nme_cor_sabor ? order.itens[0].nme_cor_sabor : 'N/D'}
											</small>
										</h6>
										<div className="card-text d-inline-block mb-3">
											<small>
												<i className="material-icons">fact_check</i>
												&nbsp;
												Nº Lote: {order.num_lote ? order.num_lote : 'N/D'}
											</small>

											<br/>

											<small>
												<i className="material-icons">save</i>
												&nbsp;
												{moment(order.dta_create).format('DD/MM/YYYY HH:mm')}
											</small>
											
											<br/>

											<small>
												<i className="material-icons">today</i>
												&nbsp;
												{
													order.dta_inicio_realizado ? moment(order.dta_inicio_realizado).format('DD/MM/YYYY HH:mm') : (
														order.dta_inicio_previsto ? moment(order.dta_inicio_previsto).format('DD/MM/YYYY') : "N/D"
													)
												}
											</small>
											&nbsp;
											<small>
												<i className="material-icons">schedule</i>
												&nbsp;
												{
													order.dta_conclusao_realizado ? moment(order.dta_conclusao_realizado).format('DD/MM/YYYY HH:mm') : (
														order.dta_conclusao_previsto ? moment(order.dta_conclusao_previsto).format('DD/MM/YYYY') : "N/D"
													)
												}
											</small>
										</div>

										<Row>
											<Col>
												<Button outline theme="secondary" size="sm" tag={NavLink} to={`/pcp/op/new?id=${order.id}`}>
													<i className="material-icons">preview</i> Detalhes
												</Button>
												&nbsp;
												<Button outline theme="primary" size="sm" onClick={() => openOPSheet(order)}>
													<i className="material-icons">print</i> Imprimir
												</Button>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						)
					})}
				</Row>

				{(pagination && pagination.length > 0) && (
					<Row>
						<Col sm="12" className="text-center">
							<ButtonGroup>
								{pagination.map((value, index) => (
									<Button
										key={index} theme={value.current ? 'danger' : 'white'}
										onClick={() => { loadOrders(value.offset, value.limit) }}
									>
										{value.index}
									</Button>
								))}
							</ButtonGroup>
						</Col>
					</Row>
				)}
			</Container>
		</>
	)
}

export default PageView;