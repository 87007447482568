import React, {useCallback, useEffect, useRef, useState } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Button,
    CardBody,
    CardFooter,
    FormInput
} from "shards-react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Form } from '@unform/web';
import PageTitle from "../components/common/PageTitle";
import { Select } from '../components/unform';
import NumberFormat from 'react-number-format';
import queryString from 'query-string';
import { numberFormat } from '~/utils/formatValue';
import _ from 'lodash';
import { useToasts } from 'react-toast-notifications';

import { useAuth } from '~/hooks/Auth';

import { 
    SalesComissionService
  } from '~/services/WebliniaERPAPI';
import { set } from 'react-ga';

function PageView(props) {
    const formRef = useRef(null);
    
    const { addToast } = useToasts();
    const { user, enterprise } = useAuth();

    const [isLoadingSearch, setIsLoadingSearch] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [years, setYears] = useState([
        { id: 2021, nome: 2021},
        { id: 2022, nome: 2022},
        { id: 2023, nome: 2023},
        { id: 2024, nome: 2024},
        { id: 2025, nome: 2025},
        { id: 2026, nome: 2026},
        { id: 2027, nome: 2027},
        { id: 2028, nome: 2028},
        { id: 2029, nome: 2029},
        { id: 2030, nome: 2030},
        { id: 2031, nome: 2031}
    ]);
    const [months, setMonths] = useState([
        { id: '01', nome: 'Janeiro'},
        { id: '02', nome: 'Fevereiro'},
        { id: '03', nome: 'Março'},
        { id: '04', nome: 'Abril'},
        { id: '05', nome: 'Maio'},
        { id: '06', nome: 'Junho'},
        { id: '07', nome: 'Julho'},
        { id: '08', nome: 'Agosto'},
        { id: '09', nome: 'Setembro'},
        { id: '10', nome: 'Outubro'},
        { id: '11', nome: 'Novembro'},
        { id: '12', nome: 'Dezembro'}
    ]);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [vlrTotalBrutoComissoes, setVlrTotalBrutoComissoes] = useState(0);
    const [vlrTotalCSLL, setVlrTotalCSLL] = useState(0);
    const [vlrTotalIRRF, setVlrTotalIRRF] = useState(0);
    const [vlrTotalPISCofins, setVlrTotalPISCofins] = useState(0);
    const [vlrTotalISS, setVlrTotalISS] = useState(0);
    const [vlrTotalLiquidoComissoes, setVlrTotalLiquidoComissoes] = useState(0);

    const [items, setItems] = useState(null);

    // const calcularComissaoRepresentantes = async () => {
    //     try {
    //         setIsLoadingSearch(true);
    //         const service = new SalesComissionService();
    //         await service.calcularComissaoRepresentantes(enterprise.id_empreendimento, selectedYear, selectedMonth);
    //     }
    //     catch(err) {
    //         console.log(err);
    //     }

    //     await getApuracaoComissaoRepresentantes();
    // }

    const getApuracaoComissaoRepresentantes = async () => {
        setIsLoadingSearch(true);
        const service = new SalesComissionService();
        try {
            let id_empreendimento = enterprise.id_empreendimento;
            let date_period = `${selectedYear}-${selectedMonth}`;
            let { data: _items } = await service.getApuracaoComissaoRepresentantes(id_empreendimento, date_period);
            let _vlrTotalBrutoComissoes = 0;
            let _vlrTotalLiquidoComissoes = 0;
            _items = _items.map(_item => {
                _item.vlr_liquido = _item.vlr_bruto_comissao;
                _vlrTotalBrutoComissoes += _item.vlr_bruto_comissao;
                _vlrTotalLiquidoComissoes += _item.vlr_liquido;
                return _item;
            });
            setVlrTotalBrutoComissoes(_vlrTotalBrutoComissoes);
            setVlrTotalLiquidoComissoes(_vlrTotalLiquidoComissoes);
            setItems(_items);
            setIsLoadingSearch(false);
        }
        catch(error) {
            setIsLoadingSearch(false);
            setItems(null);
            if (error.response) {
                const { status, data } = error.response;
                switch (status) {
                    case 404: // Not Found
                        // TODO: exibir uma mensagem para o usuario de que nao encontrou comissoes no periodo selecionado
                        addToast('Não encontrado nenhum registro referente ao mês/ano.\nVerifique por favor!', {
                            placement: 'bottom-right',
                            appearance: 'warning',
                            autoDismiss: true
                          });
                        break;
                    default:
                        // TODO: exibir uma mensagem padrao para o usuario
                        addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                            placement: 'bottom-right',
                            appearance: 'error',
                            autoDismiss: true
                          });
                        break;
                }
            }
        }
    }

    const updateTaxValue = (id_vendedor, field_name, field_value) =>{
        let _items = _.cloneDeep(items);
        let _vlrTotalBrutoComissoes = 0;
        let _vlrTotalCSLL = 0;
        let _vlrTotalIRRF = 0;
        let _vlrTotalPISCofins = 0;
        let _vlrTotalISS = 0;
        let _vlrTotalLiquidoComissoes = 0;

        _items = _items.map(_item => {
            _item.vlr_csll       = (_item.vlr_csll) ? _item.vlr_csll : 0;
            _item.vlr_irrf       = (_item.vlr_irrf) ? _item.vlr_irrf : 0;
            _item.vlr_pis_cofins = (_item.vlr_pis_cofins) ? _item.vlr_pis_cofins : 0;
            _item.vlr_iss        = (_item.vlr_iss) ? _item.vlr_iss : 0;

            if (_item.id_vendedor === id_vendedor) {
                _item[field_name] = field_value;
            }
            
            _item.vlr_liquido = (_item.vlr_bruto_comissao - (_item.vlr_csll + _item.vlr_irrf + _item.vlr_pis_cofins + _item.vlr_iss));

            _vlrTotalBrutoComissoes += _item.vlr_bruto_comissao;
            _vlrTotalCSLL += _item.vlr_csll;
            _vlrTotalIRRF += _item.vlr_irrf;
            _vlrTotalPISCofins += _item.vlr_pis_cofins;
            _vlrTotalISS += _item.vlr_iss;
            _vlrTotalLiquidoComissoes += _item.vlr_liquido;

            return _item;
        });
        setVlrTotalBrutoComissoes(_vlrTotalBrutoComissoes);
        setVlrTotalCSLL(_vlrTotalCSLL);
        setVlrTotalIRRF(_vlrTotalIRRF);
        setVlrTotalPISCofins(_vlrTotalPISCofins);
        setVlrTotalISS(_vlrTotalISS);
        setVlrTotalLiquidoComissoes(_vlrTotalLiquidoComissoes);
        setItems(_items);
    };

    const saveComissaoRepresentante = async () => {
        let _items = _.cloneDeep(items);
        _items = _items.map(_item => {
            if (!(_item.vlr_liquido && !isNaN(_item.vlr_liquido) && parseFloat(_item.vlr_liquido) > 0)) {
                _item.vlr_csll = (_item.vlr_csll) ? _item.vlr_csll : 0;
                _item.vlr_irrf = (_item.vlr_irrf) ? _item.vlr_irrf : 0;
                _item.vlr_iss  = (_item.vlr_iss)  ? _item.vlr_iss  : 0;
                _item.vlr_pis_cofins  = (_item.vlr_pis_cofins) ? _item.vlr_pis_cofins  : 0;
                _item.vlr_liquido = (_item.vlr_bruto_comissao - (_item.vlr_csll + _item.vlr_irrf + _item.vlr_iss + _item.vlr_pis_cofins));
            }
            return _item;
        });

        setIsLoadingSave(true);
        let payload = {
            id_empreendimento: enterprise.id_empreendimento,
            id_usuario: user.id,
            mes_competencia: `${selectedYear}-${selectedMonth}`,
            vendedores: _items
        }
        try {
                const service = new SalesComissionService();
                const { data } = await service.saveComissaoRepresentante(payload);
                console.log(data);
                addToast(`Ação realizada com sucesso!`, {
                    placement: 'bottom-right',
                    appearance: 'success',
                    autoDismiss: true
                });
                setIsLoadingSave(false);
                clearForm();
        }
        catch(error) {
            setIsLoadingSave(false);
            const { response: { status, data } } = error;
            switch (status) {
                case 406:
                addToast('Alguns campos não foram preenchidos corretamente.\nVerifique por favor!', {
                    placement: 'bottom-right',
                    appearance: 'warning',
                    autoDismiss: true
                });
                break;
                case 500:
                addToast(`Ocorreu um erro no servidor!\nRetorno da API: ${data}`, {
                    placement: 'bottom-right',
                    appearance: 'error',
                    autoDismiss: true
                });
                break;
                default:
                break;
            }
        }
    } 

    const clearForm = () => {
        setItems(null);
        setSelectedYear(null);
        setSelectedMonth(null);
        setVlrTotalBrutoComissoes(0);
        setVlrTotalCSLL(0);
        setVlrTotalIRRF(0);
        setVlrTotalPISCofins(0);
        setVlrTotalISS(0);
        setVlrTotalLiquidoComissoes(0);
    }

    return (
        <>
            <Container className='main-content-container px-6 pb-6'>
                <Row noGutters className='page-header py-4'>
                    <PageTitle
                        title='Comissão de Representantes' 
                        subtitle='Relatórios' 
                        className='text-sm-left'
                    />
                </Row>

                <Row>
                    <Col lg="12">
                        <Card small className='mb-3'>
                           <Form ref={formRef} >
                               <CardBody>
                                   <Row>
                                        <Col lg="2">
                                            <Select
                                                name='year'
                                                label='Selecione o Ano'
                                                data={years}
                                                noMargin
                                                onChange={(e) => setSelectedYear(e.target.value)}
                                            />
                                        </Col>
                                        <Col lg="2">
                                            <Select
                                                name='month'
                                                label='Selecione o Mês'
                                                data={months}
                                                noMargin
                                                onChange={(e) => setSelectedMonth(e.target.value)}
                                            />
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='d-flex ml-auto my-auto justify-content-end'>
                                            {isLoadingSearch && (
                                                <>
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                    &nbsp;
                                                    Aguarde, buscando informações...
                                                </>
                                            )}

                                            {!isLoadingSearch && (
                                                <Button theme="primary" size="md" onClick={getApuracaoComissaoRepresentantes}>
                                                    <i className="material-icons">search</i> Apurar Comissões
                                                </Button>
                                            )}
                                        </Col>
                                    </Row>
                               </CardBody>
                           </Form>
                        </Card>
                    </Col>  
                </Row>    
                
                <Row>
                     <Col lg="12">
                        <Card small className='mb-3'>
                            <Form ref={formRef}>
                                <CardBody>
                                    <table className='table table-bordered table-condensed table-hover mb-0'>
                                        <thead>
                                            <th>Representante</th>
                                            <th width="130" className='text-center'>Bruto</th>
                                            <th width="130" className='text-center'>CSLL</th>
                                            <th width="130" className='text-center'>IRRF</th>
                                            <th width="130" className='text-center'>PIS/COFINS</th>
                                            <th width="130" className='text-center'>ISS</th>
                                            <th width="130" className='text-center'>Liquido</th>
                                        </thead>
                                        <tbody>
                                            {items && items.map(item => (
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle' }}>{item.nme_vendedor}</td>
                                                    <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(item.vlr_bruto_comissao, 2, ',', '.')}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <NumberFormat 
                                                            className="text-right"
                                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                            value={item.vlr_csll}
                                                            defaultValue= {item.vlr_csll}
                                                            onValueChange={({floatValue}) => updateTaxValue(item.id_vendedor, 'vlr_csll', floatValue)}
                                                            customInput={FormInput}
                                                        />
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <NumberFormat 
                                                            className="text-right"
                                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                            value={item.vlr_irrf}
                                                            defaultValue= {item.vlr_irrf}
                                                            onValueChange={({floatValue}) => updateTaxValue(item.id_vendedor, 'vlr_irrf', floatValue)}
                                                            customInput={FormInput}
                                                        />
                                                    </td>
                                                    
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <NumberFormat 
                                                            className="text-right"
                                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                            value={item.vlr_pis_cofins}
                                                            defaultValue= {item.vlr_pis_cofins}
                                                            onValueChange={({floatValue}) => updateTaxValue(item.id_vendedor, 'vlr_pis_cofins', floatValue)}
                                                            customInput={FormInput}
                                                        />
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                        <NumberFormat 
                                                            className="text-right"
                                                            decimalScale={2} thousandSeparator="." decimalSeparator=","
                                                            allowEmptyFormatting isNumericString allowedDecimalSeparators={[',', '.']}
                                                            value={item.vlr_iss}
                                                            defaultValue= {item.vlr_iss}
                                                            onValueChange={({floatValue}) => updateTaxValue(item.id_vendedor, 'vlr_iss', floatValue)}
                                                            customInput={FormInput}
                                                        />
                                                    </td>
                                                    <td className='text-right' style={{ verticalAlign: 'middle' }}>R$ {numberFormat(item.vlr_liquido, 2, ',', '.')}</td>
                                                </tr>   
                                            ))}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th className="text-right">Totais</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalBrutoComissoes, 2, ',', '.')}</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalCSLL, 2, ',', '.')}</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalIRRF, 2, ',', '.')}</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalPISCofins, 2, ',', '.')}</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalISS, 2, ',', '.')}</th>
                                                <th className="text-right">R$ {numberFormat(vlrTotalLiquidoComissoes, 2, ',', '.')}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </CardBody>
                                <CardFooter className="text-right">
                                    {isLoadingSave && (
                                        <>
                                            <i className="fa fa-spinner fa-spin"></i>
                                            &nbsp;
                                            Aguarde, salvando informações...
                                        </>
                                    )}
                                    
                                    {!isLoadingSave && (
                                        <Button theme="accent" size="md" onClick={saveComissaoRepresentante}>
                                            <i className="material-icons">save</i> Salvar
                                        </Button>
                                    )}
                                </CardFooter>
                            </Form>
                        </Card>
                     </Col>
                </Row>
            </Container>
        </>
    )

}

export default PageView
